ocCommonProviders.directive("variablesDirective", ["api", "db", "login", "modal", "variablesService", "$uibModal", "enumerationService", "subscriptionModel", "growlHandlingService", "$timeout",
    function (api, db, login, modal, variablesService, $modal, enumerationService, subscriptionModel, growlHandlingService, $timeout) {
        "use strict";
        return {
            restrict: "C",
            scope: false,
            templateUrl: "/static/partials/variables.directive.html",
            link: function (scope, element, attrs, attachmentsController) {

                scope.saveVariable = function (variable) {
                    if (variable.id < 0) {
                        variablesService.setVariable(variable, function () {
                          scope.discardVariable();
                          growlHandlingService.success("Successfully created Variable");
                        });
                    } else {
                        var oldvar = subscriptionModel.getByID(subscriptionModel.get("variable").data, scope.editVariableId);
                        if (oldvar && oldvar.example && variable.example && variable.example.length > oldvar.example.length) {
                            modal.confirm({
                                message: "Are you sure you want to save variable used in Text Banner with longer example?",
                                ok: "Yes",
                                cancel: "No"
                            },
                                function () {
                                    variablesService.updateVariable(variable, function () {
                                      scope.discardVariable();
                                      growlHandlingService.success("Successfully updated Variable.");
                                    });
                                },
                                function () { }
                            );
                        } else {

                            variablesService.updateVariable(variable, function () {

                              scope.discardVariable();
                              growlHandlingService.success("Successfully updated Variable.");
                            });
                        }
                    }

                };

                scope.variableTransform = function (variable) {
                    return variablesService.variableTransform(variable).is_used_in;
                }

                scope.removeVariable = function (variable) {
                    if (variable.id > 0) {
                        modal.confirm({
                            message: "Are you sure you want to delete this Variable?",
                            ok: "Yes",
                            cancel: "No"
                        },
                            function () {
                                variablesService.removeVariable(variable, function () {
                                  scope.loadVariablesList();
                                  scope.discardVariable();
                                  growlHandlingService.success("Variable has been removed.");
                                });
                            },
                            function () { }
                        );
                    }
                };

                scope.countValue = function (variable) {
                    if (variable.list_value) {
                        return variable.list_value.length;
                    } else {
                        return 0;
                    }
                }

                scope.addEnumerationModal = function (variable) {
                    var ModalInstanceCtrl = ['$scope', '$uibModalInstance', 'variable',
                        function ($scope, $uibModalInstance, variable) {
                            $scope.variable = variable;
                            $scope.list_value = variable.list_value ? angular.copy(variable.list_value) : [];
                            $scope.value = '';
                            $scope.description = '';

                            $scope.ok = function () {
                                $uibModalInstance.close();
                            };

                            const _subscribeVariable = (msg) => {
                                if (msg.operation == "list_post" || msg.operation == "list_delete") {
                                    var variables = subscriptionModel.get('variable');
                                    var filtered = variables.data.filter((variable) => variable.id === msg.data.id)[0];
                                    $scope.list_value = filtered.list_value;
                                    $scope.$apply();
                                }
                            };
                            subscriptionModel.on('variable', _subscribeVariable)

                            $scope.removeValue = function (valueId) {
                                enumerationService.removeValue($scope.variable, valueId);
                            }

                            $scope.addValue = (form) => {
                                if (!$scope.value) return;
                                const validDate = (date, onSuccess) => {
                                    window.api.validDate({ value: date }, (resp) => {
                                        $timeout(function () {
                                            form.value.$setValidity("value", resp.valid);
                                        });
                                        if (resp.valid) {
                                            onSuccess();
                                        }
                                    });
                                }

                                const doRequest = () => enumerationService.addValue($scope.variable, $scope.value, $scope.description).then(function (data) {
                                    $scope.value = '';
                                    $scope.description = '';
                                    angular.element('#addEnumerationInput').focus();
                                });

                                if (variable.type === 'date') {
                                    validDate($scope.value, doRequest);
                                } else {
                                    doRequest();
                                }
                            };
                        }
                    ]


                    var modal = $modal.open({
                        templateUrl: "/static/partials/enumerationModal.html",
                        controller: ModalInstanceCtrl,
                        backdrop: "static",
                        resolve: {
                            variable: () => variable
                        }
                    });
                }
            }
        };
    }
]);
