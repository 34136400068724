ocCommonProviders.service("api", ["db","login","$q","modal", function(db, login, $q, modal) {
	"use strict";
    this.baseUrl = config.backendUrl;
    this.authenticationUrl = this.baseUrl + "/hello";
    this.authenticationAdminUrl = this.baseUrl + "/adminhello";
    this.test = function() {
        return "..";
    };

	this.getAuthenticatedUrl = _getAuthenticatedUrl;

    function _getAuthenticatedUrl() {
    	var ns = "login";
    	return config.backendUrl;
    }

    this.customers = {
    	getAll: function(ns, scope) {
    		var defer = $q.defer();
    		const onSuccess = function(data) {
          db.set("login", "loading", false);
          scope.login.customers = data;
          scope.$apply();
          defer.resolve();
        };
    		const onFailure = function(data) {
          modal.info(
            {
              message: "Loading customer list for the admin user failed. Please try login again",
              ok: "Ok",
              cancel: ""
            },
            function() {
              location.reload();
              defer.reject();
            },
            function() {}
          );
        };
    		window.api.customerGetAll(onSuccess, onFailure);
    		return defer.promise;
    	}
    };

    var that = this;
    this.projects = {
    	getAllForCustomer: function(ns, customerId) {
        var defer = $q.defer();
        db.set(ns, "loading", true);
        const onSuccess = function(data) {
          if(!db.get("login", "loggedInBoolean")) {
            db.set("login", "loggedInBoolean", !!window.model.auth);
          }
          db.set("login", "loading", false);
          defer.resolve();
        }
        const onFailure = function(data) {
          modal.info(
            {
              message: "Loading routines data failed. Please try login again, and if the problem still exists contact Posti helpdesk",
              ok: "Ok",
              cancel: ""
            },
            function() {
              location.reload();
            },
            function() {}
          );
          defer.reject();
        }
        window.api.projectGetAll(onSuccess, onFailure);
        return defer.promise;
      },
    };
}]);
