ocCommonProviders.directive("tagsPreviewDirective", ["tagsService", function (tagsService) {
    "use strict";
    return {
        restrict: "C",
        scope:{
            item:"<"
        },
        template: "<span class=\"tag label label-info\" ng-attr-style=\"background:{{tag.color}};color:{{tag.text}}\" ng-repeat=\"tag in tags\">{{tag.value}}</span><span class=\"tagsMask\" ng-if=\"tags.length>1\"></span>",
        link: function (scope, element, attrs) {
            _setData();
            scope.$on("selectTableElement", function (event, data) {
                _setData(data.tag);
            });

            scope.$watch("item",(newValue,oldValue)=>{
                void 0
                if(newValue && oldValue && (newValue.value!=oldValue.value)){
                    scope.tags[0].value=newValue.value;
                }

                if(newValue && oldValue && (newValue.color!=oldValue.color)){
                    scope.tags[0].color=newValue.color;
                }

                if(angular.isUndefined(newValue) && oldValue){
                    scope.tags=[];
                }

                if(angular.isUndefined(oldValue) && newValue){
                    scope.tags.push(newValue);
                }
            });

            function _setData(tagsFromEvent) {
                var currentElement = scope.$parent.attachementData || scope.$parent.selectedElement;

                          if (!tagsFromEvent) {
                    if(currentElement&& currentElement.tag){
                        scope.tags=currentElement.tag;
                    }else if(scope.item){
                        scope.tags=[scope.item];
                    }

                                   } else {
                    scope.tags = tagsFromEvent;
                }
                var tmp = [];
                if (scope.tags) {
                    for (var i = 0; i < scope.tags.length; i++) {
                        if (typeof scope.tags[i].valueOriginal == "undefined") {
                            tmp.push(scope.tags[i]);

                                                    }

                    }
                    scope.tags = tmp;
                    for (i = 0; i < scope.tags.length; i++) {
                        if (scope.tags[i].color !== null)
                            scope.tags[i].text = tagsService.fixTextcontrast(scope.tags[i].color);
                        else
                            scope.tags[i].text = tagsService.fixTextcontrast("rgba(159,201,235,1)");
                    }
                }

            }

        }
    };
}]);
