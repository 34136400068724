ocCommonProviders.directive("userRightsTable", [
  "flowService",
  "userManageService",
  "db",
  "modal",
  "resourceAccessService",
  "subscriptionModel",
  function(flowService,userManageService,db,modal,resourceAccessService,subscriptionModel) {
    "use strict";
    return {
      restrict: "C",
      replace: true,
      templateUrl: "/static/partials/userRightsTable.html",
      link: function(scope) {
        var customerId = window.api.customerid;
        scope.updateCategory = _updateCategory;
        scope.toggleHiddenCtg = _toggleHiddenCtg;
        scope.showHiddenCategories = false;
        scope.init = _init;
        scope.deletePermissionsCategory = _deletePermissionsCategory;
        subscriptionModel.on("project",_subscribeProject);
        scope.$on("$destroy", () =>subscriptionModel.off("project", _subscribeProject));

        function _subscribeProject(){
          _init();
        }

        if(window.api.customerid !== -1) _init();
        function _init() {
          userManageService.getUserGroupDict().then(userGroups => {
            let withDeleted = scope.showHiddenCategories === true ? 1 : 0;
            scope.userGroups = userGroups.group;
            userManageService.getGroupPermission(customerId).then(groupPermissions =>{
                    flowService.getFlows().then(flows => {
                      scope.VM = _getVM(userGroups.group,groupPermissions,flows);
                    });
              });
          });
        }

        function _getVM(userGroups, groupPermissions, projects) {
          let VM = [];
          let _userGroups = [];
          userGroups.forEach(ug => {
            if (ug != "power") { _userGroups.push({ name: ug});}
          });
          projects.forEach(project => {
            if (project.category.length>0) {

              project.category.forEach((category)=>{
                VM.push({name: category.value,project_id: project.id,flowName: project.name,userGroups: _setPermissions(project.id,category,_userGroups,groupPermissions)});
              });
          };
        });
          return VM;
        }

        function _setPermissions(projectId,category, userGroups, groupPermissions) {
          return userGroups.map((ug)=>{
            let hasPermission=groupPermissions.some(item => (
              item.permission === category.value &&
              item.project_id === projectId &&
              item.group_value === ug.name));
            return {name:ug.name,isActive:hasPermission}
          })
        }

        function _getProjectNameById(project_id, projects) {
          return projects.filter(project => project.id === project_id)[0].name;
        }

        function _toggleHiddenCtg(isVisible) {
          scope.showHiddenCategories = isVisible ? false : true;
          _init();
        }

        function _updateCategory(userGroup, permission, project_id) {
          const requestData = {
            group_value: userGroup.name,
            permission: permission
          };

          if (userGroup.isActive) {
            userManageService.putPermissionGroup(customerId, project_id, requestData)

          } else {
            userManageService.deletePermissionGroup(customerId, project_id, requestData)
          }
        }

        function _deletePermissionsCategory(vm) {
          modal.confirm(
            {
              message: `Are you sure that you want to delete category '${
                vm.name
              }' in flow '${vm.flowName}' ?`,
              ok: "Yes",
              cancel: "No"
            },
            function() {
              const requestData = { permission: vm.name };
              userManageService.deletePermissionsCategory(
                  customerId,
                  vm.project_id,
                  vm.flowName,
                  requestData
                )
                .then(() => {
                  _init();
                  resourceAccessService.loadAccessList(customerId);
                });
            }
          );
        }
      }
    };
  }
]);
