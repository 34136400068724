ocCommonProviders.directive("groupedConnectionsDirective",["Connection","db","resourceAccessService", function (Connection,db,resourceAccess) {
    return {
    restrict: "C",
    scope:{  connections: "=" , info: "="},
    templateUrl:"/static/partials/groupedConnection.html",
    controller:"groupConectionsController",
    link: function(scope, element, attrs){
        scope.activePartial=attrs.partial;

            scope.connectionsList = scope.connections;
        scope.isCtgEditing=false;
        scope.findEditingConnection(scope.connectionsList);

        scope.setFiltering = function(setFilters){
            scope.$parent.$parent.$parent.attachementSearch="";
            Connection.setFiltering(scope.$parent.filter,setFilters);
        };

        scope.getResourceAccess=(res)=>resourceAccess.getAccess(res);
        scope.restoreValue = function(search,filter){
            scope.$parent.$parent.$parent.attachementSearch=search;
            scope.$parent.$parent.$parent.filter = filter ;
        };

        scope.filterDefault = function(value){
            var statusNames = {"run":"running","edit":"editing","stop":"stopped","pending":"pending","expired":"expired","waiting":"waiting","toEdit":"edit","toRun":"run","toStop":"stop","inProduction":"production","deleted": "deleted","approve":"approve"};

            if(value.status=== statusNames.run)
                return true;
            if(value.status=== statusNames.stop)
                return true;
            if( value.status=== statusNames.pending)
                return true;
            if(value.status=== statusNames.edit)
                return true;
            if(value.status=== statusNames.expired)
                return false;
            if(value.status=== statusNames.deleted)
                return false;
            if(value.status=== statusNames.waiting)
                return true;
            if(value.status=== statusNames.approve)
                return true;
        };

        scope.filterStatuses = function(value,index,filter,add){
            if(typeof scope.loading ==="undefined") scope.loading = [];
                return Connection.filterStatuses(scope.loading[value.id],scope.$parent.statusNames,value,scope.$parent.filter);
        };

        scope.regexSearchTags = function(tags,reg){
            var pattern = new RegExp(reg+"", "i");
            for(var i=0; i<tags.length;i++){
                if(pattern.test(tags[i].value))
                    return true;
            }
            return false;
        };
        scope.concatTags = function(tags){
         var str = "";
            for(var i=0; i<tags.length;i++){
                str += " " + tags[i].value;
            }
            return str;
        };

        scope.searchInConnections = function (value){
            if(value.id < 0 )
                return true;
            if(value.status==="editing" && value.edited_by === db.get("login","usernameString"))
                return true;
            if(value.status==="editing" && value.edited_by !== db.get("login","usernameString") && scope.$parent.$parent.$parent.$parent.attachementSearch.length<0)
                return false;

            if(((new RegExp(buildSearchPattern(RegExp.escape(scope.$parent.$parent.$parent.$parent.attachementSearch)), "ig")).test(value.rule_name + " " + value.content_title +" " + value.content_name +" " + value.content_description + " "+ value.title +  " " + value.description + scope.concatTags(value.tag)))){
                return true;
            }

            return false;

            function buildSearchPattern(inputSearchText){
                var patternText="";
                inputSearchText.split(/\s/g).forEach(
                    function preparePattern(value){
                        patternText+="(?=.*" + value  + ".*)";
                    }
                );
                patternText+= ".+";
                return patternText;
            }
        };
        RegExp.escape = (function(text) {
            var specials = ['$', '^', '/', '.', '*', '+', '?', '|', '(', ')', '[', ']', '{', '}', '\\'];
            return text.replace(new RegExp('(\\' + specials.join('|\\') + ')', 'g'), '\\$1');
        });

    }
};

}]);
