ocCommonProviders.provider("login", ["validationErrors",function(validationErrors) {
  "use strict";
  var isLoggedInBoolean = false,
    backendUrl = "";

  var authenticationHeaderString = "",
    httpObject = null
  window.login = {
    setSessionParameters: function(token, expire) {
      this.sessionToken = token;
      this.sessionExpire = expire;
      localStorage.setItem("authentication_token", token);
    },
    formatDate: function(dateString) {
      if (!dateString || dateString === "") return "";
      var d = moment(dateString, "YYYY-MM-DDZZ");
      if (d.isValid() === true) return d.format("YYYY-MM-DD");
      else {
        d = moment(dateString);
        if (d.isValid() === true) return d.format("YYYY-MM-DD");
      }
      return "";
    },
    isAuthenticated: function() {
      return isLoggedInBoolean;
    },
    getAuthenticationHeaderString: function() {
      return authenticationHeaderString;
    },
    setHttp: function(httpObjectParam) {
      httpObject = httpObjectParam;
      return true;
    },
    logout: function() {
      isLoggedInBoolean = false;
      authenticationHeaderString = "";
    }
  };
  return {
    $get: function() {
      return window.login;
    }
  };
}]);
