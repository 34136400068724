ocCommonProviders.factory("regionFactory", function() {
  return {
    set ScaleX(value) {
      this.scaleX = value;
    },
    set ScaleY(value) {
      this.scaleY = value;
    },
    set Shape(value) {
      this.shape = value;
    },
    get Instance() {
      return new fabric.Rect({
          left: this.shape.x+1,
          top: this.shape.y*this.scaleX,
          width: this.shape.width,
          height: this.shape.height,
          opacity: 1.0,
          scaleX: this.scaleX,
          scaleY: this.scaleY,
          fill: ""
        });
      }
  };
});
