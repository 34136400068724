const variableModalConfig = {
  thousandSeparators: [
    ["No change", "none"],
    ["comma (,)", "comma"],
    ["blankSpace ( )", "blankspace"],
    ["apostrophe (')", "apostrophe"]
  ],
  decimalSeparators: [
    ["dot (.)", "dot"],
    ["comma (,)", "comma"]
  ],
  decimalPlaces: [
    ["0", "0"],
    ["1", "1"],
    ["2", "2"],
    ["3", "3"],
    ["4", "4"],
    ["5", "5"]
  ],
  capitalization: [
    ["No change", "none"],
    ["Change all letters to lowercase", "lower"],
    ["Change first letters to uppercase", "firstletter"],
    ["Capitalize all leters", "upper"]
  ],
  removeWhiteSpacesFromThe: [
    ["No change", "none"],
    ["From beginning", "beginning"],
    ["From end", "end"],
    ["From both", "both"]
  ],
  showSign: [
    ["Before variable", "before"], 
    ["After variable", "after"]
  ],
  paddingDirection: [
    ["Left", "beginning"], 
    ["Right", "end"]
  ]
};

ocCommonProviders.constant("variableModalConfig", variableModalConfig);

