ocCommonProviders.directive("administrationListFlow", ['pagesListService', 'db', 'login', 'modal', 'api', function (pagesListService, db, login, modal, api) {
    "use strict";
    return {
        restrict: "C",
        templateUrl: "/static/partials/administrationListFlowElement.html",
        link: function (scope, element, attrs) {
            scope.showdragdrop = false;
            var afterIndex, beforeIndex;
            var swappedElement, swappedElementId;
            var draggedElement;

            function changePriority(draggedElement, swappedElement) {
              const data = {
                "page1_id": draggedElement.id,
                "page1_order": draggedElement.page_order,
                "page2_id": swappedElement.id,
                "page2_order": swappedElement.page_order
              }
              const onSuccess = function (data) {
                if (data.changed) {
                  pagesListService.loadPages(scope.selectedFlow).then(scope.applyPagesData);
                }
              };
              const onFailure = function(data) {
                pagesListService.loadPages(scope.selectedFlow).then(scope.applyPagesData);
              }
              window.api.pageOrder(data, onSuccess, onFailure);
            }

            function include(arr, obj) {
                return (arr.indexOf(obj));
            }

            scope.changeOrder = function () {
                scope.$parent.$parent.blockingLayerHigher = true;
                _listManage();
                scope.showdragdrop = true;
            }

            scope.exitOrder = function () {
                scope.$parent.$parent.blockingLayerHigher = false;
                _listManage();
                scope.showdragdrop = false;
            }

            function findPage(id) {
                for (var i = 0; i < scope.pages.length; i++) {
                    if (scope.pages[i].id === id) {
                        return scope.pages[i];
                    }
                }
            }

            function _listManage() {
                var listElement = $("#pageScroll .mask");
                if (!scope.showdragdrop) {
                    listElement.sortable({
                        placeholder: "basicInfo-placeholder",
                        axis: "y",
                        cursor: "move",
                        delay: 150,
                        scroll: true,
                        start: function(event, ui) {
                            var start = listElement.sortable("toArray", {
                                key: "value"
                            });
                            beforeIndex = include(start, ui.item.context.id);
                        },
                        stop: function(event, ui) {
                            var stop = listElement.sortable("toArray", {
                                key: "value"
                            });
                            afterIndex = include(stop, ui.item.context.id);

                            if (beforeIndex > afterIndex) {
                                swappedElementId = ui.item.next().attr("id");
                            }

                            if (afterIndex > beforeIndex) {
                                swappedElementId = ui.item.prev().attr("id");
                            }

                            if (afterIndex !== beforeIndex) {
                                let swappedPage=findPage(parseInt(swappedElementId));
                                changePriority(findPage(parseInt(ui.item.attr("id"))),swappedPage);
                            }
                        }
                    });
                } else {
                    listElement.sortable("destroy");
                }
            }
        }
    };
}]);
