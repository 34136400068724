module.controller("appController", ["$scope", "login", "db", "api", "modal", "serverTime", "$route", "$rootScope",
    "userService", "$uibModal", "$timeout", "$location","menuService","growlHandlingService","subscriptionModel","resourceAccessService","navigationContextService",
    function($scope, login, db, api, modal, serverTime, $route, $rootScope,
        userService, $uibModal, $timeout, $location,menuService,growlHandlingService,subscriptionModel,resourceAccess,navigationContext) {

        navigationContext.clearRouteParams();

              var ns = "login";
        localStorage.setItem("customModalIsOpened",false);
        if (!db.isNamespace(ns)) {
            db.createNamespace(ns);

            var usernameString = !!window.model.auth ? "default" : "";

            db.set(ns, "usernameString", usernameString);
            db.set(ns, "loggedInBoolean", !!window.model.auth);
            db.set(ns, "errors", "");
            db.set(ns, "loading", false);
            db.set(ns, "tabsArray", [{
                "title": "opuscapita.common.header.productName",
                "content": "#/"
            }]);
        }

        $scope.config = config;
        $scope.config.isAdmin = false;
        $scope.getAuthStatus = function() { 
            return !$scope.config.isAdmin && db.get("login", "loggedInBoolean");
        };

        $scope.update = function() {
            $scope.tabsArray = db.get(ns, "tabsArray");
        };

        $scope.helpers = {
            toUrl: function(url) {
                window.open(url.toLowerCase());
            }
        };

        $scope.about = function() {
            $uibModal.open({
                templateUrl: "/static/partials/about.html",
                controller: "aboutController",
                backdrop: "static"
            });
        };

        $scope.login = {
            adminCustomerIdInt: 0,
            customers: [],
            setAdminCustomerId: function(redirectUrl, hasAccess) {
              const onSuccess = function(data) {
                if (!data.customer.active) {
                  modal.info({
                      message: "You are working in context of inactive Customer.",
                      ok: "Ok",
                      cancel: ""
                    },
                    function() {},
                    function() {}
                  );
                }
                userService.setUser(data.customer); 
                $scope.ual = userService.getUAL();
                db.set(ns, "customerName", "Admin");
                db.set(ns, "enclosure_enabled", data.customer.enclosure_enabled);
                $rootScope.$broadcast("setUserAccess");

                void 0
                if (redirectUrl && hasAccess) {
                  $rootScope.$broadcast("CHANGE_ADDRESS", redirectUrl);
                } else {
                  $rootScope.$broadcast("CHANGE_ADDRESS", "Administration");
                }
                db.set("login", "loggedInBoolean", !!window.model.auth);
                var name = $scope.getCustomerNameById($scope.login.customers, subscriptionModel.auth.customerid);
                $scope.username = db.get(ns, "Admin");
                $scope.username = "Admin(" + db.get(ns, "adminUserName") + ") : " + name;
                $scope.config.isAdmin = false;
                api.projects.getAllForCustomer("tab1", subscriptionModel.auth.customerid); 

              };
              const onFailure = function(data, status) {
                subscriptionModel.trigger("api.error", data);
              };
              return window.api.customerSelect(subscriptionModel.auth.customerid, onSuccess, onFailure);

            }
        };
        $scope.getCustomerNameById = function(customers, id) {
            for (var i = 0; i < customers.length; i++) {
                if (customers[i].id == id)
                    return customers[i].name + "";
            }

            return subscriptionModel.get("customer").data.filter((c)=>c.id===id)[0].name;

        };
        $scope.update();

        $scope.$watch(
            function() {
                return db.get(ns, "loading");
            },
            function() {
                $scope.loading = db.get(ns, "loading");
            }
        );

        var _isOpenProjects = function() {
            var projects = db.get("tab1", "projects");
            for (var project in projects) {
                if (Object.size(projects[project].files) > 1) return true;
            }
            return false;
        };

        $scope.changePassword=function(){
            $location.path('/change-password');
        }

        $scope.logoutFunction = function() {
            if (_isOpenProjects()) {
                modal.confirm({
                        message: "You have pending attachments in preview. If you logout now, all pending attachments in preview state will be removed from projects and they are not taken into production. You can return to Connect and solve the pending attachments before you logout.",
                        ok: "Logout and remove pending attachments",
                        cancel: "Return to solve issues"
                    },
                    function() {
                        _logout();
                    },
                    function() {}
                );
            } else {
                _logout();
            }
        };

       var _logout = function() {
            login.logout();
            db.set(ns, "passwordString", "");
            window.model.auth = null;
            location.reload();
        };

        var embededLinks = [{
            "title": "Attachments",
            "link": "/attachments",
            "access_level": 1,
            "access_admin": 0
        }, {
            "title": "Target Lists",
            "link": "/target-lists",
            "access_level": 1,
            "access_admin": 0
        }, {
            "title": "Enclosures",
            "link": "/enclosures",
            "access_level": 3,
            "access_admin": 0
        }, {
            "title": "Area",
            "link": "/dca",
            "access_level": 3,
            "access_admin": 0
        }, {
            "title": "Image",
            "link": "/banners",
            "access_level": 3,
            "access_admin": 0
        }, {
            "title": "Text",
            "link": "/text-boxes",
            "access_level": 3,
            "access_admin": 0
        }, {
            "title": "Maintenance",
            "link": "/maintenance",
            "access_level": 1,
            "access_admin": 1
        }, {
            "title": "Administration",
            "link": "/administration",
            "access_level": 1,
            "access_admin": 1
        }, {
            "title": "Connections",
            "link": "/connections",
            "access_level": 1,
            "access_admin": 0
        }, {
            "title": "Rules",
            "link": "/rules",
            "access_level": 1,
            "access_admin": 0
        }, {
            "title": "Attachments",
            "link": "/attachments",
            "access_level": 1,
            "access_admin": 0
        }, {
            "title": "Statistics",
            "link": "/statistics",
            "access_level": 1,
            "access_admin": 0
        }, {
            "title": "Preview",
            "link": "/preview",
            "access_level": 1,
            "access_admin": 0
        }, {
            "title": "Log",
            "link": "/history",
            "access_level": 1,
            "access_admin": 0
        }, {
            "title": "Configuration",
            "link": "/configuration",
            "access_level": 1,
            "access_admin": 0
        },{
            "title": "Users",
            "link": "/users",
            "access_level": 3,
            "access_admin": 1
        }, {
            "title": "Help",
            "link": "/help",
            "access_level": 1,
            "access_admin": 0
        }]

        function _findURL(url) {
            for (var idx in embededLinks) {
                var link = embededLinks[idx];
                if (link.link === url) {
                    return link.title;
                }
            }
            return false;
        }

        function _checkUserAccess(url, user) {
            for (var idx in embededLinks) {
                var link = embededLinks[idx];
                if (link.link === url) {
                    if (!user.admin_user) {
                        if (user.application_level === "premium" && !link.access_admin) {
                            return true;
                        } else {
                            if (link.access_level === 1 && !link.access_admin) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    } else {
                        if (user.application_level === "premium") {
                            return true;
                        } else {
                            if (link.access_level === 1) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    }
                }
            }
            return false;
        }

        $scope.submitLoginFunction = function(usernameString,passwordString,onSuccess,onError) {

            db.set(ns, "errors", "");
            db.set(ns, "loading", true);
            db.set(ns, "usernameString", usernameString);

            $scope.onSuccessFunction = function(data) {
                if(data.group.some(item => item === "system")) {
                  const msg = "System user cannot log in to the application via UI.";
                  modal.info({
                    message: msg,
                    ok: "Ok",
                    cancel: ""
                  });
                  onError(msg);
                  return;
                }
                onSuccess(data);
                if (data.current_server_time_utc) {
                    serverTime.setServerTime(data.current_server_time_utc);
                }
                login.setSessionParameters(data.authentication_token, data.authentication_token_expires);
                window.config['count'] = 0;
                window.onerror = function(msg, url, lineno, columnno, error) {
                  let img = document.getElementById('posti-track');
                  if(!img){
                    img = new Image();
                    img.id = 'posti-track';
                    img.style.display = 'none';
                    document.body.appendChild(img);
                  }
                  window.config.count += 1;
                  const err = Base64.encodeURI(error.stack);
                  img.src = `/c.gif?t=${data.authentication_token}&m=${err}&l=error&c=${window.config.count}`;
                  return false;
                }
                if (!data.admin_user) {
                    db.set(ns, "enclosure_enabled", data.enclosure_enabled);
                    userService.setUser(data);
                    $scope.ual = userService.getUAL();
                    $rootScope.$broadcast("setUserAccess", "");
                    db.set(ns, "customerName", data.firstname + " " + data.lastname +" : "+data.customer_name);
                    $scope.username = db.get(ns, "customerName");
                    var url = _findURL($location.path());
                    var checkAccess = _checkUserAccess($location.path(), data);
                    var params=$route.current.params;
                    resourceAccess.loadAccessList(data.customerid);

                    if (url && checkAccess) {
                        $rootScope.$broadcast("CHANGE_ADDRESS", url);
                    } else {
                        if(!Object.keys(params).length>0){
                            $rootScope.$broadcast("CHANGE_ADDRESS", "Connections");
                        }else{
                            $rootScope.$broadcast("CHANGE_ADDRESS", {name:"Connections",params:params});
                        }


                    }
                    db.set("login", "loggedInBoolean", !!window.model.auth);
                    $scope.$apply();
                } else {
                    db.set(ns, "enclosure_enabled",true);
                    userService.setAdmin(data);
                    $scope.ual = userService.getUAL();
                    $scope.config.isAdmin = true;
                    db.set(ns, "adminUserName", data.firstname + " " + data.lastname);

                    api.customers.getAll("login", $scope).then(function() {
                        $scope.login.customers.sort(function(a, b) {
                            return b.id - a.id;
                        });
                        $rootScope.$broadcast("CHANGE_ADDRESS", "Administration");
                        if ($scope.login.customers.length > 0) {
                            const a = $scope.login.customers;
                            if(a.filter(e => e.id == data.customerid)[0]) {
                              subscriptionModel.auth.customerid = data.customerid;
                            } else {
                              subscriptionModel.auth.customerid = $scope.login.customers[0].id;
                            }
                            var tmpUser = {
                              "application_level" : $scope.login.customers[0].application_level,
                              "admin_user" : true
                            }
                            var url = _findURL($location.path());
                            var checkAccess = _checkUserAccess($location.path(), tmpUser);
                            $scope.login.setAdminCustomerId(url, checkAccess);
                        } else {
                            $rootScope.$broadcast("setUserAccess", "");
                            db.set(ns, "customerName", "Admin");
                            db.set("login", "loggedInBoolean", !!window.model.auth);
                            $scope.username = db.get(ns, "Admin");
                            $scope.username = "Admin(" + db.get(ns, "adminUserName") + ") : ";
                            $scope.config.isAdmin = false;
                            $timeout(function() {
                                $rootScope.$emit("LOADOFF");
                            }, 10);
                        }
                    });
                }
                db.set(ns, "passwordString", "");
                menuService.showUserGuideFirtsTime($scope);
            };

            $scope.onFailureFunction = function(err) {

                db.set(ns, "loading", false);
                db.set(ns, "errors", "Authentication failed");
                db.set(ns, "loggedInBoolean", !!window.model.auth);
                const resp = err.response.data;
                if (resp.msg) {
                  onError(resp.msg)
                }
            };
            window.api.hello(usernameString, passwordString, $scope.onSuccessFunction, $scope.onFailureFunction);
        };

        if (login.isAuthenticated()) _logout();

        $scope.$on("LOAD", function() {
            $scope.loadIndicator = true;
        });
        $scope.$on("NOTLOAD", function() {
            $scope.loadIndicator = false;
        });

    }
]);
