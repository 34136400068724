ocCommonProviders.directive("rulesPreviewDirective", function() {
        "use strict";
        return {
            restrict: "C",
            scope: false,
            template: '<div class="borderGrey scrollList scrollBar" style=\'width:375px\' data-height=\'200\'>' +
                '<div class="mask">' +
                '<div class="ruleTargetElement" ng-class="{ \'highlightTable\' : $index%2==0 ,\'selected\' : rule.id == activeConnectionObj.rule_id }" ng-repeat="rule in rules" >' +
                '<div class="title" ng-click="selectRule(rule)">{{rule.name}}</div>' +
                '</div>{{noRulesSelected}}' +
                '</div>',
            link: function(scope) {
                scope.loadRules().then(function(data) {
                    if(scope.activeConnectionObj){
                        scope.selectRuleById(data);
                    }
                });
            }
        }; 
    });
