ocCommonProviders.service("treeViewService", function() {
    var _state={list:[]};
    this.setState=_setState;
    this.getState=_getState;

    function _setState(list){
        _state.list=list
    }

    function _getState(){
        return _state;
    }


    });