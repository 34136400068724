ocCommonProviders.directive("imageSliderDirective", ["api", "db", "login", "modal", "userService", "$compile", "pagesListService", "attachmentTargetList",
    function(api, db, login, modal, userService, $compile, pagesListService, attachmentTargetList) {
        "use strict";
        return {
            restrict: "C",
            scope: false,
            templateUrl: "/static/partials/image.html",
            link: function(scope, element, attrs, attachmentsController) {
                scope.image = {};
                scope.display = true;
                scope.defaultDpi = 300;
                scope.minDpi = 200;
                scope.maxDpi = 600;
                scope.maxH = 297;
                scope.maxW = 210;
                scope.settings = null;
                scope.precision = 100;
                var width;
                var height;
                var dpi;




                function pixelToMM(pixel, dpi) {
                    return (pixel * 25.4) / dpi;
                }

                function milimeterToPixel(mm, dpi) {
                    return (mm * dpi) / 25.4;
                }

                function pixelToDPI(px, mm) {
                    return px * 25.4 / mm;
                }

                function countRatio(w, h) {
                    return w / h;
                }

                function getHFromRatio(pxW) {
                    return Math.floor(pxW / scope.image.ratio * scope.precision) / scope.precision;
                }

                function getWFromRatio(pxH) {
                    return Math.floor(scope.image.ratio * pxH * scope.precision) / scope.precision;
                }

                function getMaxW(dpi, pxW) {
                    var result = pixelToMM(pxW, dpi);
                    if (result > scope.maxW)
                        return scope.maxW;
                    else {
                        return result;
                    }

                }

                function getMaxH(dpi, pxH) {
                    var result = pixelToMM(pxH, dpi);
                    if (result > scope.maxH)
                        return scope.maxH;
                    else {
                        return result;
                    }
                }

                function validateData() {
                    var status = true;
                    if (scope.image.dpi < scope.minDpi || scope.image.dpi > scope.maxDpi) {
                        scope.image.validD = false;
                        status = false;
                    } else {
                        scope.image.validD = true;
                    }

                    if (scope.image.width < Math.floor(pixelToMM(scope.image.pxW, scope.maxDpi) * scope.precision) / scope.precision || scope.image.width > Math.floor(pixelToMM(scope.image.pxW, scope.minDpi) * scope.precision) / scope.precision) {
                        status = false;
                        scope.image.validW = false;
                    } else {
                        scope.image.validW = true;
                    }

                    if (scope.image.height < Math.floor(pixelToMM(scope.image.pxH, scope.maxDpi) * scope.precision) / scope.precision || scope.image.height > Math.floor(pixelToMM(scope.image.pxH, scope.minDpi) * scope.precision) / scope.precision) {
                        status = false;
                        scope.image.validH = false;
                    } else {
                        scope.image.validH = true;
                    }

                    return status;
                }

                function updateParent(image) {
                    var target = scope.$parent.fileData.settings;
                    if (validateData()) {
                        target.dpi = scope.image.dpi;
                        target.dpi_detected = false;
                        target.validity = true;
                        target.new_width = scope.image.width;
                        target.new_height = scope.image.height;
                    } else {
                        target.validity = false;
                    }
                }

                scope.$watch("$parent.fileData", function(a, b) {
                    if (typeof a !== "undefined" &&
                        typeof a.settings != "undefined" &&
                        typeof a.settings.px_h !== "undefined" &&
                        ((typeof a.settings.dpi_set !== "undefined" && a.settings.dpi_set !== true) ||
                            (typeof a.settings.dpi_set == "undefined" && a.settings.dpi < 200)
                        ) || ( a.isNew && a.settings.dpi_detected === false && typeof a.settings.dpi_set == "undefined")) {
                        var w, h, r, d;
                        w = a.settings.px_w;
                        h = a.settings.px_h;
                        r = countRatio(w, h);
                        d = a.settings.dpi;
                        if (d < scope.minDpi) {
                            d = scope.defaultDpi;
                        }
                        if (d > scope.maxDpi) {
                            d = scope.maxDpi;
                        }

                        scope.image = {
                            width: Math.floor(pixelToMM(w, d) * scope.precision) / scope.precision,
                            height: Math.floor(pixelToMM(h, d) * scope.precision) / scope.precision,
                            ratio: r,
                            dpi: d,
                            pxW: a.settings.px_w,
                            pxH: a.settings.px_h,
                            validW: true,
                            validH: true,
                            validD: true
                        };
                        scope.minH = Math.floor(pixelToMM(scope.image.pxH, scope.maxDpi) * scope.precision) / scope.precision;
                        scope.maxH = Math.floor(pixelToMM(scope.image.pxH, scope.minDpi) * scope.precision) / scope.precision;
                        scope.minW = Math.floor(pixelToMM(scope.image.pxW, scope.maxDpi) * scope.precision) / scope.precision;
                        scope.maxW = Math.floor(pixelToMM(scope.image.pxW, scope.minDpi) * scope.precision) / scope.precision;
                        init();
                        updateParent();
                        scope.display = true;
                        scope.$parent.resize();
                    } else {
                        scope.display = false;
                    }

                });

                function setHeight(h) {
                    height.slider({
                        value: h
                    });
                    scope.image.height = Math.floor(h * scope.precision) / scope.precision;
                    scope.image.width = getWFromRatio(h);
                    scope.image.dpi = Math.floor(pixelToDPI(scope.image.pxH, h));
                    width.slider("value", getWFromRatio(h));
                    dpi.slider("value", pixelToDPI(scope.image.pxH, h));
                }

                function setWidth(w) {
                    width.slider({
                        value: w
                    });
                    scope.image.width = Math.floor(w * scope.precision) / scope.precision;
                    scope.image.height = getHFromRatio(w);
                    scope.image.dpi = Math.floor(pixelToDPI(scope.image.pxW, w));
                    height.slider("value", getHFromRatio(w));
                    dpi.slider("value", pixelToDPI(scope.image.pxW, w));
                }

                function setDpi(dpiVal) {
                    scope.image.dpi = dpiVal;
                    dpi.slider("value", dpiVal);
                    width.slider({
                        value: pixelToMM(scope.image.pxW, dpiVal)
                    });
                    height.slider({
                        value: pixelToMM(scope.image.pxH, dpiVal)
                    });
                    scope.image.width = Math.floor(pixelToMM(scope.image.pxW, dpiVal) * scope.precision) / scope.precision;
                    scope.image.height = Math.floor(pixelToMM(scope.image.pxH, dpiVal) * scope.precision) / scope.precision;
                }

                function init() {

                                       dpi = $(".dpiSlider").slider({
                        min: scope.minDpi,
                        max: scope.maxDpi,
                        value: scope.image.dpi,
                        step: 0.1
                    });
                    width = $(".widthSlider").slider({
                        min: pixelToMM(scope.image.pxW, scope.maxDpi),
                        max: pixelToMM(scope.image.pxW, scope.minDpi),
                        step: 0.01,
                        value: scope.image.width
                    });
                    height = $(".heightSlider").slider({
                        min: pixelToMM(scope.image.pxH, scope.maxDpi),
                        max: pixelToMM(scope.image.pxH, scope.minDpi),
                        step: 0.01,
                        value: scope.image.height
                    });

                    dpi.on("slide", function(event, ui) {
                        setDpi(ui.value);
                        updateParent();
                        scope.$digest();
                    });

                    width.on("slide", function(event, ui) {
                        setWidth(ui.value);
                        updateParent();
                        scope.$digest();
                    });

                    height.on("slide", function(event, ui) {
                        setHeight(ui.value);
                        updateParent();
                        scope.$digest();
                    });
                }

                scope.imageWidthChange = function(val) {
                    setWidth(val);
                    updateParent();
                };
                scope.imageHeightChange = function(val) {
                    setHeight(val);
                    updateParent(); 
                };
                scope.imageDPIChange = function(val) {
                    setDpi(val);
                    updateParent();
                };

                init();
                return true;
            }
        };
    }
]);
