ocCommonProviders.service("userManageUtilsService", [
  "inputSearchService",
  "$filter",
  function(inputSearch, $filter) {
    this.initSearch = _initSearch;
    function _initSearch() {
      inputSearch.settings({
        basicFilters: [
          {
            name: "usersTextFilter",
            type: "input",
            state:"",
            actionHandler: function(){
              return inputSearch.getCollectionOrginal().filter(user => {
                var searchPattern = this.state.toLowerCase();
                var usernameIndexSearch = user["username"]
                  .toLowerCase()
                  .indexOf(searchPattern);
                var firstNameIndexSearch = user["firstname"]
                  .toLowerCase()
                  .indexOf(searchPattern);
                var emailIndexSearch = user["email"]
                  .toLowerCase()
                  .indexOf(searchPattern);
                if (
                  usernameIndexSearch != -1 ||
                  firstNameIndexSearch != -1 ||
                  emailIndexSearch != -1
                ) {
                  return true;
                }
                return false;
              });
            }
          },
          {
            name:"statusActive",
            type:"selection",
            filters:[
              {
                name: "active",
                label: "Active",
                type: "checkbox",
                state:true,
              },
              {
                name: "inactive",
                label: "Inactive",
                type: "checkbox",
                state:true,
              }
          ],
          actionHandler:function(){
            return inputSearch.searchSettings.collectionState.filter((user)=>{
              return (user.active && this.filters[0].state) || (!user.active && this.filters[1].state);
            });
          }
        }
        ],
        additionalFilters: [
          {
            name: "createdAt",
            label: "Created At",
            type: "group",
            filters: [
              {
                name: "createdAtFrom",
                label: "from",
                display: "horizontal",
                type: "data",
                state:""
              },
              {
                name: "createdAt",
                label: "to",
                display: "horizontal",
                type: "data",
                state:""
              }
            ],
            actionHandler: function(){
              return inputSearch.searchSettings.collectionState.filter((user)=>{
                 var createdAt=new Date(user.created_at).getTime();
                 var from=new Date(this.filters[0].state).getTime();
                 var to=new Date(this.filters[1].state).getTime()+86400000;
                 if(!isNaN(from) && isNaN(to)){
                  return (createdAt>=from)
                 }else if(isNaN(from) && !isNaN(to)){
                  return (createdAt<=to);
                 }
                 else if(!isNaN(from) && (!isNaN(to))){
                  return (createdAt>=from) && (createdAt<=to);
                 }else{
                   return true;
                 }
              });
            },
          },
          {
            name: "modifedAt",
            label: "Modified At",
            type: "group",
            filters: [
              {
                name: "createdAtFrom",
                label: "from",
                display: "horizontal",
                type: "data",
                state:""
              },
              {
                name: "createdAtTo",
                label: "to",
                display: "horizontal",
                type: "data",
                state:""
              }
            ],
            actionHandler: function(){
                return inputSearch.searchSettings.collectionState.filter((user)=>{
                  var modified_at=new Date(user.modified_at).getTime();
                  var from=new Date(this.filters[0].state).getTime();
                  var to=new Date(this.filters[1].state).getTime()+86400000;

                                    if(!isNaN(from) && isNaN(to)){
                   return (modified_at>=from)
                  }else if(isNaN(from) && !isNaN(to)){
                   return (modified_at<=to);
                  }
                  else if(!isNaN(from) && (!isNaN(to))){
                   return (modified_at>=from) && (modified_at<=to);
                  }else{
                    return true;
                  }
               });
            },
          }
        ]
      });
    }
  }
]);
