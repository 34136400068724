module.controller("groupConectionsController", [
  "$scope",
  "$timeout",
  "$q",
  "login",
  "db",
  "modal",
  "api",
  "attachmentTargetList",
  "serverTime",
  "$anchorScroll",
  "$location",
  "errorHandlingService",
  "$window",
  "$document",
  "userService",
  "$rootScope",
  "$uibModal",
  "pagesListService",
  "bannerList",
  "flowService",
  "Connection",
  "groupService",
  "utilityService",
  "rulesService",
  "textBoxService",
  "textBoxDrawService",
  "growlHandlingService",
  "subscriptionModel",
  "resourceService",
  "navigationContextService",
  function (
    $scope,
    $timeout,
    $q,
    login,
    db,
    modal,
    api,
    attachmentTargetList,
    serverTime,
    $anchorScroll,
    $location,
    errorHandling,
    $window,
    $document,
    userService,
    $rootScope,
    $uibModal,
    pagesListService,
    bannerList,
    flowService,
    Connection,
    groupService,
    utilityService,
    rulesService,
    textBoxService,
    textBoxDrawService,
    growlHandlingService,
    subscriptionModel,
    Resource,
    NavigationContext
  ) {
    "use strict";
    Connection.initController($scope, "group");
    $rootScope.$broadcast("CHANGE_TAB", "Connections");
    $scope.selectedToPagePreview = [];
    $scope.variableList = [];
    $scope.dcaView = $scope.info.id;
    $scope.dcaViewBackup = null;
    $scope.attr = null;

    var _avoidUpdateConnections = function () {
      const connectionList = $scope.connectionsList || [];
      let activeConnection;

      if($scope.activeConnectionObj) {
        activeConnection = connectionList.find(c => c.id === $scope.activeConnectionObj.id);
      } else {
        activeConnection = connectionList.find(c => c.id === -1 && c.isnew);
      }

      if(!activeConnection) {
        return false;
      }

      return activeConnection.lock === false && activeConnection.status === 'editing' && activeConnection.editing_by === db.get("login", "usernameString");
    }

    var _subscribeConnection = function (change) {

      if(_avoidUpdateConnections()) {
        return;
      }

      if ($scope._planType == "group") {
        $scope.loadConnectionListPremium();
      } else {
        $scope.loadConnectionList();
      }
    };


    $scope.$on("$destroy", function () {
      subscriptionModel.off("connection", _subscribeConnection);
    });
    subscriptionModel.on("connection", _subscribeConnection);

    $scope.changeResourceType = function (type) {

      var deffered = $q.defer();
      $scope.dcaViewMain.name = type;
      $scope.selectedTextBoxTableElement = null;

      if ($scope.dcaViewMain.name === "Textboxes")
        textBoxService.loadTextBoxes($scope).then(function (resources) {
          $scope.textBoxList = resources;
          if ($scope.activeConnectionObj && $scope.activeConnectionObj.content_id > 0) {
            textBoxService
              .fillWithData($scope, { resourceid: $scope.activeConnectionObj.content_id })
              .then(function (data) {
                $scope.selectedTextBoxTableElement =
                  $scope.activeConnectionObj.content_id;
                if ($scope.info.id > 0 && data[0].textbox_value) {
                  pagesListService
                    .initDrawings($scope, $scope.info)
                    .then(function () {
                      Connection.loadSVGFabric(
                        data[0].textbox_value,
                        $scope.info,
                        selectedImage,
                        $scope.activeConnectionObj,
                        data[0].settings.background_color || "#ffffff"
                      );
                    });
                }
              });
          } else {
            if (
              $scope.info.id > 0 &&
              $scope.fileData &&
              $scope.fileData.textbox_value
            ) {
              $scope.selectedTextBoxTableElement = $scope.fileData.id;
              pagesListService
                .initDrawings($scope, $scope.info)
                .then(function () {
                  Connection.loadSVGFabric(
                    $scope.fileData.textbox_value,
                    $scope.info,
                    selectedImage,
                    $scope.activeConnectionObj
                  );
                });
            }
          }
        });

      if ($scope.dcaViewMain.name === "Banners")
        bannerList.loadBannerList($scope, "banner").then(function (resources) {
          var con = $scope.activeConnectionObj;
          var ban = resources;
          $scope.bannerListFilters = Resource.getFiltersOfMaterialList(resources, (filtered) => {
            $scope.dcaBannersList = filtered
          });
          if (ban.length > 0) {
            if ($scope.activeConnectionObj && ($scope.activeConnectionObj.content_id > 0)) {
              var findedBannerObj;
              for (var i = 0; i < ban.length; i++) {
                if (ban[i].id === con.content_id) findedBannerObj = ban[i];
              }
              pagesListService.initDrawings($scope).then(function () {
                bannerList
                  .loadBannerImage(
                    $scope,
                    con.content_id,
                    findedBannerObj,
                    $scope.info,
                    con.id
                  )
                  .then(function () {
                    $timeout(function () {
                      $scope.changeBannerImagePosition(
                        con.horizontal_align,
                        con.vertical_align,
                        con.scale
                      );
                    }, 1001);
                  });
              });
            } else {
              if (
                $scope.info.id > 0 &&
                $scope.fileData &&
                $scope.fileData.filename
              ) {
                pagesListService
                  .initDrawings($scope, $scope.info)
                  .then(function () {
                    bannerList
                      .loadBannerImage(
                        $scope,
                        $scope.fileData.id,
                        $scope.fileData,
                        $scope.info,
                        con.id
                      )
                      .then(function () {
                        $timeout(function () {
                          $scope.changeBannerImagePosition(
                            con.horizontal_align,
                            con.vertical_align,
                            con.scale
                          );
                        }, 1001);
                      });
                  });
              }
            }
          }
        });

      return deffered.promise;
    };

    if ($scope.info.name == "Attachment") {
      $scope.dcaViewMain = {
        name: "Attachments"
      };
    } else if ($scope.info.name == "Enclosures") {
      $scope.dcaViewMain = {
        name: "Enclosures"
      };
    } else {
      $scope.dcaViewMain = {
        name: "Banners"
      };
    }

    $scope.dcaBannersList = null;

    $scope.textBoxList = null;

    $scope.connectionPreview = "att";

    $scope.pagesListService = pagesListService;

    $scope.leftComboElement = "Rules";

    var selectedImage = null;

    $scope.dcaSwitch = function (index, isLoaded, val) {
      function loadBanner(idx, val) {
        var defer = $q.defer();
        var elem = parseInt(idx);
        $scope.dcaView = idx;
        pagesListService
          .loadImage($scope, val.content_id, "obj")
          .then(function () { }, function () { });

        bannerList.loadBannerList($scope, "banner").then(function (resources) {
          $scope.dcaBannersList = resources;
          defer.resolve();
        });

        return defer.promise;
      }

      if (isLoaded) {
        return loadBanner(index, val);
      }

      if ($scope.selectedAttachmentTableElement === null && !isLoaded) {
        return loadBanner(index);
      }

      if ($scope.selectedAttachmentTableElement !== null && !isLoaded) {
        modal.confirm(
          {
            message: "Do you want to clear Banner selection?",
            ok: "Yes",
            cancel: "No"
          },
          function () {
            $scope.selectedAttachmentTableElement = null;
            $scope.fileData = null;
            loadBanner(index);
            $scope.dcaViewBackup = null;
          },
          function () {
            if (
              typeof $scope.dcaViewBackup !== "undefined" &&
              $scope.dcaViewBackup === null
            ) {
              $scope.dcaViewBackup = val;
            }
            var element = document.getElementById("dcaSwitchList");
            element.value = $scope.dcaViewBackup;
            $scope.dcaView = $scope.dcaViewBackup;
          }
        );
      }
    };

    var resize = function (second) {
      var maxWidth = utilityService.getDocWidth();
      var maxHeight = utilityService.getDocHeight();
      var bl = angular.element(".blockingLayer");
      bl.css({
        width: maxWidth + "px",
        height: maxHeight + "px"
      });
      if (second)
        $timeout(function () {
          $scope.$apply();
        });
    };

    angular.element($window).bind("resize", function () {
      resize(true);
    });

    function isScrolledIntoView(elem) {
      var docViewTop = $(window).scrollTop();
      var docViewBottom = docViewTop + $(window).height();

      var elemTop = $(elem).offset().top;
      var elemBottom = elemTop + $(elem).height();

      return elemBottom <= docViewBottom && elemTop >= docViewTop;
    }

    angular.element($window).bind("scroll", function () {
      if (
        $(".topIconsHolder").length > 0 &&
        !isScrolledIntoView($(".topIconsHolder")[0]) &&
        $(".pagePreviewsDirective").length > 0
      ) {
        if (!$(".pagePreviewsDirective").hasClass("top")) {
          var el = angular.element(".connectionList");
          el.css({
            "margin-bottom": "400px"
          });
          $(".pagePreviewsDirective").addClass("top");
        }
      } else {
        if ($(".pagePreviewsDirective").hasClass("top")) {
          var el = angular.element(".connectionList");
          el.css({
            "margin-bottom": "0px"
          });
          $(".pagePreviewsDirective").removeClass("top");
        }
      }
    });

    var resizeIntervalCounter = 2;

    function resInt() {
      var resizeInterval = setInterval(function () {
        resize(true);
        if (resizeIntervalCounter < 0) {
          clearInterval(resizeInterval);
          resizeInterval = null;
          resizeIntervalCounter = 2;
        }
        resizeIntervalCounter--;
      }, 500);
    }

    function addScrollTrigger() {
      $window.onscroll = function () {
        resize(true);
        $window.onscroll = false;
      };
    }
    $scope.addScrollTrigger = function () {
      $timeout(function () {
        addScrollTrigger();
      });
    };

    $scope.filterTestedConnections = function (value, index, filter, add) {
      return Connection.filterTestedConnections(value, index, filter);
    };

    $scope.filterStatuses = function (value, index, filter, add) {
      return Connection.filterStatuses(
        $scope.loading[value.id],
        $scope.statusNames,
        value,
        $scope.filter
      );
    };

    $scope.setFiltering = function (setFilters) {
      Connection.setFiltering($scope.filter, setFilters);
    };

    $scope.getConnectionIndexById = function (id, connections) {
      return Connection.getConnectionIndexById(
        id,
        connections,
        $scope.connectionsList
      );
    };

    $scope.prepareConnectionsListOnLoad = function () {
      Connection.prepareConnectionsListOnLoad(
        $scope.connectionsList,
        $scope.statusNames
      );
    };


    $scope.updateStatus = function () {
      var now = serverTime.getServerTimeUTC();
      var from, to;
      for (var i = 0; i < $scope.connectionsList.length; i++) {
        from = serverTime.dateParser($scope.connectionsList[i].valid_from_utc);
        to = serverTime.dateParser($scope.connectionsList[i].valid_to_utc);

        if (
          ($scope.connectionsList[i].status == $scope.statusNames.run &&
            now < from &&
            now < to) ||
          (typeof to !== "undefined" &&
            to === null &&
            now < from &&
            $scope.connectionsList[i].status == $scope.statusNames.run)
        ) {
          $scope.connectionsList[i].status = $scope.statusNames.pending;
          continue;
        }

        if (typeof to !== "undefined" && to !== null && to < now) {
          if ($scope.connectionsList[i].status !== $scope.statusNames.deleted)
            $scope.connectionsList[i].status = $scope.statusNames.expired;
        }
      }
    };

    $scope.changeConnectionState = function (id, con) {
      Connection.changeConnectionState(
        $scope.statusNames,
        $scope.loading[con.id],
        id,
        con
      );
      $scope.updateStatus();
    };

    $scope.compareObjects = function (ob1, ob2, different) {
      return attachmentTargetList.compareObjects2(ob1, ob2, different);
    };
    $scope.backupConnection = function (con) {
      $scope.connectionsBackup[con.id] = angular.copy(con);
    };

    $scope.changeTabState = function (id, con) {
      var evt = document.createEvent('Event'); evt.initEvent('RESCALE', true, true); document.dispatchEvent(evt);
      angular.element(document).find(".detailsContainer").remove();
      if (Connection.resourceFallback) {
        con = { ...con, ...Connection.resourceFallback };
        if (con.resource_type === "attachment") {
          $scope.selectedAttachmentTableElement = con.content_id;
          $scope.changeSelectedAttachment(con);
        }
      }

      if ($scope.activePartial == 3) {
        pagesListService.renderDca(
          $scope.$parent.group,
          con.banner_area_id,
          con.content_id,
          con.id,
          con,
          $scope.selectedToPagePreview[con.id]
        );
        if ($scope.selectedToPagePreview[con.id]) {
          $scope.selectedToPagePreview = [];
          $scope.selectedToPagePreview[con.id] = false;
        } else {
          $scope.selectedToPagePreview = [];
          $scope.selectedToPagePreview[con.id] = true;
        }
        return true;
      }

      var loadData = utilityService.loadData;

      if (!utilityService.checkIfChangable(con, $scope)) return false;

      $rootScope.$emit("LOADON");

      utilityService.resetConnectionValues($scope);

      $scope.backupConnection(con);

      if (!$scope.loading[con.id]) {
        $scope.closeAllConnectionsTabs();

        utilityService.resetConnectionValuesForRequests($scope);
        var attach = [];
        var ban = [];
        var target = [];
        var promises = [];
        if (con.banner_area_id === null) {
          if (con.resource_type === "attachment") {
            promises.push(
              attachmentTargetList
                .loadAttachmentList($scope, "attachment", con.content_id)
                .then(function (resources) {
                  attach = resources;
                  if (attach.length > 0) {
                    loadData(id, con, $scope);
                  }
                })
            );
          } else {
            promises.push(
              attachmentTargetList
                .loadAttachmentList($scope, "enclosure", con.content_id)
                .then(function (resources) {
                  attach = resources;
                  if (attach.length > 0) {
                    loadData(id, con, $scope);
                  }
                })
            );
          }
        } else {
          promises.push(
            bannerList
              .loadBannerList($scope, "banner", con.content_id)
              .then(function (resources) {
                $scope.dcaBannersList = resources;
              })
          );
        }

        promises.push(
          rulesService.getRules(true).then(function (data) {
            if (data.length > 0) {
              loadData(id, con, $scope);
            }
            $scope.setRules(data);
          })
        );

        $q.all(promises).then(function () {
          if (
            typeof $scope.activeConnectionObj !== "undefined" &&
            $scope.activeConnectionObj !== null
          ) {
            if ($scope.activeConnectionObj.resource_type == "textbox") {
              $scope.changeResourceType("Textboxes");
            }

            if ($scope.activeConnectionObj.resource_type == "banner") {
              $scope.changeResourceType("Banners");
            }

            if ($scope.activeConnectionObj && $scope.activeConnectionObj.content_id != 0) {
              $scope.selectAttachmentTableElementById($scope.activeConnectionObj.content_id);
            }
            $scope.selectRuleById($scope.rules);
          }

          if (target.length > 0 && (ban.length > 0 || attach.length > 0)) {
            if (attach.length > 0) {
              loadData(id, con, $scope);
            }
            if (ban.length > 0) {
              loadData(id, con, $scope);
            }
          }

          $rootScope.$emit("LOADOFF");
        });

        $scope.loading[con.id] = !$scope.loading[con.id];
      } else {
        $scope.activeConnection = null;
        $scope.activeConnectionObj = null;

        loadData(id, con, $scope);
        $scope.loading[con.id] = !$scope.loading[con.id];
        $rootScope.$emit("LOADOFF");
      }
    };
    $scope.openTab = function (id, con) {
      $scope.closeAllConnectionsTabs();
      $scope.loading[con.id] = true;
    };

    $scope.closeTab = function (id, con) {
      $scope.loading[con.id] = false;
    };

    $scope.showPartial = function (partialId) {
      $scope.logsListHide = true;
      $scope.activePartial = partialId;
    };

    $scope.getConnectionStatusActionName = function (id, con) {
      return Connection.getConnectionStatusActionName(
        $scope.statusNames,
        $scope.loading[con.id],
        con,
        $scope.info
      );
    };



    $scope.connectionCopyHandler = function (group, con) {
      modal.confirm(
        {
          message: `Are you sure that you want copy the connection: '${con.title}' ?`,
          ok: "Yes",
          cancel: "No"
        },
        () => {

          initConnectionCopy(group, con)
        }
      );
    }



    $scope.loadConnectionListPremium = function (isCanceled) {
      var id = $scope.getActiveProjectId();
      var defer = $q.defer();
      Connection.loadConnectionList(id).then(function (connections) {
        $timeout(() => {
          $scope.connectionsList = [];
          for (var i = 0; i < connections.length; i++) {
            if (
              $scope.info.name === "Attachments" &&
              connections[i].resource_type === "attachment"
            )
              $scope.connectionsList.push(connections[i]);

            if (
              $scope.info.name === "Enclosures" &&
              connections[i].resource_type === "enclosure"
            )
              $scope.connectionsList.push(connections[i]);

            if (connections[i].banner_area_id === $scope.info.id)
              $scope.connectionsList.push(connections[i]);
          }

          $scope.clearBlockLayers();
          $scope.prepareConnectionsListOnLoad();
          $scope.updateStatus();
          $scope.findEditingConnection($scope.connectionsList, isCanceled);
          $scope.openEditedConnection();
          $rootScope.$emit("LOADOFF");
          $rootScope.$emit("connection_statusChange");
          defer.resolve(connections);
        }, 0);
      });
      return defer.promise;
    };

    $scope.openEditedConnection = function () { };

    $scope.getActiveProjectId = function () {
      return window.api.projectid;
    };

    $scope.setConversions = function (data) {
      attachmentTargetList.setConversions($scope, data);
    };

    $scope.getHighestPriority = function () {
      return utilityService.getHighestPriority($scope);
    };
    $scope.getLowestPriority = function () {
      return utilityService.getLowestPriority($scope);
    };


    $scope.createNewEmptyConnection = function (group) {
      var connection;

      if (group.name === "Attachments" || group.name == "Enclosures") {
        $scope.dcaViewMain.name = group.name;
      } else {
        $scope.dcaViewMain.name = "Banners";
      }


      connection = {
        title: "",
        status: $scope.statusNames.edit,
        content_id: 0,
        description: "",
        lock: false,
        isnew: true,
        valid_from: "",
        valid_to: "",
        priority: $scope.getHighestPriority(),
        id: -1,
        editing_by: db.get("login", "usernameString"),
        matched_total: 0,
        sent_total: 0,
        test: false,
        horizontal_align: "left",
        vertical_align: "top",
        scale: 0,
        url: ""
      };

      connectionInit(connection);
    };


    function initConnectionCopy(group, con) {
      if (group.name === "Attachments" || group.name == "Enclosures") {
        $scope.dcaViewMain.name = group.name;
      }
      const connection = {
        title: "",
        status: $scope.statusNames.edit,
        content_id: con.content_id,
        description: con.description,
        lock: false,
        isnew: true,
        valid_from: con.valid_from,
        valid_to: con.valid_to,
        textbox_value: con.textbox_value,
        tag: con.tag,
        rule_name: con.rule_name,
        resource_type: con.resource_type,
        rule_id: con.rule_id,
        page_id: con.page_id,
        parent_id: con.id,
        background_color: con.background_color,
        banner_area_id: con.banner_area_id,
        priority: $scope.getHighestPriority(),
        id: -1,
        editing_by: db.get("login", "usernameString"),
        matched_total: con.matched_total,
        sent_total: con.sent_total,
        settings: con.settings,
        test: con.test,
        horizontal_align: con.horizontal_align,
        vertical_align: con.vertical_align,
        project_id: con.project_id,
        scale: con.scale,
        url: con.url
      };
      connectionInit(connection);
      $scope.selectedAttachmentTableElement = con.content_id;
      if (connection.resource_type == 'banner') {
        $scope.changeSelectedBanner(connection);
      } else if (connection.resource_type == 'attachment') {
        showAttachmentSelectionMode(connection)
      } else if (connection.resource_type == 'enclosure') {
        showEnclosureSelectionMode(connection)
      } else if (connection.resource_type == 'textbox') {
        $scope.changeSelectedTextBox({ id: connection.content_id })
      }

    }


    function connectionInit(connection) {
      utilityService.emptyConnectionInit($scope);
      $scope.connectionsList.push(connection);
      $scope.currentConnection = connection;
      $rootScope.$emit("connection_new", connection);
      $scope.openTab($scope.connectionsList.length - 1, connection);
      $scope.backupConnection(connection);
      $scope.scrollTo("con_" + connection.id, true);

      if ($scope.dcaViewMain.name === "Attachments") {
        $scope.loadAttachmentList($scope, "create");
      }

      if ($scope.dcaViewMain.name === "Enclosures") {
        $scope.loadEnclosouresList();
      }


      if ($scope.dcaViewMain.name === "Banners") {
        bannerList.loadBannerList($scope, "banner").then(function (resources) {
          $scope.dcaBannersList = resources;
          bannerList.loadBackgroundImage(
            $scope,
            $scope.info.background_id,
            $scope.info,
            connection.id
          );
          resize();
        });
      }

      $scope.loadTargetList(); 
      $scope.activeConnection = $scope.connectionsList.length - 1;
      $scope.activeConnectionObj = connection;
      var intervalBlockingNewConnection = setInterval(function () {
        $scope.blockAllExceptConnection(connection);
        resize();
        clearInterval(intervalBlockingNewConnection);
        $scope.addScrollTrigger();
      }, 50);
    }



    $scope.selectTableElement = function () { };


    $scope.clearFcCode = function (con) {
      con.fulfillment = [];
    };

    $scope.selectAttachmentTableElement = function (con, att) {
      if (!con.lock && con.matched_total === 0) {
        $scope.selectedAttachmentTableElement = att.id;
        attachmentTargetList.fillWithData($scope, { resourceid: att.id });
        $scope.changeSelectedAttachment(con);
        $scope.changeSelectedEnclosure(con);
      }
    };

    $scope.changeSelectedEnclosure = function (con) {
      if (!con.lock && con.matched_total === 0) {
        attachmentTargetList.fillWithData($scope, {
          resourceid: $scope.selectedAttachmentTableElement
        });
        $scope.selectedConvertions = [];
        $scope.changeEditEnclosure();
      }
    };

    function showEnclosureSelectionMode(con) {
      if (!con.lock && con.matched_total === 0) {
        attachmentTargetList.fillWithData($scope, {
          resourceid: $scope.selectedAttachmentTableElement
        });
      }
    }

    $scope.changeSelectedAttachment = function (con) {
      if (!con.lock && con.matched_total === 0) {
        attachmentTargetList.fillWithData($scope, { resourceid: $scope.selectedAttachmentTableElement });
        $scope.selectedConvertions = [];
        $scope.changeEditAttachments();
      }
    };

    function showAttachmentSelectionMode(con) {
      if (!con.lock && con.matched_total === 0) {
        attachmentTargetList.fillWithData($scope, { resourceid: $scope.selectedAttachmentTableElement });
      }
    }



    $scope.selectBannerTableElement = function (con, att) {
      $scope.selectedAttachmentTableElement = att.id;
      if (!$scope.checkIsSelectDCA()) {
        return false;
      }

      if ($scope.checkSize(att)) {
        return false;
      }

      if (!con.lock && con.matched_total === 0) {
        $scope.previousBannerElement = $scope.selectedAttachmentTableElement;
        $scope.selectedAttachmentTableElement = att.id;
        attachmentTargetList.fillWithData($scope, { resourceid: $scope.selectedAttachmentTableElement });
      }

      $scope.changeSelectedBanner(att)
    };


    $scope.changeSelectedBanner = function (con) {
      return attachmentTargetList.fillWithData($scope, { resourceid: $scope.selectedAttachmentTableElement })
        .then(function (data) {
          data = data[0];
          if ($scope.info.id > 0) {
            if ($scope.fitToSize) {
              pagesListService.initDrawings($scope, $scope.info).then(function () {
                bannerList.loadBannerImage($scope, $scope.selectedAttachmentTableElement, data.id, $scope.info, con.id)
                  .then(function () {
                    $timeout(function () {
                      $scope.changeBannerImagePosition(
                        con.horizontal_align,
                        con.vertical_align,
                        con.scale
                      );
                    }, 1001);
                  });
              });
            } else {
              $scope.selectedAttachmentTableElement = $scope.previousBannerElement;
            }
          } else {
            modal.info(
              {
                message: "Please select a free area first.",
                ok: "Ok",
                cancel: ""
              },
              function () { },
              function () { }
            );
            $scope.selectedAttachmentTableElement = null;
            return false;
          }
          $scope.changeEditBanners();
          $scope.editBanners = false;
          $scope.selectedConvertions = [];
        });
    };


    $scope.selectAttachmentTableElementById = function (id) {
      attachmentTargetList.fillWithData($scope, { resourceid: id });
      $scope.selectedConvertions = [];
    };

    $scope.selectRule = function (rule) {
      $scope.selectedRuleId = rule.id;
      if ($scope.activeConnectionObj) {
        $scope.activeConnectionObj["rule_id"] = rule.id;
      }

      if ($scope.currentConnection) {
        $scope.currentConnection["rule_id"] = rule.id;
      }



      $scope.selectedRule = rule;
    };

    $scope.changeSelectedRule = function (attachementData, rule) {
      if ($scope.selectedRuleId === null) {
        modal.info(
          {
            message: "Please select a rule first.",
            ok: "Ok",
            cancel: ""
          },
          function () { },
          function () { }
        );
      }

      $scope.editRules = false;
    };

    $scope.selectRuleById = function (data) {
      if (!$scope.selectedRule) {
        if (
          $scope.activeConnectionObj.rule_id &&
          $scope.activeConnectionObj.rule_id !== null
        ) {
          var isDeleted = true;
          for (var i = 0; i < data.length; i++) {
            if (data[i].id == $scope.activeConnectionObj.rule_id) {
              $scope.rules = data.slice(i, i + 1);
              $scope.selectRule($scope.rules[0]);
              isDeleted = false;
              break;
            }
          }

          if (isDeleted) {
            $scope.selectedRule = {
              id: $scope.activeConnectionObj.rule_id,
              name: $scope.activeConnectionObj.rule_name
            };
            $scope.rules = [];
            $scope.rules.push($scope.selectedRule);
          }

          $scope.noRulesSelected = "";
        } else {
          $scope.rules = [];
          $scope.selectedRule = null;
          $scope.noRulesSelected = "Please select a rule";
        }
      } else {
        $scope.rules = [];
        $scope.rules.push($scope.selectedRule);
        $scope.noRulesSelected = "";
      }
    };

    $scope.selectEnclosureTableElementById = function (id) {
      attachmentTargetList.fillWithData($scope, { resourceid: id });
      $scope.selectedConvertions = [];
    };

    $scope.previewMyImage = function (convertionFormat) {
      if (convertionFormat)
        attachmentTargetList.previewMyImage($scope, convertionFormat);
    };

    $scope.showSaveButton = utilityService.showSaveButton;

    $scope.showDeleteButton = utilityService.showDeleteButton;

    $scope.init = function (value) {
      $scope.loading = [];

      $scope.ual = userService.getUAL();
      if (typeof $scope.ual !== "undefined") {
        $scope.ual.isAdmin = userService.isAdmin();
      }
    };

    $scope.createNewConnection = function (
      aid,
      connection,
      index,
      banner_area_id,
      rule,
      textbox_id
    ) {
      if (banner_area_id <= 0) banner_area_id = null;
      if (textbox_id <= 0) textbox_id = null;
      const onSuccess = (data) => {
        $scope.editRules = false;
        connection.lock = true;
        var successMsg = "";
        if (data.isNew) {
          successMsg = "Your Connection has been created successfully";
        } else {
          successMsg = "Your Connection has been saved successfully";
        }

        $scope.clearBlockLayers();
        _removeTempConnection();
        NavigationContext.unsubscribe("fallback");
        growlHandlingService.success(successMsg);
        Connection.resourceFallback = null;
      }

      const onError = () => {
        NavigationContext.unsubscribe("fallback")
      }


      Connection.prepareSaveData(
        index,
        connection,
        aid,
        banner_area_id,
        rule,
        textbox_id
      ).then(function (data) {
        let promise = null;
        if (connection.isnew) {
          promise = $q.when(connection);
        } else {
          var compare = $scope.compareObjects(data, $scope.connectionsBackup[connection.id], true);
          promise = Connection.compareData(compare, connection, aid, banner_area_id, textbox_id)
        }
        promise.then(function (dataToSend) {
          if (connection.parent_id && connection.isnew) {
            Connection.connectionCopy(
              $scope.getActiveProjectId(),
              $scope.ual,
              $scope.statusNames,
              $scope.dcaViewMain,
              aid,
              connection,
              index,
              banner_area_id,
              textbox_id,
              $scope.connections).then(onSuccess, onError);
          } else {
            if ((connection.resource_type === "attachment") && Connection.resourceFallback) {
              dataToSend = { ...dataToSend, content_id: Connection.resourceFallback.content_id };
            }
            Connection.createNewConnection(
              $scope.getActiveProjectId(),
              $scope.ual,
              $scope.statusNames,
              $scope.dcaViewMain,
              aid,
              connection,
              index,
              banner_area_id,
              textbox_id,
              dataToSend,
              $scope.connections).then(onSuccess, onError);
          }
        },
          function () { }
        );
      });
    };

    $scope.closeAllConnectionsTabs = function (except) {
      $scope.loading = [];
      $scope.selectedRule = null;
    };

    $scope.loadTargetList = function (selected) {
      return attachmentTargetList.loadAttachmentList($scope, "targetlist");
    };

    $scope.loadRules = function () {
      return rulesService.getRules(true);
    };

    $scope.setRules = function (data) {
      $scope.rules = data;
    };

    $scope.loadAttachmentList = function (selected, actionType) {
      attachmentTargetList.loadAttachmentList($scope, "attachment", null, null, actionType);
    };

    $scope.loadEnclosouresList = function (selected) {
      attachmentTargetList.loadAttachmentList($scope, "enclosure");
    };

    $scope.blockAllExceptConnection = function (con, index) {
      $scope.frontElementId = con.id;
      $scope.blockingLayer = true;
    };

    $scope.clearBlockLayers = function () {
      $scope.frontElementId = null;
      $scope.blockingLayer = false;
      Connection.resourceFallback = null;
    };
    $scope.connectionStatusHandler = function (index, connection) {
      var status = $scope.getConnectionStatusActionName(index, connection);
      if (status === 'run' && connection.parent_id !== null) {
        const idx = $scope.getConnectionIndexById(connection.parent_id, $scope.connectionsList);
        const parent = $scope.connectionsList[idx];
        if (angular.isDefined(parent) && parent.status === 'running') {
          modal.confirm(
            {
              message: `This is a copy of connection ${connection.title} that is currently still running, do you want to STOP this old Connection ?`,
              ok: "Yes",
              cancel: "No"
            },
            function () {
              changeStatusHandler(idx, parent, 'stopped');
            }
          );
        }
      }
      changeStatusHandler(index, connection, status);
    }

    function changeStatusHandler(index, connection, status) {
      if (status === $scope.statusNames.approve || status === $scope.statusNames.stopRequest) {
        var requestStatus = (status === $scope.statusNames.approve) ? 'run' : 'stop';
        modal.confirm({
          message: `Are you sure that you want to send request for ${requestStatus} Connection?`,
          ok: "Yes",
          cancel: "No"
        },
          function () {
            Connection.sendEmailToApprovers(connection, requestStatus);
          }
        );

      } else {
        $scope.changeConnectionStatus(index, connection);
      }
    }

    $scope.changeConnectionStatus = function (index, connection) {
      Connection.changeConnectionStatus(
        $scope.showdragdrop,
        $scope.statusNames,
        connection,
        $scope.loading,
        $scope.blockingLayer,
        $scope.info
      ).then(
        function (connectionStatus) {
          if (connectionStatus === $scope.statusNames.edit) {
            $scope.blockingLayer = true;
            $scope.changeTabState(connection.id, connection);
          }
          Connection.sendState(connection, connectionStatus).then(
            function (data) {
              if (data.changed !== false) {
                if (connectionStatus === $scope.statusNames.run) {
                  connection.message = null;
                  if (
                    serverTime.getServerTime() <
                    serverTime.dateParser(connection.valid_from_utc)
                  )
                    connectionStatus = $scope.statusNames.pending;
                }
                connection.status = connectionStatus;
                if (connection.status === $scope.statusNames.edit)
                  connection.lock = false;
                $scope.activeConnection = index;
                $scope.activeConnectionObj = connection;
              } else {
                modal.info(
                  {
                    message: "Could not change status",
                    ok: "Ok",
                    cancel: ""
                  },
                  function () { },
                  function () { }
                );
              }
              resize();
              $scope.updateStatus();
              if (connection.status === 'editing') {
                $scope.changeTabState(connection.id, connection);
              }

            },
            function (data) {
              $scope.loading[connection.id] = false;
              modal.info(
                {
                  message: data.msg,
                  ok: "Ok",
                  cancel: ""
                },
                function () {
                  $scope.clearBlockLayers();
                  $scope.closeAllConnectionsTabs();
                  $scope.loadConnectionListPremium();
                  $scope.handleStatus(status, data);
                },
                function () { }
              );
            }
          );
        },
        function () {
          Connection.sendStopState(connection, $scope.statusNames).then(
            function (data) {
              if (data.changed) {
                connection.message = $("#whyStopped").val();
                if (!$scope.loading[connection.id]) {
                  $scope.loadConnectionListPremium();
                }
              } else {
                modal.info(
                  {
                    message: data.msg,
                    ok: "Ok",
                    cancel: ""
                  },
                  function () {
                    $scope.loadConnectionListPremium();
                    $scope.handleStatus(status, data);
                  },
                  function () { }
                );
              }
              connection.status = $scope.statusNames.stop;
            },
            function (data) {
              modal.info(
                {
                  message: data.msg,
                  ok: "Ok",
                  cancel: ""
                },
                function () {
                  $scope.clearBlockLayers();
                  $scope.closeAllConnectionsTabs();
                  $scope.loadConnectionListPremium();
                  $scope.handleStatus(status, data);
                },
                function () { }
              );
            }
          );
        }
      );
      $scope.addScrollTrigger();
    };

    $scope.deleteConnection = function (con) {
      Connection.deleteConnection($scope.statusNames, con, this).then(
        function (data) {
          if (data.deleted) {
            con.status = $scope.statusNames.deleted;
            $scope.loading = [];
            $scope.activeConnection = null;
            $scope.activeConnectionObj = null;
            $scope.loadConnectionListPremium(true);
            $scope.clearBlockLayers();
          } else {
            $odal.info(
              {
                message: "Could not delete connection",
                ok: "Ok",
                cancel: ""
              },
              function () { },
              function () { }
            );
          }
        },
        function () {
          $scope.clearBlockLayers();
          $scope.closeAllConnectionsTabs();
          $scope.loadConnectionListPremium();
        }
      );
    };

    function _closeActiveConnection() {
      $timeout(() => {
        $scope.loading = [];
        $scope.activeConnection = null;
        $scope.activeConnectionObj = null;
        $scope.clearBlockLayers();
      }, 0);
    }

    $scope.cancelConnection = function (connection) {
      if (connection.id == -1) {
        $scope.connectionsList = $scope.connectionsList.filter((connection) => connection.id !== -1);
        _closeActiveConnection();
      } else if (connection.status !== "editing") {
        _closeActiveConnection();
      } else {
        Connection.cancelEditingConnection(
          connection,
          $scope.connectionsBackup[connection.id],
          (canceledConnection) => {
            connection.lock = true;
            $timeout(() => {
              for (let i = 0; i < $scope.connectionsList.length; i++) {
                if ($scope.connectionsList[i].id === canceledConnection.id) {
                  $scope.connectionsList[i] = canceledConnection;
                  break;
                }
              }
            $scope.clearBlockLayers();
          }, 0);
        });

      }
    };

    $(".toggle").toggles({
      drag: false
    });

    $scope.$on("$routeChangeSuccess", function (scope, next, current) {
      if (typeof current != "undefined") {
        $rootScope.$emit("LOADON");
      }

    });

    $scope.handleStatus = function (status, data) {
      errorHandling.handleResponse(status, data, $scope);
    };

    $scope.isEdited = function (con) {
      if (
        con.editing_by !== db.get("login", "usernameString") &&
        con.editing_by !== null &&
        con.status !== $scope.statusNames.expired
      ) {
        return true;
      }
      return false;
    };

    $scope.openConnectionDetails = function (connection) {
      $scope.showPartial(0);
      $scope.changeTabState(connection.id, connection);
      $scope.scrollTo("con_" + connection.id, true);
    };

    $scope.findEditingConnection = function (connectionsList, isCanceled) {
      function afterOpenGroup(element) {
        $scope.backupConnection(element);
        $scope.changeTabState(element.id, element);
        if (!isCanceled) { $scope.blockAllExceptConnection(element) }
        $timeout(resize, 100);
        $scope.scrollTo("con_" + element.id, true);
      }
      for (var i = 0; i < connectionsList.length; i++) {
        if (
          connectionsList[i].status === $scope.statusNames.edit &&
          db.get("login", "usernameString") === connectionsList[i].editing_by
        ) {
          $scope
            .openGroup(
              $scope.info.id + $scope.activeProject,
              $scope.info,
              connectionsList[i]
            )
            .then(afterOpenGroup);
        }
      }
    };

    $scope.broadcastDragDrop = function () {
      var num = 0;
      var list = $scope.connectionsList;
      for (var i = 0; i < $scope.connectionsList.length; i++) {
        if (
          list[i].status != $scope.statusNames.expired &&
          list[i].status != $scope.statusNames.deleted
        ) {
          num++;
        }
      }

      if (num > 1) {
        $scope.$broadcast("startDragDrop");
      }

    };

    $scope.setConversions = function (data) {
      attachmentTargetList.setConversions($scope, data);
    };

    $scope.$on("startDragDrop", function (event, mass) {
      if (mass || typeof mass === "undefined") {
        $scope.showdragdrop = !$scope.showdragdrop;
      } else {
        $scope.dragdropswitch = false;
      }
      if ($scope.dragdropswitch) {
        $scope.blockingLayer = false;
        $("#dragdroplabel_" + $scope.info.id).removeClass("toFront");
        $("#connectionListSortable_" + $scope.info.id).removeClass("toFront");
      } else {
        $scope.blockingLayer = true;
        $("#dragdroplabel_" + $scope.info.id).addClass("toFront");
        $("#connectionListSortable_" + $scope.info.id).addClass("toFront");
        $scope.closeAllConnectionsTabs();
        $scope.setFiltering("default");
        resize();
      }
      $scope.dragdropswitch = !$scope.dragdropswitch;
    });

    $scope.changeRuleElement = function () {
      $scope.editRules = !$scope.editRules;
      $timeout(resize, 100);
    };

    $scope.changeEditAttachments = function () {
      $scope.selectedAttachmentTableElementOriginal =
        $scope.selectedAttachmentTableElement;
      $scope.editAttachments = !$scope.editAttachments;
      $timeout(resize, 100);
    };

    $scope.changeEditEnclosure = function () {
      $scope.selectedAttachmentTableElementOriginal =
        $scope.selectedAttachmentTableElement;
      $scope.editEnclosure = !$scope.editEnclosure;
      $timeout(resize, 100);
    };

    $scope.changeEditBanners = function () {
      $scope.selectedAttachmentTableElementOriginal =
        $scope.selectedAttachmentTableElement;
      $scope.editBanners = true;
      $timeout(resize, 100);
    };

    $scope.changeEditTextBoxes = function () {
      $scope.selectedTextBoxTableElementOriginal = $scope.selectedTextBoxTableElement;
      $scope.editTextBoxes = true;
      $timeout(resize, 100);
    };

    $scope.changeSelectedTextBox = function (selection) {
      $scope.selectedTextBoxTableElement = selection.id;
      textBoxService.checkTexboxValid(selection.id, () => {
        textBoxService.fillWithData($scope, { resourceid: $scope.selectedTextBoxTableElement }).then(function (data) {
          if ($scope.info.id > 0) {
            pagesListService.initDrawings($scope, $scope.info).then(function () {
              Connection.loadSVGFabric(
                data[0].textbox_value,
                $scope.info,
                selectedImage,
                $scope.activeConnectionObj,
                data[0].settings.background_color || "#ffffff"
              );
              $scope.changeEditTextBoxes();
              $scope.editTextBoxes = false;
            });
          }
        });
      });
    };

    $scope.onMouseOver = function (item) {
      return {
        id: item.id,
        type: item.resource_type,
        settings: item.settings,
        dca: $scope.info,
        scale: $scope.activeConnectionObj.scale,
        vertical_align: $scope.activeConnectionObj.vertical_align,
        horizontal_align: $scope.activeConnectionObj.horizontal_align
      }
    }

    $scope.selectTextBoxTableElement = function (con, att) {
      if (!$scope.checkIsSelectDCA()) {
        return false;
      }

      if ($scope.checkVariable(att)) {
        return false;
      }


      if (!con.lock && con.matched_total === 0) {
        $scope.previousTextBoxElement = $scope.selectedTextBoxTableElement;
        $scope.selectedTextBoxTableElement = att.id;
        textBoxService.fillWithData($scope, {
          resourceid: $scope.selectedTextBoxTableElement
        });
      }
    };

    $scope.checkVariable = function (att) {
      var splitArray = [];
      if (att.variable_value) {
        splitArray = att.variable_value.split(",");
      }

      var varList = $scope.$parent.variableList;
      var varListNames = [];
      for (var j = 0; j < varList.length; j++) {
        varListNames.push(varList[j].value);
      }

      for (var i = 0; i < splitArray.length; i++) {
        if (!(varListNames.some((vName) => splitArray[i] === vName))) return true;
      }
      return false;
    };

    $scope.checkSize = function (data) {
      return utilityService.checkSize(data, $scope);
    };

    $scope.checkIsSelectDCA = function () {
      return utilityService.checkIsSelectDCA($scope);
    };


    function loadOpenedGroup() {
      $scope.openedGroup = groupService.getOpenedGroups();
      if ($scope.checkIsOpenGroup($scope.info.id + $scope.activeProject)) {
        $timeout(function () {
          if (name.indexOf("Attachments") > -1) {
            group.shape = {
              width: 210,
              height: 297,
              x: 0,
              y: 0
            };
          }

          _drawAllBanners($scope.info);
        }, 100);
      }
    }

    $scope.checkIsOpenGroup = function (name) {
      for (var i = 0; i < $scope.openedGroup.length; i++) {
        if ($scope.openedGroup[i] === name) return true;
      }
      return false;
    };

    loadOpenedGroup();

    function _getDcaById(dcaList, id) {
      for (var i = 0; i < dcaList.length; i++) {
        if (dcaList[i].id === id) return dcaList[i];
      }
    }

    $scope.$on("OPENCONNECTION", function (connectionId, con) {
      $rootScope.$emit("LOADON");
      for (var i = 0; i < $scope.connectionsList.length; i++) {
        if ($scope.connectionsList[i].id == con.id) {
          $scope
            .openGroup(
              $scope.info.id + $scope.getActiveProjectId(),
              $scope.info,
              $scope.connectionsList[i]
            )
            .then(function () {
              $scope.changeTabState(con.id, con);
              $scope.scrollTo("con_" + con.id, true);
              $rootScope.$emit("LOADOFF");
            });
          break;
        }
      }
    });

    $scope.openGroup = function (id, group, listElement) {
      var defer = $q.defer();
      $scope.openedGroup.push(id);
      groupService.openGroup(id);


      $timeout(function () {
        if ($scope.name === "Attachments") {
          group.shape = {
            width: 210,
            height: 297,
            x: 0,
            y: 0
          };
        }
        _drawAllBanners(group);
        defer.resolve(listElement);
      }, 100);
      return defer.promise;
    };

    $scope.openGroupWithCreateNewConnection = function (name, group) {
      if (group.name === "Attachments" || group.name === "Enclosures")
        $scope.dcaViewMain.name = group.name;

      $scope.openGroup(name, group, 0).then(function () {
        $scope.createNewEmptyConnection(group);
      });
    };

    $scope.openGroupWithPrioritize = function (name, group) {
      $scope.openGroup(name, group).then(function () {
        $scope.broadcastDragDrop(name);
      });
    };

    $scope.closeGroup = function (name) {
      groupService.closeGroup(name);
      for (var i = 0; i < $scope.openedGroup.length; i++) {
        if ($scope.openedGroup[i] === name) $scope.openedGroup.splice(i, 1);
      }
    };

    function _drawAllBanners(dca) {
      var resource = pagesListService.getPagesList().filter((page) => dca.id === page.bid)[0];
      var deffered = $q.defer();
      var canvas = new fabric.StaticCanvas("bannerCanvas_" + dca.id);
      var resource = pagesListService.getPagesList().filter((page) => dca.id === page.bid)[0];

      canvas.setHeight(190);
      canvas.setWidth(134);
      _addDCAs(canvas, dca)
      if (resource && angular.isDefined(resource.bgid)) {
        pagesListService.loadImage($scope, resource.bgid, 'thumbnail').then((url) => {

          canvas.setBackgroundImage(url, canvas.renderAll.bind(canvas), {
            width: canvas.width - 1,
            height: canvas.height - 3,
            scale: 3.0
          });
          _addDCAs(canvas, dca)
          deffered.resolve();
        });
      } else {
        _addDCAs(canvas, dca)
        deffered.resolve();
      }
      return deffered.promise;
    }

    function _addDCAs(canvas, dca) {
      if (typeof dca.shape !== "undefined") {
        canvas.add(_quickFabricRect(dca.shape));
      }
    }

    function _quickFabricRect(shape) {
      var dca = new fabric.Rect({
        left: shape.x * 0.64,
        top: shape.y * 0.64,
        width: shape.width,
        height: shape.height,
        opacity: 1.0,
        scaleX: 0.64,
        scaleY: 0.64,
        fill: ""
      });

      dca.set({
        strokeWidth: 1,
        stroke: "rgba(103,112,124,0.5)",
        fill: "rgba(103,112,124,0.5)"
      });
      return dca;
    }

    $scope.loadConnectionList = function () {
      var id = $scope.info.id;
      var defer = $q.defer();
      if (
        $scope.info.name !== "Attachments" &&
        $scope.info.name !== "Enclosures"
      ) {
        Connection.loadConnectionListByBannerAreaId(id).then(
          function (connections) {
            $scope.connectionsList = connections;
            $rootScope.$emit("LOADOFF");
            defer.resolve();
          },
          function (data) {
            defer.reject();
          }
        );
      } else {
        Connection.loadConnectionWithAttachmentList(
          $scope.getActiveProjectId()
        ).then(function (connections) {
          $scope.connectionsList = connections;
          $rootScope.$emit("LOADOFF");
          defer.resolve();
        });
      }
      return defer.promise;
    };

    $scope.$on("colapseAllGroup", function (event, mass) {
      groupService.closeAllGroup();
      $scope.openedGroup = [];
    });

    $scope.$on("expandAllGroup", function (event, mass) {
      $scope.openGroup($scope.info.id + $scope.activeProject, $scope.info);
    });

    $scope.changeTestStatus = function (con) {
      Connection.changeTestStatus(con).then(
        function () {
          Connection.onConnectionStatusChange(con);

        },
        function () {

          con.test = false;
        }
      );
    };


    $rootScope.$on('connection_searchPattern', function (event, newValue) {
      for (var i = 0; i < $scope.connectionsList.length; i++) {
        var con = $scope.connectionsList[i];
        if (
          con.id in $scope.selectedToPagePreview &&
          $scope.selectedToPagePreview[con.id]
        ) {
          if (
            (Connection.searchInConnections(con, newValue) &&
              !Connection.searchInConnections(con, oldValue)) ||
            (!Connection.searchInConnections(con, newValue) &&
              Connection.searchInConnections(con, oldValue))
          ) {

            pagesListService.renderDca(
              $scope.$parent.group,
              con.banner_area_id,
              con.content_id,
              con.id,
              con,
              $scope.selectedToPagePreview[con.id]
            );
          }
        }
      }
    });

    $scope.changeBannerImagePosition = function (positionX, positionY, scale) {
      bannerList.changeBannerImagePosition(
        positionX,
        positionY,
        scale,
        $scope.info,
        $scope.fileData.settings
      );
    };

    $scope.onLocationChange = function () {
      var snapshoot = angular.copy($scope.currentConnection) || {};

      var _connectionData = angular.extend(snapshoot, {
        banner_area_id: $scope.info.id,
        banner_area_name: $scope.info.name,
        rule_id: $scope.activeConnectionObj.rule_id,
        tag: []
      });

      if (angular.isDefined(snapshoot) && snapshoot.id === -1) {
        subscriptionModel.update({
          operation: "post",
          data: _connectionData,
          type: "connection",
          projectid: window.api.projectid,
          who: { name: "", id: null }
        })
      }


      NavigationContext.subscribe("fallback", (data) => {
        void 0
        if (data.target === "textbox") {
          _connectionData = angular.extend(_connectionData, {
            content_id: data.resourceid.resourceid,
            resource_type: "textbox",
          });
        } else if (data.target === "rules") {
          _connectionData = angular.extend(_connectionData, { rule_id: data.ruleid });
        } else if (data.target === "banner") {
          _connectionData = angular.extend(_connectionData, {
            content_id: data.resourceid,
            resource_type: "banner"
          });
        } else {
          _connectionData = angular.extend(_connectionData, {
            content_id: data.resourceid,
            resource_type: "attachment",
            banner_area_id: null,
          });
        }
        subscriptionModel.update({
          operation: "put",
          data: _connectionData,
          type: "connection",
          projectid: window.api.projectid,
          who: { name: "", id: null }
        });
        Connection.resourceFallback = { ..._connectionData };

      });
    }

    function _removeTempConnection() {
      subscriptionModel.update({
        operation: "remove",
        data: { id: -1 },
        type: "connection",
        projectid: window.api.projectid,
        who: { name: "", id: null }
      });
    }

    $rootScope.$on('cancel_connection', _removeTempConnection);

    resize();
  }
]);
