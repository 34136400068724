ocCommonProviders.service("textEditorFilterService", [
  "utilityService",
  function(utilityService) {
    this.doFilter = function() {
      this.dataProcessor.htmlFilter.addRules({
        elements: {
          td: _styleFilter,
          div: _styleFilter,
          a: function(el) {
            if (!el.attributes.style) {
              el.attributes.contenteditable = "false";
              el.attributes.style =
                "color:#000;text-decoration: none; background-color:lightblue;cursor:default;";
            }
          }
        }
      });
    };

    function _styleFilter(el) {
      let elAttrsDict = [];
      let isSpanWithFontFamily = false;
      let isSpanWithFontSize = false;

      if ($(el.getOuterHtml()).find("span").length === 0) {
        el.setHtml(
          `<span style="font-size:12pt;"><span style="font-family:Arial;">${el.getHtml()}</span></span>`
        );
      }

      $(el.getOuterHtml())
        .find("span")
        .each((i, e) => {
          elAttrsDict.push({
            el: e,
            attrs: utilityService.getDOMElementAttrs($(e))
          });
        });

      elAttrsDict.forEach(item => {
        if (item.attrs.some(attr => attr.value.substr(0, 9) === "font-size")) {
          isSpanWithFontSize = true;
        }

        if (item.attrs.some(attr =>attr.value.substr(0, 11) === "font-family")) {
          isSpanWithFontFamily = true;
        }
      });

      if (!isSpanWithFontSize) {
        el.setHtml(`<span style="font-size:12pt;">${el.getHtml()}</span>`);
      }

      if (!isSpanWithFontFamily) {
        el.setHtml(`<span style="font-family:Arial;">${el.getHtml()}</span>`);
      }

      el.forEach(function(node) {
        if (node.name === "variable") {
          if (node.children.length === 0) {
            node.remove();
          }
        }
      });
    }
  }
]);
