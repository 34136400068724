module.service("menuService", ["$rootScope","menu","modal","userService",function($rootScope,menu,modal,userService) {

      this.showUserGuideFirtsTime=function($scope){
        setTimeout(function(){
        if(userService.isUserFirstTimeLogin()){
            _showUserGuide($scope);
        }},1000);
    }


    this.showUserGuide=_showUserGuide;

    this.toggleMenuItem=function(menuName,itemName,isHide){
        menu[menuName].filter(function(menuItem){
          return (menuItem.name===itemName);
        })[0].hide=isHide;
    }

    this.toggleClassForMenuItem=function(menuName,itemName,className){
       let menuItem=menu[menuName].filter(function(menuItem){
          return (menuItem.name===itemName);
        })[0];

        menuItem["class"]=className;
      }

    this.getMenuByName=function(name){
      return menu[name];
    }

    function _showUserGuide($scope) {
      $rootScope.$emit("LOADON");
      var wizz = modal.wizard(
        {
          message: "Its Magic",
          ok: "Ok",
          cancel: ""
        },
        function() {
        },
        function() {},
        function() {},
        $scope
      );
    };


}]);