ocCommonProviders.directive("pagePreviewElementDirective", ["db", "api", "login", "$q", "$interval", "pagesListService",
    "attachmentTargetList", "bannerList", "adminService","userService",
    function(db, api, login, $q, $interval, pagesListService, attachmentTargetList, bannerList, adminService,userService) {
        "use strict";
        return {
            restrict: "C",
            scope: {
                page: "=",
                actions:"<"
            },
            templateUrl:function(el,attrs) {
                return "/static/partials/"+attrs.template+".html";
            },
            link: function(scope, element, attrs) {
                scope.attStart = false;
                pagesListService.selectedAttachmentId = 0;
                pagesListService.dcaList = [];
                scope.banner_areas_without_background = [];
                scope.banner_areas_connection = [];
                scope.drawed_connections = [];
                scope.isAdmin=()=>userService.isAdmin();
                scope.selectedPage = scope.page.id;
                scope.selectedFlow = window.api.projectid;
                scope.isBackground = true;
                scope.deleteBackground = function() {
                    if (!scope.backgroundPreviewUrl) {
                        return;
                    }

                    adminService.deleteBackground(scope).then(function() {
                        if(!scope.actions){
                            scope.$parent.$parent.showPartial(3);
                        }
                    });
                }
                scope.uploadBackgroundSuccess = function() {
                        if(!scope.actions){
                            scope.$parent.$parent.showPartial(3);
                        }else{
                            _loadImage(scope, scope.page.background_id).then(_generateFabric(scope, element),
                            function() {});
                        }
                    }


                scope.$on("DRAWPREVIEW", function(event, args) {
                    if (typeof scope.page.banner_areas !== "undefined") {
                        for (var i = 0; i < scope.page.banner_areas.length; i++) {
                            if (scope.page.banner_areas[i].id === args.banner_id) {
                                scope.banner_areas_without_background[args.banner_id] = false;
                                scope.banner_areas_connection[args.banner_id] = null;
                                if (!args.is_connection_open) {
                                    scope.banner_areas_without_background[args.banner_id] = true;
                                    if (args.connection.resource_type == "banner")
                                        scope.banner_areas_connection[args.banner_id] = args.connection;
                                }

                                _generateFabric(scope, element,args,i);;
                                break;
                            }
                        }


                    } else {
                        if (scope.page.name == "attachments" && typeof args.group != "undefined" && args.group.name == "Attachments") {
                            if (typeof args.attachment_id !== "undefined") {
                                _loadImage(scope, args.attachment_id).then(
                                    function() {
                                        _generateAttachmentFabric(scope, element, args.attachment_id, args.group, args.connection.id, args.is_connection_open);
                                    },
                                    function() {});
                            } else {
                                scope.fabricInstance.clear();
                            }
                        }
                    }

                });

                scope.downloadFile = function(page) {
                    if (typeof scope.backgroundPreviewId !== "undefined" && scope.backgroundPreviewId !== null)
                        attachmentTargetList.downloadFile(scope.backgroundPreviewId);
                };

                scope.backgroundPreviewUrl = null;
                scope.fabricInstance = null;
                scope.attachmentList = [];
                if (scope.page.name !== "attachments") {
                    if (typeof scope.page.background_id !== "undefined" && scope.page.background_id !== null) {
                        _loadImage(scope, scope.page.background_id).then(_generateFabric(scope, element),
                            function() {});
                    } else {
                        _generateFabric(scope, element);
                    }
                } else {
                    var hook;
                    for (var i = 0; i < scope.$parent.groups.length; i++) {
                        if (scope.$parent.groups[i].name === "Attachments" || scope.$parent.groups[i].name === "Enclosures") {
                            if (typeof scope.$parent.groups[i].connections[0] != "undefined") {
                                hook = scope.$parent.groups[i];

                            } else {
                                $(".backgroundImage").css("display", "none");
                            }

                        } 
                    } 
                }
            }
        };

        function _checkDca(id, pagesListService, dca, connection_id) {
            for (var i = 0; i < pagesListService.dcaList.length; i++) {

                if (pagesListService.dcaList[i].id == id && pagesListService.dcaList[i].dca.id == dca.id && pagesListService.dcaList[i].connection_id == connection_id) {
                    return pagesListService.dcaList[i];
                }
            }
        }

        function _dcaMatchPage(scope) {
            var matched = [];
            for (var i = 0; i < pagesListService.dcaList.length; i++) {
                if (pagesListService.dcaList[i].active === true && pagesListService.dcaList[i].visible === true) {
                    for (var j = 0; j < scope.page.banner_areas.length; j++) {
                        if (scope.page.banner_areas[j].id == pagesListService.dcaList[i].dca.id) {
                            matched.push(pagesListService.dcaList[i]);
                        } 
                    }
                } 
            }

            return matched;
        }

        function _hideOtherDCA(dca, connection_id) { 
            for (var i = 0; i < pagesListService.dcaList.length; i++) {
                if (pagesListService.dcaList[i].connection_id == connection_id) {
                    if (pagesListService.dcaList[i].active !== true || typeof pagesListService.dcaList[i].active == "undefined")
                        pagesListService.dcaList[i].active = true;
                    else
                        pagesListService.dcaList[i].active = false;
                } else {
                    if (pagesListService.dcaList[i].dca.id == dca.id)
                        pagesListService.dcaList[i].active = false;

                }
            }
        }


        function _drawelements(list, scope) {
            for (var i = 0; i < list.length; i++) {
                if ((list[i].active === true && list[i].visible === true)) {
                    var connectionTmp = scope.banner_areas_connection[list[i].dca.id];
                    if (connectionTmp) {
                        var data = {
                            resourceid: connectionTmp.content_id,
                            connectionId: connectionTmp.id
                        }

                        connectionTmp.domElement = list[i].domElement[0];
                        connectionTmp.dca = list[i].dca;
                        scope.drawed_connections[connectionTmp.id] = connectionTmp;

                        bannerList.loadBannerData(scope, data).then(function(data) {
                            var connection = scope.drawed_connections[data.connectionId];
                            var dca = connection.dca;
                            var imgInstance = new fabric.Image(connection.domElement, {
                                angle: 0,
                                opacity: 1.0,
                                scaleX: 1.27,
                                scaleY: 1.27
                            });

                            var size = bannerList.calculateImageSize(dca, imgInstance.getOriginalSize(), connection.scale, data.settings);
                            var width = size.width;
                            var height = size.height;
                            var customScale = 1.27;
                            var positionX = connection.horizontal_align;
                            var positionY = connection.vertical_align;

                            imgInstance.scaleToWidth(width * customScale);
                            imgInstance.scaleToHeight(height * customScale);

                            if (positionY == "top") {
                                imgInstance.setTop(dca.shape.y * customScale);
                            }
                            if (positionY == "bottom") {
                                imgInstance.setTop((dca.shape.y + (dca.shape.height - height)) * customScale);
                            }
                            if (positionY == "center") {
                                imgInstance.setTop((dca.shape.y + (dca.shape.height - height) / 2) * customScale);
                            }

                            if (positionX == "left") {
                                imgInstance.setLeft(dca.shape.x * customScale);
                            }
                            if (positionX == "right") {
                                imgInstance.setLeft((dca.shape.x + (dca.shape.width - width)) * customScale);
                            }
                            if (positionX == "center") {
                                imgInstance.setLeft((dca.shape.x + (dca.shape.width - width) / 2) * customScale);
                            }

                            scope.fabricInstance.add(imgInstance);
                        })
                    } else {
                        var imgInstance = new fabric.Image(list[i].domElement[0], {
                            left: list[i].dca.shape.x * 1.27,
                            top: list[i].dca.shape.y * 1.27,
                            height: list[i].dca.shape.height,
                            width: list[i].dca.shape.width,
                            angle: 0,
                            opacity: 1.0,
                            scaleX: 1.27,
                            scaleY: 1.27
                        });
                        scope.fabricInstance.add(imgInstance);
                    }

                }

            }
        }

        function _drawTextBox(attid, dca, scope, element, pagesListService, connection_id, group, connection) {
            void 0
            var imgContainer = angular.element(element[0].querySelector(".imageContainer"));
            var dummyImg = angular.element("<img src=\"\" alt=\"\"/>");
            var temp = _checkDca(attid, pagesListService, dca, connection_id);
            if (typeof temp == "undefined") {
                if (typeof attid != "undefined") {
                    pagesListService.dcaList.push({
                        id: attid,
                        active: false,
                        visible: true,
                        domElement: dummyImg,
                        dca: dca,
                        connection_id: connection_id
                    });
                    imgContainer.append(dummyImg);
                }
            } else {
                dummyImg = temp.domElement;
            }

            if (typeof connection_id != "undefined")
                _hideOtherDCA(dca, connection_id);

            if (typeof attid != "undefined") {
                const onSuccess = function (data) {
                  _loadSVGFabric(data.settings.text, dca,data.settings.background_color).then(function(src) {
                    dummyImg.attr("src", src);
                    dummyImg.css("display", "none");
                    dummyImg.unbind("load");
                    dummyImg.bind("load", function() {
                      var list = _dcaMatchPage(scope);
                      _drawelements(list, scope);
                      scope.fabricInstance.renderAll();
                    });
                    imgContainer.append(dummyImg);
                  });
                };
                window.api.resourceGet(connection.content_id, onSuccess);
            } else {
                var list = _dcaMatchPage(scope);
                _drawelements(list, scope);
            }
        }

        function _loadSVGFabric(element, dca,bgColor) {
            var deffered = $q.defer();
            var textHtml = '';
            element = element.replace(/contenteditable="false"/g, 'contenteditable="true"');
            var filter = new CKEDITOR.htmlParser.filter({
                elements: {
                    variable: function(el) {
                        if (el.attributes.test) {
                            el.setHtml(el.getHtml().replace('{' + el.attributes.original + '}', el.attributes.test));
                        }
                    }
                }
            });

            var fragment = CKEDITOR.htmlParser.fragment.fromHtml(element),
                writer = new CKEDITOR.htmlParser.basicWriter();

            filter.applyTo(fragment);
            fragment.writeHtml(writer);
            textHtml = writer.getHtml();
            var parsedBody = $(document.createElement('body'));
            parsedBody.attr("style", "line-height:normal;width:" + (dca.shape.width || 210) * 3.76 + "px;font-family: Helvetica Neue, Helvetica, Arial, sans-serif;");
            parsedBody.append(textHtml);
            parsedBody.appendTo(document.body);
            var el = parsedBody[0];

            var width = (dca.shape.width || 210) * 3.76;
            var height = (dca.shape.height || 297) * 4;
            var html = (new XMLSerializer).serializeToString(el);
            var tmpHeight = height + 50;
            void 0
            var body = '<svg xmlns="http://www.w3.org/2000/svg"  width="' + width + 'px" height="' + tmpHeight + 'px">' +
                '<style>svg { background-color: '+ bgColor+'; }</style>'+
                '<foreignObject width="100%" height="100%" fill="red">' +
                html +
                '</foreignObject>' +
                '</svg>';

            var elemHeight = el.clientHeight;
            parsedBody.remove();

            var DOMURL = window.URL || window.webkitURL || window;
            var svg = new Blob([body], {
                type: 'image/svg+xml'
            });

            var url = DOMURL.createObjectURL(svg);
            deffered.resolve(url);

            return deffered.promise;
        }


        function _drawDca(bid, attid, dca, scope, element, pagesListService, connection_id, group, connection) {
            var imgContainer = angular.element(element[0].querySelector(".imageContainer"));
            var dummyImg = angular.element("<img src=\"\" alt=\"\"/>");
            var temp = _checkDca(attid, pagesListService, dca, connection_id);
            if (typeof temp == "undefined") {
                if (typeof attid != "undefined") {
                    pagesListService.dcaList.push({
                        id: attid,
                        active: false,
                        visible: true,
                        domElement: dummyImg,
                        dca: dca,
                        connection_id: connection_id,
                        dca_id: bid
                    });
                    imgContainer.append(dummyImg);
                }
            } else {
                dummyImg = temp.domElement;
            }
            if (typeof connection_id != "undefined")
                _hideOtherDCA(dca, connection_id);

            if (typeof attid != "undefined")
                _loadImage(scope, attid, dummyImg).then(function(src) {
                    dummyImg.attr("src", src);
                    dummyImg.css("display", "none");
                    dummyImg.unbind("load");
                    dummyImg.bind("load", function() {
                        var list = _dcaMatchPage(scope);
                        _drawelements(list, scope);
                        scope.fabricInstance.renderAll();
                    });
                });
            else {
                var list = _dcaMatchPage(scope);
                _drawelements(list, scope);
            }
        }

        function _loadImage(scope, resourceId, target) {
            var deffered = $q.defer();
            var url = null;

            if (resourceId !== null) {
                url = window.api.getResourceUrl(resourceId, 'thumbnail');
            } else {
                deffered.reject();
                return deffered.promise;
            }

            _loadImageToDom(url).then(function(data) {
                if (typeof target !== "undefined") {
                    target.src = data.src;
                } else {
                    if (scope.backgroundPreviewId === resourceId)
                        scope.backgroundPreviewId = null;
                    else
                        scope.backgroundPreviewId = resourceId;
                    scope.backgroundPreviewUrl = data.src;
                }
                deffered.resolve(data.src);
            });

            return deffered.promise;
        }

        function _loadImageToDom(url) {
            var deffered = $q.defer();
            var image = new Image();

            if (image.complete) {
                deffered.resolve(image);
            } else {
                image.addEventListener("load", function() {
                    deffered.resolve(image);
                });
                image.addEventListener("error", function() {
                    deffered.reject(image);
                });
            }

            image.src = url;
            return deffered.promise;
        }

        function getDims(stringValue) {
            var result = {
                w: null,
                h: null
            };
            var o = $("<div>" + stringValue + "</div>")
                .css({
                    "position": "absolute",
                    "float": "left",
                    "white-space": "nowrap",
                    "visibility": "hidden",
                    "font": "12px Noto Sans"
                })
                .appendTo($("body"));
            result.w = o.width();
            result.h = o.height();
            o.remove();
            return result;
        }

        function addTextArea(scope, stringValue, i) {
            var dims = getDims(stringValue);
            var text = new fabric.Text(" " + stringValue, {
                top: (scope.page.banner_areas[i].shape.y * 1.270 + (scope.page.banner_areas[i].shape.height * 1.270 - dims.h) / 2),
                left: (scope.page.banner_areas[i].shape.x * 1.270 + (scope.page.banner_areas[i].shape.width * 1.270 - dims.w) / 2 - 3),
                fontSize: 12,
                fontFamily: "Noto Sans"
            });
            scope.fabricInstance.add(text);
        }

        function fixStringLength(scope,bgColor) {
            var dims = null;
            if (typeof scope.page.banner_areas != "undefined") {
                for (var i = 0; i < scope.page.banner_areas.length; i++) {
                    if (scope.banner_areas_without_background[scope.page.banner_areas[i].id]) {
                        scope.fabricInstance.add(_quickFabricRect(scope.page.banner_areas[i].shape, false,bgColor));
                    } else {
                        scope.fabricInstance.add(_quickFabricRect(scope.page.banner_areas[i].shape, true,bgColor));
                        dims = getDims(scope.page.banner_areas[i].name);
                        if (scope.page.banner_areas[i].shape.width * 1.270 > 2 + dims.w && scope.page.banner_areas[i].shape.height * 1.270 > 2 + dims.h) {
                            addTextArea(scope, scope.page.banner_areas[i].name, i);
                        } else {
                            dims = getDims(scope.page.banner_areas[i].name.substring(0, 3) + "...");
                            if (scope.page.banner_areas[i].shape.width * 1.270 > 2 + dims.w && scope.page.banner_areas[i].shape.height * 1.270 > 2 + dims.h) {
                                addTextArea(scope, scope.page.banner_areas[i].name.substring(0, 3) + "...", i);
                            }
                        }
                    }
                }
            }
        }

        function _generateFabric(scope, element, args,index) {
            void 0
            var deferred = $q.defer();
            var imgContainer = angular.element(element[0].querySelector(".imageContainer img.backgroundImage"));
            var fabricContainer = angular.element(element[0].querySelector(".fabricContainer"));
            if (typeof scope.fabricInstance !== "undefined" && scope.fabricInstance === null) {
                scope.fabricInstance = new fabric.StaticCanvas(fabricContainer[0]);
            }
            scope.fabricInstance.clear();
            scope.fabricInstance.setHeight(380);
            scope.fabricInstance.setWidth(268);
            scope.fabricInstance.renderAll();

            imgContainer.on("load", function() {
                if (scope.page.background_id !== null) {
                    var imgInstance = new fabric.Image(imgContainer[0], {
                        left: 0,
                        top: 0,
                        angle: 0,
                        opacity: 1.0,
                        scaleX: 1.0,
                        scaleY: 1.0,
                    });

                    scope.fabricInstance.add(imgInstance);
                }
                scope.fabricInstance.renderAll();
                var text;
                var o;
                var dims; 
                fixStringLength(scope);
                scope.fabricInstance.renderAll();
                imgContainer.css("display", "none");
            });


            if (scope.page.background_id !== null) {
                var imgInstance = new fabric.Image(imgContainer[0], {
                    left: 0,
                    top: 0,
                    angle: 0,
                    opacity: 1.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                });

                scope.fabricInstance.add(imgInstance);
            }

            scope.fabricInstance.renderOnAddRemove = false;
            fixStringLength(scope);
            scope.fabricInstance.renderOnAddRemove = true;

            scope.fabricInstance.renderAll();

            scope.fabricInstance.on("object:added", function(elem) {
                deferred.resolve();
            });


            if (args && args.connection.resource_type == "banner"){
                _drawDca(args.banner_id, args.attachment_id, scope.page.banner_areas[index], scope, element, pagesListService, args.connection_id, args.group, args.connection)
            }
            if (args && args.connection.resource_type == "textbox"){
                _drawTextBox(args.attachment_id, scope.page.banner_areas[index], scope, element, pagesListService, args.connection_id, args.group, args.connection);
                fixStringLength(scope,args.connection.background_color);
            }
            return deferred.promise;
        }


        function _generateAttachmentFabric(scope, element, attachment_id, group, connection_id, is_connection_open) {
            var deferred = $q.defer();
            var imgContainer = angular.element(element[0].querySelector(".imageContainer img.backgroundImage"));
            var fabricContainer = angular.element(element[0].querySelector(".fabricContainer"));
            var attachment = {
                attachment_id: attachment_id,
                active: !is_connection_open,
                visible: true,
                domElement: imgContainer,
                group: group,
                connection_id: connection_id
            };
            var selected = null;

            if (pagesListService.selectedAttachmentId == attachment_id && pagesListService.selectedAttachmentConnectionId == connection_id) {
                pagesListService.selectedAttachmentId = 0;
                pagesListService.selectedAttachmentConnectionId = 0;
            } else {
                pagesListService.selectedAttachmentId = attachment_id;
                pagesListService.selectedAttachmentConnectionId = connection_id;
                selected = attachment;
            }

            if (typeof selected != "undefined" && selected !== null && typeof selected.attachment_id != "undefined") {
                imgContainer.off("load");
                imgContainer.on("load", function() {

                    scope.fabricInstance = new fabric.StaticCanvas(fabricContainer[0]);
                    scope.fabricInstance.setHeight(380);
                    scope.fabricInstance.setWidth(268);
                    scope.fabricInstance.renderAll();

                    if (selected) {

                        selected.domElement.css("display", "block");
                        if (scope.page.background_id !== null) {
                            var imgInstance = new fabric.Image(selected.domElement[0], {
                                left: 0,
                                top: 0,
                                angle: 0,
                                opacity: 1.0,
                                scaleX: 1.0,
                                scaleY: 1.0,
                            });
                            scope.fabricInstance.add(imgInstance);
                        }
                    }
                    scope.fabricInstance.renderAll();
                    imgContainer.css("display", "none");
                    scope.fabricInstance.renderAll();
                    scope.fabricInstance.on("object:added", function(elem) {
                        deferred.resolve();
                    });
                    return deferred.promise;
                });
                scope.fabricInstance = new fabric.StaticCanvas(fabricContainer[0]);
                scope.fabricInstance.setHeight(380);
                scope.fabricInstance.setWidth(268);
                scope.fabricInstance.renderAll();

                if (selected) {
                    selected.domElement.css("display", "block");
                    if (scope.page.background_id !== null) {
                        var imgInstance = new fabric.Image(selected.domElement[0], {
                            left: 0,
                            top: 0,
                            angle: 0,
                            opacity: 1.0,
                            scaleX: 1.0,
                            scaleY: 1.0,
                        });
                        scope.fabricInstance.add(imgInstance);
                    }
                }
                imgContainer.css("display", "none");
                scope.fabricInstance.renderAll();

            } else {
                scope.fabricInstance = new fabric.StaticCanvas(fabricContainer[0]);
                scope.fabricInstance.setHeight(380);
                scope.fabricInstance.setWidth(268);
                scope.fabricInstance.renderAll();
            }

            imgContainer.css("display", "none");


        }

        function _quickFabricRect(shape, selected,bgColor) {
            var dca = new fabric.Rect({
                left: shape.x * 1.270,
                top: shape.y * 1.270,
                width: shape.width,
                height: shape.height,
                opacity: 1.0,
                scaleX: 1.270,
                scaleY: 1.270,
                fill: (bgColor|| "")
            });

            if (selected)
                dca.set({
                    strokeWidth: 1,
                    stroke: "rgba(103,112,124,0.5)",
                    fill: "rgba(206, 214, 229,0.5)"
                });

            return dca;
        }
    }
]);
