 const urlEditorConfig= {
    language: "en",
    contentsCss: [".cke_variable{background-color:yellow;white-space:pre;} .cke_editable a{text-decoration:none;color:inherit;background-color:#a3d1f7;white-space:pre;}"],
    customConfig: false,
    extraPlugins: "variable,border,linkCustom,pastefromword",
    removePlugins: "elementspath,magicline,copyformatting,wsc,scayt",
    pasteFromWordCleanupFile:"/ckeditor/plugins/pastefromword/filter/default.js",
    autoGrow_onStartup: false,
    extraAllowedContent: "span[*]{*};div[*];variable[*]{*};a[*]",
    font_defaultLabel: "Arial",
    fontSize_defaultLabel: "12pt",
    pasteFilter: null,
    enterMode: CKEDITOR.ENTER_DIV,
    linkShowAdvancedTab: false,
    linkShowTargetTab: false,
    font_names: null,
    pasteFromWordPromptCleanup : true,
    pasteFromWordRemoveFontStyles :true,
    dialog_noConfirmCancel:true,
    allowedContent:true,
    height: "76px",
    resize_enabled: false,
    fontSize_sizes:
      "8pt/8pt;9pt/9pt;10pt/10pt;11pt/11pt;12pt/12pt;14pt/14pt;16pt/16pt;18pt/18pt;20pt/20pt;22pt/22pt;24pt/24pt;26pt/26pt;28pt/28pt;36pt/36pt",
    toolbar: [
      {
        name: "insert",
        items: ["Variable"]
      }
    ]
}

ocCommonProviders.run(["ckeditorService","variableProperties",function(ckeditorService,variableProperties){
  ckeditorService.registerDefaultConfig("urlEditorConfig",angular.extend(urlEditorConfig,variableProperties.getConfig()));
}]);