module.controller("conectionsController", [
  "$scope",
  "$timeout",
  "$q",
  "login",
  "db",
  "modal",
  "api",
  "attachmentTargetList",
  "serverTime",
  "$location",
  "errorHandlingService",
  "$window",
  "userService",
  "$rootScope",
  "$uibModal",
  "pagesListService",
  "bannerList",
  "flowService",
  "Connection",
  "utilityService",
  "rulesService",
  "growlHandlingService",
  "subscriptionModel",
  "navigationContextService",
  function (
    $scope,
    $timeout,
    $q,
    login,
    db,
    modal,
    api,
    attachmentTargetList,
    serverTime,
    $location,
    errorHandling,
    $window,
    userService,
    $rootScope,
    $uibModal,
    pagesListService,
    bannerList,
    flowService,
    Connection,
    utilityService,
    rulesService,
    growlHandlingService,
    subscriptionModel,
    NavigationContext) {

    var transform=(index,change)=>{
      $scope.connectionsList[index]={
      ...change.data,
      valid_from: change.data.valid_from_utc ? moment.utc(change.data.valid_from_utc).local().format("YYYY-MM-DD"): null,
      valid_to:  change.data.valid_to_utc ? moment.utc(change.data.valid_to_utc).local().format("YYYY-MM-DD"):null,}
      $scope.connectionsList[index].lock=true;
    }

    var _subscribeConnection = function(change) {
      if(change.operation==="put"){
        for(var i=0; i<$scope.connectionsList.length;i++){
          if($scope.connectionsList[i].id===change.data.id){
            $timeout(()=>{
              transform(i,change);
            },0);
            break;
          }
        }
      }else if(change.operation==="post"){
        for(var i=0; i<$scope.connectionsList.length;i++){
          if($scope.connectionsList[i].id===-1){
            $timeout(()=>{
              transform(i,change);
            },0);
            break;
          }
        }
      }

    };


    $scope.$on("$destroy", function() {
      subscriptionModel.off("connection", _subscribeConnection);
    });

    subscriptionModel.on("connection", _subscribeConnection);

    $rootScope.$broadcast("CHANGE_TAB", "Connections");

    Connection.initController($scope, "normal");
    var vm = this;
    $scope.dcaBannersList = null;
    $scope.connectionPreview = "att";
    $scope.pagesListService = pagesListService;
    $scope.userService = userService;
    $scope.pagesPreviewList = null;
    $scope.dcaViewMain = { name: "Attachments"};
    $scope.dcaView = null;
    $scope.dcaViewBackup = null;
    $scope.rulesDetailsList = [];
    $scope.setActiveProject = utilityService.setActiveProject;
    $scope.vm = {
      searchPattern: "",
      attachmentSearchCallback: attachmentTargetList.resourceFilter
    };

    $scope.dcaSwitch = function (index, isLoaded, val) {
      function loadBanner(idx) {
        elem = parseInt(idx);
        $scope.dcaView = idx;
        $scope.connectionPreview = $scope.dcaSwitchList[idx].view;
        pagesListService.selectPageElement(idx);

        pagesListService
          .loadImage($scope, $scope.dcaSwitchList[idx].bgid, "obj")
          .then(null, null);

        bannerList.loadBannerList($scope, "banner").then(function (resources) {
          $scope.dcaBannersList = resources;
        });
      }

      if (isLoaded) {
        for (var i = 0; i < $scope.dcaSwitchList.length; i++) {
          if (index === $scope.dcaSwitchList[i].bid) {
            loadBanner(i);
          }
        }
      }

      if ($scope.selectedAttachmentTableElement === null && !isLoaded) {
        loadBanner(index);
      }

      if ($scope.selectedAttachmentTableElement !== null && !isLoaded) {
        modal.confirm(
          {
            message: "Do you want to clear Banner selection?",
            ok: "Yes",
            cancel: "No"
          },
          function () {
            $scope.selectedAttachmentTableElement = null;
            $scope.fileData = null;
            loadBanner(index);
            $scope.dcaViewBackup = null;
          },
          function () {
            if ($scope.dcaViewBackup === null) {
              $scope.dcaViewBackup = val;
            }

            var element = document.getElementById("dcaSwitchList");
            element.value = $scope.dcaViewBackup;
            $scope.dcaView = $scope.dcaViewBackup;
          }
        );
      }
    };

    var resize = function (second) {
      var maxWidth = utilityService.getDocWidth();
      var maxHeight = utilityService.getDocHeight();
      var bl = angular.element(".blockingLayer");
      bl.css({
        width: maxWidth + "px",
        height: maxHeight + "px"
      });
      if (second)
        $timeout(function () {
          $scope.$apply();
        });
    };

    angular.element($window).bind("resize", function () {
      resize(true);
    });

    $scope.filterTestedConnections = function (value, index, filter, add) {
      return Connection.filterTestedConnections(value, index, filter);
    };

    $scope.filterStatuses = function (value, index, filter, add) {
      return Connection.filterStatuses(
        $scope.loading[value.id],
        $scope.statusNames,
        value,
        $scope.filter
      );
    };

    $scope.onChange=function(searchPattern){
      $scope.attachementSearch=searchPattern;
      $rootScope.$emit("connection_searchPattern",searchPattern);
    }

    RegExp.escape = (function (text) {
      var specials = [
        "$",
        "^",
        "/",
        ".",
        "*",
        "+",
        "?",
        "|",
        "(",
        ")",
        "[",
        "]",
        "{",
        "}",
        "\\"
      ];
      return text.replace(new RegExp(
        "(\\" + specials.join("|\\") + ")",
        "g"
      ), "\\$1");
    });

    $scope.setFiltering = function (setFilters) {
      Connection.setFiltering($scope.filter, setFilters);
    };

    $scope.$watch("dcaBannersList", function () {
      if (
        $scope.activeConnection !== null &&
        $scope.activeConnectionObj !== null
      ) {
        $scope.selectBannerTableElementById(
          $scope.activeConnectionObj.content_id
        );
      }
    });

    $scope.getConnectionIndexById = function (id, connections) {
      return Connection.getConnectionIndexById(
        id,
        connections,
        $scope.connectionsList
      );
    };

    $scope.prepareConnectionsListOnLoad = function () {
      Connection.prepareConnectionsListOnLoad(
        $scope.connectionsList,
        $scope.statusNames
      );
    };

    $scope.updateStatus = function () {
      Connection.updateStatus($scope.connectionsList, $scope.statusNames);
    };

    $scope.changeConnectionState = function (id, con) {
      Connection.changeConnectionState(
        $scope.statusNames,
        $scope.loading[con.id],
        id,
        con
      );
      $scope.updateStatus();
    };

    $scope.compareObjects = function (ob1, ob2, different) {
      return attachmentTargetList.compareObjects2(ob1, ob2, different);
    };
    $scope.backupConnection = function (con) {
      $scope.connectionsBackup[con.id] = angular.copy(con);
    };

    $scope.changeTabState = function (id, con) {
      var evt =document.createEvent('Event');evt.initEvent('RESCALE',true,true);document.dispatchEvent(evt);
      var loadData = utilityService.loadData;

      if (!utilityService.checkIfChangable(con, $scope)) return false;

      $rootScope.$emit("LOADON");


      utilityService.resetConnectionValues($scope);

      $scope.backupConnection(con);
      if (!$scope.loading[con.id]) {
        $scope.closeAllConnectionsTabs();

        utilityService.resetConnectionValuesForRequests($scope);

        var attach = [];
        var ban = [];
        var target = [];
        var promises = [];

        if (con.banner_area_id === null) {
          promises.push(
            attachmentTargetList
              .loadAttachmentList($scope, "attachment", con.content_id)
              .then(function (resources) {
                attach = resources;
                if (attach.length > 0) {
                  loadData(id, con, $scope);
                }
              })
              .then(function () {
                if (con.lock) {
                  $scope.attachmentList = attach.filter(function (item) {
                    return item.id === con.content_id;
                  });
                }
              })
          );
        } else {
          promises.push(
            bannerList
              .loadBannerList($scope, "banner", con.content_id)
              .then(function (resources) {
                ban = resources;
                if (ban.length > 0) {
                  $scope.dcaSwitch(con.banner_area_id, true);
                  var findedBannerObj;
                  for (var i = 0; i < ban.length; i++) {
                    if (ban[i].id === con.content_id) findedBannerObj = ban[i];
                  }
                  pagesListService.initDrawings($scope).then(function () {
                    bannerList
                      .loadBannerImage($scope, con.content_id, findedBannerObj)
                      .then(function () {
                        loadData(id, con, $scope);
                      });
                  });
                }
              })
          );
        }

        promises.push(
          attachmentTargetList
            .loadAttachmentList($scope, "targetlist", con.targetlist_id)
            .then(function (resources) {
              target = resources;
            })
        );

        promises.push(
          rulesService.getRules(true).then(function (data) {
            if (data.length > 0) loadData(id, con, $scope);
            $scope.setRules(data);
          })
        );

        $q.all(promises).then(function () {
          if (
            typeof $scope.activeConnectionObj !== "undefined" &&
            $scope.activeConnectionObj !== null
          ) {
            $scope.selectAttachmentTableElementById(
              $scope.activeConnectionObj.content_id
            );
            $scope.selectRuleById($scope.rules);
          }

          if (target.length > 0 && (ban.length > 0 || attach.length > 0)) {
            if (attach.length > 0) {
              loadData(id, con, $scope);
            }
            if (ban.length > 0) {
              $scope.dcaSwitch(con.banner_area_id, true);
              var findedBannerObj = {
                settings: {
                  original_width: 0,
                  original_height: 0
                }
              };
              bannerList
                .loadBannerImage($scope, con.content_id, findedBannerObj)
                .then(function () {
                  loadData(id, con, $scope);
                });
            }
          }

          $rootScope.$emit("LOADOFF");
        });

        $scope.loading[con.id] = !$scope.loading[con.id];
      } else {
        $scope.activeConnection = null;
        $scope.activeConnectionObj = null;

        loadData(id, con, $scope);
        $scope.loading[con.id] = !$scope.loading[con.id];
        $rootScope.$emit("LOADOFF");
      }
    };

    $scope.openTab = function (id, con) {
      $scope.closeAllConnectionsTabs();
      $scope.loading[con.id] = true;
    };

    $scope.closeTab = function (id, con) {
      $scope.loading[con.id] = false;
    };

    $scope.showPartial = function (partialId) {
      $scope.logsListHide = true;
      $scope.activePartial = partialId;
      if ($scope.activePartial === 0 && $scope.activeProject > -1) {
        $rootScope.$emit("LOADON");
        $scope.loadConnectionList(true);
      }
    };

    $scope.hideLogs = function () {
      $rootScope.$broadcast("showNotifications", false);
    };

    $scope.$on("showNotifications", function (event, mass) {
      if (mass || typeof mass === "undefined") {
        $scope.showLog = !$scope.showLog;
      } else {
        $scope.logonoffswitch = false;
        $scope.showLog = false;
      }
    });

    $scope.getConnectionStatusActionName = function (id, con) {
      return Connection.getConnectionStatusActionName(
        $scope.statusNames,
        $scope.loading[con.id],
        con,
	    	$scope.info
      );
    };

    $scope.connectionCopyHandler=function(group,con){
      modal.confirm(
        {
          message:`Are you sure that you want copy the connection: '${con.title}' ?`,
          ok: "Yes",
          cancel: "No"
        },
        function() {
         initConnectionCopy(group,con);
        }
      );
    }

    function initConnectionCopy(group,con){
      const connection = {
        title: "",
        status: $scope.statusNames.edit,
        content_id: con.content_id,
        description: con.description,
        lock: false,
        isnew: true,
        valid_from: con.valid_from,
        valid_to: con.valid_to,
        textbox_value:con.textbox_value,
        tag:con.tag,
        rule_name:con.rule_name,
        resource_type:con.resource_type,
        rule_id:con.rule_id,
        page_id:con.page_id,
        parent_id: con.id,
        background_color:con.background_color,
        banner_area_id:con.banner_area_id,
        priority: $scope.getHighestPriority(),
        id: -1,
        editing_by: db.get("login", "usernameString"),
        matched_total: con.matched_total,
        sent_total: con.send_total,
        test: con.test,
        horizontal_align: con.horizontal_align,
        project_id:con.project_id,
        vertical_align: "top",
        scale: con.scale,
        url: con.url
    }

    $scope.loadAttachmentList($scope,"create").then((resources)=>{
      $scope.connectionsList.unshift(connection);
      $scope.attachmentList = resources;
      $scope.activeConnection = $scope.connectionsList.length - 1;
      $scope.activeConnectionObj = connection;
      $scope.backupConnection(connection);
      $scope.blockAllExceptConnection(connection);
      $scope.scrollTo("con_" + connection.id, true);
      $scope.openTab($scope.connectionsList.length - 1, connection);

}).then(()=>$scope.loadEnclosureList());}



    $scope.getActiveProjectId = function () {
      return window.api.projectid;
    };


    $scope.getIdByIndex = function (type, index) {
      if (type === "attachments") {
        return $scope.attachmentList[index].id;
      }
      if (type === "enclosures") {
        return $scope.enclosureList[index].id;
      }
    };

    $scope.getHighestPriority = function () {
      return utilityService.getHighestPriority($scope);
    };
    $scope.getLowestPriority = function () {
      return utilityService.getLowestPriority($scope);
    };



    $scope.createNewEmptyConnection = function () {
      utilityService.emptyConnectionInit($scope);

      const connection = {
        title: "",
        status: $scope.statusNames.edit,
        content_id: 0,
        description: "",
        lock: false,
        isnew: true,
        valid_from: "",
        valid_to: "",
        priority: $scope.getHighestPriority(),
        id: -1,
        editing_by: db.get("login", "usernameString"),
        matched_total: 0,
        sent_total: 0,
        test: false
      };



      $scope.loadAttachmentList($scope,"create").then((resources)=>{
            $scope.connectionsList.unshift(connection);
            $scope.attachmentList = resources;
            $scope.activeConnection = 0;
            $scope.activeConnectionObj = connection;
            $scope.backupConnection(connection);
            $scope.blockAllExceptConnection(connection);
            $scope.scrollTo("con_" + connection.id, true);
            $scope.openTab($scope.activeConnection , connection);

      }).then(()=>$scope.loadEnclosureList())
    };

    $scope.selectAttachmentTableElement = function (con, att) {
      if (!con.lock && con.matched_total === 0) {
        $scope.selectedAttachmentTableElement = att.id;
        attachmentTargetList.fillWithData($scope, {
          resourceid: att.id
        });
        $scope.selectedConvertions = [];
        $scope.changeSelectedAttachment(con);
        $scope.changeSelectedEnclosure(con);
      }
    };
    $scope.selectBannerTableElement = function (con, att) {
      if (!$scope.checkIsSelectDCA()) {
        return false;
      }
      if ($scope.checkSize(att)) {
        return false;
      }

      if (!con.lock && con.matched_total === 0) {
        var previousElement = $scope.selectedAttachmentTableElement;
        $scope.selectedAttachmentTableElement = att.id;
        attachmentTargetList
          .fillWithData($scope, {
            resourceid: att.id
          })
          .then(function (data) {
            data = data[0];
            if ($scope.dcaView !== null) {
              if ($scope.fitToSize) {
                pagesListService.initDrawings($scope).then(function () {
                  bannerList.loadBannerImage($scope, att.id, data);
                });
              } else {
                $scope.selectedAttachmentTableElement = previousElement;
              }
            } else {
              modal.info(
                {
                  message: "Please select a free area first.",
                  ok: "Ok",
                  cancel: ""
                },
                function () { },
                function () { }
              );
              $scope.selectedAttachmentTableElement = null;
              return false;
            }

            $scope.selectedConvertions = [];
          });
      }
    };

    $scope.selectBannerTableElementById = function (id) {
      if ($scope.selectedAttachmentTableElement !== null)
        bannerList.fillWithData($scope, { resourceid: id});
    };

    $scope.selectAttachmentTableElementById = function (id) {
      attachmentTargetList.fillWithData($scope, {
        resourceid: id
      });
      $scope.selectedConvertions = [];
    };
    $scope.setConversions = function (data) {
      attachmentTargetList.setConversions($scope, data);
    };
    $scope.previewMyImage = function (convertionFormat) {
      attachmentTargetList.previewMyImage($scope, convertionFormat);
    };

    $scope.showSaveButton = utilityService.showSaveButton;

    $scope.showDeleteButton = utilityService.showDeleteButton;

    $scope.createNewConnection = function(
      aid,
      connection,
      index,
      banner_area_id,
      rule,
      textbox_id
    ) {
      if (banner_area_id <= 0) banner_area_id = null;
      if (textbox_id <= 0) textbox_id = null;

      const onSuccess = (data) => {
        $scope.editRules = false;
        var successMsg = "";
        if (data.isNew) {
          successMsg = "Your Connection has been created successfully";
        } else {
          successMsg = "Your Connection has been saved successfully";
        }

        $scope.clearBlockLayers();
        NavigationContext.unsubscribe("fallback");
        growlHandlingService.success(successMsg);
        Connection.resourceFallback = null;
      }

      const onError = () => {
        NavigationContext.unsubscribe("fallback");
      }

      Connection.prepareSaveData(
        index,
        connection,
        aid,
        banner_area_id,
        rule,
        textbox_id
      ).then(function(data) {
        let promise = null;
        if(connection.isnew) {
          promise = $q.when(connection);
        } else {
          var compare = $scope.compareObjects(data,$scope.connectionsBackup[connection.id],true);
          promise = Connection.compareData(compare, connection, aid, banner_area_id, textbox_id)
        }
        promise.then(
          function(dataToSend) {
            if(connection.parent_id && connection.isnew) {
              promise = Connection.connectionCopy(
              $scope.getActiveProjectId(),
              $scope.ual,
              $scope.statusNames,
              $scope.dcaViewMain,
              aid,
              connection,
              index,
              banner_area_id,
              textbox_id,
              $scope.connectionsList).then(onSuccess,onError);
            } else {
              promise = Connection.createNewConnection(
              $scope.getActiveProjectId(),
              $scope.ual,
              $scope.statusNames,
              $scope.dcaViewMain,
              aid,
              connection,
              index,
              banner_area_id,
              textbox_id,
              dataToSend,
              $scope.connectionsList).then(onSuccess,onError);
            }
          },
          function() {}
        );
      });
    };

    $scope.loadProjectList = function () {
      var promises = [];
      flowService.getFlows().then(
        function (projects) {
          $scope.projectsList = projects;
          $scope
            .setActiveProject($scope)
            .then(function (data) {
              $scope.activeProject = data;
            })
            .then(function () {
              if (
                userService.isAdmin() &&
                $scope.activeProject === -1 &&
                projects.length > 0
              ) {
                $scope.activeProject = 0;
              }

              if (projects.length === 0) {
                var msg = "Your don't have any flows set. ";
                if (!userService.isAdmin()) {
                  msg += "Please contact your administrator.";
                }

                modal.info({
                  message: msg,
                  ok: "Ok",
                  cancel: ""
                });
              }
              if (
                $scope.activeProject === -1 &&
                !$scope.userService.isAdmin()
              ) {
                modal.info({
                  message:
                    'You do not have any "active" Flows. Please contact Administrator.',
                  ok: "OK",
                  cancel: ""
                });
              }

              if (
                $scope.projectsList.length > 0 &&
                $scope.activeProject != -1
              ) {
                promises.push($scope.selectProject($scope.activeProject, true));
              }
            }); 
        },
        function (data) {
          void 0;
        }
      );

      return $q.all(promises);
    };

    $scope.returnRealProjectIndex = function (proj) {
      return utilityService.returnRealProjectIndex(proj, $scope);
    };

    $scope.init = function (value) {
      var defer = $q.defer();
      $scope.loading = [];
      $scope.ual = userService.getUAL();

      if (typeof $scope.ual !== "undefined") {
        $scope.ual.isAdmin = userService.isAdmin();
      }
      defer.resolve();
      return defer.promise;
    };

    $scope.closeAllConnectionsTabs = function (except) {
      $scope.loading = [];
      $scope.selectedRule = null;
    };

    $scope.connectionPreviewAction = function () {
      var id = $scope.getActiveProjectId();
      Connection.connectionPreview(id).then(function () { });
    };

    $scope.loadConnectionList = function (reload) {
      var id = $scope.getActiveProjectId();
      var defer = $q.defer();
      Connection.loadConnectionList(id,reload).then(function (connections) {
        if (Connection.resourceFallback) {
          connections.push(Connection.resourceFallback);
        }
        $scope.connectionsList = connections;
        $scope.prepareConnectionsListOnLoad();
        $scope.updateStatus();
        $scope.findEditingConnection($scope.connectionsList);
        $rootScope.$emit("LOADOFF");
        defer.resolve();
      });

      return defer.promise;
    };

    $scope.loadAttachmentList = function (selected,actionType) {
      return attachmentTargetList.loadAttachmentList($scope, "attachment",null,null,actionType);
    };

    $scope.selectRule = function (rule) {
      $scope.selectedRuleId = rule.id;
      $scope.selectedRule = rule;

    };

    $scope.changeSelectedRule = function () {
      if ($scope.selectedRuleId === null) {
        modal.info(
          {
            message: "Please select a rule first.",
            ok: "Ok",
            cancel: ""
          },
          function () { },
          function () { }
        );
      }
      $scope.editRules = false;
    };

    $scope.selectRuleById = function (data) {
      if (!$scope.selectedRule) {
        if (
          $scope.activeConnectionObj.rule_id &&
          $scope.activeConnectionObj.rule_id !== null
        ) {
          var isDeleted = true;
          for (var i = 0; i < data.length; i++) {
            if (data[i].id == $scope.activeConnectionObj.rule_id) {
              $scope.rules = data.slice(i, i + 1);
              $scope.selectRule($scope.rules[0]);
              isDeleted = false;
              break;
            }
          }

          if (isDeleted) {
            $scope.selectedRule = {
              id: $scope.activeConnectionObj.rule_id,
              name: $scope.activeConnectionObj.rule_name
            };
            $scope.rules = [];
            $scope.rules.push($scope.selectedRule);
          }

          $scope.noRulesSelected = "";
        } else {
          $scope.rules = [];
          $scope.selectedRule = null;
          $scope.noRulesSelected = "Please select a rule";
        }
      } else {
        $scope.rules = [];
        $scope.rules.push($scope.selectedRule);
        $scope.noRulesSelected = "";
      }
    };

    $scope.loadRules = function () {
      return rulesService.getRules(true);
    };

    $scope.setRules = function (data) {
      $scope.rules = data;
    };

    $scope.loadEnclosureList = function (selected) {
      if ($scope.dcaViewMain.name == "Enclosures") {
        return attachmentTargetList.loadAttachmentList($scope, "enclosure");
      }
    };

    $scope.blockAllExceptConnection = function (con, index) {
      $scope.frontElementId = con.id;
      $scope.blockingLayer = true;
    };
    $scope.clearBlockLayers = function () {
      $(".toFront").removeClass("toFront");
      $scope.frontElementId = null;
      $scope.blockingLayer = false;
    };

    $scope.connectionStatusHandler=function(index,connection){
      var status = $scope.getConnectionStatusActionName(index,connection);
      if(status === 'run' && connection.parent_id !== null) {
        const idx = $scope.getConnectionIndexById(connection.parent_id, $scope.connectionsList);
        const parent = $scope.connectionsList[idx];
        if(angular.isDefined(parent) && parent.status === 'running') {
          modal.confirm(
            {
              message:`This is a copy of connection ${connection.title} that is currently still running, do you want to STOP this old Connection ?`,
              ok: "Yes",
              cancel: "No"
            },
            function() {
              changeStatusHandler(idx, parent, 'stopped');
            }
          );
        }
      }
      changeStatusHandler(index, connection, status);
    }

    function changeStatusHandler(index, connection, status) {
      if(status===$scope.statusNames.approve || status===$scope.statusNames.stopRequest){
        var requestStatus=(status===$scope.statusNames.approve)?'run':'stop';
        modal.confirm(
          {
            message:`Are you sure that you want to send request for ${requestStatus} Connection?`,
            ok: "Yes",
            cancel: "No"
          },
          function() {
            Connection.sendEmailToApprovers(connection,requestStatus);
          }
        );

      }else{
        $scope.changeConnectionStatus(index, connection);
      }
    }

    $scope.changeConnectionStatus = function (index, connection) {
      Connection.changeConnectionStatus(
        $scope.showdragdrop,
        $scope.statusNames,
        connection,
        $scope.loading,
        $scope.blockingLayer
      ).then(
        function (connectionStatus) {
          attachmentTargetList
            .loadAttachmentList($scope, "attachment", connection.content_id)
            .then(function (resources) {
              $scope.attachmentList = resources;
            });

          if (connectionStatus === $scope.statusNames.edit) {
            $scope.blockingLayer = true;
          }

          Connection.sendState(connection, connectionStatus).then(
            function (data) {
              if (data.changed !== false) {
                if (connectionStatus === $scope.statusNames.run) {
                  connection.message = null;
                  if (
                    serverTime.getServerTime() <
                    serverTime.dateParser(connection.valid_from_utc)
                  )
                    connectionStatus = $scope.statusNames.pending;
                }
                connection.status = connectionStatus;
                if (connection.status === $scope.statusNames.edit)
                  connection.lock = false;
                $scope.activeConnection = index;
                $scope.activeConnectionObj = connection;
              } else {
                modal.info(
                  {
                    message: "Could not change status",
                    ok: "Ok",
                    cancel: ""
                  },
                  function () { },
                  function () { }
                );
              }
              resize();
              $scope.updateStatus();
            },
            function (data) {
              $scope.loading[connection.id] = false;
              modal.info(
                {
                  message: data.msg,
                  ok: "Ok",
                  cancel: ""
                },
                function () {
                  $scope.clearBlockLayers();
                  $scope.closeAllConnectionsTabs();
                  $scope.loadConnectionList();
                  $scope.handleStatus(status, data);
                },
                function () { }
              );
            }
          );
        },
        function () {
          Connection.sendStopState(connection, $scope.statusNames).then(
            function (data) {
              if (data.changed) {
                connection.message = $("#whyStopped").val();
                  $scope.loadConnectionList(true);
              } else {
                modal.info(
                  {
                    message: data.msg,
                    ok: "Ok",
                    cancel: ""
                  },
                  function () {
                    $scope.handleStatus(status, data);
                  },
                  function () { }
                );
              }

              connection.status = $scope.statusNames.stop;
            },
            function (data) {
              modal.info(
                {
                  message: data.msg,
                  ok: "Ok",
                  cancel: ""
                },
                function () {
                  $scope.clearBlockLayers();
                  $scope.closeAllConnectionsTabs();
                  $scope.loadConnectionList(true);
                  $scope.handleStatus(status, data);
                },
                function () { }
              );
            }
          );
        }
        );
    };

    $scope.loadBannerList = function () {
      var id = $scope.getActiveProjectId();
      var defer = $q.defer();
      Connection.loadBannerListByProject(id).then(function (data) {
        $scope.groups = data.banner_areas;
        for (var i = 0; i < $scope.groups.length; i++) {
          $scope.groups[i].info = {
            id: $scope.groups[i].id,
            name: $scope.groups[i].name
          };

          $scope.groups[i].connections = [];
        }
        $scope.groups.push({
          name: "Attachments",
          info: {
            name: "Attachments",
            id: 0
          },
          connections: []
        });
        Connection.loadConnectionList(id).then(function (connections) {
          for (var i = 0; i < connections.length; i++) {
            for (var j = 0; j < $scope.groups.length; j++) {
              if (
                $scope.groups[j].name === "Attachments" &&
                connections[i].resource_type === "attachment"
              )
                $scope.groups[j].connections.push(connections[i]);

              if (
                $scope.groups[j].name === "Enclosures" &&
                connections[i].resource_type === "enclosure"
              )
                $scope.groups[j].connections.push(connections[i]);

              if (connections[i].banner_area_id === $scope.groups[j].id)
                $scope.groups[j].connections.push(connections[i]);
            }
          }
          defer.resolve();
        });
      });
      return defer.promise;
    };

    $scope.deleteConnection = function (con) {
      Connection.deleteConnection($scope.statusNames, con, this).then(
        function (data) {
          if (data.deleted) {
            con.status = $scope.statusNames.deleted;
            $scope.loading = [];
            $scope.activeConnection = null;
            $scope.activeConnectionObj = null;
            $scope.loadConnectionList(true);
            $scope.clearBlockLayers();
            $rootScope.$apply();
          } else {
            $uibModal.info(
              {
                message: "Could not delete connection",
                ok: "Ok",
                cancel: ""
              },
              function () { },
              function () { }
            );
          }
        },
        function () {
          $scope.clearBlockLayers();
          $scope.closeAllConnectionsTabs();
          $scope.loadConnectionList(true);
        }
      );
    };

    function _closeActiveConnection(){
      $timeout(()=>{
      $scope.loading = [];
      $scope.activeConnection = null;
      $scope.activeConnectionObj = null;
      $scope.clearBlockLayers();
      },0);
    }

    $scope.cancelConnection = function(connection) {
        if(connection.isnew){
          let filtered=$scope.connectionsList.filter((con)=>!con.isnew);
          $scope.connectionsList=filtered;
          _closeActiveConnection();
        }else if(connection.status!=="editing"){
          _closeActiveConnection();
        }else{
          Connection.cancelEditingConnection(connection,$scope.connectionsBackup[connection.id],(canceledConnection)=>{
            $timeout(()=>{
              for(let i=0;i<$scope.connectionsList.length;i++){
                if($scope.connectionsList[i].id===canceledConnection.id){
                  $scope.connectionsList[i]=canceledConnection;
                  break;
                }
              }
              $scope.clearBlockLayers();
            },0);
          });

        }
    };


    $scope.selectProject = function (index, init) {

      $scope.ual = userService.getUAL();
      return Connection.selectProject($scope, index, init).then(function () {
        rulesService.getRules(true).then(function (data) {
          $scope.rulesDetailsList = data;
        }).then(()=>{

        utilityService.loadPagesPreviews($scope);
        if ($scope.activePartial == 4) {
          $rootScope.$emit("PROJECTCHANGED");
        }
      });
      });
    };

    $(".toggle").toggles({
      drag: false
    });

    $scope.$on("$routeChangeSuccess", function (scope, next, current) {
      if (typeof current != "undefined") {
        $rootScope.$emit("LOADON");
      }

    });


    $scope.getAuthStatus = function () {
      return !$scope.config.isAdmin && db.get("login", "loggedInBoolean");
    };

    if ($scope.getAuthStatus())
      $scope.loadProjectList().then(
        function () {
          $rootScope.$emit("LOADOFF");
        },
        function () {
          $rootScope.$emit("LOADOFF");
        }
      );
    else {
      $location.path("/");
    }
    $scope.handleStatus = function (status, data) {
      errorHandling.handleResponse(status, data, $scope);
    };

    $scope.isEdited = function (con) {
      if (
        con.editing_by !== db.get("login", "usernameString") &&
        con.editing_by !== null &&
        con.status !== $scope.statusNames.expired
      ) {
        return true;
      }
      return false;
    };
    $scope.clearFcCode = function (con) {
      con.fulfillment = [];
    };
    $scope.changeSelectedAttachment = function (con) {
      if (!con.lock && con.matched_total === 0) {
        attachmentTargetList.fillWithData($scope, {
          resourceid: $scope.selectedAttachmentTableElement
        });
        $scope.selectedConvertions = [];
        $scope.changeEditAttachments();
      }
    };

    $scope.downloadStatistics = function () {
      utilityService.downloadStatistics($scope, api, login);
    };

    $scope.$on("OPENCONNECTION", function (connectionId, con) {
      $rootScope.$emit("LOADON");
      for (var i = 0; i < $scope.connectionsList.length; i++) {
        if ($scope.connectionsList[i].id == con.id) {
          $scope
            .openGroup(
            $scope.info.name + $scope.getActiveProjectId(),
            $scope.info,
            $scope.connectionsList[i]
            )
            .then(function () {
              $scope.changeTabState(con.id, con);
              $scope.scrollTo("con_" + con.id, true);
              $rootScope.$emit("LOADOFF");
            });
          break;
        }
      }
    });

    $scope.openConnectionDetails = function (connection) {
      $scope.showPartial(0);
      $scope.changeTabState(connection.id, connection);
      $scope.scrollTo("con_" + connection.id, true);
    };

    $scope.findEditingConnection = function (connectionsList,isCanceled) {
      for (var i = 0; i < connectionsList.length; i++) {
        if (
          connectionsList[i].status === $scope.statusNames.edit &&
          db.get("login", "usernameString") === connectionsList[i].editing_by
        ) {
          $scope.changeTabState(connectionsList[i].id, connectionsList[i]);
          $scope.blockAllExceptConnection(connectionsList[i]);
          $timeout(resize, 100);
          $scope.scrollTo("con_" + connectionsList[i].id, true);
        }
      }
    };

    $scope.broadcastDragDrop = function () {
      var num = 0;
      var list = $scope.connectionsList;
      for (var i = 0; i < list.length; i++) {
        if (
          list[i].status != $scope.statusNames.expired &&
          list[i].status != $scope.statusNames.deleted
        ) {
          num++;
        }
      }
      if (num > 1) {
        $scope.$broadcast("startDragDrop");
      }
    };

    $scope.$on("startDragDrop", function (event, mass) {
      if (mass || typeof mass === "undefined") {
        $scope.showdragdrop = !$scope.showdragdrop;
      } else {
        $scope.dragdropswitch = false;
      }

      if ($scope.dragdropswitch) {
        $scope.loadConnectionList(true).then(() => {
          $scope.blockingLayer = false;
          $("#dragdroplabel").removeClass("toFront");
          $("#connectionList").removeClass("toFront");
          $timeout(function () {
            $scope.$apply();
          });
        });
      } else {
        $scope.closeAllConnectionsTabs();
        $scope.setFiltering("default");
        $scope.hideLogs();
        $scope.blockingLayer = true;
        $("#dragdroplabel").addClass("toFront");
        $("#connectionList").addClass("toFront");
        resize();
      }
      $scope.dragdropswitch = !$scope.dragdropswitch;
    });

    $scope.changeRuleElement = function () {
      $scope.editRules = !$scope.editRules;
      $timeout(resize, 100);
    };

    $scope.changeEditAttachments = function () {
      $scope.selectedAttachmentTableElementOriginal =
        $scope.selectedAttachmentTableElement;
      $scope.editAttachments = !$scope.editAttachments;
      $timeout(resize, 100);
    };

    $scope.changeEditBanners = function () {
      $scope.selectedAttachmentTableElementOriginal =
        $scope.selectedAttachmentTableElement;
      $scope.editBanners = !$scope.editBanners;
      $timeout(resize, 100);
    };

    $scope.testAllConnections=function(info,testFlag){
      Connection.testAllConnections({id:-1,name:"Attachments"},testFlag);
    }

    $scope.checkSize = function (data) {
      return utilityService.checkSize(data, $scope);
    };

    $scope.checkIsSelectDCA = function () {
      return false;
    };
    resize();
    $scope.loadConnectionList(true);
  }
]);
