
ocCommonProviders.service("variableProperties", [
  "variablesService",
  "stringFormatters",
  "numberFormatters",
  "vPropertiesValidators",
  "validationService",
  "variableModalConfig",
  function (variablesService, stringFormatters, numberFormatters, vPropertiesValidators, validation, variableModalConfig) {
    const ignoredValues = ["none", "", null];
    const formattersNamesDict={
      "capitalization":"Capitalization",
      "rmwhitespacesfrom":"Remove whitespaces from the",
      "rmextrawhitespaces":"Remove extra whitespaces",
      "withpadding":"With padding",
      "tseparator":"Thousand separator",
      "paddingdlength":"Padding length",
      "paddingcharacter":"Padding character",
      "paddingdirection":"Padding direction",
      "maxlength":"Max length",
      "dseparator":"Decimal separator",
      "decimalplaces":"Decimal places",
      "showsign":"Show sign",
      "signforpositives":"Sign for positives",
    }
    var _self=this;
    this.getConfig = _getConfig;
    this.setEditedValue=_setEditedValue;


    function _execFormatting(properties, variableTestValue) {
      for (var prop in properties) {
        let formatter = _getFormatter(prop);
        if (formatter) {
          switch (formatter.type) {
            case "single":
              variableTestValue = formatter.action(
                variableTestValue,
                properties[prop]
              );
              break;
            case "multi":
              variableTestValue = formatter.actions[properties[prop]](
                variableTestValue
              );
              break;
            case "single-require":      
              variableTestValue = formatter.action(
                variableTestValue,
                ..._resolveDependencies(formatter, properties)
              );
              break;
            case "multi-require":
              variableTestValue = formatter.actions[properties[prop]](
                variableTestValue,
                ..._resolveDependencies(formatter, properties)
              );
              break;
            default:
              break;
          }
        }
      }
      return variableTestValue;
    }

    function _setEditedValue(value){
      self.editedValue=value;
    }

    function _getEditedValue(){
      return self.editedValue
    }

    function _resetEditedValue(){
      self.editedValue=null;
    }

    function _getFormatter(propName) {
      return stringFormatters
        .concat(numberFormatters)
        .filter(function (formatter) {
          return formatter.name === propName;
        })[0];
    }

    function _resolveDependencies(formatter, properties) {
      return formatter.require.map(function (prop) {
        return properties[prop];
      });
    }

    function _collectProperties(data) {
      for (var prop in data) {
        if (_isIgnoredProperty(data[prop])) {
          delete data[prop];
        }
      }
    }

    function _isIgnoredProperty(val) {
      return ignoredValues.some(function (ignored) {
        return ignored === val;
      });
    }

    function _getVariablePropertiesVM(variables) {
      return variables.reduce(function (vm, variable) {
        vm.push([variable.value + " (" + variable.type + ")", variable.value]);
        return vm;
      }, []);
    };

    function _getVariableValue(variables, choose) {
      for (var i = 0; i < variables.length; i++) {
        if (variables[i].value === choose) {
          return variables[i].example;
        }
      }
    };

    function _getFormatterDisplayName(formatterName){
      return (formattersNamesDict[formatterName] || formatterName)
    }

    function _getConfig() {
      return angular.extend(variableModalConfig, {
        actions: {
          execFormatting: _execFormatting,
          collectProperties: _collectProperties,
          getVariablePropertiesVM: _getVariablePropertiesVM,
          getVariableValue: _getVariableValue,
          getEditedValue:_getEditedValue,
          resetEditedValue:_resetEditedValue,
          getFormatterDisplayName:_getFormatterDisplayName
        },
        providers: { validationService: validation },
        validators: vPropertiesValidators
      });
    }
  }
]);
