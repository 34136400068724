ocCommonProviders.directive("timelinePreviewNavDirective",["$rootScope", function ( $rootScope) {
    "use strict";
    return {
    restrict: "C",
    templateURL:"groupFilter.html",
    link: function(scope, element, attrs){
        scope.$watch("$parent.groups",function(a,b){
            if(typeof a !=="undefined")
            init(a);
        });
        function init(list){
            for(var i=0; i<scope.$parent.groups.length;i++){
                scope.$parent.groups[i].checked = true;
            }

        }
        scope.setGrouping = function(val){
            var flag;
            switch(val){
                case "all":
                    flag = true;
                break;
                case "none":
                    flag = false;
                break;
            }

            for( var y = 0; i < scope.$parent.groups.length;i++){
                scope.$parent.groups[i].checked = flag;
            }
            $rootScope.$emit("PROJECTCHANGED");
        };
        scope.drawPrev = function(group,flag){
            initCanvasPreview(group.shape,flag);
        };
        scope.groupChanged = function(){
            $rootScope.$emit("PROJECTCHANGED");
        };
        scope.setGrouping = function(name){
            var flag;
            switch(name){
                case "all":
                    flag = true;
                break;
                case "none":
                    flag = false;
                break;
            }
            for(var i = 0; i<scope.groups.length;i++){
                        scope.groups[i].checked = flag;
                    }
            $rootScope.$emit("PROJECTCHANGED");
        };
        var initCanvasPreview = function(shape,flag){
            if(flag){
                var canvasPacker = angular.element("<div class=\"packer\" style=\"background:#CED6E5;border:1px solid #AAA; width:134px; height:190px;\"></div>");
                var canvas = angular.element("<canvas style=\"width:134px; height:190px;\"/>");
                canvasPacker.append(canvas);
                if(shape !== null){
                    angular.element(element).parent().find(".packer").remove();
                    angular.element(element).parent().append(canvasPacker);
                    canvas = new fabric.StaticCanvas(canvas[0]);
                    canvas.setHeight(190);
                    canvas.setWidth(134);
                    if(typeof shape !=="undefined")
                        canvas.add(_quickFabricRect(shape));
                    else
                        canvas.add(_quickFabricRect({x:0,y:0,width:134*1.75,height:190*1.75}));
                }
            }
            else{
                angular.element(element).parent().find(".packer").remove();
            }
        };
    }
};
function _quickFabricRect(shape){
        var dca = new fabric.Rect({ left:shape.x*0.64,
                                    top: shape.y*0.64,
                                    width: shape.width,
                                    height: shape.height,
                                    opacity: 1.0,
                                    scaleX : 0.64,
                                    scaleY : 0.64,
                                    fill:""});

        dca.set({ strokeWidth: 1, stroke: "rgba(103,112,124,0.5)",fill:"rgba(103,112,124,0.5)" });
        return dca;
}

}]);
