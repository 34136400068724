ocCommonProviders.directive("maintenanceUIDirective",["maintenanceService","$interval","$rootScope", function (maintenanceService, $interval, $rootScope) {
    "use strict";
    return {
    restrict: "C",
    template:"<div ng-repeat=\"maintenanceMessage in maintenanceMessages\" ><div ng-if=\"maintenanceMessage !== null\" class=\"alert {{maintenanceMessage.type}} alert-message {{maintenanceMessage.type}}-message\" role=\"alert\" ><div class=\"alert-message-container\">{{maintenanceMessage.message}}</div></div></div>",
    link: function(scope, element, attrs){
        scope.maintenanceMessages = [];
        scope.type = "alert-warning";
        scope.timeoutDelay = null;
        function validateMaintenance(messages){
            var tmp = null;
            if(messages == null) {
                return;
            }
            for(var i = 0; i < messages.length;i++){

                if((moment.utc(messages[i].date_from) < moment.utc() && moment.utc() < moment.utc(messages[i].date_to) && messages[i].active == 1)){
                    tmp = {"message":messages[i].message};
                    tmp.type = "alert-warning";
                    if(messages[i].type == "other")
                        tmp.type = "alert-danger";
                    if(messages[i].type == "notice")
                        tmp.type = "alert-info";
                    scope.maintenanceMessages.push(tmp);
                }

            }
        }
        function updateTimeout(time){
            if(scope.timeoutDelay - moment() > time - moment() || scope.timeoutDelay === null ){
                if(moment() < time){
                    scope.timeoutDelay = time;
                }
            }
        }
        function checkChanges(){
            var delay = 20* 60 * 1000;
            $interval(reload,delay,0);

        }
        function reload(){
            scope.maintenanceMessages = [];
            maintenanceService.loadActiveMessages(scope).then(function(data){
                validateMaintenance(data.serviceMessages);
                $rootScope.$emit("LOADOFF");
            },function(){
                $rootScope.$emit("LOADOFF");
            });
        }
        reload();
        checkChanges();
        $rootScope.$on("reloadMaintenanceMessages",reload);


    }
  };
}]);
