module.controller("modalController",["$scope","$uibModalInstance", "message", "okLabel", "cancelLabel", "url",
    function($scope,$uibModalInstance, message, okLabel, cancelLabel, url){
        $scope=getScope($scope,$uibModalInstance, message, okLabel, cancelLabel, url);

}]);

module.controller("modalControllerExtended", ["$scope","$uibModalInstance", "message", "okLabel", "cancelLabel", "url","saveLabel","saveFunction",
function($scope,$uibModalInstance, message, okLabel, cancelLabel, url,saveLabel,saveFunction) {

    $scope=getScope($scope,$uibModalInstance, message, okLabel, cancelLabel, url);

    $scope.saveLabel = saveLabel;

    $scope.save = function() {
        saveFunction();
        $uibModalInstance.dismiss();
    }

}]);

function getScope($scope,$uibModalInstance,message, okLabel, cancelLabel, url){
    $scope.message = message;
    $scope.okLabel = okLabel;
    $scope.cancelLabel = cancelLabel;
    $scope.url = url;

    $scope.ok = function() {
        $uibModalInstance.close();
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss();
    };
    return $scope;
}


ocCommonProviders.service("modal", ["$uibModal", "$q", "$timeout", "$window", "$rootScope", "subscriptionModel",
  function($uibModal, $q, $timeout, $window, $rootScope, subscriptionModel) {
    "use strict";
    this.confirm = _confirm;
    this.discard = _discard;
    this.stop = _stop;
    this.info = _info;
    this.preview = _preview;
    this.wizard = _wizardScroll;

    subscriptionModel.on("api.error", (data) => {
      let msg = 'Server error';
      if(data && data.msg) {
       msg = data.msg;
      }
      _info({ message: msg, ok: "Ok", cancel: ""},
        function() {
          if(data.code === 12103) {
              location.reload();
          }
        },
        function() {}
      );
    });

    function _wizardScroll(template, ok, cancel, renderedFunTmp, $scope) {
        var resultPromise = $q.defer();
        var renderedFun = function() {
            $(".slickers").slick({
                dots: true,
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                centerMode: false,
                autoplay: false,
                autoplaySpeed: 5000,
                variableWidth: true
            });
            $(".slickers").on("afterChange", function(ev, slick, curr) {
                $scope.slick = curr;
            });
            if ($scope.slick != "undefined") {
                $(".slickers").slick("slickGoTo", $scope.slick, false);
            }
            $(".wizardContent").on("click", function(e) {
                if ($(e.target).hasClass("wizardContent"))
                    modal.close();
            }).show();

            angular.element($window).trigger("resize");


        };

        var modal = $uibModal.open({
            templateUrl: "/static/partials/wizardScroll.html",
            controller:  "modalController",
            backdrop: "static",
            windowClass: "wizardContent",
            resultPromise: resultPromise,
            resolve: {
                message: function() {
                    return template.message;
                },
                okLabel: function() {
                    return template.ok;
                },
                cancelLabel: function() {
                    return template.cancel;
                },
                url: function() {
                    return template.url;
                }
            }
        });

        modal.result.then(ok, cancel);
        modal.rendered.then(renderedFun);
        modal.closed.then(function() {
            $rootScope.$emit("LOADOFF");
        });
    }

    function _confirm(template, ok, cancel) {
        var modal = $uibModal.open({
            templateUrl: "/static/partials/confirm.html",
            controller:  "modalController",
            backdrop: "static",
            resolve: {
                message: function() {
                    return template.message;
                },
                okLabel: function() {
                    return template.ok;
                },
                cancelLabel: function() {
                    return template.cancel;
                },
                url: function() {
                    return template.url;
                }
            }
        });

        modal.result.then(ok, cancel);
    }


    function _discard(template, ok, cancel, save) {
        var modal = $uibModal.open({
            templateUrl: "/static/partials/discard.html",
            controller:"modalControllerExtended",
            backdrop: "static",
            resolve: {
                message: function() {
                    return template.message;
                },
                okLabel: function() {
                    return template.ok;
                },
                cancelLabel: function() {
                    return template.cancel;
                },
                url: function() {
                    return template.url;
                },
                saveLabel: function() {
                    return template.save;
                },
                saveFunction: function() {
                    return save;
                }
            }
        });

        modal.result.then(ok, cancel);
    }

    function _stop(template, ok, cancel) {
        var modal = $uibModal.open({
            templateUrl: "/static/partials/stop.html",
            controller:  "modalController",
            backdrop: "static",
            resolve: {
                message: function() {
                    return template.message;
                },
                okLabel: function() {
                    return template.ok;
                },
                cancelLabel: function() {
                    return template.cancel;
                },
                url: function() {
                    return template.url;
                }
            }
        });
        $timeout(function() {
            var element = $window.document.getElementById("whyStopped");
            if (element)
                element.focus();
        }, 500);

        modal.result.then(ok, cancel);
    }

    function _info(template, ok) {
        var modal = $uibModal.open({
            templateUrl: "/static/partials/info.html",
            controller:   "modalController",
            backdrop: "static",
            resolve: {
                message: function() {
                    return template.message;
                },
                okLabel: function() {
                    return template.ok;
                },
                cancelLabel: function() {
                    return template.cancel;
                },
                url: function() {
                    return template.url;
                }
            }
        });

        modal.result.then(ok);
    }

    function _preview(template, ok) {
        var modal = $uibModal.open({
            templateUrl: "/static/partials/modal.html",
            controller:  "modalController",
            resolve: {
                message: function() {
                    return template.message;
                },
                okLabel: function() {
                    return template.ok;
                },
                cancelLabel: function() {
                    return template.cancel;
                },
                url: function() {
                    return template.url;
                }
            }
        });

        modal.result.then(ok);
    }
}]);
