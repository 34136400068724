ocCommonProviders.directive("updateDirective", ["db", "login", "uploadService", function(db, login, uploadService) {
    "use strict";
    return {
        restrict: "C",
        scope: {
            disabled: "=",
            watch: "&"
        },
        template: "<form class=\"fileUploadForm\" method=\"post\" enctype=\"multipart/form-data\">" +
            "<label for=\"attachmentUpload\" class=\" button textBlack bgGrey clickarea dlButton\">{{ buttonText }}</label>" +
            "<img ng-show=\"uiState.uploading\" src=\"/static/img/loader.gif\" alt=\"loading ...\"/>" +
            "<input  name=\"file\" id=\"attachmentUpload\" accept=\"{{ accepttype }}\" type=\"file\" ng-disabled=\"{{ false }}\" />" +
            "</form>",
        link: function(scope, element, attrs) {
            function init() {
                var formDom = element.find(".fileUploadForm"),
                    labelDom = formDom.find("label"),
                    inputDom = formDom.find("input");
                labelDom.html(attrs.buttontitle);
                scope.accepttype = attrs.acceptfiletype + "";
                scope.fillWithData = scope.$parent.fillWithData;
                if (scope.disabled) {
                    labelDom.addClass("disableBtn");
                    labelDom.attr("for", null);
                    inputDom.off("change");
                    return;
                } else {
                    labelDom.removeClass("disableBtn");
                }

                labelDom.text(attrs.buttontitle);
                labelDom.attr("for", null).on("click", function(event) {
                    inputDom.replaceWith(inputDom = inputDom.clone(true)); 
                    inputDom.click();
                });

                inputDom.on("change", function() {
                    var formDataSupported = true;
                    var form = null;
                    var customerId = window.api.customerid;
                    var projectId = window.api.projectid;
                    var method = "POST";
                    var uploadUrl = config.backendUrl + "/customer/" + customerId + "/resource?Coyote-Authentication-Token=" + login.sessionToken + "&type=" + attrs.type;
                    if (attrs.id) {
                        uploadUrl = config.backendUrl + "/customer/" + customerId + "/resource/" + attrs.id + "/copy?Coyote-Authentication-Token=" + login.sessionToken + "&type=" + attrs.type;
                        method = "POST";
                    }
                    formDom.off("submit");
                    uploadService.createUpload(scope, attrs, uploadUrl, formDom, labelDom, inputDom, method);
                    formDom.submit();
                });
            }

            function updateButton() {
                var formDom = element.find(".fileUploadForm"),
                    labelDom = formDom.find("label"),
                    inputDom = formDom.find("input");

                labelDom.off('click');
                if (scope.disabled) {
                    labelDom.addClass("disableBtn");
                } else {
                    labelDom.removeClass("disableBtn");
                    labelDom.attr("for", null).on("click", function(event) {
                        inputDom.replaceWith(inputDom = inputDom.clone(true)); 
                        inputDom.click();
                    });
                }
            }

            scope.$watch(scope.watch, function() {
                updateButton();
            });
            init();
        }

    };
}]);
