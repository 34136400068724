ocCommonProviders.service("enumerationService", ["db", "login", "errorHandlingService", "$timeout", "userService", "$q", "flowService", "api", "modal",
    function(db, login, errorHandling, $timeout, userService, $q, flowService, api, modal) {
        "use strict";
        this.getValues = _getValues;
        this.addValue = _setValue;
        this.removeValue = _removeValue;
        this.updateValue = _updateValue;

        function _getValues(variable) {
            var deferred = $q.defer();
            const onSuccess = function(data) {
              deferred.resolve(data.variable);
            }
            window.api.variableValueGetAll(variable.id, onSuccess);
            return deferred.promise;
        }

        function _setValue(variable, value, description) {
            var dataToSend = {
                "value": value,
                "description":description
            };
            return window.api.variableValuePost(variable.id, dataToSend);
        }

        function _updateValue(variable, valueId) {
            var dataToSend = {
                "value": "",
            };
            return window.api.variableValuePut(variable.id, valueId, dataToSend);
        }

        function _removeValue(variable, valueId) {
            return window.api.variableValueDelete(variable.id, valueId);
        }

    }
]);
