ocCommonProviders.directive("groupedConnectionsTimelineDirective",["api","db","login","modal","userService","Connection","$timeout","$rootScope", function (api, db, login, modal,userService, Connection, $timeout,$rootScope) {
	"use strict";

    return {
    restrict: "C",
    scope:{  connections: "=" , info: "="},
    templateUrl : "/static/partials/groupedConnectionTimeline.html",
    link: function(scope, element, attrs){}
};

}]);


ocCommonProviders.component("xyz",{

});
