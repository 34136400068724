module.controller("uiTagsInputController", ["uiTagsInputService","modal",
  function(UiTagsInput,modal) {
    const ACTION_TYPES = {
      ADD: "add",
      REMOVE: "remove"
    };

    var VMAutocomplete=[];

    this.onInvalidTag = _onInvalidTag.bind(this);
    this.onTagAdded = _onTagAdded.bind(this);
    this.onTagRemoved = _onTagRemoved.bind(this);
    this.onChange = _onChange.bind(this);
    this.limited = false;

    this.$onInit = function() {
      this.tags = UiTagsInput.getTagsVM(this.uiModel) || [];
      UiTagsInput.getVMAutocomplete().then((tags)=>{
        VMAutocomplete=tags;
      });
      _checkTagsLimit.call(this);
    };

    this.$onChanges = function(changesObj) {

           if (changesObj.uiModel) {

               this.tags =UiTagsInput.getTagsVM(changesObj.uiModel.currentValue) || [];
        UiTagsInput.getVMAutocomplete().then((tags)=>{
          VMAutocomplete=tags;
        });
        _checkTagsLimit.call(this);
      }
    };

    this.autocomplete=function(query){
      return  UiTagsInput.autocomplete(VMAutocomplete,query);
    }

    function _onInvalidTag($tag) {
      if ($tag.text.length < 3) {
        modal.info({
          message: "The tag name can contain only alphanumeric characters, white space, dash and have length between 3 and 16 characters, please try again",
          ok: "Ok",
          cancel: ""
        });
      }
    }

    function _onTagAdded($tag) {
      _onChange.call(this, $tag, ACTION_TYPES.ADD);
    }

    function _onTagRemoved($tag) {
      _onChange.call(this, $tag, ACTION_TYPES.REMOVE);
    }

    function _onChange($tag, actionType) {
      _checkTagsLimit.call(this, this.tags);
      this.subscribe({ change: { tag: $tag, action: actionType } });
    }

    function _checkTagsLimit() {
      if (_limit.call(this)) {
        this.limited = true;
      } else {
        this.limited = false;
      }
    }

    function _limit() {
      return (
        angular.isDefined(this.tagsLimit) && this.tags.length === this.tagsLimit
      );
    }
  }
]);
