ocCommonProviders.service("observableValidationService", function () {
    var observers=[];
    this.attach=_attach;
    this.checkGroup=_checkGroup;
    this.updateState=_updateState;

    function _attach(observer){
        observers.push(observer);
    }

    function _checkGroup(evt,groupName){
        var master=observers.filter((member)=>(member.groupName===groupName && member.isMaster))[0];
        observers.forEach((m)=>{if((m.groupName===groupName && !m.isMaster))m.doValidate(master.elDOM)});
    }

    function _updateState(evt,groupName){
        var elName=evt.currentTarget.attributes.getNamedItem("name").value 
        var curr=observers.filter((member)=>(member.groupName===groupName && member.name===elName))[0];
        curr.elDOM.val(evt.currentTarget.value);
    }
});