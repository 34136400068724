ocCommonProviders.service("blankPageService", ["$q", "uiFilterService",
  "pagesListService", "flowService", "errorHandlingService", "modal", "growlHandlingService", "db", "api", "login","subscriptionModel",
  function ($q, uiFilter, pageList, flowService, errorHandlingService, modal, growlHandlingService, db, api, login,subscriptionModel) {
    this.getFilters = _getFilters;
    this.getBlankPageList = _getBlankPageList;
    this.blankPageUpdate = _blankPageUpdate;
    this.blankPageDelete = _blankPageDelete;

    function _getFilters(blankPageList, onFilterCallback) {
      const filters = uiFilter.define("blankPageFilterGroup", blankPageList, {
        id: "blankPageFilterSearch",
        name: "search",
        className: `materialsFilterSearch`,
        onChangeHandler: function (blankPageList, query) {

          var fromName = item => `Page ${item.name}`.toUpperCase().indexOf(query.toUpperCase()) != -1;
          var result = blankPageList.filter(item => fromName(item));
          return result;
        }
      }, onFilterCallback);
      return filters;
    }

    function _getBlankPageList() {
      const activeProject = window.api.projectid;
      return pageList.loadPagesWithFreeAreas(activeProject)
    }

    function _blankPageUpdate(blankPage) {
      const customerId = window.api.customerid;
      const activeProject = window.api.projectid;
      const deferred = $q.defer();
      const url = `${api.baseUrl}/customer/${customerId}/project/${activeProject}/page/${blankPage.id}`;
      const onSuccess = function (data) {
        deferred.resolve(data);
      }
      const onFailure = function (error) {
        deferred.reject(error);
        _displayError(error);
      }
      window.api.pagePut(blankPage.id, blankPage, onSuccess, onFailure);
      return deferred.promise;
    }

    function _blankPageDelete(blankPage) {
      const customerId = window.api.customerid;
      const activeProject = window.api.projectid;
      const url = `${api.baseUrl}/customer/${customerId}/project/${activeProject}/page/${blankPage.id}`;
      const defer = $q.defer();
      const onSuccess = function (data) {
        growlHandlingService.success(`Blank Page '${blankPage.name}' has been successfully deleted`);
        defer.resolve(data);
      }
      const onFailure = function () {
        errorHandlingService.handleResponse(status, data, self);
        defer.reject(data);
      }
      window.api.pageDelete(blankPage.id, onSuccess, onFailure);
      return defer.promise;
    }

    function _displayError(error) {
      modal.info({ message: error.msg, ok: "Yes" }, function () { }, function () { });
    }

  }]);
