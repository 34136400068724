ocCommonProviders.service("userManageService", ["db", "login", "errorHandlingService", "api", "userService", "$q",
    "modal", "growlHandlingService","subscriptionModel",
    function (db, login, errorHandling, api, userService, $q, modal, growlHandlingService,subscriptionModel) {
        "use strict";

        this.getUsers = function () {
            var deferred = $q.defer();
            var state = subscriptionModel.get("user");
            if (state && state.initialized) {
               deferred.resolve(state.data);
            }else{
                const onSuccess = function (data) {
                  subscriptionModel.set('user', data.user, true);
                  deferred.resolve(data.user);
                }
                const onFailure = function (error) {
                  subscriptionModel.set("user", [], false);
                  deferred.reject(error.user);
                  _displayError(error);
                }
                window.api.userGetAll(onSuccess, onFailure);
            }
            return deferred.promise;
        }

        this.getUserGroupDict = function () {
            var deferred = $q.defer();
            var state = subscriptionModel.get("user_groupDict");
            if (state && state.initialized) {
                deferred.resolve(state.data);
             }else{
              const onSuccess = function (groups) {
                subscriptionModel.set("user_groupDict", groups, true);
                deferred.resolve(groups);
              }
              const onFailure = function (error) {
                subscriptionModel.set("user_groupDict", [], false);
                deferred.reject(error);
                _displayError(error);
              }
              window.api.groupGetDict(onSuccess, onFailure);
            }
            return deferred.promise;
        }

        this.addUser = function (user) {
            var deferred = $q.defer();
            const onSuccess = function (data) {
                deferred.resolve(data);
            }
            const onFailure = function (error) {
                deferred.reject(error);
                _displayError(error);
            }
            window.api.userPost(user, onSuccess, onFailure);
            return deferred.promise;
        }

        this.updateUser=function(user){
            var deferred = $q.defer();
            const onSuccess = function (data) {
                deferred.resolve(data);
            }
            const onFailure = function (error) {
                deferred.reject(error);
            }
            window.api.userPut(user.username, user, onSuccess);
            return deferred.promise;
        }

        this.getGroupPermission = function (customerId) {
            var deferred = $q.defer();
            var state = subscriptionModel.get("permission");
            if (state && state.initialized) {
                deferred.resolve(state.data);
             }else{
                const onSuccess = function (data) {
                  subscriptionModel.set("permission", data.permission, true);
                  deferred.resolve(data.permission);
                }
                const onFailure = function (error) {
                  subscriptionModel.set("permission", [], false);
                  deferred.reject(error);
                  _displayError(error);
                }
                window.api.permissionGetCustomer(onSuccess, onFailure);
            }
            return deferred.promise;
        }

        this.getPermissionDict = function (customerId, withDeleted) {
            var deferred = $q.defer();
            var state = subscriptionModel.get("groupPermissionDict");
            if (state && state.initialized) {
                deferred.resolve(state.data);
             }else{
                const onSuccess = function (data) {
                  subscriptionModel.set("groupPermissionDict", data.permission_dict, true);
                  deferred.resolve(data.permission_dict );
                }
                const onFailure = function (error) {
                  subscriptionModel.set("groupPermissionDict", [], false);
                  deferred.reject(error);
                  _displayError(error);
                }
            }
            return deferred.promise;
        }


        this.putPermissionGroup = function (customerId, project_id, requestData) {
            var deferred = $q.defer();
            const onSuccess = function (data) {
              deferred.resolve(data);
              growlHandlingService.success(`Category '${requestData.permission}' for permission '${requestData.group_value}' has been activated.`);
            }
            const onFailure = function (error) {
              deferred.reject(error);
              _displayError(error);
            }
            window.api.permissionPut(project_id, requestData, onSuccess, onFailure);
            return deferred.promise;
        }

        this.deletePermissionGroup = function (customerId, project_id, requestData) {
          var deferred = $q.defer();
          const onSuccess = function (data) {
            deferred.resolve(data);
            growlHandlingService.success(`Category '${requestData.permission}' for permission '${requestData.group_value}' has been deactivated.`);
          }
          const onFailure = function (error) {
            deferred.reject(error);
            _displayError(error);
          }
          window.api.permissionDelete(project_id, requestData, onSuccess, onFailure);
          return deferred.promise;
        }

        this.deletePermissionsCategory = function (customerId, project_id, flowName, requestData) {
            var deferred = $q.defer();
            const onSuccess = function (data) {
              deferred.resolve(data);
              growlHandlingService.success(`Category '${requestData.permission}' for flow '${flowName}'  has been deleted.`);
            }
            const onFailure = function (error) {
              deferred.reject(error);
              _displayError(error);
            }
            window.api.permissionDeleteResource('all', requestData, onSuccess, onFailure, project_id);
            return deferred.promise;
        }

        this.getUserGroup = function (username) {
            var deferred = $q.defer();
            var state = subscriptionModel.get("user_group");
            if (state && state.initialized) {
                deferred.resolve(state.data);
             }else{
              const onSuccess = function (groups) {
                subscriptionModel.set("user_group", groups, true);
                deferred.resolve(groups);
              }
              const onFailure = function (error) {
                subscriptionModel.set("user_group", [], false)
                deferred.reject(error);
                _displayError(error);
              }
              window.api.groupGetUser(username, onSuccess, onFailure);
            }
            return deferred.promise;
        }

        this.putUserGroup = function (requestData) {
            var deferred = $q.defer();
            const onSuccess = function (data) {
              deferred.resolve(data);
              growlHandlingService.success(`Permission '${requestData.groupvalue}' for user '${requestData.username}' has been activated.`);
            }
            const onFailure = function (error) {
              deferred.reject(error);
              _displayError(error);
            }
            window.api.groupPut(requestData, onSuccess, onFailure);
            return deferred.promise;
        }


        this.deleteUserGroup = function (requestData) {
            var deferred = $q.defer();
            const onSuccess = function (data) {
              deferred.resolve(data);
              growlHandlingService.success(`Permission '${requestData.groupvalue}' for user '${requestData.username}' has been deactivated.`);
            }
            const onFailure = function (error) {
              deferred.reject(error);
              _displayError(error);

            }
            window.api.groupDelete(requestData, onSuccess, onFailure);
            return deferred.promise;
        }

        this.resetPasswordForUserAccount=function(user){
            const payload = {identity:user.username};
            var deferred = $q.defer();
            const onSuccess = function (data) {
              deferred.resolve(data);
            }
            const onFailure = function (error) {
              deferred.reject(error);
              _displayError(error);
            }
            window.api.passwordReset(payload, onSuccess, onFailure);
            return deferred.promise;
        }

        this.resendActivationEmail=function(user){
          var deferred = $q.defer();
          const onSuccess = function (data) {
            deferred.resolve(data);
          }
          const onFailure = function (error) {
            deferred.reject(error);
          }
          window.api.userResendActivation(user.username, onSuccess, onFailure);
          return deferred.promise;
        }

        this.toggleAccountAccess=function(user){
            var deferred = $q.defer();
            const payload = {status:!user.active};
            const onSuccess = function (data) {
              deferred.resolve(data);
              growlHandlingService.success(`User account access: '${user.username}' has been:'${(!user.active)?"enabled":"disabled"}'`);
            }
            const onFailure = function (error) {
              deferred.reject(error);
              _displayError(error);
            }
            window.api.userStatus(user.username, payload, onSuccess, onFailure);
            return deferred.promise;
        }

        function _displayError(error) {
            modal.info({ message: error.msg, ok: "Yes" }, function () { }, function () { });
        }
    }
]);
