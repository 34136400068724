ocCommonProviders.service("uiQuantifierService", function() {
  var _qaunatifierSnapshot = [];

  this.setSnapshot = _setSnapshot;
  this.getSnapshot = _getSnapshot;

  function _setSnapshot(q, collectionState) {
    _qaunatifierSnapshot.push({name: q,collection: angular.copy(collectionState)});
    return _setSnapshot;
  }

  function _getSnapshot(q) {
    var _snapshot=_qaunatifierSnapshot.filter(quantifier => quantifier.name === q)[0];
    if(_snapshot){
        return _snapshot.collection;
    }
    return _qaunatifierSnapshot;
  }
});
