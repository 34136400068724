module.filter('typeof', function() {
  return function(obj) {
    return typeof obj
  };
});


module.filter("ocCut", function() {
  "use strict";
   return function (value, wordwise, max, tail) {
    if (!value) return "";
    value=value.toString();
    max = parseInt(max, 10);
    if (!max) return value;
    if (value.length <= max) return value;

        value = value.substr(0, max);
    if (wordwise) {
      var lastspace = value.lastIndexOf(" ");
      if (lastspace !== -1) {
        value = value.substr(0, lastspace);
      }
    }
    return value + (tail || " …");
  };
});

module.filter("shortFilter", function () {
  "use strict";
  return function (input, filterKey, filterVal) {
    var filteredInput ={};
    angular.forEach(input, function(value, key){
        if(value[filterKey] && value[filterKey] !== filterVal){
        filteredInput[key]= value;
        }
    });
    return filteredInput;
  };
});
