module.filter("ocStatus", function () {
    "use strict";
    return function (status) {
        switch (status) {
            case false:
                return "false";
            case true:
                return "true";
            default:
                return "unknown"
        }
    };
});
