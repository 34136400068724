ocCommonProviders.component("uiFilter",{
    template:"<div class='uiFilter' ng-class='$ctrl.className'></div>",
    restrict: 'E',
    bindings:{
        filters:"<",
        collection:"<",
        className:"@",
        group:"@"
    },
    controller:"uiFilterController"
});
