ocCommonProviders.service("bannerList", ["db", "login", "modal", "api", "errorHandlingService", "$q", "$rootScope", "$timeout", "serverTime", "attachmentTargetList", "previewService", "subscriptionModel","growlHandlingService","resourceService","pagesListService",
    function(db, login, modal, api, errorHandling, $q, $rootScope, $timeout, serverTime, attachmentTargetList, previewService, subscriptionModel,growlHandlingService,Resource,pageListService) {
        "use strict";
        this.loadBannerList = _loadBannerList;
        this.drawAllBanners = _drawAllBanners;
        this.clearCanvas = _clearCanvas;
        this.quickFabricRect = _quickFabricRect;
        this.loadBannerImage = _loadBannerImage;
        this.loadBannerData = _loadBannerData;
        this.changeBannerImagePosition = _changeBannerImagePosition;
        this.calculateImageSize = _calculateImageSize;
        this.compareObjects = _compareObjects;
        this.getListIndexById = _getListIndexById;
        this.getElementById = _getElementById;
        this.clickMenuElementByElementId = _clickMenuElementByElementId;
        this.downloadFile = _downloadFile;
        this.previewMyImage = _previewMyImage;
        this.selectTableElement = _selectTableElement;
        this.setConversions = _setConversions;
        this.fillWithData = _fillWithData;
        this.prepareSaveData = _prepareSaveData;
        this.saveBanner = _saveBanner;
        this.deleteBanner = _deleteBanner;
        this.unDeleteBanner = _unDeleteBanner;
        this.cancelBanner = _cancelBanner;
        this.loadImageToDom = _loadImageToDom;
        this.loadBackgroundImage = _loadBackgroundImage;
        this.imgBannerInstance=null;
        var self=this;
        var _scale = 1.275;

        function _loadBannerList(scope, type, select, with_deleted) {
            var deffered = $q.defer();
            var customerId = window.api.customerid;
            var state = subscriptionModel.get('banner');
            var _filter = function(item){ return item.status!='deleted' };
            if(state.initialized) {
                var resources = angular.copy(state.data);
                if(!with_deleted) {
                    resources = resources.filter(_filter);
                }
                _loadResourceApply(scope, type, resources, select,with_deleted);
                deffered.resolve(resources);

            } else {
                const onSuccess = function(data) {
                  var _resources = data;
                  if(!with_deleted) {
                    _resources =_resources.filter(_filter);
                  }
                  _loadResourceApply(scope, type, _resources, select);
                  deffered.resolve(_resources);
                }
                const onFailure = function(data) {
                  deffered.reject(data);
                }
                window.api.resourceGetAll('banner', 'with_deleted=1&with_tag=1', onSuccess, onFailure);
            }

            return deffered.promise;
        }

        function _loadResourceApply(scope, type, resources, select) {
            if (type == "banner") {
                scope.bannerList = resources;
                scope.connectionPreview = "ban";
                scope.bannerListFilters=Resource.getFiltersOfMaterialList(scope.bannerList,(filtered)=>scope.bannerList=filtered);
                scope.customCategory=Resource.getCustomCategory();
                scope.itemClassPredicate=Resource.getItemClass;
            }

            if (select) {
                select = _clickMenuElementByElementId(scope, type, select);
            }
        }


        function _getSelectedElement(resources,elId){
            return resources.filter((item)=>item.id===elId);
        }

        function _drawAllBanners(scope, dcaList, selectedDca, resourceid, tmpElement) {

            var selected = null;
            for (var i = 0; i < dcaList.length; i++) {
                if (selectedDca === dcaList[i].id) {
                    selected = dcaList[i];
                }
            }

            var deffered = $q.defer();
            canvas = $rootScope.canvas;
            var imgElement = document.getElementById("previewBanner");
            canvas.setHeight(379);
            canvas.setWidth(270);
            canvas.renderAll();

            if (resourceid !== null && typeof resourceid != "undefined") {
                var imgInstance = new fabric.Image(imgElement, {
                    left: 0,
                    top: 0,
                    angle: 0,
                    opacity: 1.0,
                    selectable:false,
                    scaleX: 1.0,
                    scaleY: 1.0

                });


                canvas.add(imgInstance);
            } else {
                _clearCanvas(canvas);
            }

            var rect;
            for (var i = 0; i < dcaList.length; i++) {
                if (selectedDca === dcaList[i].id) {
                    rect = _quickFabricRect(dcaList[i].shape, true);
                    if (scope.editedFreeArea === selectedDca) {
                        rect.set({
                            hasControls: true,
                            hasRotatingPoint: false,
                            selectable: true
                        });
                    } else {
                        rect.set({
                            hasControls: false,
                            hasRotatingPoint: false,
                            selectable: false
                        });
                    }
                    canvas.add(rect);
                    canvas.setActiveObject(rect);
                } else {
                    rect = _quickFabricRect(dcaList[i].shape, false);
                    rect.set({
                        hasControls: false,
                        hasRotatingPoint: false,
                        selectable: false
                    });
                    canvas.add(rect);
                }
            }

            if (tmpElement) {
                rect = _quickFabricRect(tmpElement.shape, true);
                selected = tmpElement;
                rect.set({
                    hasControls: true,
                    hasRotatingPoint: false,
                    selectable: true
                });
                canvas.add(rect);
                canvas.renderAll();
                canvas.setActiveObject(rect);
            }

            canvas.off("mouse:move");
            canvas.on("mouse:move", function(e) {
                var obj = canvas.getActiveObject();
                var precision = 100;
                if (obj !== null && selected !== null) {
                    var w = obj.width * obj.scaleX,
                        h = obj.height * obj.scaleY,
                        s = obj.strokeWidth;
                    obj.set({
                        'height': h,
                        'width': w,
                        'scaleX': 1,
                        'scaleY': 1
                    });


                    if (obj.width * obj.scaleX > canvas.width)
                        obj.set({
                            "scaleX": canvas.width / obj.width
                        });

                    if (obj.height * obj.scaleY > canvas.height)
                        obj.set({
                            "scaleY": canvas.height / obj.height
                        });

                    if (obj.top < 0)
                        obj.set({
                            "top": 0
                        });

                    if (obj.top + obj.height * obj.scaleY > canvas.height) { 
                        if (obj.scaleY == 1.275)
                            obj.set({
                                "top": canvas.height - obj.height * obj.scaleY - 1
                            });
                        else
                            obj.set({
                                "top": canvas.height - obj.height * obj.scaleY
                            });
                    }

                    if (obj.left < 0)
                        obj.set({
                            "left": 0
                        });


                    if (obj.left + obj.width * obj.scaleX > canvas.width)
                        obj.set({
                            "left": canvas.width - obj.width * obj.scaleX - 1
                        });

                        if ((obj.height / _scale) % 2 > 0.5)
                            selected.shape.height = Math.floor(obj.height/ _scale * precision) / precision;
                        else
                            selected.shape.height = Math.floor(obj.height / _scale * precision) / precision;

                        if ((obj.width / self._scale) % 2 > 0.5)
                            selected.shape.width = Math.floor(obj.width/ _scale * precision) / precision + 1;
                        else {
                            selected.shape.width = Math.floor(obj.width / _scale * precision) / precision;
                        }

                        if ((obj.top / _scale) % 2 > 0.5)
                            selected.shape.y = Math.floor(obj.top / _scale * precision) / precision + 1;
                        else
                            selected.shape.y = Math.floor(obj.top / _scale * precision) / precision;


                        selected.shape.x = Math.floor(obj.left / _scale * precision) / precision;

                        var fix = 210 - (selected.shape.x + selected.shape.width);
                        var fix2 = 297 - (selected.shape.y + selected.shape.height);
                        if (fix < 0)
                            selected.shape.width += fix;
                        if (fix2 < 0)
                            selected.shape.height += fix2;


                    obj.setCoords();
                    scope.convertToDecimal(selected);
                    scope.$apply();
                    canvas.renderAll();
                }
            });

            deffered.resolve();
            return deffered.promise;
        }

        function _clearCanvas(canvas) {
            if (typeof canvas != "undefined") {
                canvas.clear();
            }
        }

        function _quickFabricRect(shape, selected) {
            var dca = new fabric.Rect({
                left: parseFloat(shape.x) * 1.275,
                top: parseFloat(shape.y) * 1.275,
                width: parseFloat(shape.width),
                height: parseFloat(shape.height),
                opacity: 1.0,
                scaleX: 1.275,
                scaleY: 1.275,
                fill: ""
            });
            if (selected)
                dca.set({
                    strokeWidth: 1,
                    stroke: "rgba(134,183,224,1.0)",
                    fill: "rgba(134,183,224,0.2)",
                });
            else
                dca.set({
                    strokeWidth: 1,
                    stroke: "rgba(236,102,8,1.0)"
                });

            dca.set({
                hasControls: false,
                selectable: false,
                hasRotatingPoint: false
            })
            return dca;
        }


        function _loadBackgroundImage(scope, resourceId, dca, connectionid) {
            var deffered = $q.defer();
            var url = null;
            if (resourceId !== null && typeof resourceId !== "undefined") {
                url = window.api.getResourceUrl(resourceId, 'thumbnail', 'encoding=base64');
            } else {
                if(connectionid && connectionid>0){
                    var canvas = new fabric.StaticCanvas("bannerCanvas_" + connectionid);
                    canvas.setHeight(370);
                    canvas.setWidth(270);
                    canvas.renderAll();
                    var imgInstance = new fabric.Rect({
                        left: dca.shape.x * 1.275,
                        top: dca.shape.y * 1.275,
                        height: dca.shape.height,
                        width: dca.shape.width,
                        opacity: 1.0,
                        scaleX: 1.275,
                        scaleY: 1.275,
                        fill: ""
                    });

                    imgInstance.set({
                        strokeWidth: 1,
                        stroke: "rgba(236,102,8,1.0)"
                    });
                    canvas.add(imgInstance);
                }
                deffered.reject();
                return deffered.promise;
            }

            _loadImageToDom(url).then(function(data) {
                scope.backgroundPreviewUrl = data.src;

                var timeoutFun = function() {
                    var imgElementBackground = document.getElementById("previewBanner_" + connectionid);
                    var canvas = new fabric.StaticCanvas("bannerCanvas_" + connectionid);
                    var imgInstanceBackground;
                    canvas.setHeight(379);
                    canvas.setWidth(270);
                    canvas.renderAll();

                    var imgInstance = new fabric.Rect({
                        left: dca.shape.x * 1.275,
                        top: dca.shape.y * 1.275,
                        height: dca.shape.height-1,
                        width: dca.shape.width,
                        opacity: 1.0,
                        scaleX: 1.275,
                        scaleY: 1.275,
                        fill: ""
                    });
                    imgInstance.set({
                        strokeWidth: 1,
                        stroke: "rgba(236,102,8,1.0)"
                    });


                    if (resourceId !== null && typeof resourceId != "undefined") {
                        imgInstanceBackground = new fabric.Image(imgElementBackground, {
                            left: 0,
                            top: 0,
                            angle: 0,
                            opacity: 1.0
                        });
                    }

                    canvas.add(imgInstanceBackground);
                    canvas.add(imgInstance);

                    deffered.resolve(data.src);
                };

                $timeout(timeoutFun, 1000);
            });
            return deffered.promise;
        }

        function _calculateImageSize(dca, imgElement, scale, image) {
            var width, height, scaleX, scaleY;
            scaleX = imgElement.width / dca.shape.width;
            scaleY = imgElement.height / dca.shape.height;
            if (scale) {
                if (scaleX > scaleY) {
                    if (scaleX > 1) {
                        width = imgElement.width / scaleX;
                        height = imgElement.height / scaleX;
                    } else {
                        width = dca.shape.width;
                        height = dca.shape.height;
                    }
                } else {
                    if (scaleY > 1) {
                        width = imgElement.width / scaleY;
                        height = imgElement.height / scaleY;
                    } else {
                        width = dca.shape.width;
                        height = dca.shape.height;
                    }
                }
            } else {
                if (image && image["dpi_set"]) {
                    width = image.new_width;
                    height = image.new_height;
                } else {
                    width = image.original_width;
                    height = image.original_height;
                }
            }

            return {
                'width': width,
                'height': height
            }
        }

        function _changeBannerImagePosition(positionX, positionY, scale, dca, image) {
            if(!imgInstance) return;
            var size = _calculateImageSize(dca, imgInstance.getOriginalSize(), scale, image);
            var width = size.width;
            var height = size.height;
            var customScale = 1.275;

            imgInstance.scaleToWidth(width * customScale);
            imgInstance.scaleToHeight(height * customScale);

            if (positionY == "top") {
                imgInstance.setTop(dca.shape.y * customScale);
            }
            if (positionY == "bottom") {
                imgInstance.setTop((dca.shape.y + (dca.shape.height - height)) * customScale);
            }
            if (positionY == "center") {
                imgInstance.setTop((dca.shape.y + (dca.shape.height - height) / 2) * customScale);
            }

            if (positionX == "left") {
                imgInstance.setLeft(dca.shape.x * customScale);
            }
            if (positionX == "right") {
                imgInstance.setLeft((dca.shape.x + (dca.shape.width - width)) * customScale);
            }
            if (positionX == "center") {
                imgInstance.setLeft((dca.shape.x + (dca.shape.width - width) / 2) * customScale);
            }

            self.imgBannerInstance=angular.copy(imgInstance);
            canvas.renderAll();
        }

        var imgInstance;
        var canvas;

        function _loadBannerImage(scope, resourceId, targetObj, dca, connectionid) {
            var url = window.api.getResourceUrl(resourceId, 'thumbnail');

            return _loadImageToDom(url).then(function(data) {
                scope.bannerPreviewUrl = data.src;
                var previewBannerImg=new Image();
                var previewBannerSmall=new Image;
                previewBannerImg.id=`previewBanner_${connectionid}`;
                previewBannerSmall.src=data.src;
                previewBannerSmall.id=`previewBannerSmall_${connectionid}`;
                previewBannerSmall.style="position:absolute;visibility:hidden;"
                previewBannerImg.style="position:absolute;visibility:hidden;"
                const targetPreviewCanvasEl=document.getElementById("targetPreviewCanvas");
                angular.element(targetPreviewCanvasEl)
                .empty()
                .append(previewBannerSmall)
                .append(previewBannerImg)
                .append(`<canvas id='bannerCanvas_${connectionid}'></canvas>`);

                var loadCanvas = function() {

                    var imgElement = document.getElementById("previewBannerSmall_" + connectionid);
                    var imgElementBackground = document.getElementById("previewBanner_" + connectionid);
                    var imgBorder;
                    canvas = new fabric.Canvas("bannerCanvas_" + connectionid,{
                        selection:false,
                        moveCursor:false,
                        defaultCursor:"pointer",
                        hoverCursor:"pointer",
                    });

                    canvas.getObjects().forEach((o)=>{
                        void 0;
                        o.set({
                            selection:false,
                            moveCursor:false
                        })
                    })

                    canvas.setHeight(379);
                    canvas.setWidth(270);
                    canvas.renderAll();
                    canvas.defaultCursor = "pointer";
                    canvas.on("mouse:up", function(event) {
                        attachmentTargetList.downloadFile(resourceId, scope.fileData.filename, scope.fileData.mime_type);
                    });




                    if (resourceId !== null && typeof resourceId != "undefined") {
                        var size = _calculateImageSize(dca, imgElement, false, scope.fileData.settings);
                        var width = size.width;
                        var height = size.height;

                        imgInstance = new fabric.Image(imgElement, {
                            left: dca.shape.x * 1.275,
                            top: dca.shape.y * 1.275,
                            width: width,
                            height: height,
                            scaleX: 1.275,
                            scaleY: 1.275,
                            angle: 0,
                            opacity: 1.0
                        });
                        imgInstance.selectable = false;

                        imgBorder = new fabric.Rect({
                            left: dca.shape.x * 1.275,
                            top: dca.shape.y * 1.275,
                            height: dca.shape.height-1,
                            width: dca.shape.width,
                            opacity: 1.0,
                            scaleX: 1.275,
                            scaleY: 1.275,
                            fill: ""
                        });

                        imgBorder.set({
                            strokeWidth: 1,
                            stroke: "rgba(236,102,8,1.0)"
                        });
                        imgBorder.selectable = false;
                    }

                    if (angular.isDefined(dca.background_id) && dca.background_id != null) {
                        var imgInstanceBackground = new fabric.Image(imgElementBackground, {
                            left: 0,
                            top: 0,
                            angle: 0,
                            opacity: 1.0
                        });
                        imgInstanceBackground.selectable = false;
                        canvas.add(imgInstanceBackground);
                    }

                    if (resourceId !== null && typeof resourceId != "undefined") {
                        canvas.add(imgInstance);
                        canvas.add(imgBorder);
                    }
                    canvas.renderAll();
                    return canvas;
                };

                pageListService.loadPages().then((pages)=>{
                 const page=pages.filter((page)=>page.id==dca.page_id)[0];
                if (page && page.background_id) {
                    const url = window.api.getResourceUrl(page.background_id, 'thumbnail');
                    _loadImageToDom(url).then(function(data) {
                        fabric.Image.fromURL(data.src, function(img) {
                            const canvas=loadCanvas();
                            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas),{
                                width: canvas.width,
                                height: canvas.height,
                              });
                              canvas.renderAll();
                         });

                    });
                } else {
                    loadCanvas();
                }
            });

            });
        }

        function _loadImageToDom(url) {
            var deffered = $q.defer(),
                image = new Image();

            image.addEventListener("load", function() {
                deffered.resolve(image);
            });

            image.addEventListener("error", function() {
                deffered.reject(image);
            });

            image.src = url;

            return deffered.promise;
        }

        function _compareObjects(ob1, ob2, different) {
            var wynik = [];
            if (different) {
                for (var elem1 in ob1) {
                    if (ob2 !== null && typeof ob2[elem1] !== "undefined" && ob1[elem1] !== ob2[elem1])
                        wynik.push(elem1);
                }
            } 
            else {
                for (var elem in ob1) {
                    if (typeof ob2[elem] !== "undefined" && ob1[elem] === ob2[elem])
                        wynik.push(elem);
                }
            }
            return wynik;
        }

        function _getListIndexById(list, id) {
            for (var i = 0; i < list.length; i++)
                if (list[i].id == id)
                    return i;

        }

        function _getElementById(list, id) {
            void 0
            for (var i = 0; i < list.length; i++)
                if (list[i].id == id)
                    return list[i];
        }

        function _clickMenuElementByElementId(scope, type, id) {
            if (type === "banner") {
                scope.selectedAttachmentTableElement = id;
                scope.selectedTableElement = id;
            }
        }

        function _downloadFile(id, name, type) {
            var url = window.api.getResourceUrl(id);
            previewService.downloadPreview(url, id + "_" + name, type);
        }

        function _previewMyImage(scope, convertionFormat) {

            if (scope.selectedId !== null) {
                const url = window.api.getResourceUrl(scope.selectedId, convertionFormat);
                const onSuccess = function(data) {
                  scope.previewImageContent = " #fff url(data:image/jpeg;base64," + data + ") no-repeat 0px 0px";
                }
                window.api.get(url, onSuccess);
            }
        }

        function _selectTableElement(scope, att) {
            if ((scope.bannerList !== null && scope.bannerList.length > 0)) {
                if (typeof att === "undefined" && !scope.with_deleted){
                    att = scope.bannerList[0];
                }

                scope.selectedTableElement = att.id;
                scope.selectedId = att.id;
                _fillWithData(scope,{
                    "resourceid": scope.selectedId
                });

        }
    }

        function _setConversions(scope, data) {
            scope.imageList = [];
            for (var key in data.settings.conversion_urls) {
                var tmp = data.settings.conversion_urls[key];
                scope.imageList.push({
                    "title": tmp.description,
                    "filename": tmp.files[0],
                    "type": key
                });
                scope.selectedConvertions.push(key + "");
            }
        }

        function _fillWithData(scope, data, newOne) {
            var deffered = $q.defer();
            var defferedList = [];
            if (typeof newOne == "undefined")
                newOne = false;
            scope.fileData = {tag:[]};
            scope.hasUploadedFile = true;
            if (data.resourceid !== null && typeof data.resourceid !== "undefined") {
                const onSuccess = function(data) {
                  data.created_at = serverTime.utcToLocal(data.created_at);

                  scope.selectedId = data.resource_id;
                  scope.setConversions(data);
                  if (data.title === "ALL")
                    data.name = "";

                  var ww, hh;
                  if (typeof data.settings.new_width != "undefined") {
                    ww = data.settings.new_width;
                    hh = data.settings.new_height;
                  } else {
                    ww = data.settings.original_width;
                    hh = data.settings.original_height;
                  }
                  scope.fileData = {
                    "name": data.name,
                    "id": data.resource_id,
                    "filename": data.name,
                    "status": data.status,
                    "title": data.title,
                    "description": data.description,
                    "pages": data.settings.pagecount,
                    "filesize": data.filesize + "",
                    "created_by": data.created_by,
                    "editable": data.editable,
                    "isNew": newOne,
                    "inUse": data.connection_count,
                    "connection_running_count": data.connection_running_count,
                    "match_total": data.match_total,
                    "settings": data.settings,
                    "modified": false,
                    "connections": data.settings.connections,
                    "created_at": data.created_at,
                    "original_width": ww,
                    "original_height": hh,
                    "tag":data.tag
                  };

                  defferedList.push(scope.previewMyImage(scope.selectedConvertions[0]));
                  scope.elementBackup = angular.copy(scope.fileData);

                  scope.hasUploadedFile = true;
                  scope.$digest();
                  deffered.resolve(scope.fileData);
                }
                const onFailure = function(data) {
                  deffered.reject();
                }
                window.api.resourceGet(data.resourceid, onSuccess, onFailure);
            } 
            defferedList.push(deffered.promise);
            return $q.all(defferedList);
        }

        function _prepareSaveData(ob1, ob2, diff) {
            var compared = _compareObjects(ob1, ob2, diff);
            if (compared.length === 0)
                return null;
            if (compared.length === 1 && compared[0] == "preview")
                return null;
            return compared;
        }

        function _saveBanner(scope) {
            void 0
            var deffered = $q.defer();
            var elem,
                title,
                description,
                compare,
                isNew,
                tag,
                dataToSend = {};


            if (scope.fileData) {
                elem = scope.fileData;
            }


            title = elem.title;
            description = elem.description;
            tag=elem.tag || [];
            compare = _prepareSaveData(elem, scope.elementBackup, true);
            isNew = elem.isNew;

            if (title === "" || typeof title === "undefined" || title === null) {
                modal.info({
                        message: "You cannot save without entering a title.",
                        ok: "Ok",
                        cancel: ""
                    },
                    function() {},
                    function() {}
                );
                return false;
            }

            if (description === "" || typeof description === "undefined" || description === null) {
                description = "";
            }

            var errorStr = "";
            if (title.length > 128)
                errorStr += "Title should be below 128 characters long. ";

            if (description.length > 1024)
                errorStr += "Desctiption should be below 1024 characters long. ";


            if (errorStr !== "") {
                modal.info({
                        message: errorStr,
                        ok: "Ok",
                        cancel: ""
                    },
                    function() {},
                    function() {}
                );
                return false;
            }

            if (compare !== null) {
                for (var i = 0; i < compare.length; i++) {
                    switch (compare[i]) {
                        case "title":
                            dataToSend.resource_title = title;
                            break;
                        case "description":
                            dataToSend.resource_description = description;
                            break;
                        case "tag":
                            dataToSend.tag=tag;
                            break;
                        case "settings":
                            if (typeof elem.settings.dpi !== "undefined" && elem.settings.dpi_set !== true) {

                                if (elem.settings.validity === true) {
                                    dataToSend.dpi = elem.settings.dpi;
                                    dataToSend.new_width = elem.settings.new_width;
                                    dataToSend.new_height = elem.settings.new_height;
                                } else {
                                    if (elem.settings.dpi_detected !== true && elem.settings.dpi < 300)
                                        modal.info({
                                                message: "You have entered invalid dpi, width or height values, please correct the values",
                                                ok: "Ok",
                                                cancel: ""
                                            },
                                            function() {},
                                            function() {}
                                        );
                                }
                            }

                    } 
                }
                if ((compare.length == 1 && compare[0] == "isNew") || (compare.length == 2 && compare[0] == "isNew" && compare[1] == "preview")) {} else {
                    const onSuccess = function(data) {
                      _loadBannerList(scope, scope.connectionType, scope.selectedId, scope.with_deleted);
                      scope.selectedTableElement = null;
                      scope.blockingLayer = false;

                      if (scope.fileData) {
                        scope.elementBackup = angular.copy(scope.fileData);
                        scope.fileData.isNew = false;
                        scope.fileData.editable = false;
                        growlHandlingService.success("Your Banner has been successfully saved.");

                      } 

                      deffered.resolve(data);
                    }
                    const onFailure = function(data, status) {
                      scope.blockingLayer = false;
                      deffered.reject(data);
                    }
                    window.api.resourcePut(scope.selectedId, dataToSend, onSuccess, onFailure);
                }
            } 

            return deffered.promise
        }

        function _unDeleteBanner(scope, elem) {
            if (typeof elem === "undefined") {
                return false;
            }

            var unDeleteAction = function() {
                var dataToSend = {
                    id: scope.selectedId,
                    status: "active",
                    resource_title: elem.title,
                    resource_description: elem.description,
                    tag:elem.tag
                }
                const onSuccess = function(data) {
                  scope.loadBanners();
                  scope.hasUploadedFile = false;
                  scope.blockingLayer = false;

                  _selectTableElement(scope,{id:data.resourceid});
                  growlHandlingService.success("Your Banner has been successfully undeleted.");
                };
                const onFailure = function(data, status) {
                  if (data.msg !== null)
                    modal.info({
                        message: data.msg,
                        ok: "Ok",
                        cancel: ""
                      },
                      function() {
                        handleStatus(status, data, scope);
                        scope.blockingLayer = false;
                      },
                      function() {}
                    );
                };
                window.api.resourcePut(scope.selectedId, dataToSend, onSuccess, onFailure);
            };
            modal.confirm({
                    message: "Are you sure you want to undelete this Banner?",
                    ok: "Yes",
                    cancel: "No"
                },
                function() {
                    unDeleteAction();
                },
                function() {}
            );

        }

        function _deleteBanner(scope) {
            var elem;
            void 0
            if (scope.bannerList) {
                elem = scope.bannerList.filter((item)=>item.id===scope.selectedId)[0];
            }

            void 0

            if (typeof elem === "undefined") {
                return false;
            }

            if (elem.inUse > 0) {
                modal.info({
                        message: "Could not remove the Banner because it is used by connections",
                        ok: "Ok",
                        cancel: ""
                    },
                    function() {
                    },
                    function() {}
                ); 

                return false;
            }

            var deleteAction = function() {
                const onSuccess = function(data) {
                  if (data.removed === false) {
                    modal.info({
                        message: "Could not remove the Banner",
                        ok: "Ok",
                        cancel: ""
                      },
                      function() {
                        scope.blockingLayer = false;
                      },
                      function() {}
                    ); 
                  } 
                  else {
                    growlHandlingService.success("Your Banner has been successfully removed.");
                  }
                }
                const onFailure = function(data, status) {
                  scope.blockingLayer = false;
                }
                window.api.resourceDelete(scope.selectedId, onSuccess, onFailure);
            };
            deleteAction();
        }

        function _cancelBanner(scope, data) {
            var elem;
            if (scope.bannerList) {
                elem = _getElementById(scope.bannerList, scope.selectedId);
            }

            if (scope.elementBackup.description !== data.description) {
                modal.discard({
                        message: "Do you want to discard changes?",
                        ok: "Discard",
                        cancel: "Cancel",
                        save: "Save"
                    },
                    function() {
                        scope.hasUploadedFile = false;
                        scope.blockingLayer = false;
                    },
                    function() {
                        scope.blockingLayer = true;
                    },
                    function() {
                        scope.saveBanner();
                    }
                );
            } else {
                scope.hasUploadedFile = false;
            }
        }


        function _loadBannerData(scope, data) {
            var deffered = $q.defer();
            scope.fileData = {};
            if (data.resourceid !== null && typeof data.resourceid !== "undefined") {
                var connectionId = data.connectionId;
                const onSuccess = function(data) {
                  var ww, hh;
                  if (typeof data.settings.new_width != "undefined") {
                    ww = data.settings.new_width;
                    hh = data.settings.new_height;
                  } else {
                    ww = data.settings.original_width;
                    hh = data.settings.original_height;
                  }
                  scope.fileData = {
                    "name": data.name,
                    "id": data.resource_id,
                    "filename": data.name,
                    "title": data.title,
                    "description": data.description,
                    "pages": data.settings.pagecount,
                    "filesize": data.filesize + "",
                    "created_by": data.created_by,
                    "editable": data.editable,
                    "inUse": data.connection_count,
                    "connection_running_count": data.connection_running_count,
                    "match_total": data.match_total,
                    "settings": data.settings,
                    "modified": false,
                    "connections": data.settings.connections,
                    "created_at": data.created_at,
                    "original_width": ww,
                    "original_height": hh,
                    "connectionId": connectionId,
                    "tag":data.tag || [],
                  };

                  deffered.resolve(scope.fileData);
                };
                const onFailure = function(data) {
                  handleStatus(status, data, scope);
                  deffered.reject();
                };
                window.api.resourceGet(data.resourceid, onSuccess, onFailure);
            } 
            return deffered.promise;
        }



        this.handleStatus = function(status, data, scope) {
            errorHandling.handleResponse(status, data, scope);
        };

    }
]);
