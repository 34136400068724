module.controller("helpController", ["$scope","$rootScope", "$location","$anchorScroll","userService",function($scope,$rootScope, $location,$anchorScroll,userService) {
"use strict";
$rootScope.$broadcast("CHANGE_TAB", "Help");
$scope.scrollTo = function(id) {
      $location.hash(id);
      $anchorScroll();
   };

$scope.pickView = function(){
return "/static/partials/about.html";
};

$scope.partial=null;
$scope.content=null;

$scope.showPartialData = function(text){
    $scope.content = text;
};

$scope.ual = userService.getUAL();


function set_go_top_enabled(){
    if($(window).scrollTop() > 200){
        $(".help-go-top").fadeIn(200);
     }
     else{
        $(".help-go-top").fadeOut(200);
     }
}

$(function () {
    $(window).scroll(set_go_top_enabled);
});



}]);
