ocCommonProviders.service("changePasswordService", [
  "login","api","$q","modal",
  function(login,api,$q,modal) {
    this.changePassword = _changePassword;
    function _changePassword(pwd) {
      var deferred = $q.defer();
      var url = api.baseUrl;
      url += "/password/change";
      const onSuccess = function(data) {
        deferred.resolve(data);
      };
      const onFailure = function(error) {
        deferred.reject(error);
      };
      window.api.passwordPost(pwd, onSuccess, onFailure)
      return deferred.promise;
    }
  }
]);
