ocCommonProviders.service("validationService", [function () {
    var _validators;
    this.register = _register;
    this.triggerAll = _triggerAll;
    this.triggerOne = _triggerOne;
    this.getValidator=_getValidator;

    function _register(validators) {
        _validators=validators;
    }

    function _triggerAll() {
        _validators.forEach((validator) => {
            if (!validator.isValidate.call(this)) {
                validator.onError.call(this,validator.errorMsg);
                return;
            }
            validator.onSuccess.call(this);
        })
    }

    function _triggerOne(vName) {
        let validator = _validators.filter((validator) =>validator.name === vName)[0];
        if (!validator.isValidate.call(this)) {
            validator.onError.call(this,validator.errorMsg);
            return;
        }
        validator.onSuccess.call(this);
    }

    function _getValidator(vName){
        return _validators.filter((validator) =>validator.name === vName)[0];
    }

}]);