ocCommonProviders.directive("addCategory", [function () {
    "use strict";
    return {
        restrict: "C",
        templateUrl: "/static/partials/addCategory.html",
        scope:{
            access:"<",
        },
        link:function (scope, element, attrs) {
            $(".tt-input").hide();

                       init();
            function init(){
                element.bind("input",(evt)=>{
                    if(evt.target.value.length>0){
                        scope.isCategoryNameEmpty=true;
                    }else{
                        scope.isCategoryNameEmpty=false;
                    }
                    scope.$apply();
                });

                if(angular.isDefined(scope.access)){
                    scope.selectedElement=scope.access;
                }

                if(scope.selectedElement && angular.isDefined(scope.selectedElement.tag)){
                    scope.selectedElement["tag_stringify"]=_convertTags(scope.selectedElement.tag);
                }
            }

            scope.isEditable = () => {
               if(scope.selectedElement && !scope.selectedElement.tag_stringify){
                   $(".tt-input").show();
                   return true;
               }
                $(".tt-input").hide();

            };

            scope.addCtgHandler=()=>{
                var e = jQuery.Event( 'keypress', { which: 13 } );
                $(".tt-input").trigger(e);
            };

            function _convertTags(tags) {
                var tagsArray = [];
                if (tags) {
                    for (var i = 0; i < tags.length; i++) {
                        tagsArray.push(tags[i].value);
                    }

                        return tagsArray.join();
                }
            }
        }
    };
}]);