ocCommonProviders.component("resourceAccess", {
  templateUrl: "/static/partials/resourceAccess.html",
  bindings: { resource: "<" },
  controller: ["$scope","resourceAccessService","resourceAccessManageService","userService","subscriptionModel",
    function($scope,resourceAccess,resourceAccessManage, user, subscriptionModel) {
      this.addCategory = _addCategory;
      this.editCategory = _editCategory;

      const _boundSubscribeProject = _subscribeProject.bind(this);

      this.$onInit = function() {
        this.access = resourceAccess.getAccess(this.resource);
        this.templateUrl=_getTemplate.call(this,this.access);
        subscriptionModel.on("project", _boundSubscribeProject);
      };

      $scope.$on("$destroy", function(){
        subscriptionModel.off("project", _boundSubscribeProject);
      });

      function _subscribeProject(project) {
        this.access=resourceAccessManage.extractCategory(project,this.resource);
        this.templateUrl=_getTemplate(this.access);
      }

      function _addCategory(item) {
        resourceAccessManage.showAddResourceAccessModal(item);
      }

      function _editCategory(item) {
        resourceAccessManage.showEditResourceAccessModal(item);
      }

      function _getTemplate(access){
        const canEdit = user.isAdmin() || user.isGroupMember("power");
        if (access) {
          return _getTemplateWhenAccessExists.call(this,canEdit);
        } else if(canEdit) {
          return "/static/partials/addResourceAccess.html";
        }else{
          return;
        }
      }

      function _getTemplateWhenAccessExists(canEdit) {
        if (canEdit) {
          return "/static/partials/editResourceAccess.html";
        } else {
          return "/static/partials/previewResourceAccess.html";
        }
      }
    }
  ]
});
