module.controller("changePasswordController", [
  "$scope",
  "changePasswordService",
  "growlHandlingService",
  "$location",
  function($scope, changePasswordService,growlHandlingService,$location) {
    $scope.pwd = {};

    $location.path('/change-password')
    $scope.changePasswordHandler = pwd => {
      $scope.pwdForm.$invalid=false;
      if (!$scope.pwdForm.$invalid) {
        changePasswordService.changePassword(pwd).then(data => {
          if (data.changed) {
            $scope.pwd={};
            growlHandlingService.success("Your Password has been changed.");
            $location.path('/connections');
          }
        });
      }
    };
  }
]);
