ocCommonProviders.directive("additionalFilters", ["inputSearchService","$rootScope", function (inputSearch,$rootScope) {
    return {
        restrict: "E",
        templateUrl: "/static/partials/additionalFilters.html",

        link: function link(scope, element) {
            scope.isCollapsed = true;
            scope.additionalFilters = inputSearch.searchSettings.additionalFilters;
            scope.toggleCollapsed = _toggleCollapsed;
            function _toggleCollapsed() {
                scope.isCollapsed = scope.isCollapsed ? false : true;
                $rootScope.$emit("TOGGLE_FILTERS");
            }
        }
    };
}]);