ocCommonProviders.component("uiDropDownFilter", {
  templateUrl: "/static/partials/uiDropDownFilter.html",
  bindings: {
    id:"@",
    title: "<",
    icon: "@",
    menuItems: "<",
    listItems: "<",
    className: "@",
    quantifiers:"<",
    group:"@"
  },
  controller: [
    "uiFilterService",
    "$element",
    function(uiFilter,$element) {
      this.$onInit = function() {
        this.isActiveFilter=(this.listItems && this.listItems.length>0);
        this.selectedQuantifier="OR";

            if(this.isActiveFilter){
          this.isChecked = true;
        }
      };

      this.onChange = (item) => {
        uiFilter.doFiltering({from:"dropDown",group:this.group,payload:{quantifier:this.selectedQuantifier,change:item}});
      }

      this.onMosueLeave=(evt)=>{
        $element.find(".uiDropDownFilter_holder").css({"width":"auto"});
        this.hovering=false;
      }

      this.onMosueOver=(evt)=>{
        $element.find(".uiDropDownFilter_holder").css({"width":"67px",});
        this.hovering=true;
      }

      this.quantifierSelectionCallback=(q)=>{
        this.selectedQuantifier=q;
        uiFilter.doFiltering({from:"dropDown",group:this.group,payload:{quantifier:q}});
      }

      this.menuClickHandler = menuItem => {
        switch (menuItem) {
          case "All":
            this.listItems=this.listItems.map(item =>{
                item.checked = true; 
                return item;
            });
            break;
          case "None":
            this.listItems=this.listItems.map(item => {
                item.checked = false
                return item;
            });
            break;
          default:
            break;
        }
        uiFilter.doFiltering({from:"dropDown",group:this.group,payload:{quantifier:this.selectedQuantifier}});
      };
    }
  ]
});
