ocCommonProviders.directive("filterGroup",["$compile","inputSearchService",function($compile,inputSearch) {
    "use strict";
    return {
        restrict: "C",
        transclude: true,
        scope:{data:"=",label:"@"},
        link:function(scope,element){
            element.append("<label class='fl group-label'>"+scope.label+":</label>")
            for(var i=0 ;i<scope.data.filters.length;i++){
                var el = angular.element("<custom-date-filter type='elGroup' + label='"+scope.data.filters[i].label+"' name='"+scope.data.filters[i].name+"'/>"); 
                element.append($compile(el)(scope));
            }
        }
    };
}]);