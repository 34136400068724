ocCommonProviders.service("adminService", [
  "$rootScope",
  "api",
  "db",
  "login",
  "modal",
  "errorHandlingService",
  "$q",
  "flowService",
  "fontService",
  "subscriptionModel",
  "growlHandlingService",
  "menuService",
  "resourceAccessService",
  function(
    $rootScope,
    api,
    db,
    login,
    modal,
    errorHandlingService,
    $q,
    flowService,
    fontService,
    subscriptionModel,
    growlHandlingService,
    menuService,
    resourceAccess,
  ) {
    "use strict";
    this.loadCustomers = _loadCustomers;
    this.loadCustomerFlow=_loadCustomerFlow;
    this.addCustomer = _addCustomer;
    this.editCustomer = _editCustomer;
    this.deleteCustomer = _deleteCustomer;
    this.addFlow = _addFlow;
    this.editFlow = _editFlow;
    this.copyFlow= _copyFlow;
    this.editPage = _editPage;
    this.addPage = _addPage;
    this.deletePage = _deletePage;
    this.editFreeArea = _editFreeArea;
    this.addFreeArea = _addFreeArea;
    this.deleteFreeArea = _deleteFreeArea;
    this.fillWithData = _fillWithData;
    this.deleteBackground = _deleteBackground;
    this.setConversions = _setConversions;

    function _loadCustomers(scope) {
      var deferred = $q.defer();
      var state = window.model.get("customer");
      if (state.initialized) {
        scope.selectedCustomer = subscriptionModel.auth.customerid;
        scope.customerList = angular.copy(state.data);
        _loadCustomerFlow(scope);
        deferred.resolve();
      } else {
        const onSuccess = function(data) {
          scope.selectedCustomer = subscriptionModel.auth.customerid;
          scope.customerList = data;

          _loadCustomerFlow(scope);
          deferred.resolve();
          scope.$apply();
        }
        const onFailure = function(data, status) {
          deferred.reject();
        };
        window.api.customerGetAll(onSuccess, onFailure);
      }

      return deferred.promise;
    }

    function _loadCustomerFlow(scope) {
      if (scope.selectedCustomer) {
        flowService
          .getCustomerFlows(scope.selectedCustomer)
          .then(function(projects) {
            scope.flowList = projects;
            resourceAccess.loadAccessList(scope.selectedCustomer);
          });
        fontService
          .getCustomerFonts(scope.selectedCustomer)
          .then(function(font) {
            if (!font) return;
            for (var i = 0; i < font.length; i++) {
              font[i].url =
                "/customer/" +
                scope.selectedCustomer +
                "/font/" +
                font[i].id +
                "/bin?Coyote-Authentication-Token=" +
                login.sessionToken;
            } 
            scope.fontList = font;
          });
      }
    }

    function _addCustomer(scope, name, application_level, active) {
      var dataToSend = {};
      var deferred = $q.defer();
      dataToSend.name = name;
      dataToSend.application_level = application_level;
      dataToSend.active = active;

      if (checkIsEmpty(name, "name")) {
        deferred.reject();
        return deferred.promise;
      }
      if (checkIsEmpty(application_level, "application level")) {
        deferred.reject();
        return deferred.promise;
      }
      if (
        !checkIsFromList(
          application_level,
          "Application level",
          scope.application_level
        )
      ) {
        deferred.reject();
        return deferred.promise;
      }

      const onSuccess = function(data) {
        dataToSend.id = data.customerid;
        var state = subscriptionModel.get("customer");
        scope.cancelEditCustomer();
        deferred.resolve(dataToSend);
        growlHandlingService.success(
          "Your Customer has been successfully saved."
        );
      };
      const onFailure = function(data) {
        deferred.reject();
      }
      window.api.customerPost(dataToSend, onSuccess, onFailure)
      return deferred.promise;
    }

    function _editCustomer(scope, name, application_level, active, customerId) {
      var dataToSend = {};
      var deferred = $q.defer();
      dataToSend.name = name;
      dataToSend.application_level = application_level;
      dataToSend.active = active;

      if (checkIsEmpty(name, "name")) return false;
      if (checkIsEmpty(application_level, "application level")) return false;
      if (
        !checkIsFromList(
          application_level,
          "Application level",
          scope.application_level
        )
      )
        return false;
      const onSuccess = function(data) {
          scope.cancelEditCustomer();
          var state = subscriptionModel.get("customer");
          for (var i = 0; i < state.data.length; i++) {
            if (state.data[i].id == customerId) {
              state.data[i].application_level = application_level;
              state.data[i].name = name;
              state.data[i].active = active;
              break;
            }
          }
          deferred.resolve(dataToSend);
          growlHandlingService.success(
            "Your Customer has been successfully saved"
          );
        };
      const onFailure = function(data, status) {
        deferred.reject();
      }
      window.api.customerPut(customerId, dataToSend, onSuccess, onFailure);
      return deferred.promise;
    }

    function _deleteCustomer(customerId) {
      var deferred = $q.defer();
      const onSuccess = function(data) {
        if (data.removed === false) {
          modal.info(
            {
              message: "Could not remove the customer",
              ok: "Ok",
              cancel: ""
            },
            function() {},
            function() {}
          ); 
        } else {
          scope.loadAtts();
          deferred.resolve();
          growlHandlingService.success(
            "Your Customer has been successfully removed."
          );
        }
      };
      const onFailure = function(data, status) {
        deferred.reject();
      };
      window.api.customerDelete(customerId, onSuccess, onFailure);
      return deferred.promise;
    }


    function _addFlow(
      scope,
      name,
      description,
      attachment_limit,
      enclosure_limit,
      active,
      customerId
    ) {
      var dataToSend = {};
      var deferred = $q.defer();
      dataToSend.name = name;
      dataToSend.description = description;
      dataToSend.attachment_limit = attachment_limit;
      dataToSend.enclosure_limit = enclosure_limit || 0;
      dataToSend.active = active;
      dataToSend.pages_count = 0;

      if (checkIsEmpty(name, "name")) return false;
      if (checkIsInMaxSize(name, "Name", 64)) return false;
      if (checkNameIsNotForbidden(name, "Name")) return false;
      if (checkIsEmpty(description, "description")) return false;
      if (checkIsInMaxSize(description, "Description", 1024)) return false;
      if (checkIsEmpty(attachment_limit, "attachment limit")) return false;
      if (checkIsInMaxSize(attachment_limit, "Attachment limit", 10))
        return false;

      const onSuccess = function(data) {
        dataToSend.id = data.projectid;
        scope.cancelEditFlow();
        deferred.resolve(data);
        growlHandlingService.success("Flow has been successfully added.");
      }
      const onFailure = function(data, status) {
        deferred.reject();
      }
      window.api.projectPost(dataToSend, onSuccess, onFailure);
      return deferred.promise;
    }

    function _editFlow(
      scope,
      name,
      description,
      attachment_limit,
      enclosure_limit,
      active,
      customerId,
      projectId
    ) {
      var dataToSend = {};

      if (scope.copyOfFlowName !== name) {
        dataToSend.name = name;
        if (checkIsEmpty(name, "name")) return false;
        if (checkIsInMaxSize(name, "Name", 64)) return false;
        if (checkNameIsNotForbidden(name, "Name")) return false;
      }

      dataToSend.description = description;
      dataToSend.attachment_limit = attachment_limit;
      dataToSend.enclosure_limit = enclosure_limit || 0;
      dataToSend.active = active;

      if (checkIsEmpty(description, "description")) return false;
      if (checkIsInMaxSize(description, "Description", 1024)) return false;
      if (checkIsEmpty(attachment_limit, "attachment limit")) return false;
      if (checkIsInMaxSize(attachment_limit, "Attachment limit", 10))
        return false;
     const onSuccess = function(data) {};
     const onFailure = function(data) {};
     return window.api.projectPut(projectId, dataToSend, onSuccess, onFailure);
    }

    function _copyFlow(flow,nameForCopyFlow){
      var deferred = $q.defer();
      const onSuccess = function(data) {
        deferred.resolve(data);
        growlHandlingService.success(
          "Your Flow has been successfully copied."
        );
      };
      const onFailure = function(data, status) {
        deferred.reject();
      }
      window.api.projectCopy(flow.id, {name:nameForCopyFlow}, onSuccess, onFailure);
      return deferred.promise;
    }

    function _editPage(scope, name, width, height, id) {
      var dataToSend = {};
      dataToSend.name = name;
      dataToSend.width = width;
      dataToSend.height = height;
      dataToSend.id = id;

      if (checkIsEmpty(name, "name")) return false;
      if (checkIsInMaxSize(name, "Name", 64)) return false;
      if (checkIsEmpty(width, "width")) return false;
      if (checkIsInMaxSize(width, "Width", 7)) return false;
      if (checkIsEmpty(height, "height")) return false;
      if (checkIsInMaxSize(height, "Height", 7)) return false;
      const onSuccess = function(data) {
        growlHandlingService.success(
          "Your Page has been successfully saved."
        );
      };
      window.api.pagePut(id, dataToSend, onSuccess);
    }

    function _addPage(scope, name, width, height) {
      var dataToSend = {};
      dataToSend.name = name;
      dataToSend.width = parseFloat(width.replace(",", "."));
      dataToSend.height = parseFloat(height.replace(",", "."));

      if (checkIsEmpty(name, "name")) return false;
      if (checkIsInMaxSize(name, "Name", 64)) return false;
      if (checkIsEmpty(width, "width")) return false;
      if (checkIsInMaxSize(width, "Width", 7)) return false;
      if (checkIsEmpty(height, "height")) return false;
      if (checkIsInMaxSize(height, "Height", 7)) return false;
      const onSuccess = function(data) {
        dataToSend.id = data.pageid;
        dataToSend.free_areas = [];
        scope.cancelEditPage();
        growlHandlingService.success("Your Page has been successfully saved.");
      };
      window.api.pagePost(dataToSend, onSuccess);
    }

    function _deletePage(scope, id) {
      var deferred = $q.defer();
      const onSuccess = function(data) {
        scope.selectedPage = -1;
        let pages=scope.pages.filter((page)=>page.id!==id);
        scope.cancelEditPage();
        deferred.resolve(pages);
        growlHandlingService.success("Your Page has been successfully removed.");
      };
      const onFailure = function() {
        deferred.reject();
      }
      window.api.pageDelete(id, onSuccess, onFailure);
      return deferred.promise;
    }

    function _editFreeArea(scope, width, height, x, y, id) {

      var dataToSend = {};
      dataToSend.width = parseFloat(width.toString().replace(",", "."));
      dataToSend.height = parseFloat(height.toString().replace(",", "."));
      dataToSend.x = parseFloat(x.toString().replace(",", "."));
      dataToSend.y = parseFloat(y.toString().replace(",", "."));
      dataToSend.id = id;

      if (checkIsEmpty(x, "X")) return false;
      if (checkIsInMaxSize(x, "X", 7)) return false;
      if (checkIsEmpty(y, "Y")) return false;
      if (checkIsInMaxSize(y, "Y", 7)) return false;
      if (checkIsEmpty(width, "width")) return false;
      if (checkIsInMaxSize(width, "Width", 7)) return false;
      if (checkIsEmpty(height, "height")) return false;
      if (checkIsInMaxSize(height, "Height", 7)) return false;

      const onSuccess = function(data) {
        scope.cancelEditFreeArea();
        growlHandlingService.success(
          "Your Free Area has been successfully saved"
        );
      };
      window.api.freeareaPut(scope.selectedPage, id, dataToSend, onSuccess);
    }

    function _addFreeArea(scope, width, height, x, y) {
      var dataToSend = {};
      dataToSend.shape = {};
      dataToSend.width = parseFloat(width.replace(",", "."));
      dataToSend.height = parseFloat(height.replace(",", "."));
      dataToSend.x = parseFloat(x.replace(",", "."));
      dataToSend.y = parseFloat(y.replace(",", "."));
      dataToSend.pageId = scope.selectedPage;

      if (checkIsEmpty(x, "X")) return false;
      if (checkIsInMaxSize(x, "X", 7)) return false;
      if (checkIsEmpty(y, "Y")) return false;
      if (checkIsInMaxSize(y, "Y", 7)) return false;
      if (checkIsEmpty(width, "width")) return false;
      if (checkIsInMaxSize(width, "Width", 7)) return false;
      if (checkIsEmpty(height, "height")) return false;
      if (checkIsInMaxSize(height, "Height", 7)) return false;

      const onSuccess = function(data) {
        dataToSend.id = data.free_area_id;
        dataToSend.shape.width = parseFloat(width.replace(",", "."));
        dataToSend.shape.height = parseFloat(height.replace(",", "."));
        dataToSend.shape.x = parseFloat(x.replace(",", "."));
        dataToSend.shape.y = parseFloat(y.replace(",", "."));
        var e = $("#freeAreaScroll")
          .jScrollPane()
          .data("jsp");
        e.scrollToY(0);
        scope.cancelEditFreeArea();
        scope.selectFreeArea(scope.freeAreas.length - 1);
        growlHandlingService.success(
          "Your Free Area has been successfully saved."
        );
      };
      window.api.freeareaPost(scope.selectedPage, dataToSend, onSuccess);
    }

    function _deleteFreeArea(scope, id) {
      var deferred = $q.defer();
      const onSuccess = function(data) {
        scope.selectFreeArea(-1);
        scope.cancelEditFreeArea();
        growlHandlingService.success(
          "Your Free Area has been successfully removed."
        );
      };
      const onFailure = function() {
      }
      window.api.freeareaDelete(scope.selectedPage, id, onSuccess, onFailure);
      return deferred.promise;
    }


    function _fillWithData(scope, dataOuter, newOne) {
      if (typeof newOne == "undefined") newOne = false;

      if (
        dataOuter.resourceid !== null &&
        typeof dataOuter.resourceid !== "undefined"
      ) {
        const onSuccess = function(data) {
          scope.fileData = {
            id: data.resource_id,
            filename: data.name,
            title: data.title,
            description: data.description,
            pages: data.settings.pagecount,
            filesize: data.filesize + "",
            created_by: data.created_by,
            editable: data.editable,
            isNew: newOne,
            inUse: data.connection_count,
            modified: false,
            connections: data.settings.connections,
            created_at: data.created_at
          };

          scope
            .previewMyImage(
              scope.selectedConvertions[0],
              dataOuter.resourceid
            )
            .then(function() {});
          scope.$digest();
        }
        window.api.resourceGet(dataOuter.resourceid, onSuccess);
      } else {
        scope
          .previewMyImage(scope.selectedConvertions[0], null)
          .then(function() {});
      }
    }

    function _deleteBackground(scope) {
      var deferred = $q.defer();
      const onSuccess = function(data) {
        growlHandlingService.success("Your Background has been successfully removed.");
        deferred.resolve();
      };
      const onFailure = function() {
        deferred.reject();
      };
      window.api.pageDeleteBackground(scope.selectedPage, onSuccess, onFailure);
      return deferred.promise;
    }

    function _setConversions(scope, data) {
      scope.imageList = [];
      for (var key in data.settings.conversion_urls) {
        var tmp = data.settings.conversion_urls[key];
        scope.imageList.push({
          title: tmp.description,
          filename: tmp.files[0],
          type: key
        });
        scope.selectedConvertions.push(key + "");
      }
    }



    var checkIsPositive = function(value) {
      if (value > 0) {
        return true;
      } else {
        modal.info(
          {
            message: "Number in name must be greater than 0",
            ok: "Ok",
            cancel: ""
          },
          function() {},
          function() {}
        );
      }
    };

    var checkIsInt = function(value) {
      if (
        !isNaN(value) &&
        parseInt(Number(value)) == value &&
        !isNaN(parseInt(value, 10))
      ) {
        return true;
      } else {
        modal.info(
          {
            message: "Name must be integer",
            ok: "Ok",
            cancel: ""
          },
          function() {},
          function() {}
        );
      }
    };

    var checkIsEmpty = function(element, type) {
      if (
        element === "" ||
        typeof element === "undefined" ||
        element === null
      ) {
        modal.info(
          {
            message: "You cannot save without entering a " + type,
            ok: "Ok",
            cancel: ""
          },
          function() {
            $rootScope.$emit("LOADOFF");
          },
          function() {}
        );
        return true;
      }
    };

    var checkIsInMaxSize = function(element, type, max) {
      if (element.length > max) {
        modal.info(
          {
            message: type + " should be below " + max + " characters long. ",
            ok: "Ok",
            cancel: ""
          },
          function() {},
          function() {}
        );
        return true;
      }
    };

    var checkNameIsNotForbidden = function(element, type) {
      if (element.toLowerCase().localeCompare("general") === 0) {
        modal.info(
          {
            message: type + " should not be named 'General' or 'general'",
            ok: "Ok",
            cancel: ""
          },
          function() {},
          function() {}
        );
        return true;
      }
    };

    var checkIsFromList = function(element, type, list) {
      if (list.indexOf(element) < 0) {
        modal.info(
          {
            message: type + " has incorrect value",
            ok: "Ok",
            cancel: ""
          },
          function() {},
          function() {}
        );
        return false;
      }

      return true;
    };

    this.handleStatus = function(status, data, scope) {
      errorHandlingService.handleResponse(status, data, scope);
    };
  }
]);
