ocCommonProviders.directive("treeView", [
  function() {
    "use strict";
    return {
      restrict: "E",
      scope: {
        list: "<",
        propTitle: "@",
        multiPropTitle: "<",
        titlePrefix: "@",
        infoPredicate: "&",
        onSelection: "&",
        selectionArgs:"<",
        onMouseOver:"&",
        customCategory: "<",
        itemClassPredicate: "&",
        treeViewOff: "<",
        textLength: "<",
        withPreview:"<",
        detailsProps:"<",
        type:"@"
      },
      templateUrl: "/static/partials/treeView.html",
      link: function(scope) {
        scope.treeViewOff =angular.isDefined(scope.treeViewOff) && scope.treeViewOff;
        scope.textLength = scope.textLength ? scope.textLength : 18;

              const unsubscribe=scope.$watchCollection("list", (newVal) =>_subscribe(newVal));
        scope.$on("$destroy",()=>unsubscribe());


        scope.isItemEnabled=function(item){
          return !(item.className.search("item-lock")>0);
        }

        function _subscribe(newVal) {
          newVal = newVal ? newVal : [];
          var initialValue = [{ items: [] }];
          if (angular.isDefined(scope.customCategory) && scope.customCategory.isVisible && !scope.treeViewOff) {
              initialValue = [{ id: -1, title: scope.customCategory.title, items: [] }];
          }
          scope.VM = newVal.reduce((acc, currVal) => _getVM(acc, currVal),initialValue);
        }

        function _getVM(acc, currVal) {
          if (scope.treeViewOff) {
            return _getPlainList(acc, currVal);
          }
          return _getTreeView(acc, currVal);
        }

        function _getPlainList(categories, currValue) {
          const ctgItem = _getCategoryItem(currValue);
          categories[0].items.push(ctgItem);
          return categories;
        }

        function _getTreeView(categories, currValue) {
          const ctgItem = _getCategoryItem(currValue);

          if (angular.isDefined(currValue.tag) && currValue.tag.length >= 1) {
            for (let i = 0; i < currValue.tag.length; i++) {
              let ctgTitle = currValue.tag[i].value;
              let ctgIndex = _getCategoryIndexByTitle(categories, ctgTitle);

              if (ctgIndex != -1 && !categories[ctgIndex].items.some(item => item.id === ctgItem.id)) {
                categories[ctgIndex].items.push(ctgItem);
              }

              if (ctgIndex === -1) {
                categories.unshift({
                  id: ctgTitle,
                  title: ctgTitle,
                  items: [ctgItem]
                });
              }
            }
          } else {
            categories[categories.length - 1].items.push(ctgItem);
          }

          return categories;
        }

        function _getCategoryItem(currValue) {
          const extraClassName = scope.itemClassPredicate({ item: currValue,args:scope.selectionArgs });
          return {
            id: currValue.id,
            title: _getItemTitle(currValue),
            info: scope.infoPredicate({ item: currValue }),
            className: `${extraClassName ? extraClassName + " node_item" : "node_item"}`,
            onSelection: (item, ctgKey) =>_toggleSelection(item, ctgKey, () =>scope.onSelection({ item: currValue,args:scope.selectionArgs})),
            onMouseOver:()=>{
              scope.dataPreview=scope.onMouseOver({ item: currValue });
            }
          };
        }

        function _getCategoryIndexByTitle(categories, title) {
          for (var i = 0; i < categories.length; i++) {
            if (categories[i].title === title) {
              return i;
            }
          }
          return -1;
        }

        function _getItemTitle(item) {
          var title = "";
          if (angular.isDefined(scope.propTitle)) {
            title = item[scope.propTitle];
          } else if (angular.isDefined(scope.multiPropTitle)) {
            title = _getComplexTitle(item);
          }

          return scope.titlePrefix ? `${scope.titlePrefix} ${title}` : title;
        }

        function _getComplexTitle(item) {
          var title = "";
          scope.multiPropTitle.forEach((prop, index) => {
            if (index != scope.multiPropTitle.length - 1) {
              title += `${item[prop]} - `;
            } else {
              title += item[prop];
            }
          });

          return title;
        }

        function _toggleSelection(selectedItem, _ctgKey, postCallback) {
          scope.selected = selectedItem;
          scope.ctgKey = _ctgKey;
          postCallback();
        }
      }
    };
  }
]);
