ocCommonProviders.directive("enclosureListContainer",["attachmentTargetList",function (attachmentTargetList) {
    "use strict";
    return {
    restrict: "C",
    templateUrl:"/static/partials/enclosuresListContainer.html",
    link: function(scope, element, attrs){
      scope.attachmentSearchCallback=attachmentTargetList.resourceFilter;
      attachmentTargetList.loadAttachmentList(scope, "enclosure");
    }
  };
}]);
