

ocCommonProviders.directive("itemPreview", ["$compile","itemPreviewService","login","db",
function($compile, ItemPreview,login,db) {
    return {
      scope: { itemPreview: "<", type: "@",resource:"<",resourceId:"<",info:"<",withDetails:"<", detailsProps:"<"},
      restrict: "AC",
      transclude: true,
      templateUrl: "/static/partials/itemPreview.html",
      link: function($scope, element) {
        void 0
        var PREVIEW_TEMPLATE="<div class='preview' style='width:auto;padding:0px;border:none;'><div class='preview-section v-canvas' style='float:right'><canvas id='itemPreviewCanvas' width='270' height='379'/></div></div>";

        if($scope.withDetails){
          PREVIEW_TEMPLATE="<div class='preview' style='height:400px;'><item-preview-details class='details-preview' details='details' details-props='detailsProps'></item-preview-details><div class='preview-section v-canvas' style='float:right'><canvas id='itemPreviewCanvas' width='270' height='379'/></div></div>";
        }

        element.mouseleave(()=>$scope.removePreview());
        $scope.addPreview=function(event) {
            if(!$scope.resourceId) return;

            const onSuccess = function (data) {
              var dataPreview={
                id:$scope.resourceId,
                type:data.resource_type,
                settings:data.settings,
                dca:$scope.info,
                scale:$scope.resource && $scope.resource.scale,
                vertical_align:$scope.resource && $scope.resource.vertical_align,
                horizontal_align:$scope.resource && $scope.resource.horizontal_align
              }

              $scope.details=data;
              $scope.details=angular.extend($scope.details,$scope.resource);
              const currentPreview = angular.element($compile(PREVIEW_TEMPLATE)($scope));
              const coords=ItemPreview.calculatePosition(event,currentPreview);
              $scope.removePreview();
              element.append(currentPreview.css({
                "position": "fixed",
                "top":coords.top,
                "left":coords.left,
                "z-index": 1000,
              }).mouseover(function(){
                this.remove();
              }));

              ItemPreview.drawPreview(dataPreview);
            }

            window.api.resourceGet($scope.resourceId, onSuccess);
        }


       $scope.removePreview=function() {
          $(".preview").remove();
        }
      }
    };
  }
]);
