ocCommonProviders.directive("toggle",[ function () {
    "use strict";
    return {
    restrict: "C",
    template:"",
    link: function(scope, element, attrs){
		  $(element).toggles({text:{on:"ON",off:"OFF"},on:false});
    }
  };
}]);


ocCommonProviders.directive("carousel",[ function () {
  "use strict";
    return {
    restrict: "C",
    template: "<div class=\"slick login-page-carousel\" data-slick=\'{\"slidesToShow\": 1, \"slidesToScroll\": 1}\'><div><img src=\"/static/img/Connect-basic_banner.jpg\" ></div><div><img src=\"/static/img/Connect-basic-plus_banner.jpg\" ></div><div><img src=\"/static/img/Connect-premium_banner.jpg\" ></div>",
    link: function(scope, element, attrs){
      $(".slick").not('.slick-initialized').slick({dots: true,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          centerMode: false,
          autoplay: true,
          autoplaySpeed: 5000,
          variableWidth: true})
      } 
    };
}]);

