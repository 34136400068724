module.controller("blankPageController", ["$scope", "blankPageService", "modal", "subscriptionModel","$uibModal",
    function ($scope, blankPageService, modal, subscriptionModel,$modal) {
        var elemWidth = 290;
        var shownElements = 4;
        $scope.editingItemId = -1;
        $scope.showActions = false;
        _loadPageList();
        function _loadPageList() {
             blankPageService.getBlankPageList().then((result)=>{
                $scope.blankPageList =result;
                $scope.blankPageFilters = blankPageService.getFilters(  $scope.blankPageList , ( filtered ) => {
                    $scope.blankPageList= filtered;
                });      
            }); 
        }


              $scope.pageActions = [{
            name: "Change name",
            handler: (page) => {
                _handleEdit(page);
            },
        },
        {
            name: "Delete",
            handler: (page) => {
                _handleDelete(page);
            }
        }];

        subscriptionModel.on('page', (change) => {
            _loadPageList();
        });


        function _handleDelete(page) {
            modal.confirm({
                message: `Are you sure you want to delete page '${page.name}' ?`,
                ok: "Yes",
                cancel: "No"
            },
                function () {
                    blankPageService.blankPageDelete(page).then((result) => {
                        _loadPageList();
                    });
                },
                function () { });
        }

        function _handleEdit(page){
            $modal.open({
                templateUrl: "/static/partials/editBlankPageModal.html",
                controller: "editBlankPageModalController",
                backdrop: true,
                windowClass:"blank-page-modal",
                resolve:{page:()=>page}
            });
        }

        $scope.activePagePreview = 0;

        var moving = angular.element(".pageInnerElement");
        $scope.goLeft = function () {
            if ($scope.blankPageList && $scope.activePagePreview <= $scope.blankPageList.length && $scope.activePagePreview > 0) {
                $scope.activePagePreview--;
                moving.css("left", (parseInt(moving.css("left")) + elemWidth) + "px");
            }
        };

        $scope.checkLeft = function () {
            if ($scope.blankPageList && ($scope.activePagePreview <= $scope.blankPageList.length) && $scope.activePagePreview > 0) {
                return "active";
            }
            return "inactive";
        };

        $scope.checkRight = function () {
            if ($scope.blankPageList && ($scope.activePagePreview < $scope.blankPageList.length - shownElements)) {
                return "active";
            }

            return "inactive";
        };

        $scope.goRight = function () {
            if ($scope.blankPageList && ($scope.activePagePreview < $scope.blankPageList.length - shownElements)) {
                $scope.activePagePreview++;
                moving.css("left", (parseInt(moving.css("left")) - elemWidth) + "px");
            }
        };


       }]);

