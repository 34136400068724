ocCommonProviders.directive("itemPreviewDetails", [
  "itemPreviewService",
  "rulesService",
  function(ItemPreview, Rules) {
    "use strict";
    return {
      scope: { details: "<",detailsProps:"<" },
      restrict: "E",
      transclude: true,
      templateUrl: "/static/partials/itemPreviewDetails.html",
      link: function($scope, element) {
        const detailsBuilder = ItemPreview.getDetailsBuilder($scope.details);
        const isOnPropList=(propName)=>$scope.detailsProps.some((itemPropName)=>propName===itemPropName);

                Rules.getRules(true).then(function(data) {
          var builder=detailsBuilder;

          void 0
          if(isOnPropList("material_filename")){
            builder=builder.withMaterialFilename()
          }

                    if(isOnPropList("material_title")){
            builder=builder.withMaterialTitle()
          }

          if(isOnPropList("material_description")){
            builder=builder.withMaterialDescription()
          }

          if(isOnPropList("material_filesize")){
            builder=builder.withMaterialFileSize()
          }

          if(isOnPropList("material_size")){
            builder=builder.withMaterialSize()
          }

          if(isOnPropList("connection_name")){
            builder=builder.withConnectionName()
          }

          if(isOnPropList("date_from")){
            builder=builder.withElementDateFrom()
          }

          if(isOnPropList("date_to")){
            builder=builder.withElementDateTo()
          }

          if(isOnPropList("status")){
            builder=builder.withElementStatus()
          }

          if(isOnPropList("rule_name")){
            builder=builder.withElementRuleName()
          }

                   if(isOnPropList("material_name")){
            builder=builder.withElementMaterialName()
          }

          if(isOnPropList("created_by")){
            builder=builder.withElementCreateBy()
          }

          if(isOnPropList("created_at")){
            builder=builder.withElementCreateAt()
          }

          if(isOnPropList("sent")){
            builder=builder.withElementSent()
          }

          if(isOnPropList("priority")){
            builder=builder.withElementPriority()
          }

          if(isOnPropList("summary")){
            builder=builder.withElementSummary(data)
          }

          if(isOnPropList("tag")){
            builder=builder.withElementTagsInput()
          }

                   if(builder){
            builder.build(element);
          }

              });

        }
  }

 }]);
