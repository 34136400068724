ocCommonProviders.service("DCAService", ["db", "login", "modal", "api", "errorHandlingService", "$q", "$rootScope", "$timeout", "serverTime", "ValidationService", "errorHandlingService", "subscriptionModel", "growlHandlingService",
    function (db, login, modal, api, errorHandling, $q, $rootScope, $timeout, serverTime, ValidationService, errorHandlingService, subscriptionModel, growlHandlingService) {
        "use strict";
        this.loadDCAList = _loadDCAList;
        this.resourceFilter = _resourceFilter;
        this.loadImageToDom = _loadImageToDom;
        this.loadBackgroundImageURL = _loadBackgroundImageURL;
        this.loadBackgroundImage = _loadBackgroundImage;
        this.quickFabricRect = _quickFabricRect;
        this.drawAllDcas = _drawAllDcas;
        this.drawAllFreeAreas = _drawAllFreeAreas;
        this.deleteDCA = _deleteDCA;
        this.saveDCA = _saveDCA;
        this.handleStatus = _handleStatus;
        this.clearCanvas = _cc;
        this.toggleCanvasEvents=_toggleCanvasEvents;
        this.canvas = null;
        var _scale = 1.775;
        this.backgroundGrey = "rgba(174, 174, 174, 0.9)"; 
        this.goodPosition = "rgba(60, 189, 129, 0.8)"; 
        this.badPosition = "rgba(245, 29, 29, 0.8)"; 
        this.selectedDca = "rgba(174, 207, 234, 0.9)"; 
        this.backgroundPattern = null;
        var self = this;

        this.addNewPage = (name) => {
          var deffered = $q.defer();
          var customerId = window.api.customerid;
          var projectId = window.api.projectid;
          const payload = {
            name: name,
            additional_page: true,
          };
          const onSuccess = function (data) {
            deffered.resolve(data);
          }
          const onFailure = function (data, status) {
            deffered.reject(data);
          }
          window.api.pagePost(payload, onSuccess, onFailure);
          return deffered.promise;
        }

            function _loadDCAList(projectId) {
                var deffered = $q.defer();
                var customerId = window.api.customerid;
                var state = subscriptionModel.get('banner_area');
                const onSuccess = function (data) {
                  deffered.resolve(data.banner_areas);
                };
                const onFailure = function(data) {
                  subscriptionModel.set('banner_area', [], false);
                  this.handleStatus(404, data, this);
                  deffered.reject(data);
                }
                window.api.bannerAreaGetAll('with_tag=true', onSuccess, onFailure);
                return deffered.promise;
            }

            function _resourceFilter(item, query) {
                if (typeof query == "undefined" ||
                    query === "" ||
                    item.name.toUpperCase().indexOf(query.toUpperCase()) != -1)
                    return true;
                return false;
            }


            function _loadImageToDom(url) {
                var deffered = $q.defer(),
                    image = new Image();

                image.addEventListener("load", function () {
                    deffered.resolve(image);
                });

                image.addEventListener("error", function () {
                    deffered.reject(image);
                });

                image.src = url;

                return deffered.promise;
            }

            function _loadBackgroundImageURL(resourceId, dca) {
                var deffered = $q.defer();
                var url = null;
                if (resourceId !== null) {
                    url = window.api.getResourceUrl(resourceId, 'background');
                    deffered.resolve(url);
                } else {
                    var timeoutFun = function () {
                        var canvas;
                        if (dca.isEdit) {
                            if (self.canvas !== null) {
                                canvas = self.canvas;
                            } else {
                                canvas = self.canvas = new fabric.Canvas("dcaCanvas");
                            }
                        } else {
                            if (self.canvas) {
                                canvas = self.canvas;
                            } else {
                                canvas = self.canvas = new fabric.Canvas("dcaCanvas");
                            }
                        }

                        canvas.selection=false;
                        canvas.hoverCursor="default";
                        canvas.clear();
                        canvas.setHeight(528);
                        canvas.setWidth(373);
                        var imgInstance = new fabric.Rect({
                            left: 0,
                            top: 0,
                            height: 531, 
                            width: 373,
                            opacity: 1.0,
                            fill: ""
                        });
                        if (dca.isEdit) {
                            imgInstance.set({
                                strokeWidth: 0,
                                stroke: "rgba(170,170,170,1.0)",
                                fill: self.backgroundGrey
                            });
                        } else {
                            imgInstance.set({
                                strokeWidth: 0,
                                stroke: "rgba(170,170,170,1.0)"
                            });
                        }
                        imgInstance.selectable = false;
                        self.backgroundPattern = null; 
                        canvas.add(imgInstance);
                        canvas.renderAll();
                        deffered.reject(canvas);
                    };

                    $timeout(timeoutFun, 0);

                }
                return deffered.promise;
            }

            function _loadBackgroundImage(dca) {
                var deffered = $q.defer();
                var canvas;
                var imgElementBackground = document.getElementById("previewImage");
                if (dca.isEdit) {
                    if (self.canvas !== null) {
                        canvas = self.canvas;
                    } else {
                        canvas = self.canvas = new fabric.Canvas("dcaCanvas");
                    }
                } else {
                    if (self.canvas) {
                        canvas = self.canvas;
                    } else {
                        canvas = self.canvas = new fabric.Canvas("dcaCanvas");
                    }
                }

                canvas.selection=false;
                canvas.hoverCursor="default";
                canvas.clear();
                canvas.setHeight(528);
                canvas.setWidth(373);
                var imgInstance = new fabric.Rect({
                    left: 0,
                    top: 0,
                    height: 531, 
                    width: 373,
                    opacity: 1.0,
                    fill: ""
                });
                if (dca.isEdit) {
                    imgInstance.set({
                        strokeWidth: 0,
                        stroke: "rgba(170,170,170,1.0)",
                        fill: self.backgroundGrey
                    });
                } else {
                    imgInstance.set({
                        strokeWidth: 0,
                        stroke: "rgba(170,170,170,1.0)"
                    });
                }
                var imgInstanceBackground = new fabric.Image(imgElementBackground, {
                  left: 0,
                  top: 0,
                  angle: 0,
                  opacity: 1.0
                });
                self.patternSourceCanvas = new fabric.StaticCanvas();
                self.patternSourceCanvas.add(imgInstanceBackground);
                var padding = 0;
                self.backgroundPattern = new fabric.Pattern({
                  source: function () {
                    self.patternSourceCanvas.setDimensions({
                      width: 373,
                      height: 531
                    });
                    return self.patternSourceCanvas.getElement();
                  },
                  repeat: "no-repeat"
                });
                imgInstanceBackground.selectable = false;
                imgInstance.selectable = false;
                self.backgroundPattern = null; 
                canvas.add(imgInstanceBackground);
                canvas.add(imgInstance);
                canvas.renderAll();
                imgElementBackground.onload = function () {
                    canvas.renderAll();
                }

                deffered.resolve(canvas);
                return deffered.promise;
            }

            function _quickFabricRect(shape, selected, isFreeArea) {

                var dca = new fabric.Rect({
                    left: parseFloat(shape.x) * _scale,
                    top: parseFloat(shape.y) * _scale,
                    width: parseFloat(shape.width),
                    height: parseFloat(shape.height),
                    opacity: 1.0,
                    scaleX: _scale,
                    scaleY: _scale,
                    fill: ""
                });

                if (!isFreeArea) {
                    if (selected) {
                        dca.set({
                            strokeWidth: 0,
                            fill: self.selectedDca
                        });
                    } else {
                        dca.set({
                            strokeWidth: 0.5,
                            stroke: "rgba(0,0,0,0.3)",
                            fill: "rgba(0, 0, 0, 0.2)"
                        });
                    }
                } else {
                    dca.set({
                        strokeWidth: 0,
                        stroke: "rgba(0, 153, 0,1)",
                        fill: "rgba(131, 184, 155,0.4)"
                    });
                }

                return dca;
            }

            function _drawText(canvas, shape, name) {
                var o, w, h;

                o = $("<div>" + name + "</div>")
                    .css({
                        "position": "absolute",
                        "float": "left",
                        "white-space": "nowrap",
                        "visibility": "hidden",
                        "font": "16px Noto Sans"
                    })
                    .appendTo($("body"));
                w = o.width();
                h = o.height();
                o.remove();
                var textWidth = w;
                var textHeight = h;
                if (shape.width * 1.770 > 2 + textWidth && shape.height * 1.770 > 2 + textHeight) {
                    var text = new fabric.Text(name, {
                        top: (shape.y * 1.770 + (shape.height * 1.770 - textHeight) / 2),
                        left: (shape.x * 1.770 + (shape.width * 1.770 - textWidth) / 2 - 3),
                        fontSize: 16,
                        fontFamily: "Noto Sans"
                    });
                    text.dcaName = name;
                    return text;

                } else {
                    var f = "16px Noto Sans";
                    o = $("<div>" + name.substring(0, 3) + "..." + "</div>")
                        .css({
                            "position": "absolute",
                            "float": "left",
                            "white-space": "nowrap",
                            "visibility": "hidden",
                            "font": f
                        })
                        .appendTo($("body"));
                    w = o.width();
                    h = o.height();
                    o.remove();
                    var textWidth1 = w;
                    var textHeight1 = h;

                    if (shape.width * 1.770 > 2 + textWidth1 && shape.height * 1.770 > 2 + textHeight1) {
                        var text1 = new fabric.Text(" " + name.substring(0, 3) + "...", {
                            top: (shape.y * 1.770 + (shape.height * 1.770 - textHeight1) / 2 + 3),
                            left: (shape.x * 1.770 + (shape.width * 1.770 - textWidth1) / 2 - 3),
                            fontSize: 16,
                            fontFamily: "Noto Sans"
                        });
                        text1.dcaName = name;
                        return text1;
                    }

                }

                return false;
            }

            function _fixShapeValues(elem) {
                if (elem.shape.x < 0)
                    elem.shape.x = 0;
                if (elem.shape.y < 0)
                    elem.shape.y = 0;
                if (elem.shape.width < 0)
                    elem.shape.width = 0;
                if (elem.shape.height < 0)
                    elem.shape.height = 0;

            }

            function _fixStroke(element) {
                return {
                    "scaling": function (e) {
                        var obj = this,
                            w = obj.width * obj.scaleX,
                            h = obj.height * obj.scaleY,
                            s = obj.strokeWidth;

                        obj.set({
                            "height": h,
                            "width": w,
                            "scaleX": 1,
                            "scaleY": 1
                        });
                    }
                };
            }

            function _backendCheckDcaShape(element, onSuccess) {
                if (element.shape.x < 0)
                    element.shape.x = 0;
                if (element.shape.y < 0)
                    element.shape.y = 0;
                var dataToSend = {
                    "x": parseFloat(String(element.shape.x).replace(",", ".")),
                    "y": parseFloat(String(element.shape.y).replace(",", ".")),
                    "width": parseFloat(String(element.shape.width).replace(",", ".")),
                    "height": parseFloat(String(element.shape.height).replace(",", ".")),
                    "id": element.id
                };
                return window.api.bannerAreaCheck(element.page_id, dataToSend, onSuccess);
            }

            function _drawAllDcas(dcaList, canvas, selected, edited, scope, data) {

                var deffered = $q.defer();
                _fixShapeValues(selected);
                var txt;
                for (var i = 0; i < dcaList.length; i++) {
                    if (selected.page_name === dcaList[i].page_name) {
                        if (selected.id !== dcaList[i].id) {
                            var dca = _quickFabricRect(dcaList[i].shape, false, false);
                            dca.selectable = false;
                            dca.hoverCursor="default";
                            canvas.add(dca);
                        }

                        txt = _drawText(canvas, dcaList[i].shape, dcaList[i].name);
                        if (txt) {
                            txt.hoverCursor="default";
                            txt.selectable = false;
                            canvas.add(txt);
                        }
                    }
                }
                var short = "";
                for (var j = 0; j < canvas._objects.length; j++) {
                    if (canvas._objects[j].dcaName === selected.name) {
                        canvas.remove(canvas._objects[j]);
                    }
                }

                var rect = _quickFabricRect(selected.shape, true, false);
                txt = _drawText(canvas, selected.shape, selected.name);

                if (txt) {
                    if (!edited) {
                        var group = new fabric.Group([rect, txt]);
                        group.hasRotatingPoint = false;
                        if (selected.isEdit) {
                            group.selectable = true;
                            group.hoverCursor="move";
                            canvas.setActiveObject(group);
                        } else {
                            group.selectable = false;
                        }

                        if (data.connections.length !== 0) {
                            group.hasControls = false;
                        }

                        canvas.add(group);

                    } else {

                        if (selected.isNew) {
                            rect.hasRotatingPoint = false;
                            rect.set({
                                strokeWidth: 0
                            });
                            canvas.add(rect);
                            canvas.setActiveObject(rect);

                            if (data.connections.length !== 0)
                                rect.hasControls = false;
                        } else {
                            var group2;
                            if (data.connections.length !== 0) {
                                group2 = rect;
                            } else {
                                group2 = rect;
                                group2.set({
                                    strokeWidth: 0
                                });
                            }
                            group2.hasRotatingPoint = false;

                            if (selected.isEdit) {
                                group2.selectable = true;
                                group2.hoverCursor="move";
                                canvas.setActiveObject(group2);
                            } else {
                                group2.selectable = false;
                            }

                            if (data.connections.length !== 0) {
                                if (!scope.isDeleteLocked()){
                                    group2.hoverCursor="move";
                                    group2.hasControls = true;
                                }
                                else{
                                    group2.hasControls = false;
                                }
                            } else {
                                canvas.remove(txt);
                            }

                            canvas.add(group2);
                            canvas.setActiveObject(group2);
                        } 
                    }
                } else {
                    if (typeof selected.page_id !== "undefined" && selected.page_id !== null) {

                        rect.hasRotatingPoint = false;
                        if (!edited || data.connections.length !== 0) {
                            if (!scope.isDeleteLocked()){
                                rect.hoverCursor="move";
                                rect.hasControls = true;
                            }else{
                                rect.hasControls = false;
                            }
                        } else {
                            rect.set({
                                strokeWidth: 0
                            });
                        }

                        canvas.add(rect);
                        if (selected.isEdit)
                            canvas.setActiveObject(rect);
                    } 
                }

                var onMouseUp = function (e) {
                    var obj = canvas.getActiveObject();
                    var handleDcaBGCheck = function (data) {
                    obj.hoverCursor="move";
                        if (data.valid) {
                            if (typeof obj._objects == "undefined" || obj._objects.length === 0) {
                                obj.set({
                                    strokeWidth: 0,
                                    stroke: self.goodPosition,
                                    fill: self.goodPosition
                                });
                            } else {
                                obj._objects[0].set({
                                    strokeWidth: 0,
                                    stroke: self.goodPosition,
                                    fill: self.goodPosition
                                });
                            }
                            scope.selectedElement.validated = true;
                        } else {
                            if (typeof obj._objects == "undefined" || obj._objects.length === 0) {
                                obj.set({
                                    strokeWidth: 0,
                                    fill: self.badPosition
                                });
                            } else {
                                obj._objects[0].set({
                                    strokeWidth: 0,
                                    fill: self.badPosition
                                });
                            }
                            scope.selectedElement.validated = false;

                        }
                        canvas.renderAll();
                    };
                    if (obj !== null && selected.isEdit) {
                        if (selected.page_id !== null) {
                            _backendCheckDcaShape(selected, handleDcaBGCheck);
                        } 
                        else {
                            obj.set({
                                strokeWidth: 0,
                                fill: self.badPosition
                            });
                        }
                    }
                };
                onMouseUp();
                canvas.off("mouse:up");
                canvas.on("mouse:up", onMouseUp);

                canvas.on("object:scaling",(e)=>canvasObjEvntHandler(e,"object:scaling"));
                canvas.on("object:moving",(e)=>canvasObjEvntHandler(e,"object:moving"));

                function canvasObjEvntHandler(evt,evtType) {


                    var obj = canvas.getActiveObject();
                    var precision = 100;


                    if (obj !== null && selected.isEdit) {

                        if (obj.width * obj.scaleX > canvas.width)
                            obj.set({
                                "scaleX": canvas.width / obj.width
                            });

                        if (obj.height * obj.scaleY > canvas.height)
                            obj.set({
                                "scaleY": canvas.height / obj.height
                            });

                        if (obj.top < 0)
                            obj.set({
                                "top": 0
                            });

                        if (obj.top + obj.height * obj.scaleY > canvas.height) { 
                            if (obj.scaleY == 1.775)
                                obj.set({
                                    "top": canvas.height - obj.height * obj.scaleY - 1
                                });
                            else
                                obj.set({
                                    "top": canvas.height - obj.height * obj.scaleY
                                });
                        }

                        if (obj.left < 0)
                            obj.set({
                                "left": 0
                            });


                        if ((obj.left + (obj.width * obj.scaleX)) > canvas.width)
                            obj.set({"left": canvas.width - (obj.width * obj.scaleX)});
                            if(evtType==="object:scaling"){
                                selected.shape.height = parseFloat(obj.height*obj.scaleY  / _scale).toFixed(2);
                                selected.shape.width =  parseFloat(obj.width*obj.scaleX  / _scale).toFixed(2);
                            }


                            selected.shape.y = parseFloat(obj.top / _scale).toFixed(2);
                            selected.shape.x = parseFloat(obj.left / _scale).toFixed(2);

                           selected.shape=_convertShapeToFixed(selected.shape);


                        obj.setCoords();
                        scope.$apply();
                        canvas.renderAll();
                    }
                }

                function _convertShapeToFixed(shape) {
                    var _shape=angular.copy(shape);
                    _shape.width = parseFloat(_shape.width).toFixed(2);
                    _shape.width = isNaN(_shape.width) ? "" : parseFloat(_shape.width).toFixed(2);
                    _shape.height = parseFloat(_shape.height).toFixed(2);
                    _shape.height = isNaN(_shape.height) ? "" : parseFloat(_shape.height).toFixed(2);
                    _shape.x = parseFloat(_shape.x).toFixed(2);
                    _shape.x = isNaN(_shape.x) ? "" : _shape.x;
                    _shape.y = parseFloat(_shape.y).toFixed(2);
                    _shape.y = isNaN(_shape.y) ? "" : _shape.y;
                    return _shape;
                }

                for (var y = 0; y < canvas._objects.length; y++) {
                    if (canvas._objects[y].stroke === self.goodPosition) {
                        canvas.bringToFront(canvas._objects[y]);
                    }

                    if (canvas._objects[y].__text === selected.name) {
                        canvas.bringToFront(canvas._objects[y]);
                    }
                }


                deffered.resolve();
                return deffered.promise;
            }

            function polyconverter(polyString) {

                if (polyString.indexOf("MULTIPOLYGON") !== -1) {
                    return parsePolyStrings(polyString);
                } else {
                    return parsePolyStrings(polyString);
                }

                function parsePolyStrings(ps) {
                    var i, j, x, y, tmp, tmpArr, arr = [],
                        m = ps.match(/\([^\(\)]+\)/g);
                    if (m !== null) {
                        for (i = 0; i < m.length; i++) {
                            tmp = m[i].match(/-?\d+\.?\d*/g);
                            if (tmp !== null) {
                                for (j = 0, tmpArr = []; j < tmp.length; j += 2) {
                                    x = parseInt(tmp[j]) * 1.775;
                                    y = parseInt(tmp[j + 1]) * 1.775;
                                    tmpArr.push({
                                        x: x,
                                        y: y
                                    });
                                }
                                arr.push(tmpArr);
                            }
                        }
                    }
                    return arr;
                }


            }

            function _drawAllFreeAreas(freeAreaList, canvas, polygons) {
                var deffered = $q.defer();
                var polygonArray=[];
                var fabricPolygonArray = [];
                if (angular.isDefined(freeAreaList)) {
                    freeAreaList.forEach(dca => polygonArray=polygonArray.concat(polyconverter(dca.shape.wkt)));

                    for (var j = 0; j < polygonArray.length; j++) {
                        if (typeof self.patternSourceCanvas != "undefined") {
                            self.patternSourceCanvas.setHeight(331);
                            self.patternSourceCanvas.setWidth(270);
                        }

                        var polygon = new fabric.Polygon(polygonArray[j], {
                            selectable: false
                        });

                        if (self.backgroundPattern !== null) {
                            var bgPattern=angular.copy(self.backgroundPattern);
                            bgPattern.offsetX=(bgPattern.offsetX+polygon.pathOffset.x)-polygon.left;
                            bgPattern.offsetY=(bgPattern.offsetY+polygon.pathOffset.y)-polygon.top;
                            polygon.fill = bgPattern;
                        } else {
                            polygon.fill = "rgba(255,255,255,1)";
                        }


                        fabricPolygonArray.push(polygon);
                        polygon.set({
                            strokeWidth: 0,
                            stroke: "rgba(0, 153, 0,1)"
                        });
                        canvas.add(polygon);
                    }
                }
                deffered.resolve();
                return deffered.promise;
            }

            function _toggleCanvasEvents(isDisabled){
                if(isDisabled){
                    $(".upper-canvas").css("pointer-events","none");
                }else{
                    $(".upper-canvas").css("pointer-events","all");
                }


            }

            function _saveDCA(element) {
                var deffered = $q.defer();

                if (ValidationService.checkIsEmpty(element.page_name, "location")) return false;
                if (ValidationService.checkIsInMaxSize(element.description, "Description", 1024)) return false;
                if (ValidationService.checkIsEmpty(element.name, "title")) return false;
                if (ValidationService.checkIsInMaxSize(element.name, "Title", 64)) return false;
                if (ValidationService.checkIsEmpty(element.shape.x, "X")) return false;
                if (ValidationService.checkIsInMaxSize(element.shape.x, "X", 7)) return false;
                if (ValidationService.checkIsEmpty(element.shape.y, "Y")) return false;
                if (ValidationService.checkIsInMaxSize(element.shape.y, "Y", 7)) return false;
                if (ValidationService.checkIsEmpty(element.shape.width, "width")) return false;
                if (ValidationService.checkIsInMaxSize(element.shape.width, "Width", 7)) return false;
                if (ValidationService.checkIsEmpty(element.shape.height, "height")) return false;
                if (ValidationService.checkIsInMaxSize(element.shape.height, "Height", 7)) return false;

                var dataToSend = {
                    "x": parseFloat(String(element.shape.x).replace(",", ".")),
                    "y": parseFloat(String(element.shape.y).replace(",", ".")),
                    "width": parseFloat(String(element.shape.width).replace(",", ".")),
                    "height": parseFloat(String(element.shape.height).replace(",", ".")),
                    "name": element.name,
                    "description": element.description ? element.description : "",
                    "tag": element.tag,
                };

                const onSuccess = function (data) {
                  if (data.banner_area_id) element.id = data.banner_area_id;
                  element.isEdit = false;
                  element.isNew = false;
                  deffered.resolve(element);
                  growlHandlingService.success("Your Banner Area has been successfully saved.");
                }

                const onFailure = function (data) {
                  deffered.reject();
                  self.handleStatus(404, data, self);
                }

                if(element.isNew) {
                    window.api.bannerAreaPost(element.page_id, dataToSend, onSuccess, onFailure);
                } else {
                    window.api.bannerAreaPut(element.page_id, element.id, dataToSend, onSuccess, onFailure);
                }

                return deffered.promise;
            }

            function _deleteDCA(scope, dca) {
                var deffered = $q.defer();
                var customerId = window.api.customerid;
                var projectId = window.api.projectid;
                modal.confirm({
                    message: "Are you sure you want to delete this Banner Area?",
                    ok: "Yes",
                    cancel: "No"
                },
                    function () {
                        _remove(dca);
                    },
                    function () { }
                );

                function _remove(dca) {
                    const onSuccess = function (data) {
                      if (data.deleted === false) {
                        modal.info({
                            message: "Could not remove the Banner Area.",
                            ok: "Ok",
                            cancel: ""
                          },
                          function () {
                            deffered.reject();
                          },
                          function () { }
                        );
                      } else {
                        growlHandlingService.success("Your Banner Area has been successfully removed.");
                        deffered.resolve();
                      }
                    };
                    const onFailure = function(data) {
                      self.handleStatus(404, data, self);
                      deffered.reject();
                    }
                    window.api.bannerAreaDelete(dca.page_id, dca.id, onSuccess, onFailure);
                }


                return deffered.promise;
            }

            function _handleStatus(status, data, scope) {
                errorHandlingService.handleResponse(status, data, scope);
            }

            function _cc() {
                if (self.canvas !== null && typeof self.canvas !== undefined) {
                    self.canvas.clear();
                    self.canvas.dispose();
                    delete self.canvas;
                    self.canvas = null;
                }
            }
        }
]);
