module.controller("flowCopyModalController",["$scope","$uibModalInstance","flow","copyFlowCallback","onClose",
    function($scope,modalInstance,flow,copyFlowCallback,onClose) {
    $scope.orginalFlowName=flow.name;

    $scope.closeDialog = function() {
        modalInstance.close();
      };

      $scope.createCopy = function(nameForCopyFlow) {
        if (!$scope.flowCopyForm.$invalid) {
            copyFlowCallback(flow,nameForCopyFlow).then((data)=>{
              onClose(data,$scope.closeDialog)
            });
        }
      };
}]);
