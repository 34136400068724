module.filter("productionPreviewDateFilter", function() {
	"use strict";
    return function(productionConnections,date) {

            if(date!=""){
            for(var prop in productionConnections ){
                productionConnections[prop]=productionConnections[prop].filter(function(conn){
                return ((conn.valid_to_utc >= date) && conn.status==="running") ||
                        ((conn.valid_from_utc <= date) && conn.status==="pending")
                 });
             }
            }
        return productionConnections;
    };
});
