module.controller("editBlankPageModalController", ["$scope", "$uibModalInstance", "modal", "blankPageService", "page","growlHandlingService",
  function ($scope, modalInstance, modal, blankPageService, page,growlHandlingService) {
    $scope.page=angular.copy(page);
    $scope.closeDialog = function () {
      modalInstance.close();
    };

    $scope.save = function (blankPage) {
      if (!$scope.blankPageForm.$invalid) {
        blankPageService.blankPageUpdate(blankPage).then((response) => {
          growlHandlingService.success(`Page name has been updated`)
          $scope.closeDialog();
        }).catch((error) => {

          modal.info(
            {
              message: error.msg,
              ok: "Ok",
              cancel: ""
            },
            function () {
            },
            function () { }
          );
        });
      }
    };
  }]);
