ocCommonProviders.directive("enclosureListDirective", [function() {
    "use strict";
    return {
        restrict: "C",
        templateUrl: "/static/partials/enclosuresList.html",
        link: function(scope, element, attrs) {
            scope.previewImageContentUrl = "/static/img/Enclosure.svg"
        }
    };
}]);
