
var _DIALOG;
function _extractVariableType(context) {
    let element = $(".selected-variable");
    if(!element.attr('displayname')){ 
        context.getContentElement("tab", "noVariableSelected").getElement().show()
        return null;
    }
    return element.attr('displayname').match(/\(([^)]+)\)/)[1];
}
const forSeparatorBase = {
    isValidate: function () {
        _DIALOG=this;
        if(this.hasOwnProperty("type")){_DIALOG=this.getDialog();}
        let tseparator = _DIALOG.getContentElement('tab', 'tseparator').getValue();
        let dseparator = _DIALOG.getContentElement('tab', 'dseparator').getValue();
        let variableType = _extractVariableType(_DIALOG);
        if(!variableType) return false;

        return !((variableType === "decimal") && (tseparator === dseparator));
    },
    onError: function (errMsg) {
        $(_DIALOG.getContentElement("tab", "errors").getElement().$)
            .show()
            .find(".error_msg").text(errMsg);
    },
    onSuccess: function () {
        $(_DIALOG.getContentElement("tab", "errors").getElement().$).hide()
    },
    errorMsg: "*Thousand separator should be different than decimal separator."
}

ocCommonProviders.constant("vPropertiesValidators", [
    angular.extend({ name: "forTSeparator" }, forSeparatorBase),
    angular.extend({ name: "forDSeparator" }, forSeparatorBase)
]);