const stringFormattersRegex = {
  rmExtraWhiteSpaces: /[\s\t\n]+/g,
  firstToUppercase: /\w\S*/g
};

const stringFormatters = [
  {
    name: "capitalization",
    type:"multi",
    actions: {
      firstletter: function(target) {
        return target.replace(stringFormattersRegex.firstToUppercase, function(word) {
          return word.charAt(0).toUpperCase()+word.substr(1).toLowerCase();
        });
      },
      lower: function(target) {
        return target.toLowerCase();
      },
      upper: function(target) {
        return target.toUpperCase();
      }
    }
  },
  {
    name: "rmwhitespacesfrom",
    type:"multi",
    actions: {
      beginning: function(target) {
        return target.trimLeft();
      },
      end: function(target) {
        return target.trimRight();
      },
      both: function(target) {
        return target.trim();
      }
    }
  },
  {
    name: "rmextrawhitespaces",
    type:"multi",
    actions: {
      true: function(target) {
        return target.replace(stringFormattersRegex.rmExtraWhiteSpaces, " ");
      }
    }
  },
  {
    name: "paddingdirection",
    type:"multi-require",
    require: ["paddinglength", "paddingcharacter","maxlength"],
    actions: {
      beginning: function(target, paddingLength, paddingCharacter, maxLength) {
        while ((target.toString().length < Number(paddingLength)) && (Number(paddingLength)<Number(maxLength)) && paddingCharacter) {
          target = paddingCharacter + target;
        }
        return target;
      },
      end: function(target,paddingLength, paddingCharacter,maxLength) {
        while ((target.toString().length < Number(paddingLength)) && (Number(paddingLength)<Number(maxLength)) && paddingCharacter) {
          target = target + paddingCharacter;
        }
        return target;
      }
    }
  },
  {
    name: "maxlength",
    type:"single",
    action:function(target, propValue) {
        return target.substr(0, propValue);
    }
  }
];

ocCommonProviders.constant("stringFormatters", stringFormatters);
