module.controller("topMenuCntrl", [
  "db",
  "login",
  "modal",
  "$scope",
  "$location",
  "userService",
  "flowService",
  "$timeout",
  "$rootScope",
  "$route",
  "menuService",
  function(
    db,
    login,
    modal,
    $scope,
    $location,
    userService,
    flowService,
    $timeout,
    $rootScope,
    $route,
    menuService
  ) {
    "use strict";
    $scope.activeElement = null;
    $scope.activeSubElement = null;
    $scope.topMenuItemsAll = null;
    $scope.isEnclosureEnabled = null;

    $scope.subMenu =menuService.getMenuByName("subMenu"); 
    $scope.subMenuBanner = menuService.getMenuByName("subMenuBanner"); 
    $scope.subMenuAdministration = menuService.getMenuByName("subMenuAdministration"); 
    $scope.subMenuHelp = menuService.getMenuByName("subMenuHelp"); 
    $scope.subMenuSettings = menuService.getMenuByName("subMenuSettings"); 
    $scope.topMenuItems = menuService.getMenuByName("topMenuItems"); 

    $scope.$on("setUserAccess", function() {
      var ual = userService.getUAL();
      if (userService.isAdmin()) $scope.cfg = config;

      if (typeof $scope.topMenuItemsAll === "undefined") {
        $scope.topMenuItemsAll = $scope.topMenuItems;
      }

      for (var x = 0; x < $scope.topMenuItems.length; x++) {
        $scope.topMenuItems[x].hide = false;
      }

      for (var i = 0; i < $scope.topMenuItems.length; i++) {
        if (typeof ual !== "undefined") {
          if (
            !userService.isAdmin() &&
            $scope.topMenuItems[i].title === "Administration"
          ) {
            $scope.topMenuItems[i].hide = true;
          }
          if (!ual.bannersInMenu && $scope.topMenuItems[i].link === "/dca") {
            $scope.topMenuItems[i].hide = true;
          }
          if (
            !ual.targetListInMenu &&
            !ual.bannersInMenu &&
            $scope.topMenuItems[i].title === "Materials"
          ) {
            $scope.topMenuItems[i].hide = true;
          }
          if (
            (ual.targetListInMenu || ual.bannersInMenu) &&
            $scope.topMenuItems[i].link === "/attachments"
          ) {
            $scope.topMenuItems[i].hide = true;
          }


          if (
            !ual.targetListInMenu &&
            $scope.topMenuItems[i].title === "Rules"
          ) {
            $scope.topMenuItems[i].hide = true;
          }
        }
      }

      for (var y = 0; y < $scope.subMenu.length; y++) {
        $scope.subMenu[y].hide = false;
      }
      var appLevel = userService.getUser().application_level;
      for (var i = 0; i < $scope.subMenuSettings.length; i++) {
        if(($scope.subMenuSettings[i].link === "/users" && !userService.isGroupMember("admin","power"))){
          $scope.subMenuSettings[i].hide = true;
        }

        if($scope.subMenuSettings[i].link === "/page" && (appLevel === "basic" || appLevel === "basic-plus")){
          $scope.subMenuSettings[i].hide = true;
        }else{
          $scope.subMenuSettings[i].hide = false;
        }
      }

      for (var j = 0; j < $scope.subMenu.length; j++) {
        if (
          typeof ual !== "undefined" &&
          !ual.targetListInMenu &&
          $scope.subMenu[j].link === "/target-lists"
        ) {
          $scope.subMenu[j].hide = true;
        }
        if (
          typeof ual !== "undefined" &&
          !ual.bannersInMenu &&
          $scope.subMenu[j].title === "Banners"
        ) {
          $scope.subMenu[j].hide = true;
        }
        if (
          typeof ual !== "undefined" &&
          !ual.bannersInMenu &&
          $scope.subMenu[j].link === "/text-boxes"
        ) {
          $scope.subMenu[j].hide = true;
        }
        if (typeof ual !== "undefined" && $scope.subMenu[j].link === "/enclosures") {
          $scope.subMenu[j]["class"]="";
          let appLevel = userService.getUser().application_level;
          if (appLevel === "basic" || appLevel === "basic-plus") {
            $scope.subMenu[j]["class"] = "item_display_none";
            return;
          }

          if (!userService.getUser().enclosure_enabled && userService.isAdmin()) {
            $scope.subMenu[j]["class"] = "item_alert";
          }        
        }
      }
    });

    $scope.topMenuClick = function(index, item,params) {

      $scope.activeElement = index;
      $scope.activeSubElement = -1;
      $scope.activeSubBannerElement = -1;
      $scope.activeSubSettingsElement = -1;
      $scope.activeSubAdministrationElement = -1;
      var path=`${$scope.topMenuItems[$scope.activeElement].link}`;
      if(params){
        Object.keys(params).forEach(function(key,index) {
            path+=`/${params[key]}`
        });
      }
      void 0
        $location.path(path);
    };

    $scope.subMenuClick = function(index, self) {
      $scope.activeElement = -1;
      $scope.activeSubElement = index;
      $scope.activeSubBannerElement = -1;
      $scope.activeSubSettingsElement = -1;
      $scope.activeSubAdministrationElement = -1;
      $location.path($scope.subMenu[$scope.activeSubElement].link);
    };

    $scope.subMenuBannerClick = function(index, self) {
      $scope.activeElement = -1;
      $scope.activeSubAdministrationElement = -1;
      $scope.activeSubSettingsElement = -1;
      $scope.activeSubElement = -1;
      $location.path($scope.subMenuBanner[index].link);
      $scope.activeSubBannerElement = index;
    };

    $scope.subMenuSettingsClick = function(index, self) {
      $scope.activeElement = -1;
      $scope.activeSubElement = -1;
      $scope.activeSubSettingsElement = index;
      $scope.activeSubBannerElement = -1;
      $location.path(
        $scope.subMenuSettings[$scope.activeSubSettingsElement].link
      );
    };

    $scope.subMenuAdministrationClick = function(index, self) {
      $scope.activeElement = -1;
      $scope.activeSubElement = -1;
      $scope.activeSubAdministrationElement = index;
      $scope.activeSubBannerElement = -1;
      $scope.activeSubSettingsElement = -1;
      $location.path(
        $scope.subMenuAdministration[$scope.activeSubAdministrationElement].link
      );
    };

    $scope.subMenuHelpClick = function(index, self) {
      if (self.name === "quickStart") {
        menuService.showUserGuide($scope);
      } else {
        $scope.activeElement = -1;
        $scope.activeSubElement = -1;
        $scope.activeSubAdministrationElement = index;
        $scope.activeSubBannerElement = -1;
        $location.path($scope.subMenuHelp[index].link);
      }
    };

    $scope.maintenance = function() {
      $scope.activeElement = 0;
      $location.path("/maintenance");
    };

    $scope.clickSubMenuSettingsElementByName = function(name) {
        for (var i = 0; $scope.subMenuSettings.length > i; i++) {
            if ($scope.subMenuSettings[i].title === name) {
                 break;
            }
        }
        $scope.subMenuSettingsClick(i);
    }

    $scope.clickSubMenuBannerElementByName = function(name) {
      for (var i = 0; $scope.subMenuBanner.length > i; i++) {
        if ($scope.subMenuBanner[i].title === name) {
          break;
        }
      }
      $scope.subMenuBannerClick(i);
    };

    $scope.clickSubMenuElementByName = function(name) {
      for (var i = 0; $scope.subMenu.length > i; i++) {
        if ($scope.subMenu[i].title === name) {
          break;
        }
      }
      $scope.subMenuClick(i);
    };

    $scope.clickMenuElementByName = function(name,params) {
      void 0
      for (var i = 0; $scope.topMenuItems.length > i; i++) {
        if ($scope.topMenuItems[i].title === name) {
          $scope.activeElement = i;
          break;
        }
      }
      $scope.topMenuClick(i,null,params);
    };

    function _changeTab(name) {
      for (var i = 0; $scope.topMenuItems.length > i; i++) {
        if ($scope.topMenuItems[i].title === name) {
          $scope.activeElement = i;
          break;
        }
      }
    }

    $scope.$on("CHANGE_ADDRESS", function(url, val) {
      void 0
      var target=val;
      if(val.params){ 
        target=val.name;
      }

      if (target == "Enclosures" || target == "Target Lists" || target == "Attachments") {
        $scope.clickSubMenuElementByName(target);
      } else if (target == "Area" || target == "Image" || target == "Text") {
        $scope.clickSubMenuBannerElementByName(target);
      } else if(target == "Users" || target == "Configuration") {
        $scope.clickSubMenuSettingsElementByName(target);
      } else {
        $scope.clickMenuElementByName(target,val.params);
      }
      $route.reload();
    });

    $scope.$on("CHANGE_TAB", function(url, val) {
      _changeTab(val);
    });

    $scope.slick = 0;

    $scope.$on("CHANGE_SUB_ADDRESS", function(url, val) {
      $scope.clickSubMenuBannerElementByName(val);
    });

    $rootScope.changeSubAddress = function(name) {
      $rootScope.$broadcast("CHANGE_SUB_ADDRESS", name);
    };

    $rootScope.changeAddress = function(name) {
      $rootScope.$broadcast("CHANGE_ADDRESS", name);
    };
  }
]);
