ocCommonProviders.directive("rulesDirective", ["modal", "rulesService", "$filter",
    function (modal, rulesService, $filter) {
        "use strict";
        return {
            restrict: "C",
            templateUrl: "/static/partials/rules.directive.html",
            link: function (scope, element) {
                function _disableRule(rule) {
                    rule.readonly = true;
                    for (var i = 0; i < rule.rules.length; i++) {
                        rule.rules[i].readonly = true;
                        if (typeof (rule.rules[i].rules) !== 'undefined')
                            rule.rules[i] = _disableRule(rule.rules[i]);
                    }
                    return rule;
                }

                function _enableRule(rule) {
                    rule.readonly = false;
                    for (var i = 0; i < rule.rules.length; i++) {
                        rule.rules[i].readonly = false;
                        if (typeof (rule.rules[i].rules) !== 'undefined')
                            rule.rules[i] = _enableRule(rule.rules[i]);
                    }
                    return rule;
                }

                function createFilters() {
                    var filters = [];
                    const getValuesTransformed = (value) => {
                        if (angular.isArray(value)) {
                            return value.map((litsItem) => {
                                if (angular.isDefined(litsItem.value) &&
                                    angular.isDefined(litsItem.description) &&
                                    litsItem.description &&
                                    litsItem.description.length > 0) {
                                    litsItem["label"] = `${litsItem.value} (${$filter("ocCut")(litsItem.description, false, 50, "...")})`;
                                }
                                return litsItem;
                            })
                        }
                        return value;
                    }
                    for (let i = 0; i < scope.$parent.variablesGroup.length; i++) {
                        const value = scope.$parent.variablesGroup[i].value;
                        let type = scope.$parent.variablesGroup[i].type;
                        let operators = [];
                        let data = { type: type, subtype: "" };
                        let values = null;
                        let valueSetter = null;
                        let input = "text";
                        if (scope.$parent.variablesGroup[i].list_value != null && scope.$parent.variablesGroup[i].list_value.length > 0) {
                            const list = scope.$parent.variablesGroup[i].list_value;
                            values = getValuesTransformed(list);
                            data = { type: "list", subtype: type };
                            input = "select";
                            valueSetter = (rule, value) => {
                                const v = rule.$el.find('.rule-value-container option').toArray().filter((val) => val.value == value);
                                if (v.length == 0) {
                                    const select = rule.$el.find('.rule-value-container select');

                                    if (select.toArray().length > 0) {
                                        const name = select.prop("name");
                                        const disabled = select.prop("disabled");
                                        let newChild = $(`<input class="form-control" type="text" name="${name}" value="${value}">`).prop("disabled", disabled);
                                        newChild.on('input', (e) => {
                                            rule.value = newChild.val();
                                        });
                                        rule.$el.find('.rule-value-container').children().replaceWith(newChild);
                                    }
                                } else {
                                    rule.$el.find('.rule-value-container select').val(value)
                                    $('.rule-value-container select').chosen({ width: "100%!important" });
                                    $('.rule-filter-container select').chosen({ width: "100%!important" });
                                }


                            }
                            type = "integer";
                        }
                        switch (type) {
                            case "integer":
                            case "decimal":
                                if (type == "decimal") type = "double";
                                operators = ['equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal'];
                                break;
                            case "date":
                                type = "string"
                                operators = ["exact_date", "expect_for", "after", "after_and_including", "before", "before_and_including"]
                                data = { type: "string", subtype: "date" }
                                break;
                            case "string":
                                operators = ['equal', 'not_equal', 'contains', 'begins_with', 'ends_with']
                                break;
                            case "list":
                                input = "select";
                                type = "integer";
                                values = getValuesTransformed(scope.$parent.targetlist);
                                data = { type: "list", subtype: "targetlist" };
                                operators = ['in', 'not_in'];
                                break;
                            case "rule":
                                input = "select";
                                type = "integer";
                                values = getValuesTransformed(scope.$parent.ruleslist);
                                data = { type: "rule", subtype: "" };
                                operators = ['equal'];
                                break;
                        }
                        filters.push({
                            id: value,
                            type: type,
                            data: data,
                            values: values,
                            input: input,
                            valueSetter: valueSetter,
                            operators: operators,
                            validation: {
                                callback: (date) =>{
                                    if (data.subtype === 'date') {
                                        return isNaN(Date.parse(date))?"Invalid format of date":true;
                                    }
                                    return true;
                                },
                            }
                        });
                    } 
                    return filters;
                }

                var hook = angular.element(element).find(".rules");
                hook.change(function () {
                    $(this).find('.rule-filter-container select').chosen({ width: "100%!important" });
                    $(this).find('.rule-value-container select').chosen({ width: "100%!important" });
                });

                function _buildQuery() {
                    if (angular.isUndefined(scope.rule)) return;
                    if (scope.openRules.indexOf(scope.rule.id) >= 0) {
                    } else if (scope.editRuleId !== scope.rule.id) {
                        scope.rule.value = _disableRule(scope.rule.value);
                        return;
                    } else if (scope.rule.value) {
                        scope.rule.value = _enableRule(scope.rule.value);
                    }
                    var value = angular.copy(scope.rule.value);
                    hook.queryBuilder('destroy');
                    hook.queryBuilder({
                        operators: $.fn.queryBuilder.constructor.DEFAULTS.operators.concat([
                            { type: 'exact_date', nb_inputs: 1, multiple: false, apply_to: ['date'] },
                            { type: 'expect_for', nb_inputs: 1, multiple: false, apply_to: ['date'] },
                            { type: 'after', nb_inputs: 1, multiple: false, apply_to: ['date'] },
                            { type: 'after_and_including', nb_inputs: 1, multiple: false, apply_to: ['date'] },
                            { type: 'before', nb_inputs: 1, multiple: false, apply_to: ['date'] },
                            { type: 'before_and_including', nb_inputs: 1, multiple: false, apply_to: ['date'] },
                        ]),
                        lang: {
                            operators: {
                                exact_date: 'exact date',
                                expect_for: 'expect for',
                                after: 'after',
                                after_and_including: 'after and including',
                                before: 'before',
                                before_and_including: 'beforeand including'
                            }
                        },
                        filters: createFilters(),
                        rules: value,
                        plugins: ['not-group']
                    });
                    if (scope.editRuleId !== scope.rule.id) {
                        $(hook).find('.btn:not(disabled)').addClass('disabled');
                        $(hook).find('button').removeAttr('data-not');
                    }
                    $('.rule-value-container select').chosen({ width: "100%!important", position:"fixed" });
                    $('.rule-filter-container select').chosen({ width: "100%!important", position:"fixed" });
                }


                scope.$on("reBuildQuery", function () {
                    _buildQuery();
                });

                scope.removeRule = function (rule) {
                    var with_connection = false;

                    if (rule.id > 0) {
                        let msg = "";

                        if (rule.active_connection) {
                            msg = `This rule is used in Connections: ${rule.active_connection}. Deleting this rule will result with deleting those Connections. Do you want to proceed?`;
                            with_connection = true;
                        } else {
                            msg = "Are you sure you want to delete this Rule?";
                        }


                        modal.confirm({
                            message: msg,
                            ok: "Yes",
                            cancel: "No"
                        },
                            function () {

                                rulesService.removeRule(rule, with_connection, function () {
                                    scope.discardRule();
                                    delete scope.$parent.ruleslist[rule.id];
                                    rulesService.spawnModal("Rule has been removed");
                                });
                            },
                            function () { }
                        );


                    }
                };

            }
        }; 
    }
]);
