ocCommonProviders.directive("rulesInConnectionsDirective", ["api", "db", "login", "modal", "rulesService",
    function(api, db, login, modal, rulesService) {
        "use strict";
        return {
            restrict: "C",
            scope: true,
            template:
                '<div class="of targetListSearchHolder">'+
                '<div class="packTargetInput">'+
                '<div class="magnifierHolder">'+
                '<input class="show targetInput magnifier" id="ruleSearch"  placeholder="Search..." ng-model="searchRule"/>'+
                '</div>'+
                '</div>'+
                '</div>'+
                '<div class="borderGrey scrollList scrollBar" data-height=\'165\')>' +
                '<div class="mask">' +
                '<div class="ruleTargetElement listRow"  ng-click="selectRule(rule)" ng-class="{ \'highlightTable\' : $index%2==1 ,\'selectedTable\' : rule.id == selectedRuleId ,\'previouslySelectedTable\' : rule.id===previouslySelectedRule }" ng-repeat="rule in rulesFiltered = ( rules | filter:searchRule | filter: { status:\'active\' } )" >' +
                '<div class="title">{{rule.name}}</div>' +
                '</div>' +
                '</div>' +
                '</div>' +
                '<div style="margin-top:5px;">' +
                '<navigation-context name="connections_textbox" target-url="/rules" from="connections" action-click="onLocationChange()">Define new Rule</navigation-context>'+
                '<div class="button bgGreen fr" style="width:74px;height:30px;font-size:1em;" ng-click="changeSelectedRule(attachementData)" >Confirm</div>'+
                '</div>',
            link: function(scope, element, attrs, attachmentsController) {
                scope.loadRules().then(function(data){
                    scope.setRules(data);
                    scope.previouslySelectedRule = scope.activeConnectionObj.rule_id;
                });

            }
        }; 
    }
]);
