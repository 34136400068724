CKEDITOR.dtd["variable"] = CKEDITOR.dtd["span"];
CKEDITOR.dtd["div"]["variable"] = 1;
CKEDITOR.dtd["a"]["span"] = 0;
const textBannerEditorConfig = {
  language: "en",
  contentsCss: [
    "body {font-family:'Arial';font-size:12;} .cke_variable{background-color:yellow;} .cke_editable a{text-decoration:none;color:inherit;background-color:#a3d1f7;}"
  ],
  customConfig: false,
  removePlugins:
    "elementspath,magicline,copyformatting,wsc,scayt,tabletools,tableselection",
  extraPlugins:
    "variable,border,linkCustom,pastefromword,_tabletools,_tableselection,colordialog",
  pasteFromWordCleanupFile:
    "../ckeditor/plugins/pastefromword/filter/default.js",
  autoGrow_onStartup: false,
  fillEmptyBlocks: false,
  extraAllowedContent: "span[*]{*};div[*];variable[*]{*};a[*]",
  font_defaultLabel: "Arial",
  fontSize_defaultLabel: "12pt",
  pasteFilter: null,
  enterMode: CKEDITOR.ENTER_DIV,
  linkShowAdvancedTab: false,
  linkShowTargetTab: false,
  font_names: null,
  pasteFromWordPromptCleanup: true,
  pasteFromWordRemoveFontStyles: true,
  dialog_noConfirmCancel: true,
  allowedContent: true,
  height: "12cm",
  basicEntities: false,
  resize_enabled: false,
  fontSize_sizes:
    "3pt/3pt;4pt/4pt;5pt/5pt;6pt/6pt;7pt/7pt;8pt/8pt;9pt/9pt;10pt/10pt;11pt/11pt;12pt/12pt;14pt/14pt;16pt/16pt;18pt/18pt;20pt/20pt;22pt/22pt;24pt/24pt;26pt/26pt;28pt/28pt;36pt/36pt",
  toolbar: [
    {
      name: "basicstyles",
      items: ["Bold", "Italic", "Underline", "Subscript", "Superscript"]
    },
    {
      name: "paragraph",
      items: ["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"]
    },
    {
      name: "links",
      items: ["linkCustom"]
    },
    "/",
    {
      name: "insert",
      items: ["SpecialChar", "Variable", "Table"]
    },
    {
      name: "styles",
      items: ["Font", "FontSize"]
    },
    {
      name: "colors",
      items: ["TextColor"]
    },
    {
      name: "document",
      items: ["Source"]
    }
  ],
  instanceEvents: [
    {
      name: "dataReady",
      require: ["fontService", "db"],
      handler: function(evt) {
        var self = this;
        return function(fontService, db) {
          const customerId = window.api.customerid;
          fontService
            .getCustomerFontsFontWithPublic(customerId)
            .then(avaliableFonts => {
              $(".cke_combo__font").on(
                "DOMSubtreeModified",
                ".cke_combo_text",
                evt => {
                  var hasItalic,hasBold=false;
                  var selectedFont = evt.currentTarget.textContent;
                  var searchResult = avaliableFonts.filter(font => font.name === selectedFont)[0];

                  if (searchResult) {
                    if (searchResult.style.search("italic")==-1) {
                      self.getCommand("italic").disable();
                    } else {
                      hasItalic=true;
                      self.getCommand("italic").enable();
                    }

                    if (searchResult.style.search("bold")==-1) {
                      self.getCommand("bold").disable();
                    } else {
                      hasBold=true;
                      self.getCommand("bold").enable();
                    }

                    if (hasItalic && hasBold && searchResult.style.search("italic_bold")==-1) {

                      if (self.getCommand("italic").state == 1) {
                        self.getCommand("bold").disable();
                      }

                      if (self.getCommand("bold").state == 1) {
                        self.getCommand("italic").disable();
                      }
                    }
                  }

                  if($(".cke_button__italic.cke_button_disabled").length>0){
                    $(".cke_button__italic").prop("title","Italic is not supported for this font");
                  }else{
                    $(".cke_button__italic").prop("title","Italic (Ctrl+B)");
                  }

                  if($(".cke_button__bold.cke_button_disabled").length>0){
                    $(".cke_button__bold").prop("title","Bold is not supported for this font");
                  }else{
                    $(".cke_button__bold").prop("title","Bold (Ctrl+B)");
                  }
                }
              );

              if (CKEDITOR.env.gecko) {
                if (this.document) {
                  this.document.$.execCommand(
                    "enableInlineTableEditing",
                    false,
                    "false"
                  );
                  this.document.$.execCommand(
                    "enableObjectResizing",
                    false,
                    "false"
                  );
                }
              }
            });
        };
      }
    },
    {
      name: "beforeCommandExec",
      handler: function(evt) {
        const _checkIfTableChild = startContainer => {
          var _currNode = $(startContainer.$);
          while (_currNode[0] && _currNode[0].tagName != "BODY") {
            if (
              _currNode[0].tagName === "TR" ||
              _currNode[0].tagName === "TD" ||
              _currNode[0].tagName === "TBODY"
            ) {
              void 0;
              evt.cancel();
              break;
            }
            _currNode = _currNode.parent();
          }
        };

        if (evt.data.name === "table") {
          var range = this.getSelection().getRanges()[0];
          if (range) {
            _checkIfTableChild(range.startContainer);
          }
        }
      }
    },
    {
      name: "afterCommandExec",
      handler: function(evt) {
        if (evt.data.name === "source") {
          $(".cke_source").prop("readonly", true);
        }
      }
    },
    {
      name: "paste",
      handler: function(evt) {
        var sel = evt.editor.getSelection(true);
        var range = sel.getRanges()[0];
        var firstNode = range.startContainer.getParent();
        var tagName = firstNode.getName();
        if (
          tagName === "td" ||
          tagName === "tr" ||
          tagName === "tbody" ||
          tagName === "table"
        ) {
          void 0;
          evt.stop();
        }
      }
    }
  ]
};
ocCommonProviders.constant("textBannerEditorConfig", textBannerEditorConfig);
