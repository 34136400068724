ocCommonProviders.component("uiQuantifier", {
    templateUrl: "/static/partials/uiQuantifier.html",
    bindings: {
      collection:"<",
      quantifiers: "<",
      selected:"<",
      onSelection:"&"
    },
    controller:["uiQuantifierService",function(Quantifier) {
        this.$onInit=function(){
          this.selectedQuantifier=this.quantifiers[this.quantifiers.indexOf(this.selectedDefault)] || this.quantifiers[0] ;
          Quantifier.setSnapshot("OR",this.collection)("AND",this.collection);
        }

              this.selectionHandler=(_quantifier)=>{
          this.onSelection({q:_quantifier});
        }
      }]
  });
