module.controller("notificationsController", ["$scope", "login", "db", "logsService", function($scope, login, db, logsService) {
    "use strict";
    $scope.logsListHide=true;
    $scope.logsList=null;
    $scope.notification={
    	title:"Flow log"
    };

    $scope.projectId=null;
    $scope.customerId=null;

    $scope.canILoad = true;
    $scope.customersHistory=[];
    $scope.isDataEnd= false;

	var self=$scope;
    $scope.$on("showNotifications",function(event,mass){
        if(mass || typeof mass === "undefined"){
        $scope.logsListHide = !$scope.logsListHide;
	    if(!$scope.logsListHide){
	        $scope.customersHistory=[];
	        $scope.isDataEnd= false;
	        $scope.loadCustomerLogs(prepareDataToSend());
            var logsH = $(".conList").height()-28;
            if(logsH < 300 )
            logsH=300;
            $(".notificationHolder .logs").height(logsH);

            }

        }
        else{

        $scope.logsListHide = !mass;
        }
	});

    function prepareDataToSend(){
       var obj = {} ;
       obj.master = false;
       obj.projects = window.api.projectid;
       obj.date_start = "NOW";
       obj.lines_limit=10;
       obj.lines_offset = $scope.customersHistory.length;

       return obj;
    }


    $(".notificationHolder .logs").on("scroll",function(){
        if(($(this).scrollTop() + $(this).innerHeight()) >= this.scrollHeight && $scope.canILoad && !$scope.isDataEnd )
        {
            $scope.loadCustomerLogs(prepareDataToSend());
        }

    });

    $scope.$on("$destroy", function() {
         $(".notificationHolder .logs").off("scroll");
    });

    $scope.setNotificationName= function(name){
        $scope.notification.title=name;
    };

    $scope.loadCustomerLogs= function(dataToSend){
	        logsService.loadCustomerLogs($scope,window.api.customerid,dataToSend);
    };



}]);
