ocCommonProviders.service("maintenanceService", ["$rootScope","api", "db","login","modal","errorHandlingService","$q",
 function($rootScope, api, db, login, modal, errorHandlingService, $q) {
    "use strict";
    this.serviceMessages = _serviceMessages;
    this.loadServiceMessages = _loadServiceMessages;
    this.loadActiveMessages = _loadActiveMessages;
    this.addServiceMessage = _addServiceMessage;
    this.editServiceMessage = _editServiceMessage;

    var dateFormat = "YYYY-MM-DD HH:mm:ss";

    function _serviceMessages() {
        var deffered = $q.defer();
        const onSuccess = function (data) {
          deffered.resolve(data);
        };
        window.api.serviceMessageGetAll('active=true', onSuccess);
        return deffered.promise;
    }
    function _loadActiveMessages(scope,from){
        var deffered = $q.defer();
        var url=null;
        let params = 'active=true';
        if(typeof form !== "undefined") {
          paramse += "date_from="+from;
        }
        const onSuccess = function(data){
          $rootScope.$emit("LOADON"); 
          deffered.resolve(data);
        }
        const onFailure = function(data) {
          $rootScope.$emit("LOADON"); 
          deffered.reject();
        }
        window.api.serviceMessageGetAll(params, onSuccess, onFailure);
        return deffered.promise;
    }
    function _loadServiceMessages(scope){
        var deffered = $q.defer();
        scope.canILoad = false;
        const onSuccess = function(data){
          $rootScope.$emit("LOADON"); 
          scope.serviceMessageList = data.serviceMessages;

          for(var i = 0; i < scope.serviceMessageList.length; i++){
            var date_from = scope.serviceMessageList[i].date_from;
            var date_to = scope.serviceMessageList[i].date_to;
            date_from = moment.utc(date_from).local().format(dateFormat);
            date_to = moment.utc(date_to).local().format(dateFormat);
            scope.serviceMessageList[i].date_from = date_from;
            scope.serviceMessageList[i].date_to = date_to;
          }
          deffered.resolve();
          scope.canILoad = true;
          scope.$apply();
        }
        const onFailure = function(data) {
          $rootScope.$emit("LOADON"); 
          scope.canILoad = true;
          deffered.reject();
        }
        window.api.serviceMessageGetAll('', onSuccess, onFailure);
        return deffered.promise;
     }

    function _addServiceMessage(scope, type, active, message, date_from, date_to){
        var dataToSend={};
        var deffered = $q.defer();
        dataToSend.type = type;
        dataToSend.active = active;
        dataToSend.message = message;
        dataToSend.date_from = date_from;
        dataToSend.date_to = date_to;
        const onSuccess = function(data){
          dataToSend.id = data.messageid;
          dataToSend.date_from = moment.utc(dataToSend.date_from).local().format(dateFormat);
          dataToSend.date_to = moment.utc(dataToSend.date_to).local().format(dateFormat);
          scope.serviceMessageList.unshift(dataToSend);
          scope.selectServiceMessage(data.out);
          modal.info({
              message: "Your service message has been successfully saved",
              ok: "Ok",
              cancel: ""
            },
            function(){
              scope.cancelEditServiceMessage();
              deffered.resolve(dataToSend);
            },
            function(){
            }
          );
        }
        const onFailure = function() {
          deffered.reject();
        }
        window.api.serviceMessagePost(dataToSend, onSuccess, onFailure);
        return deffered.promise;
    }

    function _editServiceMessage(scope, type, active, message, date_from, date_to, serviceMessageId){
        var dataToSend={};
        var deffered = $q.defer();
        dataToSend.type = type;
        dataToSend.active = active;
        dataToSend.message = message;
        dataToSend.date_from = date_from;
        dataToSend.date_to = date_to;

        const onSuccess = function(data){
          modal.info({
              message: "Your service message has been successfully saved",
              ok: "Ok",
              cancel: ""
            },
            function(){ scope.cancelEditServiceMessage(); deffered.resolve(); },
            function(){}
          );

        }
        const onFailure = function() {
          deffered.reject();
        }
        window.api.serviceMessagePut(serviceMessageId, dataToSend, onSuccess, onFailure);
        return deffered.promise;
    }


var checkIsEmpty = function(element, type){
    if(element === "" || typeof element === "undefined" || element === null){
				modal.info({
                	message: "You cannot save without entering a " + type,
                    ok: "Ok",
                    cancel: ""
                    },
                    function(){$rootScope.$emit("LOADOFF");},
                    function() {}
                );
        return true;
    }
};

var checkIsFromList = function(element, type, list){
    if(list.indexOf(element) < 0 ){
        modal.info({
                	message: type + " has incorrect value",
                    ok: "Ok",
                    cancel: ""
                    },
                    function(){},
                    function() {}
                );
        return false;
    }
    return true;
};


    this.handleStatus = function (status,data,scope){
        errorHandlingService.handleResponse(status,data,scope);
    };
}]);
