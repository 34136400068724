ocCommonProviders.factory("imgRegionFactory", ["regionFactory",function(region) {
    return {
      set ScaleX(value) {
        this.scaleX = value;
      },
      set ScaleY(value) {
        this.scaleY = value;
      },
      set Image(value) {
        this.img = value;
      },
      get Instance() {
        let imgConfigBase = {
          x: 0,
          y: 0,
          angle: 0,
          opacity: 1.0,
          fill: ""
        };
        if (this.img) {
          return new fabric.Image(this.img, imgConfigBase);
        } else {
          region.ScaleX = this.scaleX;
          region.ScaleY = this.scaleY;
          region.Shape = angular.extend(imgConfigBase, {
            height: 297,
            width: 210
          });
          return region.Instance;
        }
      }
    };
  }
]);
