ocCommonProviders.directive("opuscapitaCommonMenu", function() {
    "use strict";
    return {
        restrict: "AC",
        scope: {
          ngTabs: "="
        },
        templateUrl: "/static/posti/menu/template.html",
        link: function($scope, elem, attr) {
            $scope.loadContent = function(location) {
                setTimeout(function() {
                    this.document.location.href = location;
                });
            };
        }
    };
});
