ocCommonProviders.directive("attachmentListContainer",["attachmentTargetList","resourceService",function(attachmentTargetList,Resource) {
    "use strict";
    return {
    restrict: "C",
    templateUrl:"/static/partials/attachmentListContainer.html",
    link: function(scope, element, attrs){
      scope.attachmentSearchCallback=attachmentTargetList.resourceFilter;
      scope.attachmentList=scope.attachmentList.filter((attachment)=>attachment.status!=="deleted");
      scope.attachmentListFilters=Resource.getFiltersOfMaterialList(scope.attachmentList,(filtered)=>scope.attachmentList=filtered);
    }
  };
}]);
