module.filter("ocPageGroupOrderFilter", ['utilityService','pagesListService',function(utilityService,pagesListService){
	"use strict";
    return function (values) {

                if(values && values.length>0){
            return values.sort((group1,group2)=>{
               let _group1Order=getGroupOrderById(group1);
               let _group2Order=getGroupOrderById(group2);

                               if(_group1Order!=-1 && _group2Order!=-1){
                   if(_group1Order>_group2Order){
                       return 1;
                   }else if(_group2Order>_group1Order){
                       return -1;
                   }else{
                       return 0;
                   }
               }
            });
        }

        return values;
    };


    function getGroupOrderById(group){
        if(angular.isUndefined(group.page_id)) return -1;
       return pagesListService.getPagesList().filter((page)=>page.pid===group.page_id)[0].page_order;
    }
}]);
