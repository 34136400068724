ocCommonProviders.component("uiSearchFilter", {
  templateUrl: "/static/partials/uiSearchFilter.html",
  bindings: {
    className: "@",
    id: "@",
    group: "@"
  },
  controller: [
    "uiFilterService",
    function(uiFilter) {
      this.$onInit = () => {
        this.query = uiFilter.getFilterStatus(this.group, "search", this.id);
      };

      this.onChangeHandler = pQuery => {
        uiFilter.doFiltering({
          from: "search",
          id: this.id,
          group: this.group,
          payload: { query: pQuery }
        });
      };
    }
  ]
});
