ocCommonProviders.directive("scrollBar", [function() {
    "use strict";
    return {
        restrict: "C",
        template: "",
        link: function(scope, element, attrs) {
            $(document).ready(function(){
            $(element).css({
                height: attrs.height+ "px"
            });
            $(element).jScrollPane({
                autoReinitialise: true,
                contentWidth: "0px"
            });
            scope.$on("scrollBarScroll", function(a, b) {
                var api = $(element).data('jsp');
                api.scrollToElement(b);
            });
        });

        }

    };
}]);