ocCommonProviders.directive("urlEditor", [
  "ckeditorService",
  "variablesService",
  "textBoxService",
  function(ckeditorService, variablesService, textBoxService) {
    return {
      require: "?ngModel",
      restrict: "C",
      link: function(scope, elm, attr, ngModel) {
        textBoxService.loadFonts().then(function(fontList) {
          variablesService.getAllVariables().then(function(variableList) {
            var tmpList = [];
            for (var i = 0; i < variableList.length; i++) {
              tmpList.push(variableList[i].value);
            }
            var ck = CKEDITOR.replace(
              elm[0],
              ckeditorService.getConfiguration("urlEditorConfig", {
                variables: tmpList,
                fullVariables: variableList,
                font_names: fontList
              })
            );

            if (!ngModel) return;
            ck.on("instanceReady", function() {
              ck.setData(ngModel.$viewValue);
            });

            function updateModel() {
              setTimeout(function() {
                scope.$apply(function() {

                                    scope.parsedUrl =ck.document.getBody().$.innerText,variableList;
                  scope.contentHtml=ck.document.getBody().getHtml();
                  ngModel.$setViewValue(ck.getData());
                });
              }, 0);
            }

            ck.on("change", function(event) {
              updateModel();
            });

            ck.on("dataReady", updateModel);
            ck.on("paste", function(event) {
              var sel = ck.getSelection();
              var element = sel.getStartElement();
              let innerTextLink=$("<span>"+event.data.dataValue+"</span>").find("a").text();

                   if(innerTextLink){
               event.data.dataValue=innerTextLink;
              }
            });

            ngModel.$render = function(value) {
              var str = ngModel.$viewValue;
              if (str) {
                str = str.replace(
                  /{/g,
                  "<span><variable contenteditable='false' style='background-color:yellow'>{"
                );
                str = str.replace(/}/g, "}</variable></span>");
              }
              ck.setData(str);
            };
          });
        });
      }
    };
  }
]);
