ocCommonProviders.directive("connectionView", ["userService", function(userService) {
    return {
        restrict: "C",
        priority: 100000,
        scope: false,

        link: function() {},
        templateUrl: function() {
            if (typeof(userService.getUser()) === "undefined") {
                return "/static/partials/connectionsPremium.html";
            }

            if (typeof(userService.getUser()) !== "undefined" && userService.getUser().application_level === "premium") {
                return "/static/partials/connectionsPremium.html";
            } else {
                return "/static/partials/connections.html";
            }
        },
    };
}]);
