ocCommonProviders.directive("projectElement",["userService",function (userService) {
    "use strict";
    return {
    restrict: "C",
    templateUrl:function (scope) {
         if ( typeof(userService.getUser()) !== "undefined" && userService.getUser().application_level === "premium") {
                    return "/static/partials/projectElementPremium.html";
         }else{
                    return "/static/partials/projectElement.html";
         }
     },
    link: function(scope, element, attrs){
      scope.isEnclosureEnabled = userService.isEnclosureEnabled();
    }
  };
}]);
