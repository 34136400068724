ocCommonProviders.component("inputSearch", {
      templateUrl: "/static/partials/inputSearch.html",
      bindings:{ collection: "="},
      controller:["$scope","$element","$rootScope","inputSearchService",function($scope,$element,$rootScope,inputSearch){
        var inputSearchEl = $element.find(".input-text").on("input", evt => {
          var payload={ name:"usersTextFilter",state: evt.target.value };
          inputSearch.updateFilterState(payload);
          inputSearch.doCustomFilters();
        });

        $rootScope.$on("SEARCH",()=>{
            this.collection=inputSearch.searchSettings.collectionState;
        });  
    }]
  });
