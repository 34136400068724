module.filter("ocDate", ["$filter", function($filter) {
  "use strict";
    var angularDateFilter = $filter("date");
    return function(theDate) {
       return moment.utc(theDate).local().format("YYYY-MM-DD");
    };
}]);

module.filter("ocDateShort",["$filter",function($filter) {
  "use strict";    
    var angularDateFilter = $filter("date");
    return function(theDate) {
       return angularDateFilter(theDate, "yyyy-MM-dd");
    };
}]);
module.filter("reverse", function() {
  "use strict";
  return function(items) {
    return items.slice().reverse();
  };
});

module.filter("bytes", function() {
  "use strict";
return function(bytes, precision) {
if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return "-";
if (typeof precision === "undefined") precision = 1;
var units = ["bytes", "kB", "MB", "GB", "TB", "PB"],
number = Math.floor(Math.log(bytes) / Math.log(1024));
return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + " " + units[number];
};
}); 
