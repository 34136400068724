ocCommonProviders.directive("pagePreviewsDirective",[function () {
    "use strict";
    var elemWidth=290;
    var shownElements = 4;
    return {
    restrict: "C",
    templateUrl:"/static/partials/pagesPreview.html",

    link: function(scope, element, attrs){
    scope.list = [];
    scope.activePagePreview = 0;

    var moving = angular.element(".pageInnerElement");
    scope.goLeft = function(){
        if(scope.activePagePreview<=scope.list.length && scope.activePagePreview >0){
            scope.activePagePreview--;
            moving.css("left",(parseInt(moving.css("left")) + elemWidth) +"px");
        }
    };

    scope.checkLeft = function(){
        if(scope.activePagePreview<=scope.list.length && scope.activePagePreview >0){
            return "active";
        }

        return "inactive";
    };

    scope.checkRight = function(){
        if(scope.activePagePreview < scope.list.length- shownElements){
            return "active";
        }

        return "inactive";
    };

    scope.goRight = function(){

        if(scope.activePagePreview < scope.list.length- shownElements){
            scope.activePagePreview++;
            moving.css("left",(parseInt(moving.css("left")) - elemWidth) +"px");
        }
    };

    scope.$watch("pagesPreviewList",function(newVal,oldVal){
        if(typeof scope.pagesPreviewList !== "undefined" && scope.pagesPreviewList !== null && scope.pagesPreviewList.length>0) {
            scope.activePagePreview = 0;
            moving.css("left", 0 +"px");
            scope.list = scope.pagesPreviewList;
            scope.list.push({name:"attachments",id:-1});
        }
        else{

            if(scope.list.length == 1 && scope.list[0].name!="attachments") 
                scope.activePagePreview = 0;
                moving.css("left", 0 +"px");
                scope.list.push({name:"attachments",id:-1});
            if( typeof newVal !== "undefined" && newVal.length === 0){
                scope.list = [];
                scope.list.push({name:"attachments",id:-1});
            }
        }

    });


}
  };


}]);
