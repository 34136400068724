ocCommonProviders.service("fontService", ["$q", "login", "api", "db", "modal", "errorHandlingService", "subscriptionModel",
    function($q, login, api, db, modal, errorHandlingService, subscriptionModel) {
        "use strict";
        "normal,italic,bold,italic_bold"
        this.getCustomerFonts = _loadCustomerFonts;
        this.getCustomerFontsFontWithPublic=_getCustomerFontsFontWithPublic;
        this.deleteFontById = _deleteFont;
        this.unDeleteFontById = _unDeleteFont;

        function _loadCustomerFonts(customerId) {
            var defer = $q.defer();
            var state = subscriptionModel.get('font');
            if(state.initialized) {
                defer.resolve(angular.copy(state.data));
            } else {
                const onSuccess = function(data) {
                  defer.resolve(data.font);
                }
                const onFailure = function(data) {
                  defer.reject(data);
                }
                window.api.fontGetAll(onSuccess, onFailure);
            }
            return defer.promise;
        }

        function _getCustomerFontsFontWithPublic(customerId){
            var defer = $q.defer();
            const onSuccess = function(data) {
              defer.resolve(data.font);
            }
            const onFailure = function(data) {
              defer.reject(data);
            }
            window.api.fontGetAllWithPublic(onSuccess, onFailure);
            return defer.promise;
        }


        function _deleteFont(customerId, fontId) {
            var defer = $q.defer();
            const onSuccess = function(data) {
              defer.resolve(data);
            };
            const onFailure = function(data) {
              defer.reject(data);
            }
            window.api.fontDelete(fontId, onSuccess, onFailure);
            return defer.promise;
        }


        function _unDeleteFont(customerId, fontId) {
            var defer = $q.defer();
            const data = {status: 'active'};
            const onSuccess = function(data) {
              defer.resolve(data);
            };
            const onFailure = function(data) {
              defer.reject(data);
            };
            window.api.fontPut(fontId, data, onSuccess, onFailure);
            return defer.promise;
        }

    }
]);
