ocCommonProviders.directive("opuscapitaCommonFooter", function() {
    "use strict";
    return {
        restrict: "AC",
        scope: {
          ngConfig: "="
        },
        templateUrl: "/static/posti/footer/template.html",
        link: function(scope, elem, attr) { }
    };
});
