ocCommonProviders.service("logsService", [
  "$rootScope",
  "$q",
  "api",
  "db",
  "login",
  "modal",
  "serverTime",
  "userService",
  "errorHandlingService",
  function(
    $rootScope,
    $q,
    api,
    db,
    login,
    modal,
    serverTime,
    userService,
    errorHandlingService
  ) {
    "use strict";
    this.parseDates = _parseDates;
    this.loadCustomerProjects = _loadCustomerProjects;
    this.loadProjectsHistory = _loadProjectsHistory;
    this.loadCustomerLogs = _loadCustomerLogs;
    this.loadCustomerLogsSearch = _loadCustomerLogsSearch;
    this.loadCustomerHistory = _loadCustomerHistory;
    this.handleStatus = _handleStatus;

    function _parseDates(list) {
      for (var i = 0; i < list.length; i++) {
        list[i].entry_time_utc = serverTime.utcToLocal(list[i].entry_time_utc);
      }
    }

    function _loadCustomerProjects(scope, id) {
      var deffered = $q.defer();
      scope.canILoad = false;
      const onSuccess = function(data) {
        var projects = data.projects;
        for (var i = 0; i < projects.length; i++) {
          if (projects[i].active || scope.isAdmin)
            scope.filter[projects[i].id] = true;
        }
        scope.customersProjects = projects;
        scope.canILoad = true;
        $rootScope.$emit("LOADOFF");
        deffered.resolve(data);
        scope.$apply();
      }
      const onFailure = function(data, status) {
        scope.canILoad = false;
        if (data)
          modal.info(
            {
              message: data.msg,
              ok: "Ok",
              cancel: ""
            },
            function() {
              errorHandlingService.handleResponse(status, data);
              $rootScope.$emit("LOADOFF");
              deffered.reject();
            },
            function() {}
          );
      }
      window.api.projectGetAll(onSuccess, onFailure);
      return deffered.promise;
    }

    function _loadProjectsHistory(scope, id) {
      var deffered = $q.defer();
      var customerId = window.api.customerid;
      const onSuccess = function(data) {
        _parseDates(data.history);
        scope.projectsHistory = scope.logsList = data.history;
        scope.$apply();
        deffered.resolve(data);
      }
      const onFailure = function(data, status) {
        errorHandlingService.handleResponse(404, data);
        deffered.reject();
      }
      window.api.projectHistory(id, onSuccess, onFailure);
      return deffered.promise;
    }

    function _loadCustomerLogs(scope, id, datatoSend) {
      scope.canILoad = false;
      var deffered = $q.defer();
      const onSuccess = function(data) {
        _parseDates(data.log_lines);
        if (data.log_lines.length === 0) scope.isDataEnd = true;
        if (userService.isAdmin()) {
          scope.customersHistory = scope.customersHistory.concat(
            data.log_lines
          );
        } else {
          var activeLogLines = [];
          for (var i = 0; i < data.log_lines.length; i++) {
            if (checkIfLineFlowIsActive(scope, data.log_lines[i])) {
              activeLogLines.push(data.log_lines[i]);
            }
          }
          scope.customersHistory = scope.customersHistory.concat(
            activeLogLines
          );
        }
        scope.$apply();
        scope.canILoad = true;
        deffered.resolve(data);
      }
      const onFailure = function(data) {
        errorHandlingService.handleResponse(404, data);
        deffered.reject();
      }
      window.api.logGetCustomer(datatoSend, onSuccess, onFailure);
      return deffered.promise;
    }

    function checkIfLineFlowIsActive(scope, logLine) {
      var vResult = false;
      for (var i = 0; i < scope.customersProjects.length; i++) {
        if (
          logLine.project_id === null ||
          (scope.customersProjects[i].active &&
            logLine.project_id == scope.customersProjects[i].id)
        ) {
          vResult = true;
        }
      }
      return vResult;
    }

    function _loadCustomerLogsSearch(scope, id, datatoSearch) {
      scope.canILoad = false;
      var deffered = $q.defer();
      const onSuccess = function(data) {
        _parseDates(data.log_lines);


        if (userService.isAdmin()) {
          scope.customersHistory = data.log_lines;
        } else {
          var activeLogLines = [];
          for (var i = 0; i < data.log_lines.length; i++) {
            if (checkIfLineFlowIsActive(scope, data.log_lines[i])) {
              activeLogLines.push(data.log_lines[i]);
            }
          }
          scope.customersHistory = activeLogLines;
        }
        scope.searchHits = data.hits;
        scope.$apply();
        scope.canILoad = true;
        deffered.resolve(data);
      }
      const onFailure = function(data, status) {
        scope.canILoad = false;
        errorHandlingService.handleResponse(404, data);
        deffered.reject();
      }
      window.api.logSearchCustomer(datatoSearch, onSuccess, onFailure);
      return deffered.promise;
    }

    function _loadCustomerHistory(scope, id) {
      scope.canILoad = false;
      var deffered = $q.defer();
      const onSuccess = function(data) {
        _parseDates(data.history);
        if (userService.isAdmin()) {
          scope.customersHistory = scope.customersHistory.concat(
            data.history
          );
        } else {
          var activeLogLines = [];
          for (var i = 0; i < data.history.length; i++) {
            if (checkIfLineFlowIsActive(scope, data.history[i])) {
              activeLogLines.push(data.history[i]);
            }
          }
          scope.customersHistory = scope.customersHistory.concat(
            activeLogLines
          );
        }

        scope.$apply();
        scope.canILoad = true;
        deffered.resolve(data);
      }
      const onFailure = function(data, status) {
        scope.canILoad = false;
        errorHandlingService.handleResponse(404, data);
        deffered.reject();
      }
      window.api.customerHistory(id, onSuccess, onFailure);
      return deffered.promise;
    }

    function _handleStatus(status) {
      if (status === 400) logout();
    }
  }
]);
