ocCommonProviders.directive("ref", [
  "userService",
  function(userService) {
    return {
      restrict: "E",
      scope: {
        refId: "<",
        collection: "<",
        paramToDisplay:'@'
      },
      templateUrl: "/static/partials/refInfo.html",
      link: function(scope) {
        let orginal=scope.collection.filter(item => (item.id === scope.refId))[0];

        scope.getDisplayValue=function(){
          return orginal[scope.paramToDisplay];
        }

        scope.hasRefItem=angular.isDefined(orginal);

          }
    };
  }
]);
