ocCommonProviders.provider("db", function() {
	"use strict";
	var namespaces = {};
	window.db = {
    createNamespace: function(nameString) {
      if(typeof(nameString) !== "string") {
        return false;
      }
      namespaces[nameString] = {
        scopeObjects: [], 
        dataObject: {}
      };
      return true;
    },

    isNamespace: function(nameString) {
      if(typeof(nameString) !== "string") {
        return false;
      }
      if(typeof(namespaces[nameString]) !== "object") {
        return false;
      }
      return true;
    },

    set: function(nameString, dataKeyString, data) {
      if(namespaces[nameString]) {
        namespaces[nameString].dataObject[dataKeyString] = data;

        return true;
      }
      return false;
    },
    get: function(nameString, dataKeyString) {
      if(namespaces[nameString]) {
        return namespaces[nameString].dataObject[dataKeyString];
      }
      return false;
    }
  };
	return {
		$get: function () {
			return window.db;
		}
	};
});
