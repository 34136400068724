ocCommonProviders.directive("maintenanceEditServiceMessage", [
  "$uibModal",
  "$window",
  function($uibModal, $window) {
    "use strict";
    return {
      restrict: "C",
      templateUrl: "serviceMessageEditElement",
      link: function(scope, element, attrs) {
        scope.resize(true);
        element.find(".dateTimeFrom, .dateTimeTo").datetimepicker({
          locale: "en-gb",
          format: "YYYY-MM-DD HH:mm:ss",
          widgetPositioning: {
            horizontal: "auto",
            vertical: "bottom"
          }
        });

        element.find(".dateTimeFrom").on("blur", function() {
          scope.$parent.serviceMessage.date_from = $(this).val();
          scope.$apply();
        });
        element.find(".dateTimeTo").on("blur", function() {
          scope.$parent.serviceMessage.date_to = $(this).val();
          scope.$apply();
        });
      }
    };
  }
]);
