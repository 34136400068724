module.filter("ocConnectionPreviewPriorityFilter", function() {
  "use strict";
  return function(obj) {
    if (angular.isDefined(obj[Object.keys(obj)[0]])) {
      obj[Object.keys(obj)[0]] = obj[Object.keys(obj)[0]].sort((a, b) => {
        if (a.priority < b.priority) return 1;
        if (a.priority > b.priority) return -1;
        return 0;
      });
    }
    return obj;
  };
});
