ocCommonProviders.service("multiUserService", [
  "growlHandlingService",
  function(growlHandlingService) {
    function informTheUsers(payload, msg) {
      if (payload && (payload.data.id > 0)) {
        var message = buildMessage(payload, msg);
        growlHandlingService.info(message);
      }
    }

    function buildMessage(payload, msg) {
      let actionName = "";
      let actionType = "";
      let messageContent = "";
      let title = "";

      if (msg.type === "resource") {
        actionType = msg.data.resource_type;
      } else {
        actionType = msg.type;
      }

      switch (msg.operation) {
        case "status":
          actionName = " has changed";
          break;
        case "list_post":
        case "post":
          actionName = " has added";
          break;
        case "put":
          actionName = " has updated";
          break;
        case "delete":
          actionName = " has deleted";
          break;
        default:
          break;
      }
      if ("name" in msg.data) {
        title = msg.data.name;
      } else if ("title" in msg.data) {
        title = msg.data.title;
      } else if ("value" in msg.data) {
        if(msg.operation==="list_post" && "value" in msg.data.value){
          title = msg.data.value.value;
        }else{
          title=msg.data.value;
        }
      }
      messageContent =
        "User " +
        msg.who.name +
        " " +
        actionName +
        " " +
        actionType +
        " " +
        title;
      return messageContent;
    }

    return {
      onUserAction: informTheUsers
    };
  }
]);
