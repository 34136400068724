ocCommonProviders.service("growlHandlingService", [
  "growl",
  function(growl) {
    const growlConfigGlobal = {
      ttl: 4000
    };

    const growlConfigDefault={
      info:{ttl:growlConfigGlobal.ttl},
      success:{ttl:growlConfigGlobal.ttl}
    }

    const growlHandling = {
      warning: function(msg) {return growl.warning(msg);},
      info: function(msg){ return growl.info(msg,growlConfigDefault.info);},
      success: function(msg) {return growl.success(msg, growlConfigDefault.success);},
      error: function(msg) {return growl.error(msg);}
    };

    return growlHandling;
  }
])
