ocCommonProviders.service("flowService", ["$q", "login", "api","db","modal","errorHandlingService", "subscriptionModel", function($q, login, api, db, modal, errorHandlingService, subscriptionModel) {
    "use strict";
    this.getFlows = _loadFlows;
    this.getCustomerFlows = _loadCustomerFlow;
    this.getFlowsWithBanner = _loadWithBanner;
    this.isExistFlowWithoutEnclosureLimitZero=_isExistFlowWithoutEnclosureLimitZero;

    function _loadWithBanner(id){
            var deferred = $q.defer();
            const onSuccess = function(data){
              subscriptionModel.set('page', data.pages, true);
              deferred.resolve(data);
            };
            const onFailure = function(data) {
              deferred.reject();
            }
            window.api.pageGetAllWithBanner(onSuccess, onFailure);
            return deferred.promise;
    }

    function _loadCustomerFlow(customerId){
        var deferred = $q.defer();
        var state = subscriptionModel.get('project');
        if(state.initialized) {
            deferred.resolve(angular.copy(state.data))
        } else {
          const onSuccess = function(data){
            deferred.resolve(data.projects);
          };
          const onFailure = function(data) {
            deferred.reject(data);
          }
          window.api.projectGetAllWithPages(onSuccess, onFailure);
        }
        return deferred.promise;
    }

    function _loadFlows(){
        var state = subscriptionModel.get('project');
        var deferred = $q.defer();
        if(state.initialized) {
            deferred.resolve(angular.copy(state.data))
        } else {
          const onSuccess = function(data){
            deferred.resolve(data.projects);
          };
          const onFailure = function(data) {
            deferred.reject(data);
          }
          window.api.projectGetAllWithPages(onSuccess, onFailure);
        }
        return deferred.promise;
    }

    function _isExistFlowWithoutEnclosureLimitZero(){
       return  _loadFlows().then(function(flows){
           return  flows.some(function(flow){
                return (flow.enclosure_limit>0);
            });
        });

    }
}]);
