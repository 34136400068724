ocCommonProviders.service("uiFilterService", function() {

  this.filterGroups = [];
  this.define = _define.bind(this);
  this.getCollection = _getCollection.bind(this);
  this.getFilterStatus = _getFilterStatus.bind(this);
  this.triggerFilterCallback = _triggerFilterCallback.bind(this);
  this.doFiltering = _doFiltering.bind(this);
  this.reloadCallbackDef=(callback)=>this.reloadCallback=callback;

  function _define(filterGroup,collection, ...defFilters) {
    var groupExists=this.filterGroups.some(fGroup => fGroup.name === filterGroup);
    if(!groupExists){
      this.filterGroups.push({ name: filterGroup,collection:collection, items: defFilters });
    }else{
      let groupIndex=this.filterGroups.indexOf(this.filterGroups.filter((fGroup)=>filterGroup===fGroup.name)[0]);
      this.filterGroups[groupIndex].items=defFilters;
      this.filterGroups[groupIndex].collection=collection;
    }

    if(this.reloadCallback){
      this.reloadCallback(this.filterGroups);
    }
    return this.filterGroups;
  }


  function _getCollection(filterGroups,filterGroup) {

   var result=filterGroups.filter((fGroup)=>fGroup.name===filterGroup)[0].collection;
   var deepCopy=JSON.parse(JSON.stringify(result));
   return deepCopy;
  }


  function _getFilterStatus(fGroupName, fName, fId) {
    const fGroup = this.filterGroups.filter(fGroup => fGroup.name === fGroupName)[0];
    switch (fName) {
      case "search":
        return fGroup.items.filter(item => item.id === fId)[0].query;
      case "dropDown":
        return fGroup.items.filter(item => item.id === fId)[0].listItems;
      default:
        throw new Error("uiFilterService::uiFilterService => Unknown filter Id");
    }
  }

  function _triggerFilterCallback(filtered,fGroupItems) {
    return fGroupItems[fGroupItems.length - 1](filtered);
  }

  function _doFiltering(target) {
    const fGroup = this.filterGroups.filter(fGroup => fGroup.name === target.group)[0];
    var result = _getCollection(this.filterGroups,target.group);

    fGroup.items.forEach((item, index) => {
      if ( fGroup.items.length - 1 != index) {
        if (item.name === target.from) {
          switch (item.name) {
            case "search":
              result = item.onChangeHandler(result, target.payload.query);
              item.query=target.payload.query;
              break;
            case "dropDown":
              result = item.onChangeHandler(result,target.payload.quantifier);
              break;
          }
          return;
        }
      }
    });

    this.triggerFilterCallback(result,fGroup.items);
  }
});
