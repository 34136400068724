ocCommonProviders.component("navigationContext", {
  templateUrl: "/static/partials/navigationContext.html",
  bindings: {
    name: "@",
    context: "<",
    targetUrl: "@",
    from: "@",
    fromAnchor: "<",
    className: "@",
    actionClick:"&",
    afterFallback:"&"
  },
  transclude: true,
  controller: ["navigationContextService","$rootScope",function(NavigationContext, $rootScope) {
      this.$onInit = function() {
        var listenerOff=$rootScope.$on("$routeChangeStart", function($event, next, current) { 
          if(angular.isDefined(next.params.from) && angular.isDefined(current.params.from)){
            NavigationContext.clearRouteParams();
            listenerOff();
          }
        });
      };

      this.goTo = (targetUrl, from) => {
        NavigationContext.goTo(targetUrl, from);
        this.actionClick();
      }
    }
  ]
});
