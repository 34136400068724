ocCommonProviders.directive("bannersListContainer",["attachmentTargetList",function (attachmentTargetList) {
    "use strict";
    return {
    restrict: "C",
    templateUrl:"/static/partials/bannersListContainer.html",
    link: function(scope, element, attrs){
      scope.attachmentSearchCallback=attachmentTargetList.resourceFilter;
    }
  };
}]);
