ocCommonProviders.service("resourceService", ["$q","uiFilterService","uiQuantifierService","login","db","uiDrawUtilsService",function($q,uiFilter,Quantifier,login,db,UIDrawUtils) {
    this.getResourceById=_getResourceById;
    this.getFiltersOfMaterialList = _getFiltersOfMaterialList.bind(this);
    this.getStatistics = _getStatistics;
    this.getCustomCategory = _getCustomCategory;
    this.getItemClass = _getItemClass;

    function _getResourceById(resId){
      const deffered = $q.defer();
      const customerId = window.api.customerid;
      const onSuccess = function(res) {
        deffered.resolve(res);
      }
      window.api.resourceGet(resId, onSuccess);
      return deffered.promise;
    }


    function _getDropDownFilterVM(materials){
    if(!materials) return [];
     var result=materials.reduce((acc, currValue) => {
        if (angular.isDefined(currValue.tag) && currValue.tag.length >= 1) {

          for (let i = 0; i < currValue.tag.length; i++) {
            let label = currValue.tag[i].value;
            let resId=currValue.tag[i].resource_id;
            let _isOnFilterList = acc.some(listItem => listItem.label === label);

            if (!_isOnFilterList) {
              acc.push({
                label:label,
                checked: true,
              });
            }
          }
        }

        return acc;
      }, []);

      if (result.length > 0 && materials.some(item => angular.isUndefined(item.tag) || (item.tag.length === 0))) {
        result.push({
          label: "others",
          checked: true,
        });
      }
      return result;
    }


    function _getFiltersOfMaterialList(materials, onFilterCallback) {
      let itemsList=_getDropDownFilterVM(materials);

      return uiFilter.define("materialsFilterGroup",materials,{
          id: "materialsFilterSearch",
          name: "search",
          className: `materials_filter__search ${itemsList.length==0?'materials_filter__search--fullWidth':''}`,
          onChangeHandler: function(materials,query){
            void 0
            var fromTitle = item =>item.title.toUpperCase().indexOf(query.toUpperCase()) != -1;
            var fromName = item => item.name.toUpperCase().indexOf(query.toUpperCase()) != -1;
            var result = materials.filter(item => fromTitle(item) || fromName(item));
            void 0
            return result;
          }
        },
        {
          id: "materialsFilterDropDown",
          name: "dropDown",
          className: "materials_filter__drop_down ",
          menuItems: ["All", "None"],
          quantifiers: ["AND", "OR"],
          listItems: itemsList,
          onChangeHandler:function(materials,quantifier){
            var idsForFiltering=[];
            if(quantifier==="OR"){
              return angular.copy(materials.map((item)=>{
                if(angular.isDefined(item.tag) && item.tag.length>0){
                  item.tag=item.tag.filter((itemTag)=>this.listItems.some((ctg)=>ctg.label===itemTag.value && ctg.checked));
                  if(item.tag.length===0){
                    idsForFiltering.push(item.id);
                  }
                }else{
                  this.listItems=this.listItems.map((ctg,index)=>{
                    let _snapshot=Quantifier.getSnapshot("OR")[index];
                    if(!_snapshot) return ctg;
                    if(ctg.label==="others" && angular.isDefined(ctg.className)){
                      ctg.checked= _snapshot.checked;
                      delete ctg["className"];
                      return ctg;
                    }
                    _snapshot.checked=ctg.checked;
                    return ctg;
                  });
                  if(this.listItems.some((ctg)=>ctg.label==="others" && !ctg.checked)){
                    idsForFiltering.push(item.id);
                  }
                }
                return item;
              })).filter((item)=>!idsForFiltering.some((id)=>item.id===id));
            }else if(quantifier==="AND"){
              this.listItems=this.listItems.map((ctg)=>{
                if(ctg.label==="others"){
                  ctg.checked=false;
                  return angular.extend({className:"disabled"},ctg);
                }
                return ctg;
              });
              let selectedTags=(item)=>item.tag.filter((itemTag)=>this.listItems.some((ctg)=>ctg.label===itemTag.value && ctg.checked));
              let checkedCntr=this.listItems.filter((ctg)=>ctg.checked).length;

              if(checkedCntr===0){
                return materials.filter(item =>item.tag.length===0);
              }
              return materials.filter(item =>{
                return (selectedTags(item).length===checkedCntr) && (selectedTags(item).length===item.tag.length);
              }).filter((item)=>!(item.tag.length===0 && this.listItems.some((ctg)=>ctg.label==="others" && !ctg.checked)));
            }
          }

        },
        onFilterCallback
      );
    }

    function _getStatistics(item) {
      if (angular.isUndefined(item.connection_running_count) || angular.isUndefined(item.connection_count))
        return;
      return `In use ${item.connection_running_count}/${item.connection_count}`;
    }

    function _getCustomCategory() {
      var filterItems = uiFilter.getFilterStatus("materialsFilterGroup","dropDown","materialsFilterDropDown");
      return { title: "others", isVisible: filterItems.length > 0 };
    }

    function _getItemClass(item,args) {
      var itemClass=item.status === "deleted" ? "item-deleted" :"";
      if(item.resource_type==="banner" && args){
        itemClass+=!UIDrawUtils.isImageCompatibleWithArea(item.settings,args.shape)?" item-lock":"";
      }
      return itemClass;
    }

  }
]);
