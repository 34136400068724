ocCommonProviders.service("ckeditorService",["fontService","db","$injector",function (fontService,db,$injector) {
    var _configs = [];
    this.getConfiguration = _getConfiguration;
    this.createInstance = _createInstance;
    this.toggleToolbarItem=_toggleToolbarItem;
    this.registerDefaultConfig=_registerDefaultConfig;
    this.changeBodyStyleCss=_changeBodyStyleCss;
    this.blockUsupportedFonts=_blockUsupportedFonts;

    function _createInstance(id,configName,extConfig) {
      var editor = CKEDITOR.instances[id];
      const config=_getConfiguration(configName,extConfig);

      if (editor) {editor.destroy(true);}
        editor=CKEDITOR.replace(id,config);

      if(angular.isDefined(config.globalEvents)){
        config.globalEvents.forEach((evt)=>CKEDITOR.on(evt.name,evt.handler));
      }

      if(angular.isDefined(config.instanceEvents)){
        config.instanceEvents.forEach((customEvt)=>{
          if(customEvt.require){
            editor.on(customEvt.name,function(evt){
              var handler=customEvt.handler.call(this,evt);
              handler.$inject=customEvt.require;
              $injector.invoke(handler);
            });
          }else{
            editor.on(customEvt.name,customEvt.handler);
          }
        });
      }

      editor.subscribe=function(evtName,evtHanlder,mode){
          var listener;
          if(mode==="editable"){
            let editable=editor.editable();
            listener=editable.attachListener(editable, evtName, function(evt) {
              evtHanlder(evt);
            });
          }
          listener=editor.on(evtName,evtHanlder);
          return {
            off:()=>{
              if(angular.isDefined(listener)){
                listener.removeListener
              }else{
                throw new Error("ckeditorService::editor:subscribe:off=> unsuported listener")
              }

            }
          }
        }

      return editor;
    }

    function _getConfiguration(configId, configExtensionObj, additionalProperties) {
      if (angular.isDefined(configExtensionObj)) {
        _configs[configId] = _addProperty(configId, configExtensionObj);
      }

      if (angular.isArray(additionalProperties) && additionalProperties.length > 0) {
        additionalProperties.forEach(function (ext) {
          _configs[configId][ext.key].push(ext.value);
        });
      }
      return _configs[configId];
    };

    function _registerDefaultConfig(id,defaultConfig){
      _configs[id]=defaultConfig;
    }

    function _addProperty(configId, objProp) {
      return angular.extend(_configs[configId], objProp);
    };

    function _changeBodyStyleCss(style){
      $('.cke_wysiwyg_frame').css(style.attr,style.value);
    }

    function _toggleToolbarItem(predicate,toolbarItemId){
      $(`#cke_editor${this.id}`).find(toolbarItemId).click((elem)=>{
        var content = $(`#${this.id}_contents`);
          setTimeout(function () {
            if (predicate) {
              this.setReadOnly(false);
              $(`#cke_editor${this.id}`).find(toolbarItemId).show();
            } else {
              this.setReadOnly(true);
              $(`#cke_editor${this.id}`).find(toolbarItemId).hide();
            }
          }, 100);
      });
    }

    function _blockUsupportedFonts(){
      const customerId = window.api.customerid;
      $("#cke_combo__font")

      return fontService.getCustomerFontsFontWithPublic(customerId)

    }

  }]);
