module.filter("ocSearch", function() {
  "use strict";
  return function(collection, searchPattern, searchCallback) {
    if (collection && searchPattern && searchPattern != "") {
      var regexp = new RegExp(searchPattern.toUpperCase());
     return collection.filter(function(item) {
        return searchCallback(item,searchPattern)
      });
    }
    return collection;
  };
});
