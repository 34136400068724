ocCommonProviders.directive("plainTextLimiter", function($window) {
  "use strict";
  return {
    restrict: "C",
    scope: {
      plainText: "<",
      pxLimit: "<"
    },
    templateUrl: "/static/partials/plainTextLimiter.html",
    link: function(scope, element, attr) {
      scope.limitValidation = function() {
        let result=_getPlainTextPxLengthById() >= scope.pxLimit;
        return result;
      };

      scope.getParsedText = function() {
        if (scope.limitValidation()) {
          return scope.plainText.substr(0,scope.plainText.length - 1);
        }
        return scope.plainText;
      };

      function _getPlainTextPxLengthById() {
        return element.find(".plainTextLimit_content").width();
      }
    }
  };
});
