ocCommonProviders.service("previewService", ["modal", "$q", function(modal, $q) {
    "use strict";

    this.downloadPreview = _downloadPreview;

    function getText(data) {
        if (data.textContent) {
            return data.textContent;
        }
        return data.innerText;
    }

    function _downloadPreview(url, filename, type) {
        var fname = filename || 'preview_' + getDateTime() + '.pdf';
        var ftype = type || 'application/pdf';
        var deferred = $q.defer();
        var t = new Date().getTime();
        const onSuccess = function() {
          deferred.resolve();
        }
        const onFailure = function(error) {
          var msg = "";
          try {
            var d = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.data)));
            msg = d.msg;
          } catch(e) {
            msg = "Uploading materials is not available at the moment. Please try at a later time. For more information please contact System Administrators."
          }
          modal.info({
              message: msg,
              ok: "Ok",
              cancel: ""
            },
            function() {},
            function() {});
          deferred.resolve();
        }
        window.api.download(url, fname, ftype, onSuccess, onFailure);
        return deferred.promise;
    }

    function getDateTime() {
        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1;
        var day = now.getDate();
        var hour = now.getHours();
        var minute = now.getMinutes();
        var second = now.getSeconds();
        if (month.toString().length == 1) {
            var month = '0' + month;
        }
        if (day.toString().length == 1) {
            var day = '0' + day;
        }
        if (hour.toString().length == 1) {
            var hour = '0' + hour;
        }
        if (minute.toString().length == 1) {
            var minute = '0' + minute;
        }
        if (second.toString().length == 1) {
            var second = '0' + second;
        }
        var dateTime = day + '_' + month + '_' + year + '_' + hour + '_' + minute + '_' + second;
        return dateTime;
    }

}]);
