module.filter("replaceEnter", ["$sce", function($sce) {
    "use strict";
    return function(text) {
        return text && $sce.trustAsHtml(text.replace(/(?:\r\n|\r|\n)/g, "<br />"));
    };
}]);


module.controller("historyController", ["$rootScope", "$scope", "login", "db", "layout", "modal", "api", "logsService", "userService", "$window", "$interval", "$q",
    function($rootScope, $scope, login, db, layout, modal, api, logsService, userService, $window, $interval, $q) {
        "use strict";
        $rootScope.$broadcast("CHANGE_TAB", "Log");
        $scope.customersHistory = null;
        $scope.canILoad = true;
        $scope.customersHistory = [];
        $scope.isDataEnd = false;
        $scope.customersProjects = [];
        $scope.filter = [];
        $scope.filterGeneral = {
            val: true
        };
        $scope.searchHits = 0;
        $scope.historyQuery = "";
        $scope.historyFlow = "ALL";
        $scope.isAdmin = userService.isAdmin();

        var lastSendData;
        var lastSearchData;

        $scope.loadCustomerLogs = function(id, dataToSend) {
            return logsService.loadCustomerLogs($scope, id, dataToSend);
        };

        $scope.loadCustomerLogsSearch = function(id, dataToSearch) {
            return logsService.loadCustomerLogsSearch($scope, id, dataToSearch);
        };

        function loadWithInterval() {
            $scope.interval = $interval(function() {
                if ($(document).height() > $(window).height() || $scope.isDataEnd) {
                    $interval.cancel($scope.interval);
                } else {
                    if ($scope.historyQuery.length === 0 && $scope.canILoad) {
                        loadLog();
                    }
                }
            }, 200);
        }

        $(window).off("scroll");
        $(window).on("scroll", function() {
            if (($(this).scrollTop() + $(this).height()) - $(document).outerHeight(true) >= 0 && $scope.canILoad && $scope.historyQuery.length === 0) {
                $scope.customerHistory = null;
                loadLog();
            }

        });

        $scope.$on("$destroy", function() {
            $(window).off("scroll");
            $interval.cancel($scope.interval);
        });

        $scope.refreshMasterLogs = function(value) {
            $scope.filterGeneral.val = value;
        };

        function compareDataToSend(obj1, obj2) {
            if (obj1.date_start === obj2.date_start && obj1.projects === obj2.projects)
                return true;

            return false;
        }


        function prepareDataToSend() {
            var obj = {};
            obj.master = $scope.filterGeneral.val;
            obj.projects = prepareFilterFlowArray();
            if ($scope.historyFromQuery) {
                obj.date_start = $scope.historyFromQuery;
            } else {
                obj.date_start = "NOW";
            }
            obj.lines_limit = 10;
            obj.lines_offset = $scope.customersHistory.length;
            return obj;
        }

        function loadLog() {
            var temp_dataToSend = prepareDataToSend();
            var deferred = $q.defer();

            if (lastSendData) {
                if (!compareDataToSend(temp_dataToSend, lastSendData)) {
                    $scope.customersHistory = [];
                }
            }

            if ((!lastSendData && temp_dataToSend.lines_offset === 0) || (lastSendData && temp_dataToSend.lines_offset >= 0)) {
                lastSendData = temp_dataToSend;
                return $scope.loadCustomerLogs(window.api.customerid, temp_dataToSend);
            } else {
                deferred.reject();
                return deferred.promise;
            }
        }

        function prepareDataToSearch() {
            var obj = {};
            obj.master = $scope.filterGeneral.val;
            obj.projects = prepareFilterFlowArray();
            if ($scope.historyFromQuery) {
                obj.date_start = $scope.historyFromQuery;
            } else {
                obj.date_start = "NOW";
            }
            obj.search = $scope.historyQuery;

            return obj;
        }

        function loadSearchLog() {
            $scope.customersHistory = [];
            $scope.loadedSearchLog = false;
            $scope.loadCustomerLogsSearch(window.api.customerid, prepareDataToSearch($scope.historyQuery)).then(function() {
                $scope.loadedSearchLog = true;
            });


        }


        $scope.loadedSearchLog = false;


        function chooseLogFilterTyp() {
            if ($scope.historyQuery.length > 0) {
                loadSearchLog();
            } else {
                $scope.customersHistory = [];
                loadWithInterval();
            }
        }


        $scope.historyDateChange = function() {
            $scope.isDataEnd = false;
            chooseLogFilterTyp();
        };

        $scope.historyFlowChange = function() {
            $scope.isDataEnd = false;
            chooseLogFilterTyp();
        };

        $scope.historyQueryChange = function() {
            chooseLogFilterTyp();
        };

        $scope.matchFlow = function(log) {
            var tmp_variable = "--";
            angular.forEach($scope.customersProjects, function(value, key) {
                if (value.id === log.project_id) {
                    tmp_variable = value.name;
                }
            });


            return tmp_variable;
        };

        $scope.setFiltering = function(param) {
            if (param === "all") {
                angular.forEach($scope.filter, function(value, key) {
                    $scope.filter[key] = true;
                });
                $scope.refreshMasterLogs(true);
            }
            if (param === "none") {
                angular.forEach($scope.filter, function(value, key) {
                    $scope.filter[key] = false;
                });
                $scope.refreshMasterLogs(false);
            }
            $scope.isDataEnd = false;
            chooseLogFilterTyp();
        };

        function prepareFilterFlowArray() {

            var temp_flowArray = "";
            var count = 0;
            angular.forEach($scope.filter, function(value, key) {
                if (value) {
                    temp_flowArray = temp_flowArray + key + ",";
                    count++;
                }
            });

            if (Object.keys($scope.filter).length === 0) return "ALL";
            if (count === 0) return "";
            if ($scope.customersProjects.length === count) return "ALL";

            return temp_flowArray.substring(0, temp_flowArray.length - 1);
        }
        $scope.$on("$routeChangeSuccess", function(scope, next, current) {
            if (typeof current != "undefined") {
                $rootScope.$emit("LOADON");
            }

            if ($scope.getAuthStatus()) {
                logsService.loadCustomerProjects($scope, window.api.customerid).then(function() {
                    loadLog().then(function() {
                        loadWithInterval();
                    });
                })
            } else {
                loadLog().then(function() {
                    loadWithInterval();
                });
            }

        });

        $scope.getAuthStatus = function() {
            return !$scope.config.isAdmin && db.get("login", "loggedInBoolean");
        };



    }
]);
