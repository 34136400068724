ocCommonProviders.service("itemPreviewService", ["uiDrawService","login","db","$window","tagsService","$filter",function(UiDraw,login,db,$window,tagsService,$filter) {
    this.drawPreview = _drawPreview;
    this.getDetailsBuilder=_getDetailsBuilder;
    this.calculatePosition=_calculatePosition;

    function _drawPreview(resource) {
      const context = UiDraw.context.init("itemPreviewCanvas");
      const settings=resource.settings;
      const horizontalAlign=resource.horizontal_align;
      const verticalAlign=resource.vertical_align;
      const scale=resource.scale;
      const dca=resource.dca;

      if (resource.type === "textbox") {
        const svg=context.getSvg(settings.text,dca.shape);
        const svgUrl=context.getObjectUrl(svg);
        context.addImgFromUrlWithDca(svgUrl,{shape:dca.shape,color:settings.background_color,scale:1});
      }else if(resource.type==="banner" || resource.type==="attachment"){
        const url = window.api.getResourceUrl(resource.id, 'thumbnail');
        if(resource.type==="banner" ){
          context.addImgFromUrlWithDca(url,{
            shape:dca.shape,
            settings:settings,
            horizontal_align:horizontalAlign,
            vertical_align:verticalAlign,
            scale:scale
          });
        }else{
          context.addImgFromUrlWithDca(url,  {
            scale:{x:1,y:1},
            shape:{x:0, y:0, width:210, height: 297},
            top:0,
            left:0,
            width:270,
            height:380,
          })
        }
      }else{
        context.addImgFromUrl(`${config.backendUrl}/static/img/Enclosure.svg`,
        {
          scale:{x:1,y:0.7},
          shape:{x:0, y:0, width:210, height: 297},
          top:50,
          left:0,
          width:270,
          height:350,
        })
      }
    }

    function _calculatePosition(event,elPreview){
      var top,left;
      if (event.pageX+$(elPreview).width() < $window.outerWidth){
          left = event.clientX + 40 + "px";
      }else {
          left = ($window.outerWidth - $(elPreview).width())+40 + "px";
      }

      if (event.pageY+$(elPreview).height() < $window.outerHeight) {
          top = event.clientY+40 + "px";
      } else {
          top = (event.clientY - $(elPreview).height() )+40 + "px";
      }

      return { top:top, left:left}
    }

    function _getDetailsBuilder(details){
      var detailsContent=[]
      void 0;
      return {
        withMaterialFilename:function(){
          const elName=angular.element(`<div><span><b>Name</b><br/><span class='capital word_break'>${details.name || '-'}</span></span></div><br/>`);
          detailsContent.push(elName);
          return this;
        },
        withMaterialTitle:function(){
          const elName=angular.element(`<div><span><b>Title</b><br/><span class='capital word_break'>${details.title || '-'}</span></span></div><br/>`);
          detailsContent.push(elName);
          return this;
        },
        withMaterialDescription:function(){
          const elName=angular.element(`<div><span><b>Description</b><br/><span class='capital word_break'>${details.description || '-'}</span></span></div><br/>`);
          detailsContent.push(elName);
          return this;
        },
        withMaterialFileSize:function(){
          const filesize=$filter("bytes")(details.filesize,1);
          const elName=angular.element(`<div><span><b>Filesize</b><br/><span class='capital word_break'>${filesize}</span></span></div><br/>`);
          detailsContent.push(elName);
          return this;
        },
        withMaterialSize:function(){
          const elName=angular.element(`<div><span>
                <b>width: </b><span class='capital word_break'>${details.settings.new_width || details.settings.original_width} mm</span>&nbsp;
                <b>height: </b><span class='capital word_break'>${details.settings.new_height || details.settings.original_height} mm</span>
          </span></div><br/><br/>`);
          detailsContent.push(elName);
          return this;
        },
        withConnectionName:function(){
          const elName=angular.element(`<div><span><span class='capital word_break'>${details.title}</span></span></div>`);
          detailsContent.push(elName);
          return this;
        },
        withElementDateFrom:function(){
          const elDateFrom = angular.element(`<span>${moment.utc(details.valid_from_utc).local().format("YYYY-MM-DD")} -  </span>`);
          detailsContent.push(elDateFrom);
          return this
        },
        withElementDateTo:function(){
          var elDateTo;

          if (details.valid_to_utc !== null) {
              elDateTo = moment.utc(details.valid_to_utc).local().format("YYYY-MM-DD");
          } else {
              elDateTo = '<span class="infin">&infin;</span>';
          }

          detailsContent.push(elDateTo);
          return this
        },
        withElementStatus:function(){
          const elStatus = angular.element(`<div>Status: ${details.status}</div><br/>`);
          detailsContent.push(elStatus);
          return this
        },
        withElementRuleName:function(){
          var ruleName;
          if (details.rule_name != null && details.rule_name.length > 25) {
            ruleName = details.rule_name.substr(0, 25) + "...";
          } else {
              if (details.rule_name === null) {
                ruleName = "-";
              } else {
                ruleName = details.rule_name;
              }
          }

          const elRuleName=angular.element(`<div>Rule: ${ruleName} </div>`)
          detailsContent.push(elRuleName);
          return this;
        },
        withElementSummary:function(rulesList){
          details.summary = " - ";
          for (var i = 0; i < rulesList.length; i++) {
              if (details.rule_id === rulesList[i].id) {
                details.summary = rulesList[i].html_value;
              }
          }

          const elSummary = angular.element(`<div>Rule Summary: ${details.summary} </div><br/>`);
          detailsContent.push(elSummary);
          return this
        },
        withElementMaterialName:function(){

          var materialName,elMaterialName;
          if (details.content_title && (details.content_title.length > 25)) {
              materialName = details.content_title.substr(0, 25) + "...";
          } else {
              materialName = details.content_title;
          }

          if (details.banner_area_id === null){
            elMaterialName = angular.element(`<div>Attachment: ${materialName} </div>`);
          }else{
            elMaterialName = angular.element(`<div>Banner: ${materialName}</div>`);
          }

          detailsContent.push(elMaterialName);
          return this;
        },
        withElementCreateBy:function(){
          const elCreateBy = angular.element(`<div>Created by: ${details.created_by} </div>`);
          detailsContent.push(elCreateBy);
          return this;
        },
        withElementCreateAt:function(){
          const elCreateAt = angular.element(`<div>Created at: ${moment.utc(details.created_at).local().format('YYYY-MM-DD')}</div><br/>`);
          detailsContent.push(elCreateAt);
          return this;
        },
        withElementSent:function(){
          const elSent = angular.element(`<div>Sent: ${details.sent_total} </div>`);
          detailsContent.push(elSent);
          return this;
        },
        withElementPriority:function(){
           const elPriority = angular.element(`<div>Priority: ${details.priority} </div>`);
           detailsContent.push(elPriority);
           return this;
        },
        withElementTagsInput:function(){
          var elTags=angular.element("<div class='bootstrap-tagsinput' style='padding:0px;'></div>");
          if(details.tag){
            for (var i = 0; i < details.tag.length; i++) {
                details.tag[i].text = tagsService.fixTextcontrast(details.tag[i].color);
                elTags.append(angular.element("<span class='tag label label-info' style=\"background:" + details.tag[i].color + ";color:" + details.tag[i].text + "\">" + details.tag[i].value + "</span>"));
            }
          }

          elTags.append(angular.element("<span class='verticalTagsMask'></span>"))
          detailsContent.push(elTags);
          return this;
        },
        build:function(element){
            if(detailsContent.length>0){
              detailsContent.forEach((itemDetails)=>{
                  element.append(itemDetails);
              });
            }

          return element;
        }
      }
    }
  }
]);
