const numberFormattersRegex = { tSeparator: /\B(?=(\d{3})+(?!\d))/g };
const separatosMap = {
  dot: ".",
  comma: ","
}

function setCharAt(str, index, chr) {
  if (index > (str.length - 1)) return str;
  return str.substr(0, index) + chr + str.substr(index + 1);
}

function _padToDecimalPlaces(target, dplaces, dseparator) {
  target=target.replace(".",dseparator).replace(",",dseparator);
  let dpartLength = target.split(dseparator)[1] ? target.split(dseparator)[1].length : 0;
  if (dpartLength < dplaces) {
    if (dpartLength===0) {
      target += dseparator;
    };
    for (let i = 0; i < dplaces - dpartLength; i++) {
      target += '0';
    }
  }
  return target;
}

const numberFormatters = [
  {
    name: "tseparator",
    type: "multi-require",
    require: ["dseparator"],
    actions: {
      comma: function (target, dseparator) {
        let targetParts = target.split(".");
        if (dseparator === "comma" && targetParts) return target;
        let result = targetParts[0].replace(numberFormattersRegex.tSeparator, ",");
        if (targetParts[1]) {
          result += "." + targetParts[1];
        }
        return result;
      },
      blankspace: function (target) {
        let targetParts = target.split(".");
        let result = targetParts[0].replace(numberFormattersRegex.tSeparator, " ");
        if (targetParts[1]) {
          result += "." + targetParts[1];
        }
        return result;
      },
      apostrophe: function (target) {
        let targetParts = target.split(".");
        let result = targetParts[0].replace(numberFormattersRegex.tSeparator, "'");
        if (targetParts[1]) {
          result += "." + targetParts[1];
        }
        return result;
      }
    }
  },
  {
    name: "dseparator",
    type: "multi-require",
    require: ["tseparator"],
    actions: {
      comma: function (target, tseparator) {
        if (tseparator != 'comma') {
          let lastIndexOf = target.lastIndexOf(".");
          if (lastIndexOf === -1) return target;
          return setCharAt(target, lastIndexOf, ",");
        }
        return target;
      },
      dot: function (target, tseparator) {
        if (tseparator != 'dot') {
          let lastIndexOf = target.lastIndexOf(".");
          if (lastIndexOf === -1) return target;
          return setCharAt(target, lastIndexOf, ".");
        }
        return target;
      }
    }
  },
  {
    name: "decimalplaces",
    type: "single-require",
    require: ["decimalplaces", "dseparator"],
    action: function (target, dplaces, dseparator) {
      return _padToDecimalPlaces(
        Number(Math.round(target + "e" + dplaces) + "e-" + dplaces).toString(),
        dplaces,
        separatosMap[dseparator]);
    }
  },

  {
    name: "showsign",
    type: "multi-require",
    require: ["signforpositives"],
    actions: {
      before: function (target, signForPositives) {
        if (parseFloat(target) > 0 && signForPositives) return "+" + target;
        if (parseFloat(target) < 0)
          return "-" + target.substr(1, target.length);
        return target;
      },
      after: function (target, signForPositives) {
        if (parseFloat(target) > 0 && signForPositives) return target + "+";
        if (parseFloat(target) < 0)
          return target.substr(1, target.length) + "-";
        return target;
      }
    }
  }
];

ocCommonProviders.constant("numberFormatters", numberFormatters);
