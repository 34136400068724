ocCommonProviders.directive("uploadDirective", ["db", "login", "uploadService","flowService",function(db, login, uploadService,flowService) {
    "use strict";
    return {
        restrict: "C",
        template: "<form class=\"fileUploadForm\" method=\"post\" enctype=\"multipart/form-data\">" +
            "<label for=\"attachmentUpload\" class=\"button textWhite bgSilver clickarea dlButton\">{{ buttonText }}</label>" +
            "<img ng-show=\"uiState.uploading\" src=\"/static/img/loader.gif\" alt=\"loading ...\"/>" +
            "<input  name=\"file\" id=\"attachmentUpload\" accept=\"{{ accepttype }}\" type=\"file\" ng-disabled=\"{{ false }}\" />" +
            "</form>",
        link: function(scope, element, attrs) {

            var formDom = element.find(".fileUploadForm"),
                labelDom = formDom.find("label"),
                inputDom = formDom.find("input");
            scope.accepttype = attrs.acceptfiletype + "";
            var isFont = false;
            if(attrs.isfont){
              isFont = true;
            }

            labelDom.text(attrs.buttontitle);
            labelDom.attr("for", null).on("click", function(event) {
                inputDom.replaceWith(inputDom = inputDom.clone(true)); 
                inputDom.click();
            });

            inputDom.on("change", function(evt) {
                if(angular.isDefined(evt.target.value) && evt.target.value.length>0){
                var customerId = window.api.customerid;
                var projectId = window.api.projectid;
                var method = "POST";
                var uploadUrl = config.backendUrl + "/customer/" + customerId + "/resource?Coyote-Authentication-Token=" + login.sessionToken + "&type=" + attrs.type;
                if (scope.isBackground) {
                    uploadUrl = config.backendUrl + "/customer/" + customerId + "/project/" + projectId + "/page/" + scope.selectedPage + "/background?Coyote-Authentication-Token=" + login.sessionToken + "&type=background";
                    method = "PUT";
                }
                if (attrs.id) {
                    uploadUrl = config.backendUrl + "/customer/" + customerId + "/resource/" + attrs.id + "/copy?Coyote-Authentication-Token=" + login.sessionToken + "&type=" + attrs.type;
                    method = "POST";
                }
                if (isFont) {
                    uploadUrl = config.backendUrl + "/customer/" + customerId + "/font?Coyote-Authentication-Token=" + login.sessionToken;
                    method = "POST";
                }
                formDom.off("submit");

                uploadService.createUpload(scope, attrs, uploadUrl, formDom, labelDom, inputDom, method).then(()=>{
                    inputDom.val('');
                });
                    formDom.submit();
                }
            });

        }

    };
}]);
