module.controller("dcaController", ["$rootScope", "$scope", "$q", "$timeout", "login", "db", "modal", "api", "flowService", "DCAService", "Connection", "$interval", "$window", "userService", "serverTime", "utilityService", "subscriptionModel","resourceService","uiTagsInputService","$uibModal",
    function ($rootScope, $scope, $q, $timeout, login, db, modal, api, flowService, DCAService, Connection, $interval, $window, userService, serverTime, utilityService, subscriptionModel,Resource,uiTagsInput,$modal) {
        "use strict";
        $rootScope.$broadcast("CHANGE_TAB", "DCA");
        $scope.activeProject = 0;
        $scope.projectsList = [];
        $scope.connectionLimit = 5;
        $scope.selectedTableElement = -1;
        $scope.dcaList = [];
        $scope.freeAreaList = [];
        $scope.freeAreaListPolygon = null;
        $scope.updateInterval = null;
        $scope.loadProjectList = _loadProjectList;
        $scope.selectProject = _selectProject;
        $scope.selectedInfoPredicate=Resource.getStatistics;
        $scope.loadDCAList = _loadDCAList;
        $scope.dcaFilter = _dcaFilter;
        $scope.selectTableElement = _selectTableElement;
        $scope.createNewDCA = _createNewDCA;
        $scope.fillWithData = _fillWithData;
        $scope.saveDCA = _saveDCA;
        $scope.deleteDCA = _deleteDCA;
        $scope.cancelDCA = _cancelDCA;
        $scope.drawPreview = _drawPreview;
        $scope.changePage = _changePage;
        $scope.updatePage = _updatePage;
        $scope.editDCA = _editDCA;
        $scope.increaseLimit = _increaseLimit;
        $scope.isDeleteLocked = _isDeleteLocked;
        $scope.blockingLayer = false;
        $scope.ual = null;
        $scope._dcamodel = subscriptionModel.get('banner_area');
        $scope.pageAddShow = !config.production && (userService.isAdmin() || userService.getUser().group.includes("approver"));

        $scope.addNewPage=function(flow){
            $modal.open({
              templateUrl: "/static/partials/addBlankPageModal.html",
              controller: "addBlankPageModalController",
              backdrop: true,
              windowClass:"blank-page-modal",
          });
        }

        var self = this;
        this.shapeConfigDefault = {
            "y": 20,
            "x": 20,
            "height": 20,
            "width": 20
        }

        var _subscribeDCA = function (data) {
            $scope._dcamodel = subscriptionModel.get('banner_area');
            $scope.dcaList = $scope._dcamodel.data;
            if (data.data && $scope.selectedTableElement == data.data.id) {
                $scope.selectedTableElement = null;
                $scope.blockingLayer = false;
                _loadDCAList().then(function () {
                    _selectTableElement($scope.dcaList[0]);
                });
            }
            $scope.$apply();
        }


        subscriptionModel.on('banner_area', _subscribeDCA);
        subscriptionModel.on('page', (change)=>{
           const projects=subscriptionModel.get("project").data;
           $scope.projectsList=projects;

        });


        $scope.$on('$destroy', function () {
            subscriptionModel.off('banner_area', _subscribeDCA);
        });

        $scope.getAuthStatus = function () {
            return !$scope.config.isAdmin && db.get("login", "loggedInBoolean");
        };

        if ($scope.getAuthStatus()) {
            $scope.loadProjectList();
            _clearCanvas();
        }

        $scope.init = function (value) {
            $scope.loading = [];
            $scope.hasAccessToCategories=userService.isAdmin() || userService.isGroupMember("power");
            void 0
            $scope.ual = userService.getUAL();
            if (typeof $scope.ual !== "undefined") {
                $scope.ual.isAdmin = userService.isAdmin();

            }
        };

        function _isDeleteLocked() {
            var locked = false;
            if ($scope.selectedElement != null && $scope.selectedElement.connections != null) {
                for (var i = 0; i < $scope.selectedElement.connections.length; i++) {
                    var conn = $scope.selectedElement.connections[i];
                    if (conn.status == "expired" || conn.status == "deleted" || conn.status == "stopped") {
                        continue
                    } else {
                        return true;
                    }
                }
            }
            return locked;
        }

                function _clearCanvas() {
            DCAService.clearCanvas();
        }

        function _loadProjectList() {
            var deferred = $q.defer();
            $scope.ual = userService.getUAL();
            flowService.getFlows().then(
                function (projects) {
                    $rootScope.$emit("LOADOFF");
                    $scope.projectsList = projects;

                    $scope.activeProject = $scope.setActiveProject();
                    if ($scope.ual.isAdmin) {
                        $scope.activeProject = 0;
                    }
                    if ($scope.activeProject == -1) {
                        modal.info({
                            message: "You do not have any \"active\" Flows. Please contact Administrator.",
                            ok: "OK",
                            cancel: ""
                        });
                    }
                    if ($scope.projectsList.length > 0 && $scope.activeProject != -1) {
                        $scope.selectProject($scope.activeProject, true);
                        deferred.resolve();
                    }
                },
                function (data) {
                    $rootScope.$emit("LOADOFF");
                    deferred.reject();
                }
            );
            return deferred.promise;
        }

        $scope.setActiveProject = function () {
            var index = -1;
            for (var i = 0; i < $scope.projectsList.length; i++) {
                if ($scope.projectsList[i].active == 1) {
                    index = i;
                    break;
                }
            }
            return index;
        };

        $scope.returnRealProjectIndex = function (proj) {
            var index = 0;
            for (var i = 0; i < $scope.projectsList.length; i++) {
                if ($scope.projectsList[i].id == proj.id) {
                    index = i;
                }
            }
            return index;
        };

        function _selectProject(index, init) {
            if (index === 0 && db.get("login", "activeProjectIndex") && init) {
                index = db.get("login", "activeProjectIndex");
            }
            db.set("login", "activeProjectIndex", index);
            window.api.setProject($scope.projectsList[index].id);
            $scope.activeProject = index;
            $scope.selectedTableElement = -1;
            $scope.selectedElement = null;
            if ($scope.projectsList[$scope.activeProject].pages.length === 0) _blankPageModal();
            $scope.loadDCAList(index).then(function (dcaList) {
                $scope.dcaListFilters=Resource.getFiltersOfMaterialList(dcaList,(filtered)=>$scope.dcaList=filtered);
                if (dcaList.length > 0)
                    _selectTableElement(dcaList[0]);
            },"dcaFilterGroup");
        }

        function _loadDCAList(id) {
            var deferred = $q.defer();
            DCAService.loadDCAList($scope.projectsList[$scope.activeProject].id).then(function (banner_areas) {
                for (var i = 0; i < banner_areas.length; i++) {
                    for (var j = 0; j < $scope.projectsList[$scope.activeProject].pages.length; j++) {
                        if (banner_areas[i].page_id === $scope.projectsList[$scope.activeProject].pages[j].id) {
                            banner_areas[i].page_name = $scope.projectsList[$scope.activeProject].pages[j].name;
                            banner_areas[i].page_background_id = $scope.projectsList[$scope.activeProject].pages[j].background_id;
                            break;
                        }
                    }

                    banner_areas[i].connections = [];
                }

                Connection.loadConnectionList($scope.projectsList[$scope.activeProject].id).then(function (connections) {
                    Connection.updateStatus(connections, {
                        "run": "running",
                        "edit": "editing",
                        "stop": "stopped",
                        "pending": "pending",
                        "expired": "expired",
                        "waiting": "waiting",
                        "toEdit": "edit",
                        "toRun": "run",
                        "toStop": "stop",
                        "inProduction": "production",
                        "deleted": "deleted"
                    });
                    for (var j = 0; j < banner_areas.length; j++) {
                        for (var i = 0; i < connections.length; i++) {
                            if (connections[i].banner_area_id === banner_areas[j].id)
                                banner_areas[j].connections.push(connections[i]);
                        }
                    }
                    $scope.dcaList = banner_areas;
                    deferred.resolve($scope.dcaList);
                });



            });

            return deferred.promise;
        }

        function _clearDCAList() {
            for (var j = 0; j < $scope.dcaList.length; j++) {
                if ($scope.dcaList[j].isNew) {
                    $scope.dcaList.splice(j, 1);
                }
                $scope.dcaList[j].isEdit = false;
            }
        }

        function _selectTableElement(dca) {
            $scope.connectionLimit = 5;
            if ((typeof $scope.dcaList !== "undefined" && $scope.dcaList !== null && $scope.dcaList.length > 0)) {
                _clearDCAList();
                $scope.selectedTableElement = dca.id;
                var pages = $scope.projectsList[$scope.activeProject].pages;
                for (var i = 0; i < pages.length; i++) {
                    if (pages[i].id == dca.page_id) {
                        $scope.freeAreaList = pages[i].free_areas;
                        $scope.freeAreaListPolygon = pages[i].free_areas_wkt;
                        break;
                    }
                }
                $scope.fillWithData(dca);
            } else {
                $scope.selectedElement = null;
            }


        }
        $scope.onTagChange=(change)=>{
            $scope.selectedElement.tag=uiTagsInput.tagChangeResolve($scope.selectedElement.tag,change);
        };

        function _createNewDCA() {
            if ($scope.projectsList.length === 0 || typeof $scope.projectsList[$scope.activeProject] == "undefined" || $scope.projectsList[$scope.activeProject].pages.length === 0) return false;

            for (var j = 0; j < $scope.dcaList.length; j++) {
                if ($scope.dcaList[j].isNew) {
                    $scope.dcaList.splice(j, 1);
                }
            }

            var data = {
                "horizontal_align": "left",
                "scale": 1,
                "name": "",
                "keep_aspect_ratio": 1,
                "vertical_align": "top",
                "shape": {
                    "y": 20,
                    "x": 20,
                    "height": 20,
                    "width": 20
                },
                "page_id": null,
                "id": -1,
                "page_name": "",
                "page_background_id": null,
                "isEdit": true,
                "isNew": true,
                "tag":[],
                "connections": []
            };

            $scope.dcaList.unshift(angular.copy(data));
            $scope.freeAreaList = [];
            $scope.freeAreaListPolygon = null;

            _fillWithData(data, true);
            $scope.selectedElement = data;
            $scope.selectedElement.page_name = $scope.projectsList[$scope.activeProject].pages[0].name;
            $scope.selectedElement.page_id = $scope.projectsList[$scope.activeProject].pages[0].id;
            $scope.selectedElementCopy = angular.copy($scope.selectedElement);

            _changePage();
            $scope.blockingLayer = true;

        }

        function _fillWithData(data, newOne) {
            if (newOne) {
                $scope.selectedTableElement = -1;
            }
            $scope.selectedElement = angular.copy($scope.dcaList.filter((item) => item.id === data.id)[0]);
            $scope.drawPreview(data);
        }

        function _updatePage(selectedElement) {
            $interval.cancel($scope.updateInterval);
            $scope.updateInterval = $interval(function () {
                _drawPreview(selectedElement);
            }, 700, 1);
        }

        function _changePage() {
            for (var j = 0; j < $scope.projectsList[$scope.activeProject].pages.length; j++) {
                if ($scope.projectsList[$scope.activeProject].pages[j].name === $scope.selectedElement.page_name) {
                    $scope.selectedElement.page_background_id = $scope.projectsList[$scope.activeProject].pages[j].background_id;
                    $scope.selectedElement.page_id = $scope.projectsList[$scope.activeProject].pages[j].id;
                    $scope.freeAreaList = $scope.projectsList[$scope.activeProject].pages[j].free_areas;
                    $scope.freeAreaListPolygon = $scope.projectsList[$scope.activeProject].pages[j].free_areas_wkt;
                    self.page = $scope.projectsList[$scope.activeProject].pages[j];
                    _fitDCASizetoFreeAreaSize($scope.freeAreaList, $scope.selectedElement, self.page);
                    _drawPreview($scope.selectedElement);
                    break;
                }
            }
            if ($scope.freeAreaList.length === 0) {
                modal.info({
                    message: "This page has no Free Area defined, unable to draw Banner Area. Please contact your administrator.",
                    ok: "Ok",
                    cancel: ""
                },
                    function () { },
                    function () { }
                );

            }
        }

        function _fitDCASizetoFreeAreaSize(freeAreaList, selectedElement, page) {
            if ((freeAreaList.length === 1) && (page.banner_areas_count === 0) || ((freeAreaList.length === 1) && !page.banner_areas_count)) {
                let freeArea = freeAreaList[0];
                selectedElement.shape.x = parseFloat(freeArea.shape.x).toFixed(2);
                selectedElement.shape.y = parseFloat(freeArea.shape.y).toFixed(2);
                selectedElement.shape.width = parseFloat(freeArea.shape.width).toFixed(2);
                selectedElement.shape.height = parseFloat(freeArea.shape.height).toFixed(2);
            } else {
                selectedElement.shape.x = parseFloat(self.shapeConfigDefault.x).toFixed(2);
                selectedElement.shape.y = parseFloat(self.shapeConfigDefault.y).toFixed(2);
                selectedElement.shape.width = parseFloat(self.shapeConfigDefault.width).toFixed(2);
                selectedElement.shape.height = parseFloat(self.shapeConfigDefault.height).toFixed(2);
            }
        }


        function _drawPreview(data) {
            DCAService.loadBackgroundImageURL(data.page_background_id, data).then(function (dataImage) {
                DCAService.loadImageToDom(dataImage).then(function (image) {
                    $scope.previewImageContentUrl = image.src;
                    DCAService.loadBackgroundImage(data).then(function (canvas) {
                        if (data.isEdit) {
                            var editable = data.connections.length <= 0;
                            DCAService.drawAllFreeAreas($scope.freeAreaList, canvas, $scope.freeAreaListPolygon).then(function () {
                                DCAService.drawAllDcas($scope.dcaList, canvas, $scope.selectedElement, true, $scope, data).then(function () { });
                            });
                        } else {
                            DCAService.drawAllDcas($scope.dcaList, canvas, $scope.selectedElement, false, $scope, data).then(function () { });
                        }
                    });
                });
            }, function (canvas) {
                if (data.isEdit) {
                    DCAService.drawAllFreeAreas($scope.freeAreaList, canvas, $scope.freeAreaListPolygon).then(function () {
                        DCAService.drawAllDcas($scope.dcaList, canvas, $scope.selectedElement, true, $scope, data).then(function () { });
                    });
                } else {
                    DCAService.drawAllDcas($scope.dcaList, canvas, $scope.selectedElement, false, $scope, data).then(function () { });
                }
            });
        }

        function compareSize() {
            var isSame = true;
            if ($scope.selectedElementCopy.shape.width !== $scope.selectedElement.shape.width ||
                $scope.selectedElementCopy.shape.height !== $scope.selectedElement.shape.height)
                isSame = false;

            return isSame;
        }

        function _saveDCA(element) {

            if (element.validated === false) return false;
            DCAService.saveDCA(element).then(function (data) {
                _bannerAreasCountIncrement(self.page);
                _loadDCAList().then(function (dcaList) {
                    $scope.dcaListFilters=Resource.getFiltersOfMaterialList(dcaList,(filtered)=>$scope.dcaList=filtered);
                    _selectTableElement(data);
                    $scope.blockingLayer = false;
                },"dcaFilterGroup");
            });
        }

        function _bannerAreasCountIncrement(page) {
            if (page) {
                if (!page.banner_areas_count) {
                    page.banner_areas_count = 0;
                }
                page.banner_areas_count += 1;
            }
        }

        function _editDCA() {
            $scope.blockingLayer = true;
            $scope.selectedElementCopy = angular.copy($scope.selectedElement);
            $scope.selectedElement.isEdit = true;
            _drawPreview($scope.selectedElement);
        }

        function _checkStopped() {
            var deferred = $q.defer();
            var stopped = []
            for (var i = 0; i < $scope.selectedElement.connections.length; i++) {
                var conn = $scope.selectedElement.connections[i];
                if (conn.status == "stopped") {
                    stopped.push("\"" + conn.title + "\"");
                }
            }
            if (stopped.length == 0) {
                deferred.resolve();
            } else {
                deferred.reject(stopped);
            }
            return deferred.promise;
        }

        function _deleteDCA(dca) {

            if (_isDeleteLocked()) return;
            _checkStopped().then(function () {
                _deleteDCAService(dca)
            },
                function (data) {
                    modal.confirm({
                        message: "Following connections are stopped and will be marked as deleted : " + data.join(",") + ", proceed ?",
                        ok: "Yes",
                        cancel: "No"
                    },
                        function () {
                            _deleteDCAService(dca);
                        },
                        function () { 
                        });
                });

        }


        function _deleteDCAService(dca) {
            DCAService.deleteDCA($scope, dca).then(function () {
                _bannerAreasCountDecrement(findPageWithDCA(dca));
                _cancelDCA(dca);
                _loadDCAList().then(function () {
                    if (typeof $scope.dcaList[0] !== "undefined")
                        _selectTableElement($scope.dcaList[0]);
                    else
                        $scope.selectedElement = false;

                });
                $scope.blockingLayer = false;
            },
                function () { });
        }

        function _bannerAreasCountDecrement(page) {
            if (page.banner_areas_count > 0) {
                page.banner_areas_count -= 1;
            }
        }

        function findPageWithDCA(DCA) {
            return $scope.projectsList[$scope.activeProject].pages.filter(function (page) {
                return DCA.page_id === page.id;
            })[0];
        }

        function _cancelDCA(data) {
            $scope.blockingLayer = false;
            if (!$scope.dcaList[0] || ($scope.dcaList.length===1 && $scope.dcaList[0].id===-1)) {
                $scope.selectedElement = null;
                return;
            }
            if ($scope.selectedElement && $scope.selectedElement.isEdit) $scope.selectedElement.isEdit = false;


            if ($scope.selectedElement && $scope.selectedElement.isNew) {
                if ($scope.dcaList.length > 0) {
                    _loadDCAList().then(function () {
                        _selectTableElement($scope.dcaList.filter((item) => item.id != -1)[0]);
                    });
                }
            } else {
                _loadDCAList().then(function () {
                    _selectTableElement(data);
                });
            }
        }

        function _increaseLimit() {
            $scope.connectionLimit = $scope.selectedElement.connections.length;
            $timeout(resize, 100);
        }

        function _dcaFilter(item) {
            if (item.isNew) return false;
            return DCAService.resourceFilter(item, $scope.query);
        }

        function _blankPageModal() {
            modal.info({
                message: "Your don't have any pages set. Please contact your administrator.",
                ok: "Ok",
                cancel: ""
            },
                function () { },
                function () { }
            );
        }


        var resize = function (second) {
            var maxWidth = utilityService.getDocWidth();
            var maxHeight = utilityService.getDocHeight();
            var bl = angular.element(".blockingLayer");
            bl.css({
                width: maxWidth + "px",
                height: maxHeight + "px"
            });
        };
        $scope.resize = resize;
        angular.element($window).bind("resize", function () {
            resize(true);
        });

        resize();

    }
]);
