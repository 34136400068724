module.controller("usersController", ["$scope", "$rootScope", "userManageService", "adminService", "userService","$uibModal","subscriptionModel","userManageUtilsService","inputSearchService",
    function($scope, $rootScope, userManageService, adminService, userService,$modal,subscriptionModel,userManageUtils,inputSearch) {
        "use strict";
        $rootScope.$broadcast("CHANGE_TAB", "Settings");

        $scope.isAddingUser = false;
        $scope.isAddingPermission = false;
        $scope.blankUser = {};
        $scope.blankGroup = {};
        $scope.blankCategories={};
        $scope.blankActivate = {};
        $scope.blankPermission = {};
        $scope.selectedUser = null;
        $scope.users = [];
        $scope.selectedCustomer = null;
        $scope.customers = [];
        $scope.groupDict = [];
        $scope.permissionDict = [];
        $scope.groupPermissions = [];
        $scope.blockingLayer=false;
        $scope.isAdminUser = userService.isAdmin();

        $scope.init = function() {
            if(window.api.customerid !== -1) {
                if($scope.isAdminUser) {
                    var placeholder = {login:{}, $apply:function() {}};
                    adminService.loadCustomers(placeholder).then(function() {
                        placeholder.customerList.unshift({name:'Blank', id:-1});
                        $scope.customers = placeholder.customerList;
                    });

                }
                userManageService.getUserGroupDict().then(function(data) {
                    $scope.groupDict = data.group;
                    loadUsers();
                });

                subscriptionModel.on("user",subscribeUser);
                $scope.$on("$destroy", function() {
                    subscriptionModel.off("user", subscribeUser);
                });
            }

            userManageUtils.initSearch();
            if(window.api.customerid !== -1) loadUsers();
        };

        $scope.addUserGroup = function() {
            userManageService.addUserGroup($scope.selectedUser.id, $scope.blankGroup.groupid)
        }

        $scope.loadPermissions = function() {
            if($scope.selectedCustomer) {
                loadPermissionDict($scope.selectedCustomer);
                loadGroupPermissions($scope.selectedCustomer);
            }
        }

        $scope.createPermission=function(){
            $scope.isAddingPermission=true;
            $scope.blockingLayer=true;
        }

        $scope.addPermission = function(customer, permission) {
            userManageService.putPermissionGroup(customer, permission).then(function(res) {
                $scope.blankPermission = {};
                $scope.upsertOff('isAddingPermission');
                loadGroupPermissions(customer);
            });
        }

        $scope.deletePermission = function(customer, permission) {
            userManageService.deletePermissionGroup(customer, permission).then(function(res) {
                loadGroupPermissions(customer);
            });
        }

        $scope.createUser=function(){
            $modal.open({
                templateUrl: "/static/partials/newUserModal.html",
                controller: "newUserModalController",
                backdrop: 'static',
                windowClass:"ui-modal",
                resolve:{header:()=>"New User",user:()=>new Object()}
            });
        }

        function subscribeUser(data){
            void 0;
            if(data.data) {
                loadUsers();
            }
        }

        function loadGroupPermissions(customer) {
            userManageService.getGroupPermission(customer).then((data) => {
               $scope.groupPermissions = data.permission
            });
        }

        function loadPermissionDict(customer) {
            userManageService.getPermissionDict(customer).then((data) => {
                $scope.permissionDict = data.permission_dict;
            });
        }

        function loadUsers() {
            $rootScope.$emit("LOADON");
            userManageService.getUsers().then(function(users) {
                $scope.users = users;
                inputSearch.updateCollectionState(users);
                $rootScope.$emit("LOADOFF");
            });
        }

        $scope.init();

    }
]);
