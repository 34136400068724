ocCommonProviders.service("subscriptionModel", [
  "multiUserService",
  function(multiUserService) {
    "use strict";

    const _callbacks = {};
    const trigger = (event, data) => {
      if(event in _callbacks) {
        _callbacks[event].forEach((callback) => {
          callback(data);
        });
      }
    }

    const _datamodel = {
      customer: {initialized: false, data:[]},
      umscustomer:{initialized: false, data:[]},
      project:{initialized: false, data:[]},
      font:{initialized: false, data:[]},
      attachment:{initialized: false, data:[]},
      banner:{initialized: false, data:[]},
      textbox:{initialized: false, data:[]},
      targetlist:{initialized: false, data:[]},
      enclosure:{initialized: false, data:[]},
      page:{initialized: false, data:[]},
      connection:{initialized: false, data:[]},
      banner_area:{initialized: false, data:[]},
      rule:{initialized: false, data:[]},
      variable:{initialized: false, data:[]},
      user:{initialized: false, data:[]},
      user_groupDict:{initialized: false, data:[]},
      permission:{initialized: false, data:[]},
      groupPermissionDict:{initialized: false, data:[]},
      user_group:{initialized: false, data:[]},
    }
    var _pushID = null;
    var _connected = false;
    window.ff = _datamodel;
    function getArrayId(id, list) {
      for (var i = 0; i < list.length; i++) {
        if (list[i].id == id) {
          return i;
        }
      }
      return -1;
    }
    window.model = {
      auth: null,
      update: function(msg) {
        var name = null;
        if (msg.type == "resource") {
          name = msg.data.resource_type;
        } else {
          name = msg.type;
        }

        trigger(`push.${name}`, msg);
        if (name == "page") {
          var p = _datamodel["project"];
          var page=_datamodel["page"];
          var id = getArrayId(msg.projectid, p.data);
          if (p.data[id].pages != null) {
            if (msg.operation == "post") {
              p.data[id].pages.push(msg.data);
            } else if (msg.operation == "put") {
              var id2 = getArrayId(msg.data.id, p.data[id].pages);
              p.data[id].pages[id2] = msg.data;

            } else if (msg.operation == "delete") {
              if(page.data.length>0){
                page.data = page.data.filter(page => page.id != msg.data.id);
              }
              p.data[id].pages = p.data[id].pages.filter(page => page.id != msg.data.id);
            } else if (msg.operation == "background_put" || msg.operation == "background_delete") {
              let pages = p.data[id].pages;
              let page = pages.filter(page => msg.data.id === page.id)[0];
              pages[pages.indexOf(page)] = msg.data;
            } else if (msg.operation == "priority") {
              var a1 = msg.data;
              for (var i = 0; i < a1.length; i++) {
                for (var j = 0; j < p.data[id].pages.length; j++) {
                  if (a1[i].id == p.data[id].pages[j].id) {
                    p.data[id].pages[j].page_order = a1[i].page_order;
                    break;
                  }
                }
              }
            }
            var data = { id: id, data: msg.data, operation: msg.operation };
            trigger("admin_page", data);
          } else {
            p.data[id].pages = [];
            p.data[id].pages.push(msg.data);
          }
        }

        if(name=="customer"){
          var state = _datamodel["customer"];
          var customer= state.data.filter(_customer => _customer.id === msg.data.id)[0];
          state.data[state.data.indexOf(customer)] = msg.data;
        }

        if(name==="variable"){
          var state = _datamodel["variable"];
          var v_id=msg.data.id
          var id=state.data.indexOf(state.data.filter((v)=>v_id==v.id)[0]);

          if (msg.operation == "delete") {
            state.data = state.data.filter(variable => variable.id != v_id);
          }
        }

        if(name=='rule'){
          var r = _datamodel["rule"];
          if (msg.operation == "delete") {
            r.data = r.data.filter(rule => rule.id != msg.data.id);
          }
        }


        if (name === "project") {
            var state = _datamodel["project"];
             if ((msg.operation == "put") || (msg.operation == "delete")){
              let project = state.data.filter(_project => _project.id === msg.data.id)[0];
              state.data[state.data.indexOf(project)] = msg.data;
             }

        }

        var state = _datamodel[name];
        if (state != null && state.initialized == true) {
          var data = { id: -1, data: msg.data, operation: msg.operation };
          if (msg.operation == "post") {
            if (msg.type == "connection") {
              state.data.push(msg.data);
            } else{
              state.data.push(msg.data);
              data.id = state.data.length - 1;
            }
          } else if (msg.operation == "put"&& state.data) {
            var id = getArrayId(msg.data.id, state.data);

            if (id == -1 && (msg.data.resource_type != null || name == "font")) {
              state.data.push(msg.data);
              data.id = state.length - 1;
            } else if(name!="permission") {
              state.data.splice(id, 1, msg.data);
              data.id = id;
            }else if(name==="permission"){
              state.data.push(msg.data);
              data.id = state.length - 1;
            }

          } else if (msg.operation == "delete") {
            var id = getArrayId(msg.data.id, state.data);
            if (msg.type == "connection" || msg.type == "font") {
              state.data[id].status = msg.data.status;
            } else if (msg.type == "resource") {
              state.data.splice(id, 1, msg.data);
            }else if(msg.type === "permission"){
              const predicate=(item)=>(item.project_id!=msg.data.project_id
                || item.permission!=msg.data.permission
                || item.group_value != msg.data.group_value)
              state.data=state.data.filter(predicate);
            }

          } else if (msg.operation == "remove") {
            if (msg.type == "connection") {
              state.data=state.data.filter((con)=>con.id!=msg.data.id);
            }
          } else if (msg.operation == "list_post") {
            var id = getArrayId(msg.data.id, state.data);
            if (!state.data[id].list_value) {
              state.data[id].list_value = [];
            }
            state.data[id].list_value.push(msg.data.value);
            data.id = id;
          } else if (msg.operation == "list_delete") {
            var id = getArrayId(msg.data.id, state.data);
            var listid = getArrayId(
              msg.data.value.id,
              state.data[id].list_value
            );
            state.data[id].list_value.splice(listid, 1);
            data.id = id;
          }else if (msg.operation === "status") {
            var id = getArrayId(msg.data.id, state.data);
            state.data[id].status = msg.data.status;
            state.data[id].editing_by = msg.data.editing_by;
            data.id = id;

          } else if (msg.operation == "test") {
            var id = getArrayId(msg.data.id, state.data);
            state.data[id].test = msg.data.test;
            data.id = id;
          } else if (msg.operation == "priority") {
            var a1 = msg.data;
            for (var i = 0; i < a1.length; i++) {
              for (var j = 0; j < state.data.length; j++) {
                if (a1[i].id == state.data[j].id) {
                  state.data[j].priority = a1[i].priority;
                  break;
                }
              }
            }
          } else if (msg.operation == "background_put") {
            var id = getArrayId(msg.data.id, state.data);
            state.data[id].background_id = msg.data.background_id;
          } else {
            void 0;
          }

          trigger(name, data);
        }else if(name==="page"){
          trigger(name, data);
        }else {
          void 0;
        }
        if (_pushID != msg.who.id) {
          multiUserService.onUserAction(data, msg);
        }
      },
      setConnected: function(value) {
        _connected = value;
      },

      getConnected: function() {
        return _connected;
      },
      get: function(type) {
        return angular.copy(_datamodel[type]);
      },
      set: function(type, obj, initialized) {
        let store = _datamodel[type];
        if(store) {
          store['data'] = angular.copy(obj);
          store['initialized'] = initialized;
          _datamodel[type] = store;
        } else {
          _datamodel[type] = {
            'data': angular.copy(obj),
            'initialized': initialized,
          }
          void 0
        }
        if(initialized) trigger(type, obj);
      },
      on: function(event, callback) {
        if(!(event in _callbacks)) {
          _callbacks[event] = []
        }
        _callbacks[event].push(callback)
        return true;
      },
      off: function(event, callback) {
        if(_callbacks[event]) {
          for (let i = 0;i<_callbacks[event].length;i++) {
            if(_callbacks[event][i] == callback) {
              _callbacks[event].splice(i, 1)
              return true
            }
          }
        }
        return false;
      },
      trigger: function(event, data) {
        return trigger(event, data);
      },
      disposeCustomer: function() {
        _datamodel['project'] = {initialized: false, data:[]};
        _datamodel['font'] = {initialized: false, data:[]};
        _datamodel['attachment'] = {initialized: false, data:[]};
        _datamodel['banner'] = {initialized: false, data:[]};
        _datamodel['textbox'] = {initialized: false, data:[]};
        _datamodel['targetlist'] = {initialized: false, data:[]};
        _datamodel['enclosure'] = {initialized: false, data:[]};
        _datamodel['page'] = {initialized: false, data:[]};
        _datamodel['connection'] = {initialized: false, data:[]};
        _datamodel['banner_area'] = {initialized: false, data:[]};
        _datamodel['rule'] = {initialized: false, data:[]};
        _datamodel['variable'] = {initialized: false, data:[]};
        _datamodel['user'] = {initialized: false, data:[]};
        _datamodel['user_groupDict'] = {initialized: false, data:[]};
        _datamodel['permission'] = {initialized: false, data:[]};
        _datamodel['groupPermissionDict'] = {initialized: false, data:[]};
        _datamodel['user_group'] = {initialized: false, data:[]};
      },
      disposeProject: function() {
        _datamodel['page'].initialized = false;
        _datamodel['connection'].initialized = false;
        _datamodel['banner_area'].initialized = false;
        _datamodel['rule'].initialized = false;
        _datamodel['variable'].initialized = false;
      },
      getByID: function(list, id) {
        var id = getArrayId(id, list);
        if (id >= 0) {
          return list[id];
        }
        return null;
      },
      setPushId: function(data) {
        _pushID = data.pushid;
      }
    };
    return window.model;
  }
]);
