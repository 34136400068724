ocCommonProviders.service("resourceAccessMangeService", ["$uibModal","utilityService",function($modal,utilityService) {
    const RESOURCE_ACCESS_MODAL_TEMPLATE="/static/partials/resourceAccessModal.html";
    const RESOURCE_ACCESS_MODAL_CTRL="resourceAccessModalController";
    const ADD_RESOURCE_ACCESS_MODAL_HEADER="Add category";
    const EDIT_RESOURCE_ACCESS_MODAL_HEADER="Edit category";

    this.showAddResourceAccessModal = _showAddResourceAccessModal;
    this.showEditResourceAccessModal = _showEditResourceAccessModal;

    function _showAddResourceAccessModal(resource) {
      return showModal(ADD_RESOURCE_ACCESS_MODAL_HEADER,null,resource);
    }

    function _showEditResourceAccessModal(access){
      return showModal(EDIT_RESOURCE_ACCESS_MODAL_HEADER,access);
    }

    function showModal(modalHeader,access,resource){
      var _payload={header:modalHeader};
      if(access){
        _payload=angular.extend(_payload,{dataAccess:access});
      }else{
        _payload=angular.extend(_payload,{resource_type:utilityService.getResourceTypeBySectionName(resource.name),banner_area_id:resource.id});
      }
        $modal.open({
            templateUrl: RESOURCE_ACCESS_MODAL_TEMPLATE,
            backdrop: true,
            controller: RESOURCE_ACCESS_MODAL_CTRL,
            windowClass: "ui-modal-category",
            resolve:{payload:()=>angular.copy(_payload)}
          });
    }

  }]);
