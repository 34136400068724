module.controller("conectionsControllerPremium", ["$scope", "$timeout", "$q", "login", "db", "modal", "api", "attachmentTargetList", "serverTime", "$anchorScroll", "$location", "errorHandlingService",
    "$window", "$document", "userService", "$rootScope", "pagesListService", "bannerList", "flowService", "rulesService", "Connection", "$route", "utilityService", "$filter", "variablesService", "DCAService","resourceAccessService",
    function($scope, $timeout, $q, login, db, modal, api, attachmentTargetList, serverTime, $anchorScroll, $location, errorHandling,
        $window, $document, userService, $rootScope, pagesListService, bannerList, flowService, rulesService, Connection, $route, utilityService, $filter, variablesService, DCAService,resourceAccess) {
        "use strict";
        Connection.initController($scope, "premium");
        $rootScope.$broadcast("CHANGE_TAB", "Connections");


        $scope.dcaBannersList = null;
        $scope.connectionPreview = "att";
        $scope.pagesListService = pagesListService;
        $scope.userService = userService;
        $scope.dcaViewMain = {name: "Attachments"};
        $scope.dcaView = null;
        $scope.dcaViewBackup = null;
        $scope.rulesDetailsList = [];
        $scope.setActiveProject = utilityService.setActiveProject;
        $scope.downloadFile = function(id) {
            if (id === 0)
                attachmentTargetList.downloadFile(scope.selectedAttachmentTableElement);
            else
                attachmentTargetList.downloadFile(scope.selectedAttachmentTableElement);
        };

        $scope.onChange=function(searchPattern){
            $scope.attachementSearch=searchPattern;
            $rootScope.$emit("connection_searchPattern",searchPattern);
        }

        var resize = function(second) {
            var maxWidth = utilityService.getDocWidth();
            var maxHeight = utilityService.getDocHeight();
            var bl = angular.element(".blockingLayer");
            bl.css({
                width: maxWidth + "px",
                height: maxHeight + "px"
            });
            if (second)
                $timeout(function() {
                    $scope.$apply();
                });
        };

        angular.element($window).bind("resize", function() {
            resize(true);
        });

        $scope.filterTestedConnections = function(value, index, filter, add) {
            return Connection.filterTestedConnections(value, index, filter);
        };

        $scope.filterStatuses = function(value, index, filter, add) {
            return Connection.filterStatuses($scope.loading[value.id], $scope.statusNames, value, $scope.filter);
        };

        $scope.setFiltering = function(setFilters) {
            Connection.setFiltering($scope.filter, setFilters);
        };

        $scope.getConnectionIndexById = function(id, connections) {
            return Connection.getConnectionIndexById(id, connections, $scope.connectionsList);
        };

        $scope.prepareConnectionsListOnLoad = function() {
            Connection.prepareConnectionsListOnLoad($scope.connectionsList, $scope.statusNames);
        };


        $scope.compareObjects = function(ob1, ob2, different) {
            return attachmentTargetList.compareObjects(ob1, ob2, different);
        };

        $scope.updateStatus = function() {
            Connection.updateStatus($scope.connectionsList, $scope.statusNames);

        };

        $scope.showPartial = function(partialId) {
            $rootScope.$emit("LOADON");
            $scope.logsListHide = true;
            $scope.activePartial = partialId;

            if ($scope.activePartial === 0) {
                $scope.loadProjectList();
            }

            if ($scope.activePartial == 3) {
                $scope.groups = [];
                $scope.loadBannerList().then(function() {
                    pagesListService.resetDcaList();
                    utilityService.loadPagesPreviews($scope);
                    $rootScope.$emit("LOADOFF");
                }, function() {
                    $rootScope.$emit("LOADOFF");
                });
            }


        };

        $scope.getActiveProjectId = function() {
            return window.api.projectid;
        };

        $scope.loadProjectList = function() {

            var promises = [];
            flowService.getFlows().then(
                    function(projects) {
                        var deferred = $q.defer();
                        $rootScope.$emit("LOADOFF");
                        $scope.projectsList = projects;
                        $scope.setActiveProject($scope)
                            .then(function(data) {
                                $scope.activeProject = data;
                            })
                            .then(function() {
                                if (userService.isAdmin() && $scope.activeProject === -1 && $scope.projectsList.length > 0) {
                                    $scope.activeProject = 0;
                                    deferred.resolve($scope.activeProject);
                                }

                                if (projects.length === 0) {
                                    deferred.reject();
                                    var msg = "Your don't have any flows set. ";
                                    if (!userService.isAdmin()) {
                                        msg += "Please contact your administrator.";
                                    }

                                    modal.info({
                                        message: msg,
                                        ok: "Ok",
                                        cancel: ""
                                    });
                                }
                                if ($scope.activeProject === -1 && !$scope.userService.isAdmin()) {
                                    deferred.reject();
                                    modal.info({
                                        message: "You do not have any \"active\" Flows. Please contact Administrator.",
                                        ok: "OK",
                                        cancel: ""
                                    });
                                }

                                deferred.resolve($scope.activeProject);
                            }); 

                        return deferred.promise;
                    },
                    function(data) {
                        $rootScope.$emit("LOADOFF");
                    }
                )
                .then(function() {
                    var prom = $q.defer();
                    if ($scope.projectsList.length > 0 && $scope.activeProject !== -1) {
                        $scope.selectProject($scope.activeProject, true).then(function() {
                            prom.resolve();
                        });
                    } else {
                        prom.resolve();
                    }
                    return prom.promise;
                })
                .then(function() {
                    if ($scope.activePartial == 3) {
                        $scope.loadBannerList()
                            .then(function() {
                                pagesListService.resetDcaList();
                                utilityService.loadPagesPreviews($scope);
                            });
                    }
                    if ($scope.activePartial == 4) {
                        $rootScope.$emit("PROJECTCHANGED");
                    }
                }, function() {});
        };

        $scope.returnRealProjectIndex = function(proj) {
            return utilityService.returnRealProjectIndex(proj, $scope);
        };

        $scope.init = function(value) {
            $scope.loading = [];
            $scope.ual = $scope.userService.getUAL();
            if (typeof $scope.ual !== "undefined") {
                $scope.ual.isAdmin = $scope.userService.isAdmin();
            }
        };

        $scope.loadBannerList = function() {
            var defer = $q.defer();
            if ($scope.activeProject > -1) {
                var id = $scope.getActiveProjectId();
                $scope.pages = pagesListService.getPagesList(id);
                $scope.loadBannerListByProject()
                    .then(function() {
                        $scope.loadConnectionList();
                    })
                    .then(function() {
                        defer.resolve();
                    });
            } else {
                defer.reject();
            }

            return defer.promise;
        };
        $scope.loadBannerListByProject = function() {
            var id = $scope.getActiveProjectId();
            var deferred = $q.defer();
            DCAService.loadDCAList(id).then(function(banner_areas) {
                $scope.groups2 = banner_areas;
                for (var i = 0; i < $scope.groups2.length; i++) {
                    for (var j = 0; j < $scope.pages.length; j++) {
                        if ($scope.pages[j].pid === $scope.groups2[i].page_id) {
                            $scope.groups2[i].page_name = $scope.pages[j].name;
                            $scope.groups2[i].background_id = $scope.pages[j].background_id;
                        }
                    }

                    $scope.groups2[i].info = {
                        "id": $scope.groups2[i].id,
                        "name": $scope.groups2[i].name
                    };

                    $scope.groups2[i].connections = [];
                }
                $scope.groups2 = $filter("orderBy")($scope.groups2, ["page_name", "name"]);
                $scope.groups2.push({
                    "name": "Attachments",
                    "info": {
                        "name": "Attachments",
                        "id": 0
                    },
                    "id": 0,
                    "connections": []
                });

                flowService.isExistFlowWithoutEnclosureLimitZero().then(function(exist){
                    if (exist) {
                    $scope.groups2.push({
                        "name": "Enclosures",
                        "info": {
                            "name": "Enclosures",
                            "id": -1
                        },
                        "id": -1,
                        "connections": []
                    });
                    }

            });



                deferred.resolve();
            }, function() {
                deferred.reject();
            });
            return deferred.promise;

        };

        $scope.loadConnectionList = function() {
            var id = $scope.getActiveProjectId();
            var deferred = $q.defer();

            Connection.loadConnectionList(id).then(function(connections) {
                if (Connection.resourceFallback) {
                  connections.push(Connection.resourceFallback);
                }
                $scope.connectionsList = connections;
                $scope.prepareConnectionsListOnLoad();
                $scope.updateStatus();
                _groupingOfConnections($scope);
                deferred.resolve();

            }, function() {
                deferred.reject();
            });

            return deferred.promise;
        };


        function _groupingOfConnections($scope){
            if($route.current.params.projectid && $route.current.params.connectionid){
                $scope.connectionsList=$scope.connectionsList.filter((connection)=>connection.id===parseInt($route.current.params.connectionid));
                if($scope.connectionsList[0]){
                    $scope.attachementSearch=$scope.connectionsList[0].title;
                }
                $timeout(function(){
                    $scope.expandAllGroup();
                },1000);
            }
            for (var i = 0; i < $scope.connectionsList.length; i++) {
                for (var j = 0; j < $scope.groups2.length; j++) {
                    if ($scope.groups2[j].name === "Attachments" && $scope.connectionsList[i].resource_type === "attachment")
                        $scope.groups2[j].connections.push($scope.connectionsList[i]);

                    if ($scope.groups2[j].name === "Enclosures" && $scope.connectionsList[i].resource_type === "enclosure")
                        $scope.groups2[j].connections.push($scope.connectionsList[i]);

                    if ( $scope.connectionsList [i].banner_area_id === $scope.groups2[j].id) {
                        $scope.groups2[j].connections.push($scope.connectionsList[i]);
                    }
                }
            }
            $scope.groups = $filter('ocPageGroupOrderFilter')($scope.groups2);

        }

        $scope.selectProject = function(index, init) {
            $rootScope.$emit("LOADON");
            $scope.ual = userService.getUAL();

            if($route.current.params.projectid && $route.current.params.connectionid){
                for(var i=0;i<$scope.projectsList.length;i++){
                    if($scope.projectsList[i].id===parseInt($route.current.params.projectid)){
                        index=i;
                        break;
                    }
                }
            }



            return Connection.selectProject($scope, index, init).then(function() {
                rulesService.getRules(true).then(function(data) {
                    $scope.rulesDetailsList = data;
                });

                variablesService.getVariables().then(function(data) {
                    $scope.variableList = data;
                });


                if ($scope.activePartial == 4)
                    $rootScope.$emit("PROJECTCHANGED");
                if ($scope.activePartial == 3) {
                    utilityService.loadPagesPreviews($scope);
                    $rootScope.$emit("LOADOFF");
                }
            }, function() {
                $rootScope.$emit("LOADOFF");
            });
        };

        $(".toggle").toggles({
            drag: false
        });

        $scope.$on("$routeChangeSuccess", function(scope, next, current) {
            if (typeof current != "undefined") {
                $rootScope.$emit("LOADON");
            }

        });

        $scope.loadProjectList();

        $scope.handleStatus = function(status, data) {
            errorHandling.handleResponse(status, data, $scope);
        };


        $scope.downloadStatistics = function() {
            utilityService.downloadStatistics($scope, api, login);
        };

        $scope.checkSize = function(data) {
            utilityService.checkSize(data, $scope);
        };

        $scope.checkIsSelectDCA = function() {
            utilityService.checkIsSelectDCA($scope);
        };

        $scope.connectionPreviewAction = function() {
            var id = $scope.getActiveProjectId();
            Connection.connectionPreview(id).then(function() {});
        };


        $scope.expandAllGroup = function() {
            $scope.$broadcast("expandAllGroup");
        };

        $scope.colapseAllGroup = function() {
            $scope.$broadcast("colapseAllGroup");
        };


        resize();
    }
]);
