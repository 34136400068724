ocCommonProviders.service("rulesService", ["db", "login", "errorHandlingService", "$timeout", "userService", "$q", "flowService", "api", "modal", "subscriptionModel","uiFilterService",
    function(db, login, errorHandling, $timeout, userService, $q, flowService, api, modal, subscriptionModel,uiFilter) {
        "use strict";

        this.getRules = _getRules;
        this.setRule = _setRule;
        this.removeRule = _removeRule;
        this.updateRule = _updateRule;
        this.spawnModal = _spawnModal;
        this.searchInRules = _searchInRules;
        this.getFilters = _getFilters;

        function _getRules(with_inactive) {
            var state = subscriptionModel.get('rule')
            var deferred = $q.defer();
            if(state.initialized) {
                var output = state.data;
                if(!with_inactive) {
                  output = output.filter(el => el.status == "active");
                }
                deferred.resolve(output);
            } else {
                const onSuccess = function(data) {
                  subscriptionModel.set('rule', data.rule, true);
                  var output = data.rule;
                  if(!with_inactive) {
                    output = output.filter(el => el.status == "active");
                  }
                  deferred.resolve(output);
                }
                window.api.ruleGetAll('with_inactive=1', onSuccess);
            }
            return deferred.promise;
        }

        function _setRule(name, rules) {
            var deferred = $q.defer();
            var dataToSend = {
                "name": name,
                value: rules
            };
            const onSuccess = function(data) {
              deferred.resolve(data)
            }
            const onFailure = function(data) {
              deferred.reject(data);
            }
            window.api.rulePost(dataToSend, onSuccess, onFailure);
            return deferred.promise;
        }

        function _updateRule(rule, rules, onSuccess) {
            var dataToSend = {
                "name": rule.name,
                value: rules
            };
            return window.api.rulePut(rule.id, dataToSend, onSuccess);
        }

        function _removeRule(rule,with_connection,success,failure) {
            return window.api.ruleDelete(rule.id, `with_connection=${with_connection}`,success,failure);
        }

        function _spawnModal(msg) {
            return modal.info({
                    message: msg,
                    ok: "Ok",
                },
                function() {},
                function() {}
            );

        }

        function _searchInRules(value, searchValue) {
            if (value.id < 0)
                return true;

            if (((new RegExp(buildSearchPattern(RegExp.escape(searchValue)), "ig")).test(value.name + " " + _concatVariables(value.value)))) {
                return true;
            }

            return false;

            function buildSearchPattern(inputSearchText) {
                var patternText = "";
                inputSearchText.split(/\s/g).forEach(
                    function preparePattern(value) {
                        patternText += "(?=.*" + value + ".*)";
                    }
                );
                patternText += ".+";
                return patternText;
            }
        }

        function _concatVariables(variable) {
            if (typeof variable == "undefined")
                return "";
            var str = "";

            for (var i = 0; i < variable.rules.length; i++) {
                str += " " + variable.rules[i].field;
            }

            return str;
        }

        function _getFilters(onFilterCallback,collection){
            return uiFilter.define("rulesFilterGroup",collection,{
                id: "rulesFilterSearch",
                name: "search",
                className: "variables_filter__search",
                query:"",
                onChangeHandler: function(rules,query){
                    var fromName = item =>item.name.toUpperCase().indexOf(query.toUpperCase()) != -1;
                    var result = rules.filter(item => fromName(item));
                    return result;
                }
          },onFilterCallback)
        }
    }
]);
