ocCommonProviders.directive("timelinePreviewDirective",[ function () {
    "use strict";
    return {
    restrict: "C",
    link: function(scope, element, attrs){
        var el = angular.element(element);
        var shape = null;
        var idi;
        var id;
        var regex = /[\w ]tl_([\d]{1,9})/;
        var regexAttachments = /[\w ]tl_(att)/;
        var canvas;
        var canvasPacker;
        if(el.hasClass("vis-label")){
            idi = el[0].className+"";
            if(idi.match(regex) !== null){
                id = idi.match(regex)[1];
                if(id !== null){
                    for( var i = 0; i < scope.info.length; i++){
                        if(scope.info[i].id == id){
                            shape = scope.info[i].shape;
                            break;
                        }
                    }
                    canvasPacker = angular.element("<div class=\"packer\" style=\"background:#CED6E5;border:1px solid #AAA; width:134px; height:190px;\"></div>");
                    canvas = angular.element("<canvas style=\"width:134px; height:190px;\"/>");
                    canvasPacker.append(canvas);
                    if(shape !== null ){
                        angular.element(element).find(".packer").remove();
                        angular.element(element).append(canvasPacker);
                        canvas = new fabric.StaticCanvas(canvas[0]);
                        canvas.setHeight(190);
                        canvas.setWidth(134);
                        canvas.add(_quickFabricRect(shape));
                    }
                }
            }else{
                if(idi.match(regexAttachments) !== null){
                    canvasPacker = angular.element("<div class=\"packer\" style=\"background:#CED6E5;border:1px solid #AAA; width:134px; height:190px;\"></div>");
                    canvas = angular.element("<canvas style=\"width:134px; height:190px;\"/>");
                    canvasPacker.append(canvas);

                    angular.element(element).find(".packer").remove();
                    angular.element(element).append(canvasPacker);
                    canvas = new fabric.StaticCanvas(canvas[0]);
                    canvas.setHeight(190);
                    canvas.setWidth(134);
                    canvas.add(_quickFabricRect({x:0,y:0,width:134*1.75,height:190*1.75}));
                }
            }

        }
            var descr = el.find(".vis-inner");
            if(typeof descr !=="undefined"){
                if( typeof descr.attr("data-title") != "undefined" ){
                    if("len",descr.attr("data-title").length > 25)
                        descr.text(descr.attr("data-title").substr(0,25)+"...");
                    else
                        descr.text(descr.attr("data-title").substr(0,25));

                }
                else{
                    descr.attr("data-title",descr.text());
                    descr.attr("title",descr.text());
                }
            }
    }
};
function _quickFabricRect(shape){
        var dca = new fabric.Rect({ left:shape.x*0.64,
                                    top: shape.y*0.64,
                                    width: shape.width,
                                    height: shape.height,
                                    opacity: 1.0,
                                    scaleX : 0.64,
                                    scaleY : 0.64,
                                    fill:""});

        dca.set({ strokeWidth: 1, 
                   stroke: "rgba(103,112,124,0.5)",
                   fill:"rgba(103,112,124,0.5)"
                });
        return dca;
}

}]);