ocCommonProviders.service("resourceAccessService", [
  "db",
  "login",
  "$q",
  "growlHandlingService",
  "flowService",
  "api",
  "modal",
  "utilityService",
  function(db, login, $q, growlHandlingService, flowService, api, modal,utilityService) {
    var self = this;
    this.updateAccess = _updateAccess;
    this.deleteAccess = _deleteAccess;
    this.loadAccessList = _loadAccessList;
    this.getAccess = _getAccess;

    function _loadAccessList(customerId) {
     return _getPermissionCategories(customerId).then(ctgs => {
        self.resourceCategories = ctgs;
      });
    }

    function _getAccess(resource) {
      if(!resource) return;
      var resType=utilityService.getResourceTypeBySectionName(resource.name);
      var activeFlowId = window.api.projectid;
      var flowAccess = self.resourceCategories.filter(flow => {
        return flow.id === activeFlowId;
      })[0];

      if(flowAccess){
          return flowAccess.category.filter((item)=>{
            if(!item.banner_area_id){
              return (item.resource_type===resType);
            }else{
              return ((item.resource_type===resType) && (item.banner_area_id===resource.id));
            }

          })[0];
      }
      return
    }


    function _getPermissionCategories(customerId) {
      return flowService.getCustomerFlows(customerId);
    }

    function _updateAccess(resourceType, tag) {
      var deferred = $q.defer();
      const onSuccess = function(data) {
        deferred.resolve(data);
      }
      const onFailure = function(error) {
        deferred.reject(error);
      }
      window.api.permissionPutResource(resourceType, tag, onSuccess, onFailure);
      return deferred.promise;
    }

    function _deleteAccess(resourceType,payload) {
      var deferred = $q.defer();
      const onSuccess = function(data) {
        deferred.resolve(data);
      }
      const onFailure = function(error) {
        deferred.reject(error);
      }
      window.api.permissionDeleteResource(resourceType, payload, onSuccess, onFailure);
      return deferred.promise;
    }

    function _displayError(error) {
      modal.info(
        { message: error.msg, ok: "Yes" },
        function() {},
        function() {}
      );
    }
  }
]);
