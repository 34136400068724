const ACTION_TYPES = {
  ACTION_ADD: "add",
  ACTION_UPDATE: "update",
  ACTION_DELETE: "delete"
};
module.controller("resourceAccessModalController", ["$scope", "$rootScope", "$uibModalInstance", "resourceAccessService", "payload", "$window",
  function ($scope, $rootScope, modalInstance, resourceAccess, payload, $window) {
    var _isEditMode;
    var _accessCopy;

    _init();
    function _init() {
      var _tagColorSnapshot="";
      $scope.header = payload.header;
      if (angular.isDefined(payload.dataAccess)) {
        $scope.access = { tag: [angular.copy(payload.dataAccess)] };
      } else {
        $scope.access = { tag: [] };
      }

      _accessCopy = angular.copy($scope.access);
      _isEditMode = (_accessCopy.tag.length > 0);
      $scope.isEditMode = _isEditMode;
      $scope.focusSave = false;
      $scope.closeDialog = () => modalInstance.close();

      $scope.isSaveBlocking = (access) => {


        if (_isEditMode) {
          if (angular.isDefined(access.tag_stringify) && (access.tag_stringify.length > 0) && (_accessCopy.tag[0].value != access.tag_stringify)) {
            return false;
          }

          if (((_accessCopy.tag[0].value.length > 0) && angular.isDefined(access.tag_stringify) && (access.tag_stringify.length === 0) && (angular.isDefined($(".tt-input").val()) && $(".tt-input").val().length === 0))) {
            return false;
          } 


          if($scope.colorHasBeenChanged){
            return false;
          }

                    return true;
        }
        return angular.isDefined(access.tag_stringify) && !(access.tag_stringify.length > 0);
      }

      $scope.saveChanges = access => {
        const actionType = _getActionType(access, payload);
        if (actionType === ACTION_TYPES.ACTION_DELETE) {
          _triggerActionDelete(payload);
        } else if (actionType === ACTION_TYPES.ACTION_UPDATE) {
          _triggerActionUpdate(access, payload);
        }
      };

      $scope.handleColorChange = color => {
        if(_accessCopy && _accessCopy.tag[0]){
          $scope.colorHasBeenChanged=(_accessCopy.tag[0].color!==color);
                $scope.$apply();
        }

            }
    }

    function _triggerActionUpdate(access, payload) {
      const resourceType = (payload.resource_type || payload.dataAccess.resource_type);
      var requestPayload = { tag: `${access.tag_stringify}:${access.tag[0].color}` };
      if (resourceType === "banner") {
        const bannerAreaId = payload.banner_area_id || payload.dataAccess.banner_area_id;
        requestPayload = angular.extend(requestPayload, { banner_area_id: bannerAreaId });
      }
      resourceAccess.updateAccess(resourceType, requestPayload).then(() => modalInstance.close());
    }

    function _triggerActionDelete(payload) {
      const resourceType = payload.dataAccess.resource_type;
      if (resourceType === "banner") {
        resourceAccess.deleteAccess(resourceType, { banner_area_id: payload.dataAccess.banner_area_id }).then(() => modalInstance.close());
      } else {
        resourceAccess.deleteAccess(resourceType).then(() => modalInstance.close());
      }
    }

    function _getActionType(access, payload) {
      if (angular.isDefined(payload.dataAccess) && (access.tag.length === 0)) {
        return ACTION_TYPES.ACTION_DELETE;
      } else if ((!angular.isDefined(payload.dataAccess) && (access.tag.length === 1)) || angular.isDefined(payload.dataAccess)) {
        return ACTION_TYPES.ACTION_UPDATE;
      }
    }
  }
]);
