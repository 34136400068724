ocCommonProviders.service("utilityService", ["$q", "$rootScope", "pagesListService", "$location", "$anchorScroll", "flowService", "attachmentTargetList", "db", "subscriptionModel",
    function ($q, $rootScope, pagesListService, $location, $anchorScroll, flowService, attachmentTargetList, db, subscriptionModel) {
        "use strict";
        this.getElementById = _getElementById;
        this.getElementByName = _getElementByName;
        this.getElement = _getElement;
        this.getDocHeight = _getDocHeight;
        this.getDocWidth = _getDocWidth;
        this.prepareDateFormatShort = _prepareDateFormatShort;
        this.attachmentLimit = _attachmentLimit;
        this.getDateIncremented = _getDateIncremented;
        this.getTimes = _getTimes;
        this.daysDiff = _daysDiff;
        this.loadPagesPreviews = _loadPagesPreviews;
        this.arrayFind = _arrayFind;
        this.returnRealProjectIndex = _returnRealProjectIndex;
        this.setActiveProject = _setActiveProject;
        this.scrollTo = _scrollTo;
        this.downloadStatistics = _downloadStatistics;
        this.isFilterDefault = _isFilterDefault;
        this.checkSize = _checkSize;
        this.checkIsSelectDCA = _checkIsSelectDCA;
        this.loadData = _loadData;
        this.checkIfChangable = _checkIfChangable;
        this.resetConnectionValues = _resetConnectionValues;
        this.resetConnectionValuesForRequests = _resetConnectionValuesForRequests;
        this.getHighestPriority = _getHighestPriority;
        this.getLowestPriority = _getLowestPriority;
        this.emptyConnectionInit = _emptyConnectionInit;
        this.showSaveButton = _showSaveButton;
        this.showDeleteButton = _showDeleteButton;
        this.checkBrowserIsIE = _checkBrowserIsIE;
        this.checkBrowserIsChrome = _checkBrowserIsChrome;
        this.disableSVG = _disableSVG;
        this.getDOMElementAttrs = _getDOMElementAttrs;
        this.getResourceTypeBySectionName=_getResourceTypeBySectionName;

        function _getElementById(list, value) {
            return _getElement(list, "id", value);
        }

        function _getElementByName(list, value) {
            return _getElement(list, "name", value);
        }

        function _getElement(list, param, value) {
            if (list.length === 0 || !angular.isArray(list))
                return null;
            for (var i = 0; i < list.length; i++) {
                if (list[i][param] == value)
                    return list[i];
            }
            return null;
        }
        function _getDocHeight() {
            return Math.max(
                document.body.scrollHeight, document.documentElement.scrollHeight,
                document.body.offsetHeight, document.documentElement.offsetHeight,
                document.body.clientHeight, document.documentElement.clientHeight
            );
        }

        function _getDocWidth() {
            return Math.max(
                document.body.scrollWidth, document.documentElement.scrollWidth,
                document.body.offsetWidth, document.documentElement.offsetWidth,
                document.body.clientWidth, document.documentElement.clientWidth
            );
        }

        function _prepareDateFormatShort(utcDate) {

            if (typeof utcDate === "undefined" || utcDate === null || utcDate === "")
                return null;
            var reg = utcDate.match(/([0-9]{4})-([0-9]{2})-([0-9]{2}) ([0-9]{2}):([0-9]{2}):([0-9]{2})/);
            return "" + reg[1] + "-" + (reg[2]) + "-" + reg[3];
        }

        function _attachmentLimit(proj, dir) {
            if (dir) {
                proj.attachment_limit--;
            } else {
                proj.attachment_limit++;
            }
            if (proj.attachment_limit < 0)
                proj.attachment_limit = 0;

        }

        function _getDateIncremented(dateValue, inc) {
            if (typeof dateValue === "undefined")
                return "";

            var monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            var ret = serverTime.getServerTime();

            ret.setYear(dateValue.getYear());
            ret.setMonth(dateValue.getMonth());
            ret.setDate(dateValue.getDate() + inc);

            var myString = monthNamesShort[ret.getMonth()] + " " + ret.getDate();

            return myString;
        }

        function _getTimes(n) {
            return new Array(n);
        }

        function _daysDiff(d1, d2) {
            return Math.round((d1 - d2) / (1000 * 60 * 60 * 24));
        }

        function _loadPagesPreviews($scope) {
            var deferred = $q.defer();
            pagesListService.loadPagesWithFreeAreas($scope.projectsList[$scope.activeProject].id).then(function (pages) {
                $scope.pagesPreviewList = pages;
                deferred.resolve();
            }, function (data) {
                deferred.reject(data);
            });

            return deferred.promise;
        }

        function _arrayFind(arr, elem) {
            for (var i = 0; i < arr.length; i++) {
                if (arr[i] === elem)
                    return true;
            }
            return false;
        }

        function _returnRealProjectIndex(proj, $scope) {
            var index = 0;
            for (var i = 0; i < $scope.projectsList.length; i++) {
                if ($scope.projectsList[i].id == proj.id) {
                    index = i;
                }
            }
            return index;
        }

        function _setActiveProject($scope) {
            var deferred = $q.defer();
            var index = -1;
            var activeId = window.api.projectid;
            if (activeId !== -1) {
                for (var i = 0; i < $scope.projectsList.length; i++) {
                    if ($scope.projectsList[i].id == activeId) {
                        index = i;
                        deferred.resolve(index);
                        window.api.setProject($scope.projectsList[index].id);
                        return deferred.promise;
                    }
                }
            }
            for (var j = 0; j < $scope.projectsList.length; j++) {
                if ($scope.projectsList[j].active == 1) {
                    index = j;
                    break;
                }
            }
            if (index > 0) {
                window.api.setProject($scope.projectsList[index].id);
            }
            deferred.resolve(index);
            return deferred.promise;
        }

        function _scrollTo(id, lazy) {
            if (lazy === false || typeof lazy === "undefined") {
                $location.hash(id);
                $anchorScroll(id);
            } else {
                var lazyScroll = setInterval(function () {

                    var old = $location.hash();
                    $location.hash(id);
                    $anchorScroll();
                    $location.hash(old);

                    clearInterval(lazyScroll);
                }, 40);

            }

        }

        function _downloadStatistics($scope, api, login) {
            if ($scope.projectsList.length === 0)
                return false;

            function whatDecimalSeparator() {
                var n = 1.1;
                n = n.toLocaleString().substring(1, 2);

                if (n === ",")
                    return ";";
                return ",";
            }
            const params = "separator=" + whatDecimalSeparator();
            const url = window.api.statisticsGetUrl(params);
            $window.location = url;
        }

        function _isFilterDefault() {
            if ($scope.filter.byRunning === true &&
                $scope.filter.byPending === true &&
                $scope.filter.byStopped === true &&
                $scope.filter.byEditing === true &&
                $scope.filter.byWaiting === true &&
                $scope.filter.byDeleted === false &&
                $scope.filter.byExpired === false) {
                return true;
            } else {
                return false;
            }
        }

        function _checkSize(data, $scope) {
            if (typeof $scope.dcaSwitchList !== "undefined" && typeof $scope.dcaView !== "undefined" && typeof $scope.info.shape !== "undefined") {
                if ($scope.dcaViewMain.name === "Textboxes") {
                    var element = data.settings.text;
                    var shape = $scope.info.shape;

                    var textHtml = '';
                    var eles = $(element).find('variable');
                    for (var i = 0; i < eles.length; i++) {
                        $(eles[i]).attr('contenteditable', true);
                    }
                    var filter = new CKEDITOR.htmlParser.filter({
                        elements: {
                            variable: function (el) {
                                if (el.attributes.test) {
                                    el.setHtml(el.getHtml().replace('{' + el.attributes.original + '}', el.attributes.test));
                                }
                            }
                        }
                    });

                    var fragment = CKEDITOR.htmlParser.fragment.fromHtml(element),
                        writer = new CKEDITOR.htmlParser.basicWriter();
                    filter.applyTo(fragment);
                    fragment.writeHtml(writer);
                    textHtml = writer.getHtml();
                    var parsedBody = $(document.createElement('body'));
                    var style = "line-height:normal;width:" + (shape.width || 210) * 3.8 + "px;"
                    parsedBody.attr("style", style);
                    parsedBody.append(textHtml);
                    parsedBody.appendTo(document.body);
                    var el = parsedBody[0];

                    var width = shape.width * 3.8;
                    var height = shape.height * 4.45;
                    var html = (new XMLSerializer).serializeToString(el);
                    var body = '<svg xmlns="http://www.w3.org/2000/svg" width="' + width + 'px" height="' + height + 'px">' +
                        '<foreignObject width="100%" height="100%">' +
                        '<body xmlns="http://www.w3.org/1999/xhtml">' +
                        html +
                        '</body>' +
                        '</foreignObject>' +
                        '</svg>';
                    var elemHeight = el.clientHeight;
                    parsedBody.remove();

                    if (elemHeight * 1.15 > height) {
                        return true;
                    }

                } else {
                    if (typeof data.settings !== "undefined" && data.settings !== null && typeof data.settings.new_width !== "undefined") {
                        if (data.settings !== null && (Math.round(data.settings.new_width) > $scope.info.shape.width ||
                            Math.round(data.settings.new_height) > $scope.info.shape.height)) {
                            return true;
                        }
                    } else {
                        if (data.settings !== null && (Math.round(data.settings.original_width) > $scope.info.shape.width ||
                            Math.round(data.settings.original_height) > $scope.info.shape.height)) {
                            return true;
                        }
                    }
                }

                return false;
            }
        }

        function _checkIsSelectDCA($scope) {

            if ($scope.info.id < 1)
                return false;
            return true;
        }

        function _loadData(id, con, $scope) {
            $scope.activeConnection = id;
            $scope.activeConnectionObj = con;


            $scope.changeConnectionState(id, con);

            if ($scope.isEdited(con)) {
                con.lock = true;
                $rootScope.$emit("LOADOFF");
            }
        }

        function _checkIfChangable(con, $scope) {
            if (($scope.loading[con.id] && con.status === $scope.statusNames.edit && con.editing_by === null) ||
                ($scope.loading[con.id] && con.status === $scope.statusNames.edit && con.editing_by === db.get("login", "usernameString")) ||
                ($scope.loading[con.id] && con.editing_by !== null && con.editing_by === db.get("login", "usernameString")) ||
                $scope.showdragdrop
            ) {
                return false;
            }
            return true;
        }

        function _resetConnectionValues($scope) {
            $scope.editEnclosure = false;
            $scope.editAttachments = false;
            $scope.editTargetList = false;
            $scope.editBanners = false;
            $scope.editTextBoxes = false;

        }

        function _resetConnectionValuesForRequests($scope) {
            $scope.attachmentList = [];
            $scope.dcaBannersList = [];
            $scope.dcaView = null;
            $scope.bannerList = [];
            $scope.pagesList = [];
            $scope.previewImageContent = {};
            pagesListService.selectPageElement(-1);
            $scope.targetFileData = {
                "id": "",
                "filename": "",
                "title": "",
                "description": "",
                "pages": "",
                "filesize": "",
                "editable": "",
                "created_by": "",
                "isNew": "",
                "inUse": "",
                "modified": "",
                "connections": "",
                "created_at": ""
            };
            $scope.fileData = {
                "id": "",
                "filename": "",
                "title": "",
                "description": "",
                "pages": "",
                "filesize": "",
                "editable": "",
                "created_by": "",
                "isNew": "",
                "inUse": "",
                "modified": "",
                "connections": "",
                "created_at": "",
            };
            $scope.activeConnection = null;
            $scope.activeConnectionObj = null;
        }

        function _getHighestPriority($scope) {
            if ($scope.connectionsList && $scope.connectionsList.length >= 1) {
                var highest = $scope.connectionsList[0].priority;
                for (var i = 0; i < $scope.connectionsList.length; i++) {
                    if ($scope.connectionsList[i].priority > highest)
                        highest = $scope.connectionsList[i].priority;
                }
                return highest + 1;
            }
            return 1;
        }

        function _getLowestPriority($scope) {
            if ($scope.connectionsList.length > 1) {
                var lowest = $scope.connectionsList[0].priority;
                for (var i = 0; i < $scope.connectionsList.length; i++) {
                    if ($scope.connectionsList[i].priority < lowest)
                        lowest = $scope.connectionsList[i].priority;
                }
                return lowest - 1;
            }
            return 0;
        }

        function _emptyConnectionInit($scope) {
            $scope.logonoffswitch = false;
            $scope.editAttachments = false;
            $scope.editEnclosure = false;
            $scope.editTargetList = false;
            $scope.editBanners = false;
            $scope.editTextBoxes = false;
            $scope.selectedAttachmentTableElement = null;
            $scope.selectedTextBoxTableElement = null;
            $scope.selectedRule = null;
            $scope.selectedRuleId = null;
            $scope.rules = [];
            $scope.targetFileData = null;
            $scope.fileData = null;
            $scope.dcaView = null;
            $scope.activeConnection = null;
            $scope.activeConnectionObj = null;
            $scope.previewImageContent = null;
            if ($scope.connectionsList === null) {
                $scope.connectionsList = [];
            }
        }

        function _checkBrowserIsIE() {
            var ua = navigator.userAgent;
            var temporary;
            var tem;
            var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if (/trident/i.test(M[1])) {
                temporary = /\brv[ :]+(\d+)/g.exec(ua) || [];
                return true;
            }
            return false;
        }

        function _checkBrowserIsChrome() {
            var ua = navigator.userAgent;
            var temporary;
            var tem;
            var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if (M[1] === "Chrome") {
                temporary = ua.match(/\bOPR\/(\d+)/);
                if (temporary === null) return true;
            }
            return false;
        }


        function _showSaveButton(con) {
            var dV = "0.4"; 
            var cV = "default"; 
            if (con.lock === false) {
                dV = "1";
                cV = "pointer";
            }
            return {
                opacity: dV,
                cursor: cV
            };
        }

        function _showDeleteButton(con) {
            if (typeof con.isnew !== "undefined" && con.isnew)
                return {
                    opacity: 0.4,
                    cursor: "default"
                };
            var dV = "0.4"; 
            var cV = "default"; 
            if (con.lock === false) {
                dV = "1";
                cV = "pointer";
            }
            return {
                opacity: dV,
                cursor: cV
            };
        }

        function _disableSVG(canvas, scale) {
            canvas.dispose();
            var dca = new fabric.Rect({
                width: 268,
                height: 379,
                opacity: 1.0,
                scaleX: scale,
                scaleY: scale,
                strokeWidth: 1.5,
                stroke: "rgba(170,170,170,1.0)",
                fill: "rgba(170,170,170,0.2)"
            });


            var text = "Your browser doesn't support";
            var textError = new fabric.Text(text, {
                left: 50,
                top: 160,
                fontSize: 12,
                fontFamily: "Noto Sans"
            });
            text = "Text Banner Preview";
            var textError2 = new fabric.Text(text, {
                left: 80,
                top: 175,
                fontSize: 12,
                fontFamily: "Noto Sans"
            });
            canvas.add(dca);
            canvas.add(textError);
            canvas.add(textError2);
        }


        function _getDOMElementAttrs(JQElement, restricts) {
            let attrsDict = [];
            $(JQElement).each(function () {
                $.each(this.attributes, function () {
                    if (this.specified) {
                        attrsDict.push({ key: this.name, value: this.value });
                    }
                });
            });

            if (restricts) {
                let filtered = [];
                attrsDict.forEach((item) => {
                    if (restricts.indexOf(item.key)==-1) {
                        filtered.push(item);
                    }
                });
                return filtered;
            }

            return attrsDict;
        }

        function _getResourceTypeBySectionName(sectionName){
            if(sectionName==="Attachments"){
                return "attachment"
             }else if(sectionName==="Enclosures"){
                 return "enclosure"
             }else{
                 return "banner"
             }
        }
    }
]);
