ocCommonProviders.service("pagesListService", ["$rootScope", "login", "errorHandlingService", "api", "db", "modal", "$q", "$timeout", "subscriptionModel",
    function ($rootScope, login, errorHandlingService, api, db, modal, $q, $timeout, subscriptionModel) {
        "use strict";
        var pagesList = [];
        var selected = 0;
        var selectedDCA = []; 
        var self = this;

        var dcaList = [];
        this.attachmentList = [];
        this.selectedAttachmentId = 0;
        this.selectedAttachmentConnectionId = 0;
        this.resetDcaList = _resetDcaList;
        this.selectPageElement = _selectPageElement;
        this.getPageElement = _getPageElement;
        this.loadImage = _loadImage;
        this.initDrawings = _initDrawings;
        this.drawCanvas = _drawCanvas;
        this.loadImageToDom = _loadImageToDom;
        this.getPagesList = _getPagesList;
        this.selectElement = _selectElement;
        this.setPagesList = _setPagesList;
        this.loadPagesWithFreeAreas = _loadPagesWithFreeAreas;
        this.loadPages = _loadPages;
        this.renderDca = _renderDca;
        this.updateShownDCAs = _updateShownDCAs;

        function _selectPageElement(elem, dca) {
            selected = elem;
        }

        function _resetDcaList() {
            var deferred = $q.defer();
            for (var i = 0; i < dcaList.length; i++) {
                dcaList[i].active = false;
                dcaList[i].visible = true;
            }
            deferred.resolve();
            return deferred.promise;
        }

        function _updateShownDCAs(dca, filteredList, dca_id, partialId) {
            function renderIt() {
                _renderDca();
            }
            for (var i = 0; i < dcaList.length; i++) {
                if (filteredList.length === 0) {
                    if (dcaList[i].dca.id == dca_id) {
                        dcaList[i].visible = false;
                    }
                } else
                    for (var j = 0; j < filteredList.length; j++) {
                        if (dcaList[i].connection_id == filteredList[j].id && dcaList[i].dca.id == filteredList[j].banner_area_id) { 
                            dcaList[i].visible = true;
                            break;
                        } else {
                            if (dcaList[i].dca.id == filteredList[j].banner_area_id) {
                                dcaList[i].visible = false;
                            }
                        }
                    }
                if (partialId === 3)
                    $timeout(renderIt, 10, 1);
            } 
        }

        function _getPageElement(dca) {
            if (typeof dca != "undefined") {
                return pagesList[selected];
            } else {
                return selectedDCA[dca].id;
            }
        }

        function _getPagesList() {
            return pagesList;
        }

        function _selectElement(id, dca) {
            if (typeof dca != "undefined") {
                selected = id;
                return pagesList[id];
            } else {
                selectedDCA[dca].id = id;
                return pagesList[id];
            }
        }

        function _loadImage(scope, resourceId, quality) {
            var _conversion = quality || 'background'
            var deffered = $q.defer();
            var url = null;
            var customerId = window.api.customerid;
            if (resourceId !== null) {
                url = window.api.getResourceUrl(resourceId, _conversion);
            } else {
                deffered.reject();
                self.initDrawings();
                return deffered.promise;
            }

            self.loadImageToDom(url).then(function (data) {
                scope.backgroundPreviewUrl = data.src;
                self.initDrawings();
                deffered.resolve(data.src);
            });
            return deffered.promise;
        }

        function _initDrawings(scope, dca) {
            var deferred = $q.defer();
            $timeout(function () {
                self.drawCanvas(scope, dca).then(function () {
                    deferred.resolve();
                }, function () {
                    deferred.reject();
                });
            }, 1000);
            return deferred.promise;
        }

        function _drawCanvas(scope, dca) {
            var deferred = $q.defer();

            if (angular.isUndefined(dca)) {
                deferred.resolve();
                return deferred.promise;
            }



            var canvas = new fabric.StaticCanvas(`resourceCanvasPreview`);

            $rootScope.canvas = canvas;
            var imgElement = document.getElementById("previewBanner");
            var imageFrame = document.getElementById("targetPreviewCanvas");
            if (imageFrame === null) {
                deferred.reject();
                return deferred.promise;
            }

            canvas.setHeight(379);
            canvas.setWidth(270);
            canvas.renderAll();
            if (typeof pagesList[selected] == "undefined" && selected === -1) {
                deferred.resolve();
                return deferred.promise;
            }

            var dca1 = new fabric.Rect({
                left: dca.shape.x * 1.275,
                top: dca.shape.y * 1.275,
                height: dca.shape.height,
                width: dca.shape.width,
                opacity: 1.0,
                scaleX: 1.275,
                scaleY: 1.275,
                fill: ""
            });

            dca1.set({
                strokeWidth: 1,
                stroke: "rgba(236,102,8,1.0)"
            });

            if (pagesList[selected].bgid !== null) {
                var imgInstance = new fabric.Image(imgElement, {
                    left: 0,
                    top: 0,
                    angle: 0,
                    opacity: 1.0
                });

                canvas.add(imgInstance);
            }
            canvas.add(dca1);

            canvas.on("object:added", function (elem) {
                deferred.resolve();
            });

            deferred.resolve();
            return deferred.promise;
        }

        function _loadImageToDom(url) {
            var deffered = $q.defer(),
                image = new Image();

            image.addEventListener("load", function () {
                deffered.resolve(image);
            });

            image.addEventListener("error", function () {
                deffered.reject(image);
            });

            image.src = url;

            return deffered.promise;
        }

        function _setPagesList(list) {
            pagesList = [];
            for (var i = 0; i < list.length; i++) {
                if (list[i].banner_areas.length > 0) {
                    for (var j = 0; j < list[i].banner_areas.length; j++) {
                        pagesList.push({
                            name: list[i].name,
                            view: "ban",
                            pid: list[i].id,
                            bid: list[i].banner_areas[j].id,
                            bgid: list[i].background_id,
                            draw_area: list[i].banner_areas[j],
                            page_order: list[i].page_order

                        });
                    }
                } else {
                }

            }
        }

        function _loadPages(id) {
            var deferred = $q.defer();
            var customerId = window.api.customerid;
            const onSuccess = function (data) {
                subscriptionModel.set('page', data.pages, true);
                deferred.resolve(data.pages);
            };
            const onFailure = function (data) {
                deferred.reject(data);
            }
            window.api.pageGetAllWithFreeArea(onSuccess, onFailure);
            return deferred.promise;
        }

        function _loadPagesWithFreeAreas(id) {
            var deferred = $q.defer();
            var customerId = window.api.customerid;
            var state = subscriptionModel.get('page');

            if (state.initialized) {
                deferred.resolve(state.data)
            } else {
                const onSuccess = function (data) {
                    subscriptionModel.set('page', data.pages, true);
                    deferred.resolve(data.pages);
                }
                const onFailure = function (data) {
                    subscriptionModel.set('page', data.pages, true);
                    deferred.reject(data);
                }
                window.api.pageGetAllWithBanner(onSuccess, onFailure);
            }

            return deferred.promise;

        }

        function _renderDca(group, banner_id, content_id, connection_id, connection, is_connection_open) {
            void 0
            $rootScope.$broadcast("DRAWPREVIEW", {
                banner_id: banner_id,
                attachment_id: content_id,
                group: group,
                connection_id: connection_id,
                connection: connection,
                is_connection_open: is_connection_open
            });
        }

    }
]);
