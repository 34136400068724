module.controller("previewUserModalController", [
    "$uibModalInstance",
    "$scope",
    "userManageService",
    "user",
    "header",
    function(
      modalInstance,
      $scope,
      userManageService,
      _user,
      header
    ) {
    _init();
      function _init() {
          $scope.user=_user;
      }

      $scope.closeDialog = function() {
        modalInstance.close();
      };

         }
  ]);
