module.controller("addBlankPageModalController",["$scope","$uibModalInstance","growlHandlingService","modal","DCAService",
    function($scope,modalInstance,growlHandlingService,modal,DCAService) {
    $scope.closeDialog = function() {
        modalInstance.close();
      };

      $scope.createBlankPage = function(nameForBlankPage) {
        if (!$scope.blankPageForm.$invalid) {
            DCAService.addNewPage(nameForBlankPage).then((response)=>{
              modal.info(
                {
                  message: "Your Empty Page has been successfully created.",
                  ok: "Ok",
                  cancel: ""
                },
                function() {
                },
                function() {}
              );
              $scope.closeDialog();
            }).catch((error)=>{

                           modal.info(
                {
                  message: error.msg,
                  ok: "Ok",
                  cancel: ""
                },
                function() {
                },
                function() {}
              );
            });
        }
      };
}]);
