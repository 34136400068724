ocCommonProviders.service("uiDrawUtilsService", function() {
  this.calculateImageSize = _calculateImageSize;
  this.calculateImagePosition=_calculateImagePosition
  this.getOffsetLeft=_getOffsetLeft;
  this.getOffsetTop=_getOffsetTop;
  this.isImageCompatibleWithArea=_isImageCompatibleWithArea;


  function _calculateImageSize(dca, imgElement, scale, image) {
    var width, height, scaleX, scaleY;
    scaleX = imgElement.width / dca.width;
    scaleY = imgElement.height / dca.height;
    if (scale) {
      if (scaleX > scaleY) {
        if (scaleX > 1) {
          width = imgElement.width/scaleX;
          height = imgElement.height/scaleX;
        } else {
          width = dca.shape.width;
          height = dca.shape.height;
        }
      } else {
        if (scaleY > 1) {
          width = imgElement.width / scaleY;
          height = imgElement.height / scaleY;
        } else {
          width = dca.shape.width;
          height = dca.shape.height;
        }
      }
    } else {
      if (image && image["dpi_set"]) {
        width = image.new_width;
        height = image.new_height;
      } else {
        width = image.original_width;
        height = image.original_height;
      }
    }

    return {
      width: width,
      height: height,
      scaleX:scaleX,
      scaleY:scaleY
    };
  }

  function _isImageCompatibleWithArea(settings,dca){
    const _compatibleWithOrginalSize=(settings && !settings.new_width && (settings.original_width < dca.width+1) && (settings.original_height < dca.height+1));
    const _compatibleWithNewSize=settings && settings.new_width && ((settings.new_width < dca.width+1) && (settings.new_height < dca.height+1));
    return _compatibleWithOrginalSize || _compatibleWithNewSize;
  }

    function _calculateImagePosition(horizontalAlign,verticalAlign,imgSize,shape,scale){
      return {
        left:_getOffsetLeft(horizontalAlign,imgSize,shape,scale),
        top:_getOffsetTop(verticalAlign,imgSize,shape,scale)
      }
  }


  function _getOffsetLeft(alignment,imgSize,shape,scale){
    const strategies={
      left:function(){
        return shape.x;
      },
      right:function(){
        return shape.x + ((shape.width - imgSize.width)*scale);
      },
      center:function(){
        return (shape.x + ((shape.width - imgSize.width)*scale) / 2);
      }
    }

    const Transformation=function(alignment){
      var _transform=function(){
        return strategies[alignment]();
      }
      return {transform:_transform}
    }


    return new Transformation(alignment).transform();
  }


    function _getOffsetTop(alignment,imgSize,shape,scale){
    const strategies={
      top:function(){
        return shape.y;
      },
      bottom:function(){
        return (shape.y + ((shape.height - imgSize.height) * scale));
      },
      center:function(){
        return (shape.y + ((shape.height - imgSize.height)* scale) / 2);
      }
    }

    const Transformation=function(alignment){
       var _transform=function(){
          return strategies[alignment]();
        }

        return {transform:_transform}
    }
    return new Transformation(alignment).transform();
  }


});
