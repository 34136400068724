ocCommonProviders.directive("customDateFilter",["inputSearchService","$rootScope",function(inputSearch,$rootScope) {
    "use strict";
    return {
        restrict: "E",
        scope:{dateFilterData:"<",label:"@",type:"@"},
        templateUrl:"/static/partials/customDateFilter.html",
        link:function(scope,el,attr){
            scope.onDateFilterChange=_onDateFilterChange;
            function _onDateFilterChange(){
                _updateFilterState(scope.dateFilterData)
            }

            function _updateFilterState(state){
                var filterSettings=scope.$parent.data.filters.filter((filter)=>(filter.name===attr.name))[0];
                filterSettings.state=state;
                inputSearch.doCustomFilters();
            }

            $rootScope.$on("TOGGLE_FILTERS",()=>{
                _updateFilterState("")
            });
        }
    };
}]);