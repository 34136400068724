ocCommonProviders.directive("customValidator", ["validationPatternService",function(validationPattern) {
    "use strict";
    return {
        restrict: "A",
        link:function(scope,element,attrs){
           element.bind("input blur",(evt)=>_doValidation());
           element.parents().find("form").submit((ev)=>_doValidation());
           function _doValidation(){
            var parent=element.parent();
                _resetAll(parent)

                                if(attrs.ngPattern){
                    _doValidationByPattern(parent)
                }

                if(element.val()===""){
                    element.addClass("ng-touched");
                    parent.append("<div class='validation validation-error field-empty'>* This field is required</div>")
                }else{
                    if(!element.hasClass("ng-invalid")){
                        parent.find(".field-empty").remove();
                    }
                }
           }

           function _doValidationByPattern(parentEl){
               var validator=validationPattern.getPatternByName(attrs.customValidator);
               if(validator){
                    if(element.hasClass("ng-invalid-pattern")){
                        parentEl.find(".invalid-pattern").remove();
                        parentEl.append(`<div class='validation validation-error invalid-pattern'>* ${validator.errMsg}</div>`)
                    }else{
                        parentEl.find(".invalid-pattern").remove();
                    }
                }
           }

           function _resetAll(parentEl){
                parentEl.find(".validation-error").remove();
           }

        }
    };
}]);