ocCommonProviders.directive("usersTable", function() {
  "use strict";
  return {
    restrict: "C",
    replace: true,
    templateUrl: "/static/partials/usersTable.html",
    controller: ["$scope","$uibModal",
      function($scope,$modal) {
        $scope.showUserPreview = function(user) {
          $modal.open({
            templateUrl: "/static/partials/previewUserModal.html",
            controller: "previewUserModalController",
            backdrop: true,
            windowClass: "ui-modal",
            resolve: { header: () => "Preview User", user: () => user }
          });
        };
      }
    ]
  };
});
