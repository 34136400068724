ocCommonProviders.directive("validationGroup", ["observableValidationService","$rootScope",function(observable,$rootScope) {
    "use strict";
    return {
        restrict: "A",
        scope:{
            validationGroup:"@",
            validationMsg:"@",
            isMaster:"<"
        },
        link:function(scope,element,attrs){
            observable.attach({
                name:attrs.name,
                isMaster:scope.isMaster,
                groupName:scope.validationGroup,
                elDOM:element,
                doValidate:(masterEl)=>{
                    var parent=element.parent();
                    var errMsg=parent.find(".validation-group-error");

                     if(masterEl.val()!=element.val()){
                        errMsg.remove();
                        element.addClass("invalid");
                        parent.append(`<div class='validation validation-group-error'>*${scope.validationMsg}</div>`)
                    }else{
                        element.removeClass("invalid");
                        errMsg.remove();
                    }
                }
            });

            element.bind("input blur",(evt)=>{
                observable.updateState(evt,scope.validationGroup);
                observable.checkGroup(evt,scope.validationGroup);
            });
        }
    };
}]);