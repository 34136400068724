ocCommonProviders.directive("browserVersion",["$uibModal", function ($uibModal) {
    "use strict";
    return {
    restrict: "C",
    scope: {
    },
    template:"",
    link: function(scope, element, attrs){

        function get_browser_info(){
            var ua=navigator.userAgent;
            var temporary;
            var tem;
            var M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if(/trident/i.test(M[1])){
                    temporary=/\brv[ :]+(\d+)/g.exec(ua) || [];
                    return {name:"IE",version:(temporary[1] || "")};
            }
            if(M[1]==="Chrome"){
                temporary=ua.match(/\bOPR\/(\d+)/);
                if(temporary !== null)   {return {name:"Opera", version:temporary[1]};}
            }
            M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, "-?"];
            if((tem=ua.match(/version\/(\d+)/i)) !== null) {M.splice(1,1,temporary[1]);}
                return {
                     name: M[0],
                     version: M[1]
            };
         }

        function openModal(){
            $uibModal.open({
          	    templateUrl: "/static/partials/incorrectBrowserVersion.html",
                controller: "browserErrorController",
                backdrop: "static",
                windowClass: "browserErroModalWidth"
            });
        }

        var browser = get_browser_info();

        if(browser.name==="Chrome" && parseInt(browser.version, 10) >= 30 )
            return;
        if(browser.name==="Firefox" && parseInt(browser.version, 10) >= 16 )
            return;
        if(browser.name==="IE" && parseInt(browser.version, 10) >= 10 )
            return;
        if(browser.name==="MSIE" && parseInt(browser.version, 10) >= 10 )
            return;

        openModal();


    }

  };
}]);
