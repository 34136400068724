ocCommonProviders.service("textBoxService", ["ckeditorService","textBannerEditorConfig","variableProperties","db", "login", "modal", "api", "errorHandlingService", "$q", "serverTime", "previewService", "$rootScope", "subscriptionModel","growlHandlingService","resourceService",
    function(Ckeditor,TextBannerEditor,VariableProperties,db, login, modal, api, errorHandling, $q, serverTime, previewService, $rootScope, subscriptionModel,growlHandlingService,Resource) {
        var self = this;

        this.getEditorInstance=_getEditorInstance;
        this.saveTextBox = _saveTextBox;
        this.updateTextBox = _updateTextBox;
        this.loadTextBoxes = _loadTextBoxes;
        this.getListIndexById = _getListIndexById;
        this.getElementById = _getElementById;
        this.selectTableElement = _selectTableElement;
        this.fillWithData = _fillWithData;
        this.deleteTextBox = _deleteTextBox;
        this.unDeleteTextBox = _unDeleteTextBox;
        this.prepareSaveData = _prepareSaveData;
        this.handleStatus = _handleStatus;
        this.compareObjects = _compareObjects;
        this.resourceFilter = _resourceFilter;
        this.loadFonts = _loadFonts;

        this.checkTexboxValid = (id, success) => {
          window.api.checkTextboxValid(id, success);
        }

        function _getEditorInstance(id,config){
            TextBannerEditor.contentsCss.push(`/customer/${window.api.customerid}/fontcss?Coyote-Authentication-Token=${login.sessionToken}`);
            Ckeditor.registerDefaultConfig("textBannerEditorConfig",angular.extend(TextBannerEditor,VariableProperties.getConfig()));
            return Ckeditor.createInstance(`editor${id}`,"textBannerEditorConfig",config);
        }


        function _loadFonts() {
            var deffered = $q.defer();
            const onSuccess = function(data) {
                deffered.resolve(data.font.join(";")+';')
            }
            const onFalure = function(data, status) {
                deffered.resolve('Arial;Calibri;Courier New;Cousine;Georgia;Times New Roman;Tahoma;Verdana;');
            }
            window.api.fontGetName(onSuccess, onFalure);
            return deffered.promise;
        }

        function _saveTextBox(title, description, textBoxValue,background_color,tag) {
            var deffered = $q.defer();
            var dataToSend = {
                "title": title,
                "description": description,
                "resource_type": "textbox",
                "textbox_value": textBoxValue,
                "background_color":`#${background_color}`,
                "tag":tag
            };
            const onSuccess = function(data) {
              deffered.resolve(data);
            };
            const onFailure = function(data, status) {
              $rootScope.$emit("LOADOFF");
              deffered.reject(data);
            };
            window.api.resourcePost(dataToSend, onSuccess, onFailure);
            return deffered.promise;
        };

        function _updateTextBox(scope,actionType) {
            var deffered = $q.defer();
            var self = this,
                elem,
                title,
                description,
                textbox_value ,
                compare,
                isNew,
                background_color,
                tag,
                dataToSend = {};

            if (scope.fileData) {
                elem = scope.fileData;
            }

            if (!elem.modified && !isNew) {
                return false;
            }

            title = elem.title;
            description = elem.description;
            textbox_value = elem.textbox_value;
            background_color=elem.background_color;
            tag=elem.tag ||[];
            compare = this.prepareSaveData(elem, scope.elementBackup, true);
            isNew = elem.isNew;


            if (title === "" || typeof title === "undefined" || title === null) {
                modal.info({
                        message: "You cannot save without entering a title.",
                        ok: "Ok",
                        cancel: ""
                    },
                    function() {},
                    function() {}
                );
                return false;
            }

            if (description === "" || typeof description === "undefined" || description === null) {
                description = "";
            }

            if (typeof tag === "undefined" || tag === null || tag.length==0) {
                tag=[]
            }

            if (compare !== null) {
                for (var i = 0; i < compare.length; i++) {
                    switch (compare[i]) {
                        case "title":
                            dataToSend.resource_title = title;
                            break;
                        case "description":
                            dataToSend.resource_description = description;
                            break;
                        case "textbox_value":
                            dataToSend.textbox_value = textbox_value;
                            break;
                        case "tag":
                            dataToSend.tag = tag;
                            break;
                        case "background_color":
                            dataToSend.background_color = `#${background_color}`;
                            break;
                    }
                }

                var customerId = window.api.customerid

                if ((compare.length == 1 && compare[0] == "isNew") || (compare.length == 2 && compare[0] == "isNew" && compare[1] == "preview")) {
                    deffered.resolve();
                } else{
                    const onSuccess = function(data) {
                      void 0
                      if(actionType!=="fallback"){
                        self.loadTextBoxes(scope, scope.selectedId);
                        if (scope.fileData) {
                          scope.elementBackup = angular.copy(scope.fileData);
                        }

                        growlHandlingService.success("Your Text Banner has been successfully saved.");
                        scope.selectedTableElement = null;
                        scope.selectedTableElementObject = null;
                        scope.blockingLayer = false;
                      }
                      deffered.resolve(data);
                    };
                    const onFailure = function(data) {
                      self.handleStatus(404, data, self);
                    };
                    window.api.resourcePut(scope.selectedId, dataToSend, onSuccess, onFailure);
                }
            } 

            return deffered.promise;
        };

        function _loadTextBoxes(scope, select,with_deleted,actionType) {
            var self = this;
            var deffered = $q.defer();
            var state = subscriptionModel.get('textbox');
            var _filter = function(item){ return item.status!='deleted' };
            const onSuccess = function(data) {
              if(state.initialized) {
                deffered.resolve(state.data)
              }else{
                var resources = data;
                if(!with_deleted) {
                  resources = resources.filter(_filter);
                }
                let selected=_getselectedById(scope,resources);
                _loadResourceApply(scope, resources, select);
                void 0
                if(actionType!="fallback"){
                  if((selected && selected.status==="deleted" && !scope.with_deleted) || (!selected && !scope.with_deleted )){
                    _selectTableElement(scope)
                  }
                }
                subscriptionModel.set('textbox', resources, true);
                deffered.resolve(resources);
              }
            }
            const onFailure = function(data) {
              subscriptionModel.set('textbox', [], false);
              deffered.reject(data);
              self.handleStatus(404, data, self);
            }
            window.api.resourceGetAll('textbox', 'with_tag=1&with_deleted=1', onSuccess, onFailure);

            return deffered.promise;
        }

        function _getselectedById(scope,resources){
            return resources.filter((item)=>item.id===scope.selectedId)[0];
        }

        function _loadResourceApply(scope, resources, select) {
            scope.textBoxList = resources;
            scope.textBoxListFilters=Resource.getFiltersOfMaterialList(scope.textBoxList,(filtered)=>scope.textBoxList=filtered);
            scope.customCategory=Resource.getCustomCategory();
        }


        function _getListIndexById(list, id) {
            for (var i = 0; i < list.length; i++)
                if (list[i].id == id)
                    return i;
        };


        function _getElementById(list, id) {
            for (var i = 0; i < list.length; i++)
                if (list[i].id == id)
                    return list[i];
        };


        function _selectTableElement(scope,att) {
            if ((typeof scope.textBoxList !== "undefined" && scope.textBoxList !== null && scope.textBoxList.length > 0)) {
                if (!att){
                    att = scope.textBoxList[0];
                }

                scope.selectedTableElement = att.id;
                scope.selectedId = att.id;
                    _fillWithData(scope,{
                        "resourceid": scope.selectedId
                    });
            }

        };


        function _fillWithData(scope, data, newOne, operation) {
            var self = this;
            var deffered = $q.defer();
            var defferedList = [];
            if (typeof newOne == "undefined")
                newOne = false;

            scope.hasUploadedFile = true;
            if (data.resourceid !== null && typeof data.resourceid !== "undefined") {
                var customerId = window.api.customerid;
                const onSuccess = function(data) {
                  data.created_at = serverTime.utcToLocal(data.created_at);
                  scope.selectedId = data.resource_id;
                  var connectionsUsed;
                  var rulesUsed;
                  if (typeof scope.targetFileData == "undefined") {
                    connectionsUsed = data.settings.connections;
                  } else {
                    if (typeof scope.targetFileData != "undefined" && scope.targetFileData !== null && typeof scope.targetFileData.connections != "undefined" && newOne && operation == "update") {
                      connectionsUsed = data.settings.connections.concat(scope.targetFileData.connections);
                    } else {
                      connectionsUsed = data.settings.connections;
                    }
                  }
                  void 0
                  scope.fileData = {
                    "id": data.resource_id,
                    "filename": data.name,
                    "title": data.title,
                    "description": data.description,
                    "pages": data.settings.target_count,
                    "filesize": data.filesize + "",
                    "editable": data.editable,
                    "operation": operation,
                    "created_by": data.created_by,
                    "isNew": newOne,
                    "text": data.settings.text,
                    "textbox_value": data.settings.text,
                    "inUse": data.connection_count,
                    "connection_running_count": data.connection_running_count,
                    "match_total": data.match_total,
                    "modified": operation == "update",
                    "connections": connectionsUsed,
                    "created_at": data.created_at,
                    "status":data.status,
                    "connection_running_count": data.connection_running_count,
                    "settings":data.settings,
                    "tag":data.tag || []
                  };

                  scope.elementBackup = angular.copy(scope.fileData);

                  deffered.resolve(scope.fileData);
                  scope.hasUploadedFile = true;
                  scope.$digest();
                }
                const onFailure = function() {
                  self.handleStatus(status, data, self);
                  deffered.reject();
                }
                window.api.resourceGet(data.resourceid, onSuccess, onFailure);
            } 
            defferedList.push(deffered.promise);
            return $q.all(defferedList);
        };

        function _unDeleteTextBox(scope,elem) {
            if (typeof elem === "undefined") {
                return false;
            }

            var unDeleteAction = function() {
                var customerId = window.api.customerid;
                var dataToSend = {
                    id: scope.selectedId,
                    status: "active",
                    resource_title: elem.title,
                    resource_description : elem.description,
                    tag:elem.tag
                }
                const onSuccess = function(data) {
                  scope.loadTextBoxes();
                  _selectTableElement(scope,{id:scope.selectedId})
                  scope.hasUploadedFile = false;
                  scope.blockingLayer = false;
                  growlHandlingService.success("Your Text Banner has been successfully undeleted.");
                }
                const onFailure = function() {
                  handleStatus(status, data, scope);
                  scope.blockingLayer = false;
                }
                window.api.resourcePut(scope.selectedId, dataToSend, onSuccess, onFailure);
            };
            modal.confirm({
                    message: "Are you sure you want to undelete this Text Banner?",
                    ok: "Yes",
                    cancel: "No"
                },
                function() {
                    unDeleteAction();
                },
                function() {}
            );

        }

        function _deleteTextBox(scope) {
            var elem;
            elem = this.getElementById(scope.textBoxList, scope.selectedId);

            if (typeof elem === "undefined") {
                return false;
            }

            if (elem.inUse > 0) {
                return false;
            }

            var deleteAction = function() {
                var customerId = window.api.customerid;
                const onSuccess = function(data) {
                  if (data.removed === false) {
                    modal.info({
                        message: "Could not remove the Text Banner.",
                        ok: "Ok",
                        cancel: ""
                      },
                      function() {
                      },
                      function() {}
                    ); 
                  }
                  scope.blockingLayer = false;
                }
                const onFailure = function() {
                  scope.blockingLayer = false;
                  self.handleStatus(status, data, self);
                }
                return window.api.resourceDelete(scope.selectedId, onSuccess, onFailure);
            };
            modal.confirm({
                    message: "Are you sure you want to delete this Text Banner?",
                    ok: "Yes",
                    cancel: "No"
                },
                function() {

                    deleteAction().then(()=>{
                        scope.loadTextBoxes().then(function(){
                            if(scope.with_deleted){
                                _selectTableElement(scope,{id:scope.selectedId});
                            }
                            scope.hasUploadedFile = false;
                            scope.blockingLayer = false;
                            growlHandlingService.success("Your Text Banner has been successfully removed.");
                        });
                    });

                },
                function() {}
            );
        };



        function _prepareSaveData(ob1, ob2, diff) {
            var compared = this.compareObjects(ob1, ob2, diff);
            if (compared.length === 0)
                return null;
            if (compared.length === 1 && compared[0] == "preview")
                return null;
            return compared;
        };

        function _handleStatus(status, data, scope) {
            errorHandling.handleResponse(status, data, scope);
        };


        function _compareObjects(ob1, ob2, different) {
            var wynik = [];
            if (different) {
                for (var elem1 in ob1) {
                    if (ob2 !== null && ob1[elem1] !== ob2[elem1])
                        wynik.push(elem1);
                }
            } 
            else {
                for (var elem in ob1) {
                    if (ob1[elem] === ob2[elem])
                        wynik.push(elem);
                }
            }
            return wynik;
        };

        function _resourceFilter(item, query) {

            if (typeof query == "undefined" || query === "")
                return true;
            if (((new RegExp(buildSearchPattern(RegExp.escape(query)), "ig")).test((item.description !== null ? item.description : "") + " " + item.title + " " + item.name + " " + item.created_by))) {
                return true;
            }
            return false;
        };

        function buildSearchPattern(inputSearchText) {
            var patternText = "";
            inputSearchText.split(/\s/g).forEach(
                function preparePattern(value) {
                    patternText += "(?=.*" + value + ".*)";
                }
            );
            patternText += ".+";
            return patternText;
        }

        RegExp.escape = (function(text) {
            var specials = ["$", "^", "/", ".", "*", "+", "?", "|", "(", ")", "[", "]", "{", "}", "\\"];
            return text.replace(new RegExp("(\\" + specials.join("|\\") + ")", "g"), "\\$1");
        });

    }
]);
