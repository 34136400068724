ocCommonProviders.directive('dropDownMenuContainer', ['userManageService', 'db','$uibModal','modal','growlHandlingService',
function (userManageService, db,$modal,modal,growlHandlingService) {
  'use strict';
  return {
    restrict: 'C',
    replace: true,
    templateUrl: '/static/partials/dropDownMenuContainer.html',
    scope: {
      user: '<'
    },
    link: function (scope) {
      scope.editUserData=_editUserData;
      scope.userGroupsVM=[];
      scope.toggleAccountAccess=_toggleAccountAccess;
      scope.resetPassword=_resetPassword;
      scope.isAccountActive=false;
      scope.resendActivation = function() {
        modal.confirm(
          {
            message: `Are you sure you want to send activation link: ${scope.user.username}?`,
            ok: "Yes",
            cancel: "No"
          },
          function() {
            userManageService.resendActivationEmail(scope.user).then(()=>{
              growlHandlingService.success(`Activation link has been sent to the email address:${scope.user.email}`);
            });
          }
        );
      }

      function _editUserData(){
        $modal.open({
          templateUrl: '/static/partials/newUserModal.html',
          controller: 'newUserModalController',
          backdrop: true,
          windowClass:'ui-modal',
          resolve:{
            user:()=>angular.copy(scope.user),
            header:()=>'Update User'
          }
        });
      }


      function _toggleAccountAccess(user){
        modal.confirm(
          {
            message: `Are you sure you want to ${user.active?"'disable'":"'enable'"} account login access for the user: ${scope.user.username}?`,
            ok: "Yes",
            cancel: "No"
          },
          function() {
            userManageService.toggleAccountAccess(user);
          }
        );

      }

      function _resetPassword(){
        modal.confirm(
          {
            message: `Are you sure you want to reset password for the user: ${scope.user.username}?`,
            ok: "Yes",
            cancel: "No"
          },
          function() {
            userManageService.resetPasswordForUserAccount(scope.user).then(()=>{
              growlHandlingService.success(`Reset password link has been sent to the email address:${scope.user.email}`);
            });
          }
        );
      }
    }
  }
}]);
