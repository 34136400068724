module.controller(
    "statisticsController", ["$scope", "login", "db", "api", "flowService", "$rootScope",
        "Connection", "userService", "$window", "$q", "$filter", "modal", "errorHandlingService", "utilityService",
        function($scope, login, db, api, flowService, $rootScope,Connection, userService, $window, $q, $filter, modal, errorHandling, utilityService) {
            "use strict";
            $rootScope.$broadcast("CHANGE_TAB", "Statistics");
            if (!String.prototype.endsWith) {
                String.prototype.endsWith = function(suffix) {
                    return this.indexOf(suffix, this.length - suffix.length) !== -1;
                };
            }
            $scope.blockingLayer = false;
            $scope.activeProject = 0;
            $scope.projectsList = [];
            $scope.ual = null;

            $scope.init = function(value) {
                $scope.loading = [];
                $scope.ual = userService.getUAL();
                if (typeof $scope.ual !== "undefined") {
                    $scope.ual.isAdmin = userService.isAdmin();
                }

            };

            $scope.getTitleByHeader=function(header){
                const titles={
                    'targets_sent':'Total materials sent by prod. systems',
                    'unique_targets_sent':'Materials sent by prod. systems (w/o duplicates)',
                    'targets_matched':'Materials matched by Connect',
                    'unique_targets_matched':'Materials matched by Connect (w/o duplicates)'
                }

                return titles[header];
            }

            $scope.isHelpForHeader=function(header){
                return angular.isDefined($scope.getTitleByHeader(header));
            }

            $scope.loadProjectList = function() {
                var deferred = $q.defer();
                $scope.ual = userService.getUAL();
                $scope.ual.isAdmin = userService.isAdmin();
                flowService.getFlows().then(
                    function(projects) {
                        $rootScope.$emit("LOADOFF");
                        $scope.projectsList = projects;

                        utilityService.setActiveProject($scope).then(function(data) {
                            $scope.activeProject = data;
                        });

                        if ($scope.ual.isAdmin && $scope.activeProject === -1 && $scope.projectsList.length > 0) {
                            $scope.activeProject = 0;
                        }

                        if ($scope.activeProject == -1) {
                            modal.info({
                                message: "You do not have any \"active\" Flows. Please contact Administrator.",
                                ok: "OK",
                                cancel: ""
                            });
                        }
                        if ($scope.projectsList.length > 0 && $scope.activeProject != -1) {
                            $scope.selectProject($scope.activeProject, true);
                            deferred.resolve();
                         }
                    },
                    function(data) {
                        $rootScope.$emit("LOADOFF");
                        deferred.reject();
                    }
                );
                return deferred.promise;
            };

            $scope.setActiveProject = function() {
                var index = -1;
                for (var i = 0; i < $scope.projectsList.length; i++) {
                    if ($scope.projectsList[i].active) {
                        index = i;
                        break;
                    }
                }
                return index;
            };

            $scope.returnRealProjectIndex = function(proj) {
                var index = 0;
                for (var i = 0; i < $scope.projectsList.length; i++) {
                    if ($scope.projectsList[i].id == proj.id) {
                        index = i;
                    }
                }
                return index;
            };

            $scope.selectProject = function(index, init) {
                if (index === 0 && db.get("login", "activeProjectIndex") && init) {
                    index = db.get("login", "activeProjectIndex");
                }
                db.set("login", "activeProjectIndex", index);
                window.api.setProject($scope.projectsList[index].id);
                $scope.activeProject = index;
                $rootScope.$emit("LOADON");
                getStatisticsData().then(
                    function() {
                        $rootScope.$emit("LOADOFF");
                    },
                    function(status, data) {
                        $rootScope.$emit("LOADOFF");

                    }
                );
            };

            $scope.statisticsData = null;
            $scope.stats_period = "this_month";
            var stats_period_from = null;
            var stats_period_to = null;
            var with_test=0;
            $scope.setTestFlag=(withTest)=>with_test=withTest?1:0;
            $scope.stats_group_by = "months";

            var getDecimalSeparator = function() {
                var n = 1.1;
                n = n.toLocaleString().substring(1, 2);
                if (n === ",") return ";";
                return ",";
            };

            var getStatisticsUrl = function() {
                calculatePeriodBoundaries();
                var url = "separator=" + getDecimalSeparator();
                url += stats_period_from ? "&period_from=" + stats_period_from.toISOString().slice(0, 10) : "";
                url += stats_period_to ? "&period_to=" + stats_period_to.toISOString().slice(0, 10) : "";
                url += "&group_by=" + $scope.stats_group_by;
                url +="&test=" + with_test;
                return url;
            };

            var getStatisticsTargetsUrl = function(connection_id, period_value, channel) {
                var url =  "connection_id=" + connection_id;
                url += "&group_by=" + $scope.stats_group_by;
                url += "&period_value=" + period_value;
                url += channel ? "&channel=" + channel : "";
                url += "&separator=" + getDecimalSeparator();
                url += stats_period_from ? "&period_from=" + stats_period_from.toISOString().slice(0, 10) : "";
                url += stats_period_to ? "&period_to=" + stats_period_to.toISOString().slice(0, 10) : "";
                return window.api.statisticsTargetsUrl(connection_id, url);
            };

            var calculatePeriodBoundaries = function() {
                var pf = new Date();
                var pt = new Date();
                var di;
                switch ($scope.stats_period) {
                    case "today":
                        break;
                    case "yesterday":
                        pf.setDate(pf.getDate() - 1);
                        pt.setDate(pt.getDate() - 1);
                        break;
                    case "this_week":
                        di = (pf.getDay() + 6) % 7;
                        pf.setDate(pf.getDate() - di);
                        pt.setDate(pt.getDate() - di + 6);
                        break;
                    case "last_week":
                        di = ((pf.getDay() + 6) % 7 + 7);
                        pf.setDate(pf.getDate() - di);
                        pt.setDate(pt.getDate() - di + 6);
                        break;
                    case "this_month":
                        pf.setDate(1);
                        pt.setDate(1);
                        pt.setMonth(pt.getMonth() + 1);
                        pt.setDate(0);
                        break;
                    case "last_month":
                        pf.setDate(1);
                        pf.setMonth(pf.getMonth() - 1);
                        pt.setDate(0);
                        break;
                    case "this_year":
                        pf.setMonth(0);
                        pf.setDate(1);
                        pt.setMonth(11);
                        pt.setDate(31);
                        break;
                    case "last_year":
                        pf.setYear(pf.getFullYear() - 1);
                        pf.setMonth(0);
                        pf.setDate(1);
                        pt.setYear(pt.getFullYear() - 1);
                        pt.setMonth(11);
                        pt.setDate(31);
                        break;
                    default:
                        pf = null;
                        pt = null;
                }
                stats_period_from = pf;
                stats_period_to = pt;
            };

            var clearStatistics = function() {
                $scope.statisticsData = null;
            };

            $scope.$watch("stats_period", calculatePeriodBoundaries);
            $scope.$watch("stats_period", clearStatistics);
            $scope.$watch("stats_group_by", clearStatistics);

            var getStatisticsData = function() {
                var deferred = $q.defer();
                $scope.statisticsData = null;
                if ($scope.projectsList.length === 0) return;
                var url = getStatisticsUrl() + "&format=json";
                const onSuccess = function(data) {
                  var ocPercent = $filter("ocPercent");
                  for (var i = 0; i < data.rows.length; i++) {
                    var row = data.rows[i];
                    for (var j = 0; j < data.headers.length; j++) {
                      var header = data.headers[j];
                      if (header.endsWith("_percent")) {
                        row[header] = ocPercent(row[header], 1);
                      }
                    }
                    row.targets_link = getStatisticsTargetsUrl(row.connection_id, row.date, row.channel);
                  }
                  $scope.statisticsData = data;
                  deferred.resolve();
                  $scope.$digest();
                }
                const onFailure = function() {
                  errorHandling.handleResponse(status, data);
                  $rootScope.$emit("LOADOFF");
                }
                window.api.statisticsGet(url, onSuccess, onFailure);
                return deferred.promise;
            };

            $scope.refreshStatistics = function() {
                if ($scope.projectsList.length === 0) return false;
                $rootScope.$emit("LOADON");
                getStatisticsData().then(
                    function() {
                        $rootScope.$emit("LOADOFF");
                    },
                    function() {
                        $rootScope.$emit("LOADOFF");
                    }
                );
            };

            $scope.clearBlockLayers = function(blockingLayer) {
                $(".toFront").removeClass("toFront");
                blockingLayer = false;
            };

            $scope.downloadStatistics = function() {
                if ($scope.loading_spinner) return false
                if ($scope.projectsList.length === 0) return false;
                $scope.loading_spinner = true;
                var url = getStatisticsUrl() + "&format=csv";
                url = window.api.statisticsGetUrl(url);
                _downloadStatistics(url).then(function() {
                    $scope.loading_spinner = false;
                });
            };

            $scope.downloadStatisticsTargets = function(url) {
                if ($scope.loading_spinner) return false;
                if ($scope.projectsList.length === 0) return false;
                $scope.loading_spinner = true;
                _downloadStatistics(url).then(function() {
                    $scope.loading_spinner = false;
                });
            };

            $scope.getAuthStatus = function() {
                return !$scope.config.isAdmin && db.get("login", "loggedInBoolean");
            };

            if ($scope.getAuthStatus())
                $scope.loadProjectList();


            function _downloadStatistics(url) {
                var deferred = $q.defer();
                const fname = 'statistics_' + getDateTime() + '.csv';
                const ftype = 'text/csv';
                const onSuccess = function() {
                  deferred.resolve();
                }
                window.api.download(url, fname, ftype, onSuccess);
                return deferred.promise;
            }


            function getDateTime() {
                var now = new Date();
                var year = now.getFullYear();
                var month = now.getMonth() + 1;
                var day = now.getDate();
                var hour = now.getHours();
                var minute = now.getMinutes();
                var second = now.getSeconds();
                if (month.toString().length == 1) {
                    var month = '0' + month;
                }
                if (day.toString().length == 1) {
                    var day = '0' + day;
                }
                if (hour.toString().length == 1) {
                    var hour = '0' + hour;
                }
                if (minute.toString().length == 1) {
                    var minute = '0' + minute;
                }
                if (second.toString().length == 1) {
                    var second = '0' + second;
                }
                var dateTime = day + '_' + month + '_' + year + '_' + hour + '_' + minute + '_' + second;
                return dateTime;
            }


        }
    ]);
