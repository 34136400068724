ocCommonProviders.component("uiTagsInput", {
  templateUrl: "/static/partials/uiTagsInput.html",
  restrict: "E",
  bindings: {
    uiModel: "<",
    type: "@",
    tagsLimit: "<?",
    title: "@",
    className: "@",
    editable:"<",
    subscribe:"&"
  },
  controller: "uiTagsInputController"
});
