ocCommonDictionaries.constant("protocols", [
  {
    displayName: "http://",
    value: "http"
  },
  {
    displayName: "https://",
    value: "https"
  },
  {
    displayName: "ftp://",
    value: "ftp"
  },
  {
    displayName: "news://",
    value: "news"
  }
]);


ocCommonDictionaries.constant("connectionDict", {
  CONNECTIONS_STATUS_CHANGE_ERROR:"Something went wrong for connections status change",
  testStatusChange: function(title) {
    const ON = "<b style='color:#8eb021;'>ON</b>";
    const OFF = "<b style='color:#d04437;'>OFF</b>";
    return {
      activeTestStatusRunning: function() {
        return "Test enabled for Connection" +title+ ", now has Test "+ON+" and Production " +ON;
      },
      activeTestStatusNotRunning: function() {
        return "Test enabled for Connection '" +title+"', now has Test "+ON+" and Production " +OFF;
      },
      inactiveTestStatusRunning: function() {
        return "Test disabled for Connection "+title+", now has Test "+OFF+" and Production " +ON;
      },
      inactiveTestStatusNotRunning: function() {
        return "Test disabled for Connection "+ title +", now has Test "+OFF+" and Production "+ OFF;
      },
      acitiveTestStatusAll:function(){
        return "Test flag has been 'enabled' for all connections in <br>'"+ title+"'";
      },
      inactiveTestStatusAll:function(){
        return "Test flag has been 'disabled' for all connections in <br>'"+ title+"'";
      }

    };
  },
  statuses:{
    WAITING:"waiting",
    DELETED:"deleted",
    RUNNING:"running",
    PENDING:"pending",
    STOPED:"stoped",
    EXPIRED:"expired"
  }
});

ocCommonDictionaries.constant("textBoxDict", {
  alerts:{
    TEXTBOX_IS_USED_IN_ACTIVE_CONNECTION_PROMPT:"This will make changes to Text Box that is used in active Connection, do you want to proceed?",
    TEXTBOX_IS_USED_IN_ACTIVE_CONNECTION_INFO:"This Text Box is used in active Connection."
  }
});

ocCommonDictionaries.constant("enclosuresDict", {
  alerts:{
    ENCLOSURE_IS_USED_IN_ACTIVE_CONNECTION_INFO:"This Enclosure is used in active Connection."
  }
})


