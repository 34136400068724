module.controller("rulesController", [
  "modal",
  "$scope",
  "$rootScope",
  "userService",
  "flowService",
  "$timeout",
  "utilityService",
  "rulesService",
  "$q",
  "variablesService",
  "attachmentTargetList",
  "subscriptionModel",
  "growlHandlingService",
  "uiSortingService",
  "navigationContextService",
  function(
    modal,
    $scope,
    $rootScope,
    userService,
    flowService,
    $timeout,
    utilityService,
    rulesService,
    $q,
    variablesService,
    attachmentTargetList,
    subscriptionModel,
    growlHandlingService,
    Sorting,
    NavigationContext
  ) {
    "use strict";
    $rootScope.$broadcast("CHANGE_TAB", "Rules");
    $scope.projectsList = null;
    $scope.setActiveProject = utilityService.setActiveProject;
    $scope.editVariableId = -1;
    $scope.editRuleId = -1;
    $scope.listSearch = "";
    $scope.addVariable = false;
    $scope.addRule = false;
    $scope.isAdmin = userService.isAdmin();
    $scope.showRules = false;
    $scope.showVariables = false;



    $scope.types = ["integer", "decimal", "string", "date"];
    $scope.variablesGroup = [];
    $scope.variablesTmpGroup = [];
    $scope.rulesGroup = [];
    $scope.rulesTmpGroup = [];
    $scope.openRules = [];
    $scope.targetlist = {};
    $scope.ruleslist = {};
    $scope._rulemodel = subscriptionModel.get("rule");

    var flowId = window.api.projectid;

    var _subscribeVariable = function(data) {
      if (data.data && $scope.editVariableId == data.data.id) {
        _discardVariable();
      }
      $scope.variablesGroup = subscriptionModel.get("variable").data;
      $scope.variableListFilters=variablesService.getFilters((filtered)=>{
        $scope.variablesGroup=filtered;
        Sorting.set("variables",filtered);
      },$scope.variablesGroup);

      Sorting.refresh("variables",  $scope.variablesGroup);
    };

    subscriptionModel.on("variable", _subscribeVariable);

    var _subscribeRule = function(data) {
      if (data.data && $scope.editRuleId == data.data.id) {
        _discardRule();
      }
      $scope.loadRulesList();
    };

    subscriptionModel.on("rule", _subscribeRule);

    $scope.$on("$destroy", function() {
      subscriptionModel.off("variable", _subscribeVariable);
      subscriptionModel.off("rule", _subscribeRule);
    });

    $scope.returnRealProjectIndex = function(proj) {
      return utilityService.returnRealProjectIndex(proj, $scope);
    };

    $scope.selectProject = function(proj) {
        $scope.ruleslist = {};
        $scope.activeProject = proj;
        for (var i = 0; i < $scope.projectsList.length; i++) {
          $scope.projectsList[i].active = 0;
        }
        $scope.projectsList[proj].active = 1;
        window.api.setProject($scope.projectsList[proj].id);
        flowId = window.api.projectid;

        init();

    };

    $scope.loadProjectList = function() {
      var deferred = $q.defer();
      flowService.getFlows().then(
        function(projects) {
          $scope.projectsList = projects;
          $scope
            .setActiveProject($scope)
            .then(function(data) {
              $scope.activeProject = data;
            })
            .then(function() {
              if (
                userService.isAdmin() &&
                $scope.activeProject === -1 &&
                $scope.projectsList.length > 0
              ) {
                $scope.activeProject = 0;
                deferred.resolve($scope.activeProject);
              }

              if ($scope.activeProject === -1)
                deferred.resolve($scope.activeProject);

              if (projects.length === 0) {
                deferred.reject();
              }

              if ($scope.activeProject === -1 && !$scope.isAdmin) {
                $scope.projectsList = [];
                deferred.reject();
                modal.info({
                  message:
                    'You do not have any "active" Flows. Please contact Administrator.',
                  ok: "OK",
                  cancel: ""
                });
              }

              deferred.resolve($scope.activeProject);
            }); 
        },
        function(data) {
          void 0;
        }
      );

      return deferred.promise;
    };

    $scope.loadRulesList = function() {
      var deferred = $q.defer();
      rulesService.getRules(false).then(function(result) {
        $scope._rulemodel = subscriptionModel.get("rule");
        $scope.rulesGroup = result;
        $scope.rulesListFilters=rulesService.getFilters((filtered)=>{
          $scope.rulesGroup=filtered;
          Sorting.set("rules",filtered);
          Sorting.refresh("rules",filtered);
        }, $scope.rulesGroup);

        Sorting.define({
          group:"rules",
          sortings:[{name:"rule_name",state:"initial",sortBy:"name"},{name:"rule_creation_at",state:"initial",sortBy:"created_at"}],
          collection:$scope.rulesGroup,
          callback:function(sorted){
            $scope.rulesGroup=sorted;
          }
        });

        if (result.length > 0) {
          $scope.showRules = true;
        } else {
          $scope.showRules = false;
        }

        for (var i = 0; i < result.length; i++) {
          if (result[i].status == "active")
            $scope.ruleslist[result[i].id] = result[i].name;
        }

        Sorting.refresh("rules",$scope.rulesGroup);
        deferred.resolve();
      });
      return deferred.promise;
    };

    $scope.loadVariablesList = function() {
      var deferred = $q.defer();
      variablesService.getVariables().then(function(data) {
        $scope.variablesGroup = data;
        Sorting.refresh("variables",$scope.variablesGroup );
        $scope.variableListFilters=variablesService.getFilters((filtered)=>{
          $scope.variablesGroup=filtered;
          Sorting.set("variables",filtered);
          Sorting.refresh("variables",filtered );
        },$scope.variablesGroup);
        deferred.resolve();
      });
      return deferred.promise;
    };

    $scope.loadTargetlist = function() {
      var deferred = $q.defer();
      attachmentTargetList.loadTargetlist().then(function(resources) {
        $scope.targetlist = {};
        for (var i = 0; i < resources.length; i++) {
          $scope.targetlist[resources[i].id] = resources[i].title;
        }
        deferred.resolve();
      });
      return deferred.promise;
    };

    $scope.createNewVariable = function() {
      $scope.addVariable = true;
      $scope.blockingLayer = true;
      $scope.showVariables = true;
      $scope.editVariableId = -1;
      $scope.variablesTmpGroup = [];
      $scope.variablesTmpGroup.push({
        value: "",
        type: "string",
        example: "",
        description: "",
        id: -1,
        editable: 1
      });
      resize();
    };

    $scope.editVariable = function(variable) {
      $scope.editVariableId = variable.id;
      $scope.blockingLayer = true;
      $("#variable_" + variable.id).addClass("toFront");
      resize();
    };

    $scope.changeShowVariables = function() {
      $scope.showVariables = !$scope.showVariables;
    };

    $scope.changeShowRules = function() {
      $scope.showRules = !$scope.showRules;
    };

    $scope.cancelVariable = function(variable) {
      var oldvar = subscriptionModel.getByID(
        subscriptionModel.get("variable").data,
        $scope.editVariableId
      );
      if (!angular.equals(oldvar, variable)) {
        modal.confirm(
          {
            message: "Do you want to discard changes?",
            ok: "Yes",
            cancel: "No"
          },
          function() {
            $scope.loadVariablesList();
            _discardVariable();
          },
          function() {}
        );
      } else {
        _discardVariable();
      }
    };

    function _discardVariable() {
      $scope.editVariableId = -1;
      $scope.addVariable = false;
      $scope.blockingLayer = false;
    }

    $scope.discardVariable = _discardVariable;

    $scope.createNewRule = function() {
      $scope.addRule = true;
      $scope.showRules = true;
      $scope.blockingLayer = true;
      $scope.editRuleId = -1;
      $scope.rulesTmpGroup = [];
      $scope.rulesTmpGroup.push({
        value: "",
        id: -1
      });
      $timeout(function() {
        resize();
        $("#rule_-1").addClass("toFront");
        $scope.reBuildQuery();
      }, 0);
    };

    $scope.reBuildQuery = function() {
      $scope.$broadcast("reBuildQuery");
    };



    function collectDropDown(){
      let offset=$(".scroll_container").scrollTop();
      $(".chosen-container .chosen-drop").css({'margin-top':-offset+"px"});
    }



    $scope.editRule = function(rule) {
      if (rule.active_connection !== null) {
        modal.info(
          {
            message:
              "Remember that Rule is used in connections: " +
              rule.active_connection,
            ok: "OK",
            cancel: ""
          },
          function() {},
          function() {}
        );
      }
      $scope.editRuleId = rule.id;
      $scope.reBuildQuery();
      $scope.blockingLayer = true;
      $("#rule_" + rule.id).addClass("toFront");

      $(".chosen-container").click(function(){
        collectDropDown();
      });

      $(".chosen-container").mouseover(function(){
        collectDropDown();
      });

      $(".scroll_container").scroll(function(){
        collectDropDown();
      });

      $timeout(function() {
        resize();
      }, 0);
    };

    function _discardRule() {
      $scope.editRuleId = -1;
      $scope.reBuildQuery();
      $scope.addRule = false;
      $scope.blockingLayer = false;
    }
    $scope.discardRule = _discardRule;

    $scope.cancelRule = function(rule) {
      var hook = angular
        .element("#rule_" + rule.id)
        .parent()
        .find(".rules");
      var value = hook.queryBuilder("getRules", {
        get_rules: true
      });

      function _prepareToCompare(rule) {
        rule.readonly = false;
        rule = _sortObject(rule);
        for (var i = 0; i < rule.rules.length; i++) {
          rule.rules[i].readonly = false;
          rule.rules[i].data = {};
          rule.rules[i].type = {};
          rule.rules[i] = _sortObject(rule.rules[i]);
          if (typeof rule.rules[i].rules !== "undefined")
            rule.rules[i] = _prepareToCompare(rule.rules[i]);
        }
        return rule;
      }

      function _sortObject(obj) {
        var new_obj = {};
        Object.keys(obj)
          .sort()
          .forEach(function(key) {
            new_obj[key] = obj[key];
          });
        return new_obj;
      }
      var oldrule = subscriptionModel.getByID(
        $scope._rulemodel.data,
        $scope.editRuleId
      );
      if (
        !angular.equals(oldrule, rule) ||
        !angular.equals(
          _prepareToCompare(oldrule.value),
          _prepareToCompare(value)
        )
      ) {
        modal.discard(
          {
            message: "Do you want to discard changes?",
            ok: "Discard",
            cancel: "Cancel",
            save: "Save"
          },
          function() {
            $("#rule_" + rule.id).removeClass("toFront");
            $scope.loadRulesList();
            _discardRule();
          },
          function() {},
          function() {
            $scope.saveRule(rule);
          }
        );
      } else {
        $("#rule_" + rule.id).removeClass("toFront");
        _discardRule();
      }
    };

    $scope.saveRule = function(rule) {
      var hook = angular.element("#rule_" + rule.id).parent().find(".rules");

      if (rule.id < 0) {
        if (hook.queryBuilder("validate")) {
          rulesService
            .setRule(
              rule.name,
              angular.toJson(
                hook.queryBuilder("getRules", {
                  get_rules: true
                })
              )
            ).then(function(data) {
              const context=NavigationContext.getContext();

              if(context){
                  NavigationContext.fallback(context,{ruleid:data.ruleid,target:"rules"});
              }else{

              $scope.loadRulesList();
              $scope.loadVariablesList();
              _discardRule();
              }
              growlHandlingService.success("Successfully created new Rule.");
            });
        }
      } else {
        if (hook.queryBuilder("validate")) {
          const onSuccess = function(data) {
            if(data.changed) {
              $scope.loadRulesList();
              $scope.loadVariablesList();
              _discardRule();
              rulesService.spawnModal("Successfully updated Rule");
            } else {
              rulesService.spawnModal("Rule condition not changed, you provided same value");
            }
          }
          rulesService
            .updateRule(
              rule,
              angular.toJson(
                hook.queryBuilder("getRules", {
                  get_rules: true
                })
              ),
              onSuccess
            );
        }
      }
    };

    $scope.openRule = function(ruleId) {
      var idx = $scope.openRules.indexOf(ruleId);
      if (idx === -1) {
        $scope.openRules = [];
        $scope.openRules.push(ruleId);
      } else {
        $scope.openRules.splice(idx, 1);
      }
      $scope.reBuildQuery();
    };

    $scope.colapseAllTabs = function() {
      $scope.showRules = false;
      $scope.showVariables = false;
    };

    $scope.expandAllTabs = function() {
      $scope.showRules = true;
      $scope.showVariables = true;
    };

    $scope.searchInRules = function(value) {
      return rulesService.searchInRules(value, $scope.listSearch);
    };

    $scope.searchInVariables = function(value) {
      return variablesService.searchInVariables(value, $scope.listSearch);
    };

    function init() {
      $scope.loadProjectList().then(function() {
        if ($scope.projectsList.length > 0) {
          window.api.setProject($scope.projectsList[$scope.activeProject].id);
          flowId = window.api.projectid;
          $scope.loadTargetlist().then(function() {
            $scope.loadVariablesList().then(function() {
              Sorting.define({
                group:"variables",
                sortings:[{name:"variable_name",state:"initial",sortBy:"value"}],
                collection: $scope.variablesGroup,
                callback:function(sorted){
                  $scope.variablesGroup=sorted
                },
              });
              $scope.loadRulesList();
            });
          });
        } else {
          showNoFlowSetAlert();
        }
      });
    }

    function showNoFlowSetAlert(){
      let msg="Your don't have any Flows set";
      if (!userService.isAdmin()) {
        msg+=("Please contact your Administrator.")
      }

      modal.info({
        message:msg,
        ok: "Ok",
        cancel: ""
      });
    }

    var resize = function(second) {
      var maxWidth = utilityService.getDocWidth();
      var maxHeight = utilityService.getDocHeight();
      var bl = angular.element(".blockingLayer");
      bl.css({
        width: maxWidth + "px",
        height: maxHeight + "px"
      });
      if (second)
        $timeout(function() {
          $scope.$apply();
        });
    };

    init();
  }
]);
