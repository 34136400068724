ocCommonProviders.service("uiTagsInputService", ["login","db","$q","api",function(login,db,$q,api) {
  this.getVMAutocomplete=_getVMAutocomplete;
  this.autocomplete=_autocomplete;
  this.getTagsVM = _getTagsVM;
  this.tagChangeResolve = _tagChangeResolve;

  function _autocomplete(VMAutocomplete,query){
    return VMAutocomplete.filter((item)=>item.text.toLowerCase().search(query.toLowerCase()) != -1);
  }

  function _getVMAutocomplete(){

    var deffered = $q.defer();
    const onSuccess = function(resp) {
      const VM=_getTagsVM(resp.tag);
      deffered.resolve(VM);
    }
    const onFailure = function() {
      deffered.reject()
    };
    window.api.tagGetAll(onSuccess, onFailure);
    return deffered.promise;
  }

  function _getTagsVM(uiModel, _subscribe) {
    if (angular.isUndefined(uiModel) || uiModel.length == 0) return [];

    var result=uiModel.reduce((acc, curr) => {
      let shortId='id-' + Math.random().toString(36).substr(2, 16);
      acc.push({ id:shortId, text: curr.value, color: null });
      return acc;
    }, []);
    return result;
  }


  function _tagChangeResolve(uiModel, change) {
    var resolve = uiModel || [];
    if (change.action === "add") {
      resolve.push({ value: change.tag.text, color: null });
    } else if (change.action == "remove") {
      resolve = resolve.filter(item => item.value != change.tag.text);
    } else {
      throw new Error("uiTagsInputService::_tagChangeResolve=>Unsuported action change");
    }
    return resolve;
  }

}]);
