Date.prototype.getMonth0 = function() {
    "use strict";
    return ("0" + (this.getMonth() + 1)).slice(-2);
};
Date.prototype.getDate0 = function() {
    "use strict";
    return ("0" + this.getDate()).slice(-2);
};
ocCommonProviders.service("serverTime", [function() {
    "use strict";
    var serverTime = null; 
    var localServerDifference = 0;
    var timeStamp = 0;
    var clockInterval = null;
    this.prepareDateForServer = _prepareDateForServer;
    this.utcToLocal = _utcToLocal;
    this.checkServerLocalTimeDifference = _checkServerLocalTimeDifference;
    this.utcDateParser = _utcDateParser;
    this.dateParser = _dateParser;
    this.setServerTime = _setServerTime;
    this.getTimeOffset = _getTimeOffset;
    this.getServerTime = _getServerTime;
    this.getServerTimeUTC = _getServerTimeUTC;
    this.getBrowserTime = _getBrowserTime;
    this.getTimeDifference = _getTimeDifference;
    this.getUTCTime = _getUTCTime;

    function _setServerTime(time) {
        if (serverTime !== null)
            return false;
        serverTime = time;
        clockInterval = setInterval(function() {
            self.timeStamp++;
        }, 1000);
    }

    function _getTimeOffset(dateObject) {
        return dateObject.getTimezoneOffset();
    }

    function _getServerTime() {
        return new Date((timeStamp + serverTime) * 1000);
    }

    function _getServerTimeUTC() {
        return moment.utc((timeStamp + serverTime) * 1000).format();
    }

    function _getBrowserTime() {
        return new Date();
    }

    function _getTimeDifference() {
        return new Date() - _getServerTime();
    }

    function _getUTCTime(dateObject) {
        return dateObject.toUTCString();
    }

    function _prepareDateForServer(dateObject) {

    }

    function _utcToLocal(dateObject) {
        var mom = moment(dateObject);
        var off = _getTimeOffset(new Date());
        mom.add(-off, "m");
        return mom.toDate();
    }

    function _checkServerLocalTimeDifference() {
        var diff = (Math.abs(new Date().getTimezoneOffset() - _getServerTime().getTimezoneOffset()) / 60);
        if (diff >= 12)
            return false;
        return true;
    }

    function _utcDateParser(utcDate) {
        if (typeof utcDate === "undefined" || utcDate === null || utcDate === "")
            return null;

        var reg = utcDate.match(/([0-9]{4})-([0-9]{2})-([0-9]{2}) ([0-9]{2}):([0-9]{2}):([0-9]{2})/);
        if (reg === null)
            return null;

        var out = new Date();
        out.setUTCFullYear(reg[1]);
        out.setUTCMonth(reg[2]);
        out.setUTCDate(reg[3]);
        out.setUTCHours(reg[4]);
        out.setUTCMinutes(reg[5]);
        out.setUTCSeconds(reg[6]);
        return out;
    }

    function _dateParser(utcDate) {
        if (typeof utcDate === "undefined" || utcDate === null || utcDate === "")
            return null;

        var reg = utcDate.match(/([0-9]{4})-([0-9]{2})-([0-9]{2}) ([0-9]{2}):([0-9]{2}):([0-9]{2})/);

        if (reg === null) {
            reg = utcDate.match(/([0-9]{4})-([0-9]{2})-([0-9]{2})/);
            var now = new Date(); 
            if (reg !== null) {
                return new Date(parseInt(reg[1]), parseInt(reg[2] - 1), parseInt(reg[3]), now.getHours(), now.getMinutes(), now.getSeconds());
            } else {
                return utcDate;
            }
        }
        var out = new Date(reg[1], reg[2] - 1, reg[3], reg[4], reg[5], reg[6]);
        return out;
    }

}]); 
