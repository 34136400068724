ocCommonProviders.service("groupService", [ function() {
    "use strict";
    this.getOpenedGroups = _getOpenedGroups;
    this.openGroup = _openGroup;
    this.closeGroup = _closeGroup;
    this.closeAllGroup = _closeAllGroup;
    var openedGroups =[];

    function _getOpenedGroups(){
        return openedGroups;
    }

    function _openGroup(id){
        openedGroups.push(id);
    }

    function _closeGroup(id){
        for (var i=0; i<openedGroups.length; i++){
            if(openedGroups[i] === id){
                 openedGroups.splice(i, 1);
                 break;
                 }
	    }
    }
    function _closeAllGroup(){
        openedGroups = [];

    }
}]);
