ocCommonProviders.directive("statisticView",["userService", function (userService) {
    "use strict";
    return {
    restrict: "C",
    templateUrl:function () {
         if ( typeof(userService.getUser()) !== "undefined" && userService.getUser().application_level === "premium") {
                    return "/static/partials/statisticsPremium.html";
         }else{
                    return "/static/partials/statistics.html";
         }
     },
     link: function(scope, element, attrs){}
  };
}]);
