const disabledStatuses={
    DELETED:"deleted",
    EXPIRED:"expired"
}

ocCommonProviders.directive("connectionsListDirective", ["api", "db", "login", "modal", "userService", "pagesListService", "attachmentTargetList",
    "Connection","$rootScope","flowService","subscriptionModel","$timeout",
    function(api, db, login, modal, userService, pagesListService, attachmentTargetList,Connection,$rootScope,flowService,subscriptionModel,$timeout) {
        "use strict";
        return {
            restrict: "C",
            scope: false,
            templateUrl: function(scope) {
                if (typeof(userService.getUser()) !== "undefined" && userService.getUser().application_level === "basic") {
                    return "/static/partials/connectionsBasicList.html";
                } else {
                    return "/static/partials/connectionsList.html";
                }
            },
            compile: function(scope) {
                return {

                pre: function (scope, iElem, iAttrs) {
                scope.connectionsList=scope.$parent.connectionsList;
                scope.production = window.config.production;

                $rootScope.$on('connection_searchPattern', function (event, query) {
                    if(scope.$parent.connectionsList){
                        scope.connectionsList=scope.$parent.connectionsList.filter((con)=>Connection.searchInConnections(con,query));
                    }
                });

                scope.searchInConnections = function (value) {
                    return Connection.searchInConnections(value, scope.attachementSearch);
                };

                $rootScope.$on('connection_statusChange', function (event,data) {
                    if(scope.$parent && scope.$parent.connectionsList){
                        scope.connectionsList=scope.$parent.connectionsList;
                    }

                    if(data){
                        scope.connectionsList[data.index].status=data.status;
                    }

                });

                $rootScope.$on('cancel_connection', function (e,index) {
                   scope.connectionsList=scope.connectionsList.filter((con)=>con.id!=-1)
                   scope.connectionsList[index].lock=true;
                });

                scope.$watch("conList.length", function(a, b) {
                        var activePartial=scope.$parent.activePartial;

                        if(activePartial!="3"){
                            if(scope.conList && scope.conList.filter((con)=>con.status!='deleted' && con.id!=-1).length>1){
                                scope.$parent.testAllVisible=true;
                                scope.testAllVisible=true;
                                scope.testAll={checked:!scope.conList.some((connection)=>connection.test!=1)};
                            }else{
                                scope.$parent.testAllVisible=false;
                                scope.testAllVisible=false;
                            }
                        }
                    scope.testAllConnections=function(info,testFlag){

                        Connection.testAllConnections(info,testFlag).then(()=>{
                            scope.connectionsList.forEach((connection)=>{
                                connection.test=testFlag;
                            });
                        });
                    }


                    if (typeof scope.info != "undefined" && typeof scope.info.id != "undefined") {
                        var searchPattern=angular.element("#attachmentSearchPhrase").val();
                        if(searchPattern!=""){
                            $rootScope.$emit("connection_searchPattern",searchPattern);
                        }
                        pagesListService.updateShownDCAs(scope.info.name, scope.conList, scope.info.id, scope.activePartial);
                    } else {
                    }

                });

                scope.$watch("$parent.connectionsList.length", function(newLength, oldLength) {
                    var activePartial=scope.$parent.activePartial;
                    if(activePartial!="3"){
                        if(scope.$parent.connectionsList && scope.$parent.connectionsList.filter((con)=>con.status!='deleted' && con.id!=-1).length>1){
                            scope.$parent.testAllVisible=true;
                            scope.testAllVisible=true;
                        }else{
                            scope.$parent.testAllVisible=false;
                            scope.testAllVisible=false;
                        }
                    }


                    if(newLength!=oldLength){
                        scope.connectionsList=scope.$parent.connectionsList;
                    }
                });

                var afterIndex, beforeIndex;
                var swappedElement, swappedElementId;
                var draggedElement;

                function include(arr, obj) {
                    return (arr.indexOf(obj));
                }

                scope.downloadFile = function(data, ok) {
                    attachmentTargetList.downloadFile(data.content_id, data.content_name, 'application/pdf');
                };

                scope.isTestCheckboxDisabled=function(attachementData,showdragdrop){
                    return !attachementData.lock || showdragdrop || _isDisabledStatus(attachementData.status);
                }

                scope.changeProductionStatus = (attachementData) => {
                    Connection.changeProductionStatus(attachementData);
                }



                function _isDisabledStatus(status){
                    for(var key in disabledStatuses){
                        if(disabledStatuses[key]===status){
                            return true;
                        }
                    }
                    return false;
                }

                scope.changeTestStatus = function(con) {
                    Connection.changeTestStatus(con).then(function() {
                        Connection.onConnectionStatusChange(con);
                        if(!con.test){
                            scope.testAll={checked:false};

                        }else{
                            var checkedCntr=0;
                            scope.conList.forEach((connection)=>{
                                if(connection.test){
                                    checkedCntr++;
                                }
                            });
                            scope.testAll={checked:checkedCntr===scope.conList.length};

                        }
                      },
                      function() {
                        con.test = false;
                      }
                    );
                  };

                function findConnection(id) {
                    const connections = model.get('connection').data;
                    for (var i = 0; i < connections.length; i++) {
                        if (connections[i].id === id) {
                            return connections[i];
                        }
                    }
                }

                function changePriority(draggedElement, swappedElement) {
                    var customerId = window.api.customerid;
                    const onSuccess = function(data) {
                    }
                    const onFailure = function(data) {
                      scope.handleStatus(404, data);
                      if (userService.getUser().application_level === "premium") {
                        scope.loadConnectionListPremium();
                      } else {
                        scope.loadConnectionList();
                      }
                    }
                    const payload = {
                      "connection1_id": draggedElement.id,
                      "connection1_prio": draggedElement.priority,
                      "connection2_id": swappedElement.id,
                      "connection2_prio": swappedElement.priority
                    }
                    window.api.connectionPriority(payload, onSuccess, onFailure);
                }

                scope.$on("startDragDrop", function(event, mass) {
                    var listElement;
                    if (userService.getUser().application_level === "premium") {
                        listElement = $("#connectionListSortable_" + scope.info.id);
                    } else {
                        listElement = $("#connectionListSortable");
                    }

                    if (scope.showdragdrop) {
                        listElement.sortable({
                            placeholder: "basicInfo-placeholder",
                            axis: "y",
                            cursor: "move",
                            start: function(event, ui) {
                                var start = listElement.sortable("toArray", {
                                    key: "value"
                                });
                                beforeIndex = include(start, ui.item.context.id);
                            },
                            stop: function(event, ui) {
                                var stop = listElement.sortable("toArray", {
                                    key: "value"
                                });
                                afterIndex = include(stop, ui.item.context.id);

                                if (beforeIndex > afterIndex) {
                                    swappedElementId = stop[afterIndex + 1].slice(4);
                                }
                                if (afterIndex > beforeIndex) {
                                    swappedElementId = stop[afterIndex - 1].slice(4);
                                }

                                if (afterIndex !== beforeIndex) {
                                    swappedElementId = parseInt(swappedElementId);
                                    swappedElement = findConnection(swappedElementId);
                                    draggedElement = findConnection(parseInt(ui.item.context.id.slice(4)));
                                    changePriority(draggedElement, swappedElement);
                                }
                            }
                        });
                    } else {
                        listElement.sortable("destroy");
                    }

                });
            },
        };
    }
}
    }
]);
