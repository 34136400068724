ocCommonProviders.directive("filtersSelection",["inputSearchService",function(inputSearch) {
    "use strict";
    return {
        restrict: "E",
        templateUrl:"/static/partials/filtersSelection.html",
        link:function(scope){
            scope.extraFilters=inputSearch.searchSettings.basicFilters.filter(filter=>(filter.type==="selection"))[0].filters;
            scope.onFilterChange=function(filter){
                inputSearch.doCustomFilters();
            }

                    }
    };
}]);