ocCommonProviders.directive("timelineHCDirective", ["$rootScope", "$compile", "Connection", "flowService", "db", "$filter", "userService",
    function($rootScope, $compile, Connection, flowService, db, $filter, userService) {
        "use strict";
        return {
            restrict: "C",
            template: function(element, attrs) {
                if (window.api.projectid > -1) {
                    return "<div class=\"tlButtons\"><div class=\"toCenter fl\"><span class=\"tlBtn tlBtn2\" ng-click=\"move(0.2)\" >&#10094;</span><span class=\"tlBtn\" ng-click=\"zoomNow()\" > Now </span><!--<span class=\"tlBtn\" ng-click=\"reset()\" > Reset </span>--><span class=\"tlBtn tlBtn2\" ng-click=\"move(-0.2)\" >&#10095;</span></div><div class=\"toRight fr\"><span class=\"tlBtn\" ng-click=\"zoom(0.2)\" >Zoom Out</span> / <span class=\"tlBtn\" ng-click=\"zoom(-0.2)\" >Zoom In</span></div></div><div class=\"visShow\"></div>";
                } else {
                    return "<div></div>";
                }
            },
            link: function(scope, element, attrs) {
                var connectionsHistory = null;
                var connectionsId = 0;
                var timeline;
                var hook = element.find(".visShow");
                var connectionsNames = [],
                    groups = [];
                var deletedStatus = [],
                    runStatus = [],
                    stopStatus = [],
                    pendingStatus = [],
                    waitingStatus = [],
                    editStatus = [],
                    expiredStatus = [];
                var statusNames = {
                    "run": "running",
                    "edit": "editing",
                    "stop": "stopped",
                    "pending": "pending",
                    "expired": "expired",
                    "waiting": "waiting",
                    "toEdit": "edit",
                    "toRun": "run",
                    "toStop": "stop",
                    "inProduction": "production",
                    "deleted": "deleted"
                };
                var elemId;
                var groupsArray = [];
                var connectionsArray = [];
                var percentage = 2;
                var connectionSearch = "";
                var defaultInterval;
                var minStart;
                var maxEnd;
                var maxStart;
                var filter;
                var intervalUpdateHook;
                var intervalSearch;

                function intervalUpdate() {
                    clearInterval(intervalUpdateHook);
                    intervalUpdateHook = setInterval(update, 100);
                }

                function handleWatch(a, b) {
                    void 0
                    filter = scope.$parent.$parent.filter;
                    intervalUpdate();
                }

                scope.$watch("$parent.filter.byRunning", handleWatch);
                scope.$watch("$parent.filter.byStopped", handleWatch);
                scope.$watch("$parent.filter.byPending", handleWatch);
                scope.$watch("$parent.filter.byEditing", handleWatch);
                scope.$watch("$parent.filter.byWaiting", handleWatch);
                scope.$watch("$parent.filter.byDeleted", handleWatch);
                scope.$watch("$parent.filter.byExpired", handleWatch);

                function _clearGroupStatuses() {
                    if (scope.$parent.$parent.groups)
                        for (var i = 0; i < scope.$parent.$parent.groups.length; i++) {
                            scope.$parent.$parent.groups[i].checked = true;
                        }
                }
                _clearGroupStatuses();

                scope.$watch("$parent.$parent.groups", function(a, b) {
                    scope.info = a;
                    loadData();
                });

                $rootScope.$$listeners.PROJECTCHANGED = [];
                scope.$watch("$parent.$parent.attachementSearch", function(a, b) {
                    connectionSearch = a;
                    clearTimeout(intervalSearch);
                    intervalSearch = setTimeout(function() {
                        $rootScope.$emit("LOADON", true);
                        setTimeout(function() {
                            update();
                            $rootScope.$emit("LOADOFF");
                        }, 2000);
                    }, 500);
                });

                $rootScope.$on("PROJECTCHANGED", function(event, mass) {
                });

                scope.reRender = false;

                function loadData() {
                    $rootScope.$emit("LOADON");
                    if (typeof window.api.projectid !== "undefined") {
                        Connection.loadConnectionWithStatusHistoryAndTags(window.api.projectid).then(function(data) {
                            connectionsHistory = data;
                            scope.$parent.$parent.connectionsList = data.connections;
                            update();
                            $rootScope.$emit("LOADOFF");
                        }, function(data) {
                            $rootScope.$emit("LOADOFF");
                        });
                    } else {
                        $rootScope.$emit("LOADOFF");
                    }
                }
                loadData();


                scope.move = function(percentage) {
                    var range = timeline.getWindow();
                    var interval = range.end - range.start;
                    if (typeof defaultInterval == "undefined") {
                        defaultInterval = {
                            end: range.end,
                            start: range.start
                        };
                    }
                    timeline.setWindow({
                        start: range.start.valueOf() - interval * percentage,
                        end: range.end.valueOf() - interval * percentage
                    });

                };

                scope.zoomNow = function() {
                    timeline.moveTo(Date.now());
                };
                scope.reset = function() {
                    timeline.setWindow({
                        start: moment(minStart).minutes(0).seconds(0).milliseconds(0).add(-1, "months"),
                        end: moment(maxEnd).minutes(0).seconds(0).milliseconds(0).add(1, "months"),
                    });
                };

                scope.zoom = function(percentage) {
                    var range = timeline.getWindow();
                    var interval = range.end - range.start;
                    if (typeof defaultInterval == "undefined") {
                        defaultInterval = {
                            end: range.end,
                            start: range.start
                        };
                    }
                    timeline.setWindow({
                        start: range.start.valueOf() - interval * percentage,
                        end: range.end.valueOf() + interval * percentage
                    });

                };

                function showStatusType(status) {
                    switch (status) {
                        case statusNames.toStop:
                            if (!filter.byStopped)
                                return false;
                            break;
                        case statusNames.toRun:
                            if (!filter.byRunning)
                                return false;
                            break;
                        case statusNames.toEdit:
                            if (!filter.byEditing)
                                return false;
                            break;
                        case statusNames.run:
                            if (!filter.byRunning)
                                return false;
                            break;
                        case statusNames.edit:
                            if (!filter.byEditing)
                                return false;
                            break;
                        case statusNames.expired:
                            if (!filter.byExpired)
                                return false;
                            break;
                        case statusNames.stop:
                            if (!filter.byStopped)
                                return false;
                            break;
                        case statusNames.waiting:
                            if (!filter.byWaiting)
                                return false;
                            break;
                        case statusNames.pending:
                            if (!filter.byPending)
                                return false;
                            break;
                        case statusNames.deleted:
                            if (!filter.byDeleted)
                                return false;
                            break;
                        default:
                            void 0;
                            break;

                    }
                    return true;
                }

                function addGroupToArray(arr, con, order) {

                    if (con.name !== "Attachments" && con.name !== "Enclosures") {
                        arr.push({
                            id: order,
                            content: "Page " + con.page_name + " - " + con.name,
                            className: "timelinePreviewDirective tl_" + con.id,
                            style: ""
                        });
                    } else {
                        arr.push({
                            id: order,
                            content: con.name,
                            className: "timelinePreviewDirective tl_att",
                            style: ""
                        });

                    }
                }

                function addConnectionToArray(arr, con, groupId, order) {
                    if(!showStatusType(con.status)) return;
                    if (con.banner_area_id === groupId.info.id) {
                        _connectionArrayElement(arr, con, groupId, order);
                    } else {
                        if (con.banner_area_id === null && con.resource_type == "attachment" && groupId.name == "Attachments") {
                            _connectionArrayElement(arr, con, groupId, order);
                        }

                        if (con.banner_area_id === null && con.resource_type == "enclosure" && groupId.name == "Enclosures") {
                            _connectionArrayElement(arr, con, groupId, order);
                        }
                    }
                }

                function _connectionArrayElement(arr, con, groupId, order) {
                    var start;
                    var end;
                    var title;
                    for (var i1 = 0; i1 < con.statuses_history.length; i1++) {
                        connectionsId++;
                        if (i1 === 0)
                            start = moment.utc(con.valid_from_utc).local();
                        else
                            start = moment.utc(con.statuses_history[i1][0]).local();

                        if (i1 + 1 <= con.statuses_history.length && typeof con.statuses_history[i1 + 1] !== "undefined") {
                            end = moment.utc(con.statuses_history[i1 + 1][0]).local();
                        } else {
                            if (con.statuses_history[i1][1] !== "deleted" && con.statuses_history[i1][1] !== "expired") {
                                if (con.valid_to_utc !== null) {
                                    end = moment.utc(con.valid_to_utc).local();
                                } else {
                                    end = moment(maxEnd).minutes(0).seconds(0).milliseconds(0).add(2, "months");
                                }
                            } else {
                                end = moment.utc(con.statuses_history[i1][0]).local();
                            }
                        }
                            arr.push({
                                id: connectionsId,
                                content: "&nbsp;",
                                start: start,
                                end: end,
                                group: order,
                                subgroup: con.id,
                                className: con.statuses_history[i1][1] + "CN",
                                groupOrder: 1
                            });
                    } 
                    connectionsId++;

                    if (con.valid_to_utc === null) {
                        title = con.title + " &nbsp;&nbsp;&nbsp;&nbsp; " + moment.utc(con.valid_from_utc).local().format("YYYY-MM-DD") + " - <span class=\"infin\">&infin;</span>";
                    } else {
                        title = con.title + " &nbsp;&nbsp;&nbsp;&nbsp; " + moment.utc(con.valid_from_utc).local().format("YYYY-MM-DD") + " - " + moment.utc(con.valid_to_utc).local().format("YYYY-MM-DD");
                    }
                    arr.push({
                        id: connectionsId,
                        content: title,
                        start: moment.utc(con.valid_from_utc).local(), 
                        end: end,
                        group: order,
                        subgroup: con.id,
                        className: "fakeCN timelineDetailDirective conId_" + con.id,
                        groupOrder: 5
                    });
                }

                function update() {
                    clearInterval(intervalUpdateHook);
                    connectionsId = 0;
                    connectionsArray = [];
                    groupsArray = [];
                    var filterOut = function(a, b) {
                        return Connection.searchInConnections(a, connectionSearch);
                    };
                    var out;
                    if (connectionsHistory !== null) {
                        out = connectionsHistory.connections.filter(filterOut);
                    }
                    if (typeof scope.info !== "undefined") {
                        for (var i = 0; i < scope.info.length; i++)
                            if (scope.info[i].checked) {
                                addGroupToArray(groupsArray, scope.info[i], i);
                                if (typeof out !== "undefined" && out.length >= 1) {
                                    for (var j = 0; j < out.length; j++) {
                                        if (moment(out[j].valid_to_utc) > maxEnd || typeof maxEnd == "undefined") {
                                            maxEnd = moment(out[j].valid_to_utc);
                                        }
                                        if (out[j].valid_from_utc < minStart || typeof minStart == "undefined") {
                                            minStart = out[j].valid_from_utc;
                                        }
                                        if (out[j].valid_from_utc > maxStart || typeof maxStart == "undefined") {
                                            maxStart = out[j].valid_from_utc;
                                        }
                                    }
                                    if (maxStart > maxEnd) {
                                        maxEnd = maxStart;
                                    }

                                    for (var z = 0; z < out.length; z++) {
                                            addConnectionToArray(connectionsArray, out[z], scope.info[i], i);
                                    }
                                }
                            }
                    } else {
                        groupsArray.push({
                            id: 1,
                            content: "Attachments",
                            className: "timelinePreviewDirective tl_att",
                            style: ""
                        });
                        if (db.get("login", "enclosure_enabled"))
                            if (userService.getUser().application_level === "premium")
                                groupsArray.push({
                                    id: 0,
                                    content: "Enclosures",
                                    className: "timelinePreviewDirective tl_enc",
                                    style: ""
                                });

                        if (connectionsHistory !== null) {
                            out = connectionsHistory.connections.filter(function(a, b) {
                                return Connection.searchInConnections(a, connectionSearch);
                            });
                        }

                        if (typeof out !== "undefined" && out.length >= 1) {
                            for (var t = 0; t < out.length; t++) {
                                if (moment(out[t].valid_to_utc) > maxEnd || typeof maxEnd == "undefined") {
                                    maxEnd = moment(out[t].valid_to_utc);
                                }
                                if (out[t].valid_from_utc < minStart || typeof minStart == "undefined") {
                                    minStart = out[t].valid_from_utc;
                                }
                                if (out[t].valid_from_utc > maxStart || typeof maxStart == "undefined") {
                                    maxStart = out[t].valid_from_utc;
                                }
                            }
                            if (maxStart > maxEnd) {
                                maxEnd = maxStart;
                            }
                            for (var x = 0; x < out.length; x++) {
                                    addConnectionToArray(connectionsArray, out[x], {
                                        "name": "Attachments",
                                        info: {
                                            id: 1
                                        }
                                    }, 1);
                            }
                        }
                    }

                    if (scope.$parent.$parent.activeProject > -1)
                        initVis();
                }

                function initVis() {
                    var now = moment().minutes(0).seconds(0).milliseconds(0);
                    var items = new vis.DataSet({
                        type: {
                            start: "ISODate",
                            end: "ISODate"
                        }
                    });

                    var groups = new vis.DataSet(groupsArray);

                    items.add(connectionsArray);
                    var container = hook[0];
                    if (typeof maxEnd === "undefined" || maxEnd === null || !maxEnd.isValid()) {
                        maxEnd = moment();
                    }
                    var options = {
                        orientation: "top",
                        start: moment(minStart).minutes(0).seconds(0).milliseconds(0).add(-1, "months"),
                        end: moment(maxEnd).minutes(0).seconds(0).milliseconds(0).add(1, "months"),
                        stack: false,
                        moveable: true,
                        selectable: false,
                        editable: false,
                        clickToUse: true,
                        min: moment(minStart).add(-2, "months"), 
                        max: moment(maxEnd).add(2, "months"),
                        groupOrder: function(a, b) {
                                return a.id - b.id;
                            }
                    };
                    if (typeof timeline === "undefined")
                        timeline = new vis.Timeline(container);

                    timeline.setOptions(options);
                    timeline.setGroups(groups);
                    timeline.setItems(items);
                    timeline.on("change", function() {
                        $compile(hook)(scope);
                    });
                } 
            }

        };
    }
]);
