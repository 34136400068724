module.controller("textController", [
  "$rootScope",
  "$scope",
  "$q",
  "$timeout",
  "login",
  "db",
  "modal",
  "flowService",
  "userService",
  "utilityService",
  "textBoxService",
  "textBoxDrawService",
  "subscriptionModel",
  "ckeditorService",
  "textBoxDict",
  "variables",
  "fonts",
  "textEditorFilterService",
  "variableProperties",
  "textBannerEditorConfig",
  "uiTagsInputService",
  "resourceService",
  "navigationContextService",
  function(
    $rootScope,
    $scope,
    $q,
    $timeout,
    login,
    db,
    modal,
    flowService,
    userService,
    utilityService,
    textBoxService,
    textBoxDrawService,
    subscriptionModel,
    ckeditorService,
    textBoxDict,
    variables,
    fonts,
    textEditorFilterService,
    variableProperties,
    textBannerEditorConfig,
    uiTagsInput,
    Resource,
    NavigationContext
    ) {
    "use strict";
    $rootScope.$broadcast("CHANGE_TAB", "TEXT");
    $scope.hasUploadedFile = false;
    $scope.selectedTableElement = null;
    $scope.selectedTableElementObject = null;
    $scope.selectedElementData = null;
    $scope.selectedId = null;
    $scope.selectedInfoPredicate=Resource.getStatistics;
    $scope.itemClassPredicate=Resource.getItemClass;
    $scope.elementBackup = {};
    $scope.connectionLimit = 5;
    $scope.variableLimit = 5;
    $scope.dcaColor = "FFFFFF";
    $scope.fileData = {};
    $scope.dcaViewMain = {};
    $scope.blockingLayer = false;
    $scope.fitToSize = true;
    $scope.ual = userService.getUAL();
    $scope.rndNumber = Math.floor(Math.random() * 100 + 1);
    $scope.selectedDca = 0;
    $scope.activeProject = 0;
    $scope.selectDcaChangeHandler = _selectDcaChangeHandler;
    $scope.selectProjectChangeHandler = _selectProjectChangeHandler;
    $scope.setActiveProject = utilityService.setActiveProject;
    $scope.refreshPreview = _refreshPreview;
    $scope.onTagChange=(change)=>{
      $scope.fileData.tag=uiTagsInput.tagChangeResolve($scope.fileData.tag,change);
      $scope.fileData.modified = true;
      $scope.blockingLayer = true;
    }
    $scope.textBoxDict = textBoxDict;
    var doFilter;
    var previousValue = "";

    _init();
    $scope.access = { tag: [] };
    subscriptionModel.on("textbox", _subscribeAttachment);

    $scope.$on("$destroy",()=>subscriptionModel.off("textbox", _subscribeAttachment));

    function _subscribeAttachment(change) {
      $scope.textBoxList = subscriptionModel.get("textbox").data;
      $scope.textBoxListFilters=Resource.getFiltersOfMaterialList($scope.textBoxList,(filtered)=>$scope.textBoxList=filtered);
      if(change.operation=="put"){
        $scope.selectResource(change.data);
      }
      $scope.$apply();
    };

    function _selectDcaChangeHandler() {
      if ($scope.dcaList.length > 0 && editor) {
        textBoxDrawService.selectChangeHandler($scope.selectedDca, $scope);
      }
      textBoxDrawService.refresh($scope);
    }

    $scope.isTextBoxInUseConnection = function(fileData) {
      if (fileData.connection_running_count > 0) {
        return true;
      }
      return false;
    };

    function _selectProjectChangeHandler() {
      var projectID = -1;
      if ($scope.activeProject > -1) {
        projectID = $scope.projectsList[$scope.activeProject].id;
      }
      window.api.setProject(projectID);
      textBoxDrawService.init().then(function() {
        if (projectID > 0) {
          textBoxDrawService.fillWithData(projectID).then(function() {
            $scope.dcaList = textBoxDrawService._dcaList;
            var pages = $scope.projectsList[$scope.activeProject].pages;
            for (var i = 0; i < $scope.dcaList.length; i++) {
              for (var j = 0; j < pages.length; j++) {
                if ($scope.dcaList[i].page_id === pages[j].id) {
                  $scope.dcaList[i].page_name = pages[j].name;
                  $scope.dcaList[i].background_id = pages[j].background_id;
                  break;
                }
              }
            }
            $scope.selectedDca = 0;
            _selectDcaChangeHandler();
            textBoxDrawService.refresh($scope);
          });
        }
      });
    }

    var editor = null;

    function _initEditor(variableList, fullVariableList) {

      _initDCAColorChangeListener();
      var deferred = $q.defer();
      setTimeout(function() {
        textBannerEditorConfig.contentsCss=["body {font-family:'Arial';font-size:12;} .cke_variable{background-color:yellow;} .cke_editable a{text-decoration:none;color:inherit;background-color:#a3d1f7;}"];
        textBannerEditorConfig.contentsCss.push(`/customer/${window.api.customerid}/fontcss?Coyote-Authentication-Token=${login.sessionToken}`);
        ckeditorService.registerDefaultConfig("textBannerEditorConfig",angular.extend(textBannerEditorConfig,variableProperties.getConfig()));
        editor = ckeditorService.createInstance(
          `editor${$scope.rndNumber}`,
          "textBannerEditorConfig",
          {
            variables: variableList,
            fullVariables: fullVariableList,
            font_names: fonts
          }
        );

        _initDCAColorChangeListener();
        editor.on("change", function(evt) {
          _refreshPreview();
          var editorData = editor.getData();
          var prevMatch = previousValue.match(/<a/g);
          var actMatch = editorData.match(/<a/g);
          if ((prevMatch && actMatch && prevMatch.length < actMatch.length) || (!prevMatch && actMatch)) {
            editor.setData(editorData, {
              noSnapshot: true
            });
          }

          $scope.fileData.modified = true;
          $scope.blockingLayer = true;
          $scope.resize(true);

          previousValue = editorData;
          $scope.fileData.textbox_value = previousValue;

          textBoxDrawService.refresh($scope);
        });


        editor.on("contentDom", function() {
          var editable = editor.editable();
          editor.addMenuItems({
            myCommand: {
              label: editor.lang.image.menu,
              command: "myCommand",
              group: "div",
              order: 9
            }
          });

          editable.attachListener(editable, "mouseup", function() {
            logSelection();
          });
        });

        function logSelection() {
          var sel = editor.getSelection(true);
          var range = sel.getRanges()[0];
          var firstNode = range.startContainer.getParent();
          var lastNode = range.endContainer.getParent();

          if (lastNode.type === CKEDITOR.NODE_ELEMENT) {
            var parents = range.endContainer.getParents();
            for (var i = 0; i < parents.length; i++) {
              if (
                parents[i].getName &&
                parents[i].getName() == "variable" &&
                range.endOffset > 0 &&
                parents[i].getText().length >= range.endOffset
              ) {
                range.setEndAfter(lastNode);
              }
            }
          }

          if (firstNode.type === CKEDITOR.NODE_ELEMENT) {
            var parents = range.startContainer.getParents();
            for (var i = 0; i < parents.length; i++) {
              if (
                parents[i].getName &&
                parents[i].getName() == "variable" &&
                range.startOffset > 0 &&
                parents[i].getText().length >= range.startOffset
              ) {
                range.setStartBefore(firstNode);
              }
            }
          }

          range.select();
        }

        $scope.addNewTextBanner = function() {
          $scope.selectedId = null;
          $scope.fileData = {};
          $scope.fileData.tag=[];
          $scope.fileData.background_color="ffffff";
          $scope.dcaColor="FFFFFF";
          textBoxDrawService.dcaColor.value="#FFFFFF";
          ckeditorService.changeBodyStyleCss({attr:'background-color', value:`#ffffff`});
          $scope.fileData.editable = true;
          $scope.hasUploadedFile = true;

          var tmpList = [];
          for (var i = 0; i < variables.length; i++) {
            tmpList.push(variables[i].value);
          }
          textBoxDrawService.refresh($scope);
          _initEditor(tmpList, variables).then(function() {
            _refreshPreview();
          });
        };

        $scope.isUploaded = function() {
          return $scope.hasUploadedFile;
        };

        editor.on("instanceReady", function(evt) {
         let self=this;
          let e = evt.editor;
          editor.contextMenu.addListener(function(element, selection) {
            if ($(element.$).hasClass("variable_content")) {
              self.addMenuGroup("basicstyles", 3);
              self.addMenuItems({
                editVariableCmd: {
                  label: "Edit variable",
                  command: "editVariableCmd",
                  group: "basicstyles",
                  order: 1
                }
              });

              self.addCommand("editVariableCmd", {
                exec: function(editor) {
                  editor.openDialog("variableDialog");
                  let dialog = CKEDITOR.dialog.getCurrent(0);
                  if (dialog) {
                    let jQueryCurrent = $(element.$);

                    while (!jQueryCurrent.is("variable")) {
                      jQueryCurrent = jQueryCurrent.parent();
                    }

                    for (var index in element.$.attributes) {
                      if (element.$.attributes[index].name === "original") {
                          variableProperties.setEditedValue(element.$.attributes[index].value);
                      }

                      utilityService
                        .getDOMElementAttrs(jQueryCurrent, [
                          "test",
                          "contenteditable",
                          "original"
                        ])
                        .forEach(el => {
                          let _el = dialog.getContentElement("tab", el.key);
                          if (_el) {
                            dialog.getContentElement("tab", el.key).setValue(el.value);
                          }
                        });
                    }
                  }
                }
              });



              return {
                editVariableCmd: CKEDITOR.TRISTATE_ON
              };
            }
          });

          e.removeMenuItem("editdiv");
          e.removeMenuItem("removediv");
          doFilter = () => textEditorFilterService.doFilter.call(this);

          doFilter();

          $("#cke_editor" + $scope.rndNumber)
            .find(".cke_button__source")
            .click(function(elem) {
              var content = $("#" + editor.id + "_contents");
              setTimeout(function() {

                if (
                  $scope.selectedTableElementObject.connection_running_count ===
                  0
                ) {
                  editor.setReadOnly(false);
                  $("#cke_editor" + $scope.rndNumber)
                    .find(".cke_button__source")
                    .show();
                } else if (
                  $scope.selectedTableElementObject.connection_running_count > 0
                ) {
                  editor.setReadOnly(true);
                  $("#cke_editor" + $scope.rndNumber)
                    .find(".cke_button__source")
                    .hide();
                }
              }, 100);
              ckeditorService.changeBodyStyleCss({attr:'background-color', value:textBoxDrawService.dcaColor.value});;
            });
          deferred.resolve();
        });
      }, 0);
      return deferred.promise;
    }

    function _initDCAColorChangeListener(){
      $scope.dcaColorChange={
        onChange:function(api,bgColor,evt){
            $scope.fileData.modified=true;
            textBoxDrawService.dcaColor.value=`#${bgColor}`;
            $scope.fileData.background_color=bgColor;
            textBoxDrawService.refresh($scope);
            ckeditorService.changeBodyStyleCss({attr:'background-color', value:`#${bgColor}`});
            $scope.$apply();
      }

    }
  }

    function _refreshPreview() {
      var content = $("#" + editor.id + "_contents");
      var innerElement = $($(content.find("iframe")[0]).contents()[0]).find(
        "body"
      );
      textBoxDrawService.textValue(innerElement);
      $scope.fitToSize = textBoxDrawService.checkIsValid();
      ckeditorService.changeBodyStyleCss({attr:'background-color', value:textBoxDrawService.dcaColor.value});
    }

    function _loadProjectList() {
      var deferred = $q.defer();
      flowService.getFlows().then(
        function(projects) {
          $scope.projectsList = projects;
          $scope
            .setActiveProject($scope)
            .then(function(data) {
              $scope.activeProject = data;
            })
            .then(function() {
              if (
                userService.isAdmin() &&
                $scope.activeProject === -1 &&
                $scope.projectsList.length > 0
              ) {
                $scope.activeProject = 0;
                deferred.resolve($scope.activeProject);
              }

              if ($scope.activeProject === -1)
                deferred.resolve($scope.activeProject);

              if (projects.length === 0) {
                deferred.reject();

                var msg = "Your don't have any flows set. ";
                if (!userService.isAdmin()) {
                  msg += "Please contact your administrator.";
                }

                modal.info({
                  message: msg,
                  ok: "Ok",
                  cancel: ""
                });
              }

              if ($scope.activeProject === -1 && !$scope.isAdmin) {
                $scope.projectsList = [];
                deferred.reject();
                modal.info({
                  message:
                    'You do not have any "active" Flows. Please contact Administrator.',
                  ok: "OK",
                  cancel: ""
                });
              }

              deferred.resolve($scope.activeProject);
            }); 
        },
        function(data) {}
      );

      return deferred.promise;
    }

    function _init(value) {
      $scope.loading = [];
      $scope.ual = userService.getUAL();
      if (typeof $scope.ual !== "undefined") {
        $scope.ual.isAdmin = userService.isAdmin();
      }

      $rootScope.$emit("LOADON");
      _loadProjectList().then(function(project) {
        _selectProjectChangeHandler();
        var tmpList = [];
        for (var i = 0; i < variables.length; i++) {
          tmpList.push(variables[i].value);
        }

        _initEditor(tmpList, variables).then(function() {
          $scope.loadTextBoxes().then(function() {
            $rootScope.$emit("LOADOFF");
          });
        });
      });
    }

    $scope.selectResource = function(attr) {
      if (attr.connection_running_count > 0) {
        editor.setReadOnly(true);
        $(".cke_combo_text").css("color", "#999");
        $(".cke_button__source ").hide();

      } else {
        editor.setReadOnly(false);
        $(".cke_combo_text").css("color", "#000");
        $(".cke_button__source ").show();
      }
      previousValue = "";
      $scope.fillWithData({ resourceid: attr.id });
      $scope.selectedTableElement = attr.id;
      $scope.selectedTableElementObject = attr;
      $scope.selectedId = attr.id;
      $scope.dcaColor=attr.settings?attr.settings.background_color.replace("#",""):"#fff";
      textBoxDrawService.dcaColor.value=attr.settings?attr.settings.background_color:"#fff";
      textBoxDrawService.refresh($scope);

      if (!$scope.$$phase) {
        $scope.$apply();
      }
    };

    $scope.fillWithData = function(data, newOne, operation) {
      $scope.selectedId = data.resourceId;
      $scope.checkboxStatus = null;
      $rootScope.$emit("LOADON");

      const context=NavigationContext.getContext();
      const actionType=context?"fallback":null;
      textBoxService.fillWithData($scope, data, newOne, operation,actionType).then(
        function() {
          if (
            $scope.selectedTableElementObject &&
            $scope.selectedTableElementObject.variable_value
          ) {
            $scope.fileData.variables = $scope.selectedTableElementObject.variable_value
              .split(",")
              .filter(function(value, index, self) {
                return self.indexOf(value) === index;
              });
          } else {
            $scope.fileData.variables = [];
          }

          if (editor) {

            editor.setData($scope.fileData.text, {
              noSnapshot: true,
              callback: function() {
                _refreshPreview();
                $rootScope.$emit("LOADOFF");
              }
            });
          }
        },
        function() {
          $timeout(function() {
            $rootScope.$emit("LOADOFF");
          }, 0);
        }
      );
    };

    $scope.saveTextBox = function() {
      if ($scope.testMode || !$scope.fitToSize) {
        return null;
      }

      if (!$scope.selectedId) {
        textBoxService.saveTextBox(
            $scope.fileData.title,
            $scope.fileData.description,
            $scope.fileData.textbox_value,
            $scope.fileData.background_color,
            $scope.fileData.tag).then(
            function(data) {
              $scope.selectedId = data.resourceid;
              $scope.fileData.id = data.resourceid;
              $scope.elementBackup = {
                title: null,
                description: null,
                textbox_value: null
              };

              const context=NavigationContext.getContext();
              const actionType=context?"fallback":null;
              textBoxService.updateTextBox($scope,actionType).then(()=>{
                if(context){
                    NavigationContext.fallback(context,{resourceid:data,target:"textbox"});
                }
              });

            },
            function() {
              $timeout(function() {
                $rootScope.$emit("LOADOFF");
              }, 0);
            }
          );
      } else {
        var context=NavigationContext.getContext();
        const actionType=context?"fallback":null;
        textBoxService.updateTextBox($scope,actionType).then((data)=>{
          if(context){
            NavigationContext.fallback(context,{resourceid:data,target:"textbox"})
          }
        });

      }
    };

    $scope.deleteTextBox = function(elem) {
      if (elem.connection_count > 0) {
        return null;
      }

      if (elem.inUse > 0) {
        return null;
      }

      textBoxService.deleteTextBox($scope);
    };

    $scope.unDeleteTextBox = function(elem) {
      if (elem.inUse > 0) {
        return null;
      }

      if (!elem.isNew) textBoxService.unDeleteTextBox($scope, elem);
    };

    $scope.cancelTextBox = function(data) {
      $scope.selectedTableElement = null;
      $scope.selectedTableElementObject = null;
      $scope.hasUploadedFile = false;
      $scope.fileData = {tag:[]};
      $scope.fileData.editable = true;
      var tmpList = [];
      for (var i = 0; i < variables.length; i++) {
        tmpList.push([variables[i].value]);
      }
      _initEditor(tmpList, variables).then(function() {
        $scope.blockingLayer = false;
        _refreshPreview();
      });
    };

    $scope.increaseLimit = function() {
      $scope.connectionLimit = $scope.fileData.connections.length;
    };

    $scope.increaseVariableLimit = function() {
      $scope.variableLimit = $scope.fileData.variables.length;
    };

    $scope.getAuthStatus = function() {
      return !$scope.config.isAdmin && db.get("login", "loggedInBoolean");
    };

    $scope.loadTextBoxes = function() {
      return textBoxService
        .loadTextBoxes($scope, false, $scope.with_deleted)
        .then(function(data) {
          if (data && data.length > 0 && !$scope.selectedTableElementObject) {
            $scope.selectResource(data[0]);
          }
        });
    };

    $scope.resize = function(second) {
      var maxWidth = utilityService.getDocWidth();
      var maxHeight = utilityService.getDocHeight();
      var bl = angular.element(".blockingLayer");
      bl.css({
        width: maxWidth + "px",
        height: maxHeight + "px"
      });
      if (second)
        $timeout(function() {
          $scope.$apply();
        });
    };

    $scope.$watch("blockingLayer", function(a, b) {
      $timeout($scope.resize, 100);
    });

    $scope.openZoomModal = function() {
      var imgAlt=`Page ${$scope.dcaList[$scope.selectedDca].page_name} - ${$scope.dcaList[$scope.selectedDca].name}`;
      var $image=$("#textViewCanvasViewer");
      var canvas = textBoxDrawService.getCanvas();
      canvas.backgroundColor ="#fff";
      var svg = $(canvas.toSVG()).filter("svg")[0];
      var svgString=new XMLSerializer().serializeToString(svg);
      var imgsrc = 'data:image/svg+xml;base64,'+ btoa(svgString);
      $image.attr("src",imgsrc);
      $image.attr("alt",imgAlt);


      $image.viewer(
      {
        toolbar: {
          zoomIn: 4,
          zoomOut: true,
          oneToOne: false,
          reset: false,
          prev: false,
          play:false,
          next: false,
          rotateLeft: false,
          rotateRight: false,
          flipHorizontal: false,
          flipVertical: false,
        },
        viewed: function() {
        $image.viewer('zoomTo', 2);
      }});

      $image.trigger('click');

    }
  }]);
