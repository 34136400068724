ocCommonProviders.provider("layout", function() {
	"use strict";
	var latestWidth = 0;
	var latestHeight = 0;
	var screenUpdater = null;
	return {
		$get: function () { 
			return { 
				init: function() {
					var that = this;
					$( window ).resize(function() { that.update(false); });
					$( document ).ready(function() { that.update(true); });
				},

				update: function(fullUpdate) {
					clearTimeout(screenUpdater);

					if(fullUpdate === true) {
						latestHeight = 0;
						latestWidth = 0;
					}

					screenUpdater = setTimeout(this.calculateDOMWidths, fullUpdate ? 0 : 0);

					if(fullUpdate === true) {
						this.calculateDOMHeights();
					}
				},

				getAttachmentItemWidth: function() {
					var contentWidth = $(".content").outerWidth();
					var width = $(".attachment.item").width(contentWidth - 364);
					return { width: width + "px" };
				},

				calculateDOMWidths: function() {

					var contentWidth = $(".content").outerWidth();

					if(latestWidth !== contentWidth) {
						latestWidth = contentWidth;
						$(".attachment.item").width(contentWidth - 364);
						var attachmentWidth = $(".attachment.item").width();
						$(".background").width(attachmentWidth + 50);
						$(".info").width(attachmentWidth - 345);
						if($(".background") && $(".history")){

						    						    var offset = $(".background").offset();
						    if($(".history"))
						    	$(".history").css("right", latestWidth - offset.left - $(".background").outerWidth());
						}
					}
				},

				calculateDOMHeights: function() {
					var screenHeight = $("body").outerHeight();
					if(screenHeight !== latestHeight) {
						latestHeight = screenHeight;
						$( ".attachment.item:not(.closed)" ).each(function( index ) {
							var convertListHeight = $(this).find(".convert.list").outerHeight();
							var infoHeight = $(this).find(".info").outerHeight();

							if(convertListHeight > 270) {
									infoHeight = 500;
									$(this).find(".info").height(infoHeight + (convertListHeight - 270));
									infoHeight = $(this).find(".info").outerHeight();
							}

							$(this).height(infoHeight + 30);
							$(this).find(".preview").height(infoHeight);
						});
						var backgroundHeight = $(".background").outerHeight();
						var attachmentListHeight = $(".attachment.list").outerHeight();

						if(backgroundHeight < (attachmentListHeight + 100) || backgroundHeight > (attachmentListHeight + 100)) {
							$(".background").height(attachmentListHeight + 100);
							backgroundHeight = $(".background").outerHeight();

						}
						var placeholderHeight = $(".tab.item.placeholder").outerHeight();
						var tabListHeight = $(".tab.list").outerHeight();
						var delta = backgroundHeight - tabListHeight;
						if(delta < 0) {
							if(Math.abs(delta) <= placeholderHeight) {
								$(".tab.item.placeholder").height(placeholderHeight + delta);
							} else {
								$(".background").height(tabListHeight - placeholderHeight + 20);
								$(".tab.item.placeholder").height(21);
							}
						} else if(delta > 0) {
							$(".tab.item.placeholder").height(placeholderHeight + 5 * delta);
						} 

						$(".history").height($(".background").height());
						$(".history section").height($(".background").height() - 50);
					}
				}

			};
		}
	};
});