ocCommonProviders.service("attachmentTargetList", ["db", "login", "modal", "api", "errorHandlingService", "$q", "serverTime", "previewService", "$rootScope", "subscriptionModel","growlHandlingService",
"uiDrawUtilsService","$timeout",
    function(db, login, modal, api, errorHandling, $q, serverTime, previewService, $rootScope, subscriptionModel,growlHandlingService,UIDrawUtils,$timeout) {
        var self = this;

        this.saveEnclosure = function(scope) {
            var deffered = $q.defer();
            var formData = new FormData();
            formData.append("resource_type", "enclosure");
            formData.append("enclosure_value", scope.fileData.filename);
            formData.append("tag", JSON.stringify(scope.fileData.tag));
            const onSuccess = function(data) {
              deffered.resolve(data);
            };
            const onFailure = function(data) {
              $rootScope.$emit("LOADOFF");
              deffered.reject(data);
            }
            window.api.resourcePost(formData, onSuccess, onFailure);
            return deffered.promise;
        };

        this.loadTargetlist = function(scope) {
            var deffered = $q.defer();
            var state = subscriptionModel.get('targetlist');
            var customerId = window.api.customerid;
            if(state.initialized) {
                deffered.resolve(state.data);
                subscriptionModel.trigger(`api.getall.targetlist`, state.data);
            } else {
                const onSuccess = function(data) {
                  deffered.resolve(data);
                }
                const onFailure = function(data) {
                  deffered.reject(data);
                  self.handleStatus(status, data, self);
                }
                window.api.resourceGetAll('targetlist', 'with_tag=1', onSuccess, onFailure);
            }

            return deffered.promise;
        };

        this.loadAttachmentList = function(scope, type, select, with_deleted,actionType) {
            var self = this;
            var deffered = $q.defer();
            var state = subscriptionModel.get(type)
            var customerId = window.api.customerid;
            var _filter = function(item){ return item.status!='deleted' };
            if(window.model.auth) {
              if(state.initialized) {
                var resources = angular.copy(state.data);
                _loadResourceApply(scope, type, resources, select)
                deffered.resolve(resources);
                subscriptionModel.trigger(`api.getall.${type}`, resources);
              } else {
                const onSuccess = function(data) {
                  _loadResourceApply(scope, type, data, select)
                  deffered.resolve(data);
                }
                const onFailure = function(data) {
                  deffered.reject(data);
                  self.handleStatus(status, data, self);
                }
                window.api.resourceGetAll(type, 'with_tag=1&with_deleted=1', onSuccess, onFailure);
              }
            } else {
              deffered.resolve([]);
            }
            return deffered.promise;
        };


        function _getselectedById(scope,resources){
            return resources.filter((item)=>item.id===scope.selectedId)[0];
        }

        function _loadResourceApply(scope, type, resources, select) {
            if (type == "attachment") {
                scope.connectionPreview = "att";
                scope.dcaViewMain.name = 'Attachments';
                scope.attachmentList = resources;
            }

            if (type == "enclosure") {
                scope.connectionPreview = "enc";
                scope.dcaViewMain.name = 'Enclosures';
                scope.enclosureList = resources;
            }

            if (type == "targetlist") {
                scope.targetList = resources;
            }
            if (select) {
                select = self.clickMenuElementByElementId(scope, type, select);
            }
        }

        function stringify(a) {
            var s = "";
            for (var i = 0; i < a.length; i++) {
                s += a[i].value + ",";
            }
            return s.substring(0, s.length - 1);
        }

        this.compareObjects2 = function(ob1, ob2, different) {
            var result = this.compareObjects(ob1, ob2, different);

            if (result.indexOf("tag") > -1) {
                if (ob1["tag"].length != ob2["tag"].length) {
                    return result;
                }
                var foundDiff = false;
                for (var i = 0; i < ob1.tag.length; i++) {
                    foundDiff = true;
                    for (var j = 0; j < ob2.tag.length; j++) {
                        if (ob1.tag[i].color == ob2.tag[j].color) {
                            foundDiff = false;
                            break;
                        }
                    } 
                    if (foundDiff)
                        return result;
                } 

                result.splice(result.indexOf("tag"), 1);
            }
            return result;
        };

        this.compareObjects = function(ob1, ob2, different) {
            var wynik = [];
            if (different) {
                for (var elem1 in ob1) {
                    if (ob2 !== null && typeof ob2[elem1] !== "undefined" && ob1[elem1] !== ob2[elem1])
                        wynik.push(elem1);
                }
            } 
            else {
                for (var elem in ob1) {
                    if (typeof ob2[elem] !== "undefined" && ob1[elem] === ob2[elem])
                        wynik.push(elem);
                }
            }
            return wynik;
        };

        this.getListIndexById = function(list, id) {
            for (var i = 0; i < list.length; i++)
                if (list[i].id == id)
                    return i;

        };

        this.getElementById = function(list, id) {
            for (var i = 0; i < list.length; i++)
                if (list[i].id == id)
                    return list[i];
        };

        this.clickMenuElementByElementId = function(scope, type, id) {
            if (type === "attachment" || type === "enclosure") {
                scope.selectedAttachmentTableElement = id;
                scope.selectedTableElement = id;
            }
        };

        this.downloadFile = function(id, filename, type) {
            if (id === undefined) {
                modal.info({
                        message: "No downloadable content",
                        ok: "Ok",
                        cancel: ""
                    },
                    function() {},
                    function() {}
                );
                return;
            }
            var url = window.api.getResourceUrl(id)
            previewService.downloadPreview(url, id + "_" + filename, type);
        };

        this.previewMyImage = function(scope, convertionFormat) {
            var deffered = $q.defer();
            if (scope.selectedId !== null) {
                $timeout(()=>{
                    var customerId = window.api.customerid;
                    const url = window.api.getResourceUrl(scope.selectedId, convertionFormat, 'encoding=base64');
                    const onSuccess = function(data) {
                    scope.previewImageContent = " #fff url(data:image/jpeg;base64," + data + ") no-repeat 0px 0px";
                    scope.$apply();
                    deffered.resolve(data);
                    }
                    const onFailure = function() {
                    deffered.reject();
                    }

                    window.api.get(url, onSuccess, onFailure);
                },0);
            }
            return deffered.promise;
        };


        this.selectTableElement = function(scope, att) {
            if ((scope.targetList  && scope.targetList.length > 0) ||
                (scope.attachmentList && scope.attachmentList.length > 0) ||
                (scope.enclosureList && scope.enclosureList.length > 0)) {
                if (!att){

                    if (!scope.targetList  && !scope.enclosureList) {
                        att = scope.attachmentList[0];
                    } else if (!scope.attachmentList && !scope.enclosureList) {
                        att = scope.targetList[0];
                    } else {
                       att = scope.enclosureList[0];
                    }
            }



            scope.selectedTableElement = att.id;
            scope.selectedId = att.id;
            self.fillWithData(scope,{
                "resourceid": scope.selectedId
            });
        }
        };

        this.setConversions = function(scope, data) {
            scope.imageList = [];
            for (var key in data.settings.conversion_urls) {
                var tmp = data.settings.conversion_urls[key];
                scope.imageList.push({
                    "title": tmp.description,
                    "filename": tmp.files[0],
                    "type": key
                });
                scope.selectedConvertions.push(key + "");
            }
        };

        this.fillWithData = function(scope, data, newOne,operation) {

            var self = this;
            scope.fitToSize = true;
            var deffered = $q.defer();
            var defferedList = [];
            void 0;
            if (typeof newOne == "undefined")
                newOne = false;

            scope.hasUploadedFile = true;
            if (data.resourceid !== null && typeof data.resourceid !== "undefined") {
                var customerId = window.api.customerid;
                const onSuccess = function(data) {

                  data.created_at = serverTime.utcToLocal(data.created_at);


                  if (data.resource_type === "banner" && !UIDrawUtils.isImageCompatibleWithArea(data.settings,scope.info && scope.info.shape)) {
                    scope.fitToSize = false;
                    modal.info({
                        message: "Cannot use an Banner which size is bigger than the Banner Area",
                        ok: "Ok",
                        cancel: ""
                      },
                      function() {
                        deffered.resolve();
                      }, 
                      function() {}
                    );
                  } else {
                    scope.selectedId = data.resource_id;
                    scope.setConversions(data);
                    if (data.title === "ALL")
                      data.name = "";

                    if (typeof data.settings.preview !== "undefined" && data.settings.preview !== null) {
                      var connectionsUsed;
                      var rulesUsed;
                      if (typeof scope.targetFileData == "undefined") {
                        connectionsUsed = data.settings.connections;
                        rulesUsed = data.settings.rules;
                      } else {
                        if (typeof scope.targetFileData != "undefined" && scope.targetFileData !== null && typeof scope.targetFileData.connections != "undefined" && newOne && operation == "update") {
                          connectionsUsed = data.settings.connections.concat(scope.targetFileData.connections);
                          rulesUsed = data.settings.rules.concat(scope.targetFileData.rules);
                        } else {
                          connectionsUsed = data.settings.connections;
                          rulesUsed = data.settings.rules;
                        }
                      }
                      scope.targetFileData = {
                        "id": data.resource_id,
                        "filename": data.name,
                        "title": data.title,
                        "status": data.status,
                        "description": data.description,
                        "pages": data.settings.target_count,
                        "filesize": data.filesize + "",
                        "editable": data.editable,
                        "operation": operation,
                        "created_by": data.created_by,
                        "isNew": newOne,
                        "inUse": data.connection_count,
                        "match_total": data.match_total,
                        "modified": operation == "update",
                        "connections": connectionsUsed,
                        "rules": rulesUsed,
                        "created_at": data.created_at,
                        "connection_running_count": data.connection_running_count,
                        "tag":data.tag || [],
                      };

                      scope.targetFileData.preview = data.settings.preview;
                      scope.elementBackup = angular.copy(scope.targetFileData);


                      deffered.resolve();
                    } else {
                      var ww, hh;
                      if (typeof data.settings.new_width != "undefined") {
                        ww = data.settings.new_width;
                        hh = data.settings.new_height;
                      } else {
                        ww = data.settings.original_width;
                        hh = data.settings.original_height;
                      }
                      scope.fileData = {
                        "id": data.resource_id,
                        "filename": data.name,
                        "title": data.title,
                        "status": data.status,
                        "description": data.description,
                        "pages": data.settings.pagecount,
                        "filesize": data.filesize + "",
                        "created_by": data.created_by,
                        "editable": data.editable,
                        "operation": operation,
                        "isNew": newOne,
                        "inUse": data.connection_count,
                        "connection_running_count": data.connection_running_count,
                        "match_total": data.match_total,
                        "modified": operation == "update",
                        "connections": data.settings.connections,
                        "settings": data.settings,
                        "created_at": data.created_at,
                        "original_width": ww,
                        "original_height": hh,
                        "tag":data.tag || [],
                      };

                      if(scope.previewMyImage){
                        defferedList.push(scope.previewMyImage(scope.selectedConvertions[0]));
                      }
                      scope.elementBackup = angular.copy(scope.fileData);

                    }
                    deffered.resolve(data);
                    scope.hasUploadedFile = true;
                    scope.$digest();

                  }

                };
                const onFailure = function(data, status) {
                  deffered.reject();
                };
                window.api.resourceGet(data.resourceid, onSuccess, onFailure);
            } 
            defferedList.push(deffered.promise);
            return $q.all(defferedList);
        };


        this.prepareSaveData = function(ob1, ob2, diff) {
            var compared = this.compareObjects(ob1, ob2, diff);
            if (compared.length === 0)
                return null;
            if (compared.length === 1 && compared[0] == "preview")
                return null;
            return compared;
        };

        this.saveAttachment = function(scope,actionType) {
            var deffered = $q.defer();
            var self = this,
                elem,
                title,
                description,
                compare,
                isNew,
                tag,
                dataToSend = {};

            if (scope.fileData) {
                elem = scope.fileData;
            }

            if (scope.targetFileData) {
                elem = scope.targetFileData;
            }

            if (!elem.modified && !isNew) {
                return false;
            }

            title = elem.title;
            tag=elem.tag || [];
            description = elem.description;
            let enclosure_value = elem.filename;
            compare = this.prepareSaveData(elem, scope.elementBackup, true);
            isNew = elem.isNew;


            if (title === "" || typeof title === "undefined" || title === null) {
                modal.info({
                        message: "You cannot save without entering a title",
                        ok: "Ok",
                        cancel: ""
                    },
                    function() {},
                    function() {}
                );
                return false;
            }

            if (description === "" || typeof description === "undefined" || description === null) {
                description = "";
            }

            void 0
            if (compare !== null) {
                for (var i = 0; i < compare.length; i++) {
                    switch (compare[i]) {
                        case "filename":
                            dataToSend.enclosure_value = enclosure_value;
                            break;
                        case "title":
                            dataToSend.resource_title = title;
                            break;
                        case "description":
                            dataToSend.resource_description = description;
                            break;
                        case "tag":
                            dataToSend.tag=tag;
                            break;
                        case "settings":
                            if (typeof elem.settings.dpi !== "undefined") {
                                dataToSend.dpi = elem.settings.dpi;
                                dataToSend.px_h = elem.settings.px_h;
                                dataToSend.px_w = elem.settings.px_w;
                            }

                    } 
                }

                var customerId = window.api.customerid;

                if ((compare.length == 1 && compare[0] == "isNew") || (compare.length == 2 && compare[0] == "isNew" && compare[1] == "preview")) {} else {
                    const onSuccess = function(data) {
                      if (scope.fileData) {
                        scope.elementBackup = angular.copy(scope.fileData);
                      }
                      if (scope.targetFileData) {
                        if(actionType!="fallback"){
                          scope.elementBackup = angular.copy(scope.targetFileData);
                          scope.selectedTableElement = null;
                          scope.blockingLayer = false;
                          self.selectTableElement(scope, scope.targetFileData);
                        }
                        growlHandlingService.success("Your Target List has been successfully saved.");
                        deffered.resolve( scope.targetFileData);
                      } else {

                        if (typeof scope.enclosureList == "undefined") {
                          if(actionType!="fallback"){
                            scope.selectedTableElement = null;
                            scope.blockingLayer = false;
                            self.selectTableElement(scope, scope.fileData);
                          }
                          growlHandlingService.success("Your Attachment has been successfully saved.");
                        } else {
                          if(actionType!="fallback"){
                            scope.selectedTableElement = null;
                            scope.blockingLayer = false;
                            self.selectTableElement(scope, scope.fileData);
                          }
                          growlHandlingService.success("Your Enclosure has been successfully saved.");
                        }
                        deffered.resolve(scope.fileData)
                      } 

                    }
                    window.api.resourcePut(scope.selectedId, dataToSend, onSuccess);
                }
            } 
            return deffered.promise;
        };

        this.deleteAttachment = function(scope) {
            var elem;
            if (scope.targetList) {
                elem = this.getElementById(scope.targetList, scope.selectedId);
            } else if (scope.attachmentList) {
                elem = this.getElementById(scope.attachmentList, scope.selectedId);
            } else {
                elem = this.getElementById(scope.enclosureList, scope.selectedId);
            }

            if (typeof elem === "undefined") {
                return false;
            }

            if (elem.inUse > 0) {
                return false;
            }

            var deleteAction = function() {
                var customerId = window.api.customerid;
                const onSuccess = function(data) {
                  if (data.removed === false) {
                    modal.info({
                        message: "Could not remove the Attachment",
                        ok: "Ok",
                        cancel: ""
                      },
                      function() {
                        scope.blockingLayer = false;
                      },
                      function() {}
                    ); 
                  } 
                  else {
                    self.loadAttachmentList(scope,
                      scope.connectionType,
                      false,
                      scope.with_deleted).then(()=>{
                      scope.hasUploadedFile = false;
                    scope.blockingLayer = false;
                    scope.hasUploadedFile = false;
                    scope.blockingLayer = false;
                    if(scope.with_deleted){
                      self.selectTableElement(scope,{id:scope.selectedId});
                    }else{
                      self.selectTableElement(scope);
                    }

                    growlHandlingService.success("Your file has been successfully removed.");
                  });
                  }
                };
                const onFailure = function() {
                  scope.blockingLayer = false;
                }
                window.api.resourceDelete(scope.selectedId, onSuccess, onFailure);
            };
            modal.confirm({
                    message: "Are you sure you want to delete this file?",
                    ok: "Yes",
                    cancel: "No"
                },
                function() {
                    deleteAction();
                },
                function() {}
            );



        };


        this.unDeleteAttachment = function(scope, elem) {
            if (typeof elem === "undefined") {
                return false;
            }

            if (elem.inUse > 0) {
                return false;
            }

            var unDeleteAction = function() {
                var customerId = window.api.customerid;
                var dataToSend = {
                    id: scope.selectedId,
                    status: "active",
                    resource_title: elem.title,
                    resource_description: elem.description,
                    tag:elem.tag
                }
                const onSuccess = function(data) {
                  scope.loadAtts();
                  scope.hasUploadedFile = false;
                  scope.blockingLayer = false;

                  self.selectTableElement(scope,{id:data.resourceid});
                  growlHandlingService.success("Your file has been successfully undeleted.");
                };
                const onFailure = function() {
                  scope.blockingLayer = false;
                }
                window.api.resourcePut(scope.selectedId, dataToSend, onSuccess, onFailure);
            };

            modal.confirm({
                    message: "Are you sure you want to undelete this file?",
                    ok: "Yes",
                    cancel: "No"
                },
                function() {
                    unDeleteAction();
                },
                function() {}
            );
        };

        this.cancelAttachment = function(scope, data) {

            var elem;
            if (scope.targetList) {
                elem = this.getElementById(scope.targetList, scope.selectedId);
            } else {
                elem = this.getElementById(scope.attachmentList, scope.selectedId);
            }

            if (scope.elementBackup.description !== data.description || scope.elementBackup.title !== data.title) {
                modal.discard({
                        message: "Do you want to discard changes?",
                        ok: "Discard",
                        cancel: "Cancel",
                        save: "Save"
                    },
                    function() {
                        scope.hasUploadedFile = false;
                    },
                    function() {
                        scope.blockingLayer = true;
                    },
                    function() {
                        scope.saveAttachment();
                    }
                );
            } else {
                scope.hasUploadedFile = false;
            }
        };

        this.handleStatus = function(status, data, scope) {
            errorHandling.handleResponse(status, data, scope);
        };

        this.resourceFilter = function(item, query) {

            if (typeof query == "undefined" || query === "")
                return true;
            if (((new RegExp(buildSearchPattern(RegExp.escape(query)), "ig")).test((item.description !== null ? item.description : "") + " " + item.title + " " + item.name + " " + item.created_by))) {
                return true;
            }
            return false;
        };

        function buildSearchPattern(inputSearchText) {
            var patternText = "";
            inputSearchText.split(/\s/g).forEach(
                function preparePattern(value) {
                    patternText += "(?=.*" + value + ".*)";
                }
            );
            patternText += ".+";
            return patternText;
        }

        RegExp.escape = (function(text) {
            var specials = ["$", "^", "/", ".", "*", "+", "?", "|", "(", ")", "[", "]", "{", "}", "\\"];
            return text.replace(new RegExp("(\\" + specials.join("|\\") + ")", "g"), "\\$1");
        });

    }
]);
