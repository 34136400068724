module.controller("newUserModalController", [
  "$uibModalInstance",
  "$scope",
  "userManageService",
  "user",
  "header",
  "validationPatternService",
  "growlHandlingService",
  "modal",
  function(
    modalInstance,
    $scope,
    userManageService,
    _user,
    header,
    validationPattern,
    growl,
    modal
  ) {
    function _init() {
      $scope.userGroupsVM = [];
      $scope.isModelChanged=false;
      $scope.selectedGroup={name:"designer"};
      $scope.header = header;
      $scope.showConfirmationEmail = !_user.id ? true : false;
      $scope.emailPattern = validationPattern.getPatternByName("email").RegExp;
      $scope.user =angular.copy(_user);
      userManageService.getUserGroupDict().then(userGroupsDict => {
        void 0
        $scope.userGroupsVM=userGroupsDict.group;
        if(_user.id){
            $scope.selectedGroup={name:_user.group[0]};
            $scope.selectedGroupOriginal=angular.copy($scope.selectedGroup);
        }
      });

      $scope.$watch("user",()=>$scope.isModelChanged=_isModelChanged(),true);
      $scope.$watch("selectedGroup",(activeGroup)=>{
        $scope.isModelChanged=_isModelChanged()
        if($scope.isModelChanged){
          $scope.user.group=[];
          $scope.user.group.push(activeGroup.name);
        }
      },true);

    }

    _init();
    $scope.closeDialog = function() {
      modalInstance.close();
    };

    function _isModelChanged(){
      return (!angular.equals($scope.user, _user) || !angular.equals($scope.selectedGroup, $scope.selectedGroupOriginal));
    }

    $scope.saveUser = function(user) {
      if (!$scope.userForm.$invalid) {
        collectUserGroups();
        if (user.id) {
          userManageService.updateUser(user).then(() =>{
            $scope.closeDialog()
            growl.success(`User '${user.username}' has been updated`)
          });
        } else {
          userManageService.addUser(user).then(() =>{
            $scope.closeDialog()
            growl.success(`User '${user.username}' has been created`)
            modal.info({
              message:`An activation email has been sent to user email address: ${user.email}`,
              ok: "Ok",
              cancel: ""
            });
          });
        }
      }
    };

    $scope.toggleEmailChange = function(isChangeMode) {
      $scope.showConfirmationEmail = isChangeMode;
    };

    function collectUserGroups() {
      $scope.user.inactive_groups=[];
      $scope.user.active_groups=[];
      if(_user.id && $scope.selectedGroupOriginal && ($scope.selectedGroupOriginal.name!==$scope.selectedGroup.name)){
        $scope.user.inactive_groups.push($scope.selectedGroupOriginal.name);
        $scope.user.active_groups.push($scope.selectedGroup.name);
      }else if(!_user.id){
        $scope.user.active_groups.push($scope.selectedGroup.name);
      }
    }
  }
]);
