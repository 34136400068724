ocCommonProviders.service("validationPatternService", function () {
    const validationPatterns={
        email:{
            RegExp:/[^@ ]@[^@].[^@]+$/i,
            errMsg:"Invalid Email Address."
        }
    };

        this.getPatternByName=function(patternName){
        return validationPatterns[patternName];
    }
});