ocCommonProviders.directive("ruleDetailDirective", ["$rootScope", "db", "login", "$window", "$compile", "tagsService", function($rootScope, db, login, $window, $compile, tagsService) {
    "use strict";
    return {
        restrict: "C",
        scope: {
          rule: '='
        },
        link: function(scope, element, attrs) {
            var rule = scope.rule;

            function detailsDom(event) {
                var details = angular.element("<div class=\"detailsContainer\"></div>");
                var data = angular.element("<div class=\"fl dataContainer\"></div>");

                var elem2 = angular.element("<div>Summary: " + rule.html_value + "</div><br/>");
                data.append(elem2);

                if(rule.active_connection !== null ){
                  var connections = rule.active_connection;
                  var elem3 = angular.element("<div>Attached to connections:</div>");
                  var elem4 = angular.element('<div>'+connections+'</div>');
                  data.append(elem3);
                  data.append(elem4);
                }
                if(rule.child_rule_names !== "") {
                  var elem3 = angular.element("<div>Embedded rules:</div>");
                  var elem4 = angular.element('<div>'+rule.child_rule_names+'</div>');
                  data.append(elem3);
                  data.append(elem4);
                }
                if(rule.parent_rule_names !== "") {
                  var elem3 = angular.element("<div>Used in rules:</div>");
                  var elem4 = angular.element('<div>'+rule.parent_rule_names+'</div>');
                  data.append(elem3);
                  data.append(elem4);
                }

                angular.element(element).parent().parent().find(".detailsContainer").remove();
                details.append(data);
                element.parent().parent().append(details);

                var top, left;
                if (event.pageX + details.width() < $window.innerWidth )
                    left = event.pageX + 2 + "px";
                else {
                    left = $window.innerWidth - details.width() + "px";
                }

                if (event.pageY + details.height() < $window.outerHeight - 300 ){
                    top = event.pageY + "px";
                }else {
                    top = event.pageY - details.height() + "px";
                }

                details.css({
                    "position": "absolute",
                    "top": top,
                    "left": left,
                    "zIndex": "210"
                });

                angular.element(element).parent().parent().find(".detailsContainer").remove();
                element.parent().parent().append(details);

            }

            element.bind("mouseover", function(e) {
                detailsDom(e);
                e.stopPropagation();
            });

            element.bind("mouseout", function() {
                angular.element(element).parent().parent().find(".detailsContainer").remove();
            });


        }
    };

}]);
