ocCommonProviders.service("tagsService", ["api", "db", "login", function(api, db, login) {
    "use strict";

    this.get = _getTag;
    this.set = _setTag;
    this.search = _search;
    this.fixTextcontrast = _fixTextcontrast;
    this.convertTagsToArray = _convertTagsToArray;
    this.filterFC = _filterFC;
    this.filterConnectionFC = _filterConnectionFC;
    this.getFCRe = _getFcRe;
    this.getFcReg = _getFcReg;

    var fcReg = new RegExp("fc_[\w. A-Za-z0-9_]{1,90}");
    var fcRE = /fc_([\w. A-Za-z0-9_]{1,90})/;

    function _getFcReg() {
        return fcReg;
    }

    function _getFcRe() {
        return fcRE;
    }

    function _fixTextcontrast(color) {
        var re = /rgba\(([0-9]{1,3}),([0-9]{1,3}),([0-9]{1,3}),([0-9.,]{1,4})\)/;
        var rgba = re.exec(color);
        if (rgba === null || rgba.length < 1)
            return "white";
        var R = rgba[1],
            G = rgba[2],
            B = rgba[3];
        var luminosity = (parseInt(R) + parseInt(G) + parseInt(B)) / 3;
        if (luminosity > 220)
            return "black";
        return "white";
    }

    function _getTag(success) {
        const onSuccess = (data) => {
          if(success) success(data.tag);
        }
        return window.api.tagGetAll(onSuccess);
    }

    function _setTag(name, type) {
        type = type || connection
        var customerId = window.api.customerid;
        const payload = {
            "name": name,
            "type": type,
        }
        return window.api.tagPost(payload);
    }

    function _search() {}

    function _filterFC(tags, flag) {
        var result = [];
        var reg = fcReg;
        var re = fcRE;

        for (var i = 0; i < tags.length; i++) {
            var str = "" + tags[i].value;
            if (flag && reg.test(str)) {
                var name = re.exec(tags[i].value);
                result.push(name[1]);
            }
            if (!flag && !reg.test(str))
                result.push(tags[i].value);
        }
        return result;
    }

    function _filterConnectionFC(tags, flag) {
        var result = [];
        var reg = fcReg;
        var re = fcRE;

        for (var i = 0; i < tags.length; i++) {
            var str = "";
            if (typeof tags[i].valueOriginal !== "undefined")
                str = "" + tags[i].valueOriginal;
            else
                str = "" + tags[i].value;
            if (flag && reg.test(str)) {
                if (typeof tags[i].valueOriginal !== "undefined") {
                    continue;
                }

                tags[i].valueOriginal = tags[i].value;
                var name = re.exec(tags[i].value);
                tags[i].value = name[1];
                result.push(tags[i]);
            }
            if (!flag && !reg.test(str))
                result.push(tags[i]);
        }
        return result;
    }

    function _convertTagsToArray(tags) {
        var result = [];
        for (var i = 0; i < tags.length; i++) {
            result.push(tags[i].value);
        }
        return result;
    }

}]);
