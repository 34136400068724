ocCommonProviders.service("uiDrawService", ["ckeditorParserService","uiDrawUtilsService","utilityService",function(CkeditorParser,DrawUtils,Utility) {
 const self=this; 
 const _CONFIG_DEFAULT={
    rectFill:(rect)=>rect.fill || "rgba(255,255,255,1)",
    rectStroke:(rect)=>rect.stroke || "rgba(236,102,8,1.0)",
    rectWidth:(rect)=>rect.width,
    rectHeight:(rect)=>rect.height,
    rectScaleX:1.274,
    rectScaleY:1.274,
 }

 const _CONFIG_GLOBAL={
   scale:{
       textBoxPreview:1.274,
   },
 }

  const _SVG=(width,height,html)=>`<svg xmlns="http://www.w3.org/2000/svg" 
 width='${width}px' height='${height}px'>
 <foreignObject width="100%" height="100%">${html}</foreignObject>
 </svg>`;

 this.configGlobal=_CONFIG_GLOBAL;

 this.context ={
    init:_init,
    drawRectangle: _drawRectangle,
    getSvg:_getSVG,
    getObjectUrl:_getObjectUrl,
    addImgFromUrl:_addImgFromUrl,
    addImgFromUrlWithDca:_addImgFromUrlWithDca,
  };

  this.getSVGTemplate=_getSVGTamplate;

    function _init(id,type){
    if(type==="static"){
      return (config)=>{
        this.canvas = new fabric.StaticCanvas(id);
        this.canvas.setWidth(config.width);
        this.canvas.setHeight(config.height);
        return self.context;
      }
    }else{
        this.canvas = new fabric.Canvas(id);
        return self.context;
    }  
  }

    function _getSVGTamplate(width,height,html){
    return _SVG(width,height,html);
  }

  function _drawRectangle(rect) {
    const rectangle = new fabric.Rect({
      left: rect.x,
      top: rect.y,
      width: _CONFIG_DEFAULT.rectWidth(rect),
      height: _CONFIG_DEFAULT.rectHeight(rect),
      fill: _CONFIG_DEFAULT.rectFill(rect) ,
      stroke: _CONFIG_DEFAULT.rectStroke(rect),
      strokeWidth: 1,
      scaleX:_CONFIG_DEFAULT.rectScaleX,
      scaleY:_CONFIG_DEFAULT.rectScaleY
    });

    this.canvas.add(rectangle);
    this.canvas.renderAll();
  }

  function _getSVG(element,svgArea){
    if (Utility.checkBrowserIsIE()) {
        Utility.disableSVG(self._canvas, self._scale);
      return;
    }
      var textHtml = CkeditorParser.parseVariable(element);
      var width = (svgArea.width || 210)*3.9;
      var height = (svgArea.height || 297)*3.45;
      var parsedBody = angular.element(document.createElement("body"));
      parsedBody.attr("style", "font-size:initial;");
      parsedBody.append(textHtml);
      parsedBody.appendTo(document.body);
      var el = parsedBody[0];
      var html = new XMLSerializer().serializeToString(el);
      var tmpHeight = height + 50;
      var body =_SVG(width,tmpHeight,html);
      var svg = new Blob([body], {type: "image/svg+xml"});
      parsedBody.remove();
      return svg;
  }


  function _getObjectUrl(obj){
    const DOMURL = window.URL || window.webkitURL || window;
    return DOMURL.createObjectURL(obj)
  }

  function _addImgFromUrlWithDca(url,drawContext){
   var _self=this;
   return new fabric.Image.fromURL(url, function(oImg) {     
      var offsetTop,offsetLeft;
      offsetLeft=drawContext.shape.x;
      offsetTop=drawContext.shape.y;

      const size=DrawUtils.calculateImageSize(drawContext.shape,oImg.getOriginalSize(),drawContext.scale,drawContext.settings);
      oImg.hasBorders = false;
      oImg.hasControls = false;
      _drawRectangle.call(_self,Object.assign(drawContext.shape,{fill:drawContext.color}));

        if(drawContext.vertical_align){
        offsetTop=DrawUtils.getOffsetTop(drawContext.vertical_align,size, drawContext.shape,_CONFIG_DEFAULT.rectScaleX)
      }

      if(drawContext.horizontal_align){
        offsetLeft=DrawUtils.getOffsetLeft(drawContext.horizontal_align,size, drawContext.shape,_CONFIG_DEFAULT.rectScaleX)
      } 

      oImg.set({
        left: offsetLeft+1,
        top: offsetTop+1,
        width: Math.ceil(size.width),
        height: size.height-2,
        scaleX:_CONFIG_DEFAULT.rectScaleX,
        scaleY:_CONFIG_DEFAULT.rectScaleY,
      });

         _self.canvas.add(oImg);
      _self.canvas.renderAll();
    });
  }

  function _addImgFromUrl(url,settings){
    var _self=this;
    return new fabric.Image.fromURL(url, function(oImg) {
       oImg.hasBorders = false;
       oImg.hasControls = false;
       oImg.set({
         left:settings.left+1,
         top: settings.top+1,
         height:settings.height,
         width:Math.ceil(settings.width),
         scaleX: settings.scale.x,
         scaleY: settings.scale.y
       });
       _self.canvas.add(oImg);
       _self.canvas.renderAll();
     });
  }

}]);
