ocCommonProviders.service("subscription", ["$q", "$rootScope", "login", "subscriptionModel", function($q, $rootScope, login, subscriptionModel) {
    "use strict";
    var source = null;
    var _reconnectAttempt = 0;
    var _customerId = null;
    var _SUBSCRIBE_TIMEOUT = 10000;
    var _onClose = function(e) {
        _reconnectAttempt += 1;
        source = null;
        void 0;
        subscriptionModel.setConnected(false);
        setTimeout(_connect, _SUBSCRIBE_TIMEOUT);
        if(_reconnectAttempt > 6) {
          window.location.reload();
        }
    };
    var _onOpen = function(e) {
        void 0;
        _reconnectAttempt = 0;
        subscriptionModel.setConnected(true);
    };
    var _onMessage = function(e) {
        var msg = e.data;
        if(msg.type == "system") {
            if(msg.operation == "pushid") {
                subscriptionModel.setPushId(msg.data);
            } else if(msg.operation == "auth") {
                if(msg.data.status == false) {
                    window.location.reload();
                }
            }
        } else {
            if(msg.type == 'array') {
                for(var i = 0;i<msg.data.length;i++) {
                    var m = msg.data[i];
                    m.who = msg.who;
                    subscriptionModel.update(m);
                }
            } else {
                subscriptionModel.update(msg);
            }
        }
    };
    var _connect = function() {
        void 0;
        source = new SockJS(config.backendUrl +
            '/subscribe?token=' + login.sessionToken + '&customerid=' + _customerId, null, {noCredentials:true});
        source.onopen = _onOpen;
        source.onmessage = _onMessage;
        source.onclose = _onClose;
    }
    return {
        subscribe: function(id) {
            if(id != _customerId) {
                _customerId = id;
                if (source == null) {
                    _connect();
                } else {
                    source.send(JSON.stringify({operation:'subscribe', customerid:id, token:login.sessionToken}));
                }
            }
        }
    }
}]);
