ocCommonProviders.service("userService", [
  "db",
  "subscription",
  function(db, subscription) {
    "use strict";
    var userData,
      adminData,
      ual,
      basicUserAccessList = {
        targetListInMenu: false,
        bannersInMenu: false,
        targetListInConnection: false,
        enclosuresInMenu: false,
        textBannersInMenu:false,
        helpPage: "basic"
      },
      basicPlusUserAccessList = {
        targetListInMenu: true,
        bannersInMenu: false,
        targetListInConnection: true,
        enclosuresInMenu: false,
        textBannersInMenu:false,
        helpPage: "basic-plus"
      },
      advancedUserAccessList = {
        targetListInMenu: true,
        bannersInMenu: true,
        targetListInConnection: true,
        enclosuresInMenu: false,
        textBannersInMenu:true,
        helpPage: "premium"
      };

    this.setUser = _setUser;
    this.setAdmin = _setAdmin;
    this.isAdmin = _isAdmin;
    this.isEnclosureEnabled = _isEnclosureEnabled;
    this.getUser = _getUser;
    this.getUAL = _getUAL;
    this.getUserLevel = _getUserLevel;
    this.isUserFirstTimeLogin = _isUserFirstTimeLogin;
    this.getUserGroups=_getUserGroups;
    this.isGroupMember=_isGroupMember;
    this.hasAccessToResource=_hasAccessToResource;

    function _setUser(data) {
      if (db.get("login", "enclosure_enabled")) {
        advancedUserAccessList = {
          targetListInMenu: true,
          bannersInMenu: true,
          targetListInConnection: true,
          enclosuresInMenu: true,
          textBannersInMenu:true,
          helpPage: "premium"
        };
      }
      userData = data;
      subscription.subscribe(data.id || data.customerid);

      switch (data.application_level) {
        case "basic":
          ual = basicUserAccessList;
          break;
        case "basic-plus":
          ual = basicPlusUserAccessList;
          break;
        case "premium":
          ual = advancedUserAccessList;
          break;
        default:
          ual = basicUserAccessList;
          break;
      }
    }

    function _setAdmin(data) {
      if (db.get("login", "enclosure_enabled")) {
        advancedUserAccessList = {
          targetListInMenu: true,
          bannersInMenu: true,
          targetListInConnection: true,
          enclosuresInMenu: true,
          helpPage: "premium"
        };
      }
      adminData = data;
    }

    function _isAdmin() {
      if (typeof adminData !== "undefined") return adminData.admin_user;
      return false;
    }

    function _getUserGroups(){
      if(_isAdmin()) return 'admin';
      if(angular.isDefined(userData)) return userData.group;
      return [];
    }

    function _isGroupMember(...params){
      let dataToCheck=(adminData || userData);
      if(!params || !dataToCheck) return;
        for(var i=0 ;i<params.length;i++){
          if(dataToCheck.group.some((groupItem)=>groupItem===params[i])){
            return true;
          }
        }
      return false;
    }

    function _hasAccessToResource(access){
      if(!access || angular.isDefined(adminData) || _isGroupMember('approver', 'power')){
        return true;
      }else{
        let premissionData=userData.permission[window.api.projectid];
        if(premissionData){
          return premissionData.some((accessValue)=>accessValue===access.value);
        }
        return false;
      }
    }

    function _isEnclosureEnabled() {
      if (typeof adminData !== "undefined") return true;

      return db.get("login", "enclosure_enabled");
    }

    function _getUser() {
      return userData;
    }

    function _getUAL() {
      return ual;
    }

    function _getUserLevel() {
      return userData.application_level;
    }

    function _isUserFirstTimeLogin() {
      let status=localStorage.getItem("isFirstTimeLogin");
      if (!status) {
        localStorage.setItem("isFirstTimeLogin",false);
        return true;
      }
       return JSON.parse(status);
    }
  }
]);
