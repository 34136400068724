ocCommonProviders.directive("timelineDetailDirective", ["$rootScope", "db", "login", "$window","tagsService","uiDrawService","$filter",
    function($rootScope, db, login, $window,tagsService,uiDraw,$filter) {
        "use strict";
        return {
            restrict: "C",
            link: function(scope, element, attrs) {

                function detailsDom(event) {
                    details = angular.element("<div class=\"detailsContainer\"></div>");
                    var data = angular.element("<div class=\"fl dataContainer\"></div>");

                    var connectionName;
                    if (information.title.length > 25) {
                        connectionName = information.title.substr(0, 25) + "...";
                    } else {
                        connectionName = information.title;
                    }
                    var elem = angular.element("<div><span><span class=\"capital\">" + connectionName + "</span></span></div>");
                    var to;
                    if (information.valid_to_utc !== null)
                        to = moment.utc(information.valid_to_utc).local().format("YYYY-MM-DD");
                    else
                        to = "<span class=\"infin\">&infin;</span>";

                    var elem2 = angular.element("<div>" + moment.utc(information.valid_from_utc).local().format("YYYY-MM-DD") + " - " + to + "</div>");
                    var elem3 = angular.element("<div>Status: " + information.status + "</div><br/>");

                    var ruleName;
                    if (information.rule_name !== null && information.rule_name.length > 25) {
                        ruleName = information.rule_name.substr(0, 25) + "...";
                    } else {
                        if (information.rule_name === null) {
                            ruleName = "-";
                        } else {
                            ruleName = information.rule_name;
                        }
                    }

                    information.summary = " - ";
                    for (var i = 0; i < rulesList.length; i++) {
                        if (information.rule_id === rulesList[i].id) {
                            information.summary = rulesList[i].html_value;
                        }
                    }
                    var elem4b = angular.element("<div>Rule: " + ruleName + "</div>");
                    var elem4c = angular.element("<div>Rule Summary: " + information.summary + "</div><br/>");

                    var materialName;
                    if (information.content_title && (information.content_title.length > 25)) {
                        materialName = information.content_title.substr(0, 25) + "...";
                    } else {
                        materialName = information.content_title;
                    }

                    var elem4a;
                    if (information.banner_area_id === null)
                        elem4a = angular.element("<div>Attachment: " + materialName + "</div>");
                    else
                        elem4a = angular.element("<div>Banner: " + materialName + "</div>");

                    var elem6 = angular.element("<div>Created by: " + information.created_by + "</div>");
                    var elem7 = angular.element("<div>Created at: " + moment.utc(information.created_at).local().format("YYYY-MM-DD") + "</div><br/>");
                    var elem8 = angular.element("<div>Sent: " + information.sent_total + "</div>");
                    var elem9 = angular.element("<div>Priority: " + information.priority + "</div>");
                    var elem10 = angular.element("<div class=\"bootstrap-tagsinput\" style=\"padding:0px;\"></div>");

                    for (var i = 0; i < information.tag.length; i++) {
                        information.tag[i].text = tagsService.fixTextcontrast(information.tag[i].color);
                        elem10.append(angular.element("<span class=\"tag label label-info\" style=\"background:" + information.tag[i].color + ";color:" + information.tag[i].text + "\">" + information.tag[i].value + "</span>")); 
                    }

                    data.append(elem);
                    data.append(elem2);
                    data.append(elem3);
                    data.append(elem4a);
                    data.append(elem4b);
                    data.append(elem4c);
                    data.append(elem6);
                    data.append(elem7);
                    data.append(elem8);
                    data.append(elem9);
                    data.append(elem10);
                    angular.element(element).parent().parent().parent().parent().parent().parent().parent().find(".detailsContainer").remove();
                    element.parent().parent().parent().parent().parent().parent().parent().append(details);

                    var top, left;
                    if (event.pageX + details.width() < $window.innerWidth)
                        left = event.pageX + 2 + "px";
                    else {
                        left = $window.innerWidth - details.width() + "px";
                    }

                    if (event.pageY + details.height() < $window.outerHeight) {
                        top = event.pageY + "px";
                    } else {
                        top = event.pageY - details.height() + "px";
                    }

                    details.css({
                        "position": "absolute",
                        "top": top,
                        "left": left,
                        "zIndex": "210"
                    });

                    if (information.resource_type === "textbox") {
                        void 0
                        var _textDetails = function() {
                            const imgContainer = angular.element("<canvas width='270' height='379' id=\"textBoxCanvas_" + information.id +"\"class=\"fl timelinePreviewDetailsContainer\"></div>");
                            const dca=scope.info.filter((i)=>i.id===information.banner_area_id)[0];
                            details.append(imgContainer);
                            details.append(data)
                            const context = uiDraw.context.init(`textBoxCanvas_${information.id}`);
                            const svg=context.getSvg(information.textbox_value,dca.shape);
                            const svgUrl=context.getObjectUrl(svg);
                            context.addImgFromUrlWithDca(svgUrl,{shape:dca.shape,color:information.background_color,scale:1});
                        }
                        var customerId = window.api.customerid;
                        if(information.textbox_value == null) {
                            const onSuccess = function(data) {
                              information.textbox_value = data.settings.text;
                              information.background_color=data.settings.background_color;
                              _textDetails();
                            }
                            window.api.resourceGet(information.content_id, onSuccess);
                        } else {
                            _textDetails()
                        }


                    } else {
                        var url = window.api.getResourceUrl(information.content_id, 'thumbnail');
                        if (information.resource_type === "enclosure")
                            url = "/static/img/Enclosure.svg";

                        var img = angular.element("<img alt=\"preview\" src =\" " + url + "\" />");
                        var imgContainer = angular.element("<div class=\"fl timelinePreviewDetailsContainer\"></div>");
                        imgContainer.append(img);

                        details.append(data);
                        details.append(imgContainer);
                    }
                }

                var details = null;
                var information;
                var connections;
                var groups;
                var rulesList;
                if (scope.$parent.$parent !== null) {
                    connections = scope.$parent.$parent.connectionsList;
                    groups = scope.$parent.$parent.groups;
                    rulesList = scope.$parent.$parent.rulesDetailsList;
                }
                var el = angular.element(element);
                var selectedImage = null;

                var idi;
                var id;
                var dca;
                var regex = /[\w ]conId_([\d]{1,9})/;
                idi = el[0].className + "";
                id = idi.match(regex)[1];
                if (scope.$parent.$parent !== null) {
                    for (var i = 0; i < connections.length; i++) {
                        if (connections[i].id == id) {
                            information = connections[i];
                            break
                        }
                    }
                }
                if(information != null) {
                    element.off("click");
                    element.bind("click", function(e) {
                        if (scope.$parent.$parent !== null) {
                            scope.$parent.$parent.activePartial = 0;
                            scope.$parent.$parent.$apply();
                            $rootScope.$broadcast("OPENCONNECTION", information);
                        }
                    });


                    $(element).mouseenter(function(e){
                        detailsDom(e);
                        angular.element(element).parent().parent().parent().parent().parent().parent().parent().find(".detailsContainer").mouseleave(function(e){
                            this.remove();
                        });
                    }).mouseleave(function(e) {
                        if(e.relatedTarget.className!="detailsContainer"){
                            angular.element(element).parent().parent().parent().parent().parent().parent().parent().find(".detailsContainer").remove();
                        }
                    });




                } else {
                }


            }
        };

    }
]);
