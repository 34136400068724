ocCommonProviders.component("uiSorting", {
  templateUrl: "/static/partials/uiSorting.html",
  bindings: {
    header: "@",
    className: "@",
    sortBy: "@",
    group: "@",
    name:"@"
  },
  controller: [
    "uiSortingService",
    function(Sorting) {
      this.$onInit = () => {
        this.state = {
          direction: Sorting.getSortingState(this.name,this.group)
        };

        Sorting.subscribe(this.name,this.group,(name,direction)=>{
          if(this.name===name){
            this.state.direction=direction;
          }
        });
        Sorting.doSorting(this.group, this.sortBy)(this.state.direction);
      };

      this.doSorting = () => {
        Sorting.updateSortingState(this.name,this.group)
        Sorting.doSorting(this.group, this.sortBy)(this.state.direction);
      };
    }
  ]
});
