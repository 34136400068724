ocCommonProviders.service("textBoxDrawService", [
  "db",
  "login",
  "$q",
  "$rootScope",
  "api",
  "modal",
  "utilityService",
  "subscriptionModel",
  "regionFactory",
  "imgRegionFactory",
  "ckeditorService",
  function(
    db,
    login,
    $q,
    $rootScope,
    api,
    modal,
    utilityService,
    subscriptionModel,
    region,
    imgRegion,
    textEditor
  ) {
    "use strict";
    var self = this;
    var _dcaColor={value:""}
    this.dcaColor=_dcaColor;
    this.init = _init;
    this.fillWithData = _fillWithData;
    this.textValue = _textValue;
    this.selectChangeHandler = _selectChangeHandler;
    this.checkIsValid = _checkIsValid;
    this.refresh=_refresh;
    this.getCanvas=()=>this._canvas;

    this._dcaList = [];
    this._selectedDCA = null;
    this._selectedView = null;
    this._selectedImage = null;
    this._backgroundImage = null;
    this._scale = 1.275;
    this._isValid = true;

    function _checkIsValid() {
      return self._isValid;
    }

    function _init() {
      var deferred = $q.defer();
      this._canvas = new fabric.StaticCanvas("textViewCanvas");
      self._canvas.setHeight(380);
      self._canvas.setWidth(270);
      self._canvas.clear();

      var imgInstance = new fabric.Rect({
        left: 0,
        top: 0,
        height: 297,
        width: 210,
        opacity: 1.0,
        scaleX: 1.275,
        scaleY: 1.275,
        fill: ""
      });

      imgInstance.set({
        strokeWidth: 1,
        stroke: "rgba(170,170,170,1.0)"
      });

      self._canvas.add(imgInstance);
      deferred.resolve();

      return deferred.promise;
    }

    function _textValue(value) {
      if (self._selectedDCA) {
        loadSVGFabric(value, self._selectedDCA.shape);
      }
    }

    function _fillWithData(projectId) {
      var deffered = $q.defer();
      const onSuccess = function(data) {
        self._dcaList = data.banner_areas;
        if (data.banner_areas.length > 0) {
          self._selectedDCA = data.banner_areas[0];
        }
        deffered.resolve(data);
      }
      const onFailure = function() {
        handleStatus(status, data, scope);
        deffered.reject(data);
      }
      window.api.bannerAreaGetAll('', onSuccess, onFailure);
      return deffered.promise;
    }

    function _selectChangeHandler(idx, scope) {
      if (self._selectedView) {
        self._canvas.remove(self._selectedView);
      }
      if (self._selectedImage) {
        self._canvas.remove(self._selectedImage);
      }
      if (self._backgroundImage) {
        self._canvas.remove(self._backgroundImage);
      }

      self._selectedDCA = self._dcaList[idx];
      _redrawPreview(self._selectedDCA, scope);


    }

    function _loadImageToDom(data) {
      var deffered = $q.defer();
      const image = new Image();
      image.addEventListener("load", function() {
        deffered.resolve(image);
      });
      image.addEventListener("error", function() {
        deffered.reject(image);
      });
      image.src="data:image/jpeg;base64,"+data;
      return deffered.promise;
    }

    function _loadBackgroundImage(image) {
      var deffered = $q.defer();
      var canvas = self._canvas;
      canvas.clear();
      canvas.setHeight(380);
      canvas.setWidth(270);
      imgRegion.Image=image;
      imgRegion.ScaleX=imgRegion.ScaleY=0.4;
      var imgRegionInstance=imgRegion.Instance;
      imgRegionInstance.set({
        strokeWidth: 1,
        stroke: "rgba(170,170,170,1.0)"
      });

      imgRegionInstance.selectable = false;
      self._backgroundImage = imgRegionInstance;
      imgRegionInstance.width=canvas.width-1;
      imgRegionInstance.height=canvas.height-1;
      canvas.add(imgRegionInstance);

      canvas.renderAll();
      deffered.resolve(canvas);

      return deffered.promise;
    }

    function _redrawPreview(data, scope) {
      if(data.background_id) {
        const url = window.api.getResourceUrl(data.background_id, 'background', 'encoding=base64');
        const onSuccess = function(img) {
          _loadImageToDom(img).then(function(image) {
            _loadBackgroundImage(image,data).then(function(canvas) {
              _refresh(scope);
            });
          });
        }
        window.api.get(url, onSuccess);
      }

      self._canvas.clear();
      var previewArea = new fabric.Rect({
        left: 0,
        top: 0,
        height: 297,
        width: 210,
        opacity: 1.0,
        scaleX: 1.275,
        scaleY: 1.275,
        fill: ""
      });

      previewArea.set({
        strokeWidth: 1,
        stroke: "rgba(170,170,170,1.0)"
      });

      self._canvas.add(previewArea);
    }

    function _refresh(scope) {
      _redrawDCA(true);
      scope.refreshPreview();
    }

    function _redrawDCA(fitToSize) {
      if(!self._selectedDCA){
        return;
      }

      if (self._selectedView){
        self._canvas.remove(self._selectedView)
      }

      region.ScaleX= region.ScaleY=self._scale=self._scale;
      region.Shape=self._selectedDCA.shape;
      var bannerAreaInstance=region.Instance;

      if (fitToSize) {
        bannerAreaInstance.set({
          strokeWidth: 1.5,
          stroke: "rgba(134,183,224,1.0)",
          fill:_dcaColor.value
        });
      } else {
        bannerAreaInstance.set({
          strokeWidth: 1,
          stroke: "rgba(240, 0, 0,1)",
          fill:_dca.value
        });
      }

      self._selectedView =bannerAreaInstance;
      self._canvas.add(self._selectedView);
      self._canvas.renderAll();
    }

    function loadSVGFabric(element, shape) {
      void 0
      if (utilityService.checkBrowserIsIE()) {
        utilityService.disableSVG(self._canvas, self._scale);
        return;
      }

      if (!element.length) return;
      var textHtml = "";
      var eles = $(element).find("variable");
      for (var i = 0; i < eles.length; i++) {
        $(eles[i]).attr("contenteditable", true);
      }

      var filter = new CKEDITOR.htmlParser.filter({
        elements: {
          variable: function(el) {
            if (el.attributes.test) {
              el.attributes.test=el.attributes.test.replace(/ /g, '&nbsp;')
              el.setHtml(
                el
                  .getHtml()
                  .replace(
                    "{" + el.attributes.original + "}",
                    "<span style='white-space:pre-wrap;'>"+el.attributes.test+"</span>"
                  )
              );
            }
          },
          span: function(el) {

            let variables = subscriptionModel.get("variable").data;
            let linkList = el.find("a");

            if (linkList.length > 0) {
              for (var lindex in linkList) {
                for (var vindex in variables) {
                 linkList[lindex].setHtml(linkList[lindex].getHtml().replace("{"+variables[vindex].value+"}",variables[vindex].example));
                }
              }
            }
          },

          table:function(el){
            const isFirefox =navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
            if(isFirefox){
              var jQuerEl=$(el.getOuterHtml());
              var border=el.attributes.border;
              if(border==="1"){
                jQuerEl.find("td").css("box-shadow", "inset 0px 0px 0px 1px rgba(0,0,0,1)");
              }else if(border==="2"){
                jQuerEl.find("td").css("box-shadow", "inset  0px 0px 0px 2px rgba(0,0,0,1)");
              }else if(border==="3"){
                jQuerEl.find("td").css("box-shadow", "inset  0px 0px 0px 1px rgba(0,0,0,1)");
              }

              el.setHtml(jQuerEl.html());
            }
          }

        }
      });

      var fragment = CKEDITOR.htmlParser.fragment.fromHtml(
          element[0].innerHTML
        ),


      writer = new CKEDITOR.htmlParser.basicWriter();
      filter.applyTo(fragment);
      fragment.writeHtml(writer);
      textHtml = writer.getHtml();
      var parsedBody = $(document.createElement("body"));
      var style =`height:${shape.height}px;width:${Math.ceil(shape.width*3.38)}px;white-space:pre;`;
      parsedBody.attr("style", style);
      parsedBody.append(textHtml);

      parsedBody.appendTo(document.body);
      var el = parsedBody[0];

      var elCopy = angular.copy(el);
      $(elCopy).find("a").css("background-color", "transparent");
      $(elCopy).find(".variable_content").css("background-color", "transparent");

      var html = new XMLSerializer().serializeToString(elCopy);

      parsedBody.remove();
      self._isValid = true;
      var tmpScaleY;
      if (utilityService.checkBrowserIsChrome()) {
        tmpScaleY = self._scale + 0.32;
      } else {
        tmpScaleY = self._scale + 0.15;
      }
      var width = Math.ceil((shape.width || 210) * 3.7);
      var height = (shape.height || 297) * 4.45;
      var tmpHeight = height + 80 * (shape.height / 297);
      var body =`<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${tmpHeight}"><foreignObject width="100%" height="100%">${html}</foreignObject></svg>`;

      var imgUrl = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(body);

      new fabric.Image.fromURL(imgUrl, function(oImg) {
        if (self._selectedImage) {
          self._canvas.remove(self._selectedImage);
        }
        oImg.hasBorders = false;
        oImg.hasControls = false;

        oImg.set({
          left: shape.x,
          top: shape.y*self._scale,
          scaleX: self._scale,
          scaleY: tmpScaleY,
          width: Math.ceil(shape.width+(shape.width*0.079)),
          height: shape.height
        });
        self._selectedImage = oImg;
        self._canvas.add(self._selectedImage);
      });
    }
  }
]);
