ocCommonProviders.service("ckeditorParserService",function () {
    this.parseVariable=_parseVariable;

        function _parseVariable(element){
        const parsedElement = element.replace(/contenteditable="false"/g,'contenteditable="true"');
        var textHtml = "";
        var filter = new CKEDITOR.htmlParser.filter({
          elements: {
            variable: function(element) {
              if (element.attributes.test) {
                element.setHtml(element.getHtml().replace("{" + element.attributes.original + "}",element.attributes.test));
              }
            }
          }
        });

            var fragment = CKEDITOR.htmlParser.fragment.fromHtml(parsedElement);
        const writer = new CKEDITOR.htmlParser.basicWriter();

            filter.applyTo(fragment);
        fragment.writeHtml(writer);
        textHtml = writer.getHtml();

        return textHtml
    }
});