ocCommonProviders.service("uploadService", ["$q", "$rootScope", "modal", function($q, $rootScope, modal) {
    "use strict";
    var userData;
    this.createUpload = _createUpload;

    function _createUpload(scope, attrs, uploadUrl, formDom, labelDom, inputDom, method) {
        var deffered = $q.defer();
        var error = function(data, status) {
            $rootScope.$emit("LOADOFF");
            modal.info({
                    message: data.msg,
                    ok: "Ok",
                    cancel: ""
                },
                function() {
                    deffered.reject(data);
                },
                null
            );
        };
        var success = function(data) {
            $rootScope.$emit("LOADOFF");
            scope.fillWithData(data, true, attrs.operation);
            if (scope.isBackground) {
                for (var i = 0; i < scope.pages.length; i++) {
                    if (scope.pages[i].id === scope.selectedPage) {
                        scope.pages[i].background_id = data.resourceid;
                    }
                }
            }
            deffered.resolve(data);
        };
        formDom.on("submit", function(e) {
            $rootScope.$emit("LOADON");
            e.preventDefault();
            var formData = new FormData(this);
            var fileUploadInput = inputDom;
            formData.append("file", fileUploadInput);
            formData.append("resource_type", attrs.type);
            const onSuccess = function(data) {
              if (attrs.isSuccess) {
                $rootScope.$emit("LOADOFF");
                scope.$eval(attrs.success);
                deffered.resolve();
              } else {
                success(data);
              }
            }
            window.api.formData(method, uploadUrl, formData, onSuccess, error);
        });
        return deffered.promise;
    }
}]);
