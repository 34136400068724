ocCommonDictionaries.constant("menu", {
  subMenu: [
    {
      name: "attachments",
      title: "Attachments",
      link: "/attachments"
    },
    {
      name: "targetLists",
      title: "Target Lists",
      link: "/target-lists"
    },
    {
      name: "banners",
      title: "Banners"
    },
    {
      name: "enclosures",
      title: "Enclosures",
      link: "/enclosures"
    }
  ],
  subMenuBanner: [
    {
      name: "area",
      title: "Area",
      link: "/dca"
    },
    {
      name: "image",
      title: "Image",
      link: "/banners"
    },
    {
      name: "text",
      title: "Text",
      link: "/text-boxes"
    }
  ],
  subMenuAdministration: [
    {
      name: "configuration",
      title: "Configuration",
      link: "/administration"
    },
    {
      name: "maintence",
      title: "Maintenance",
      link: "/maintenance"
    }
  ],
  subMenuHelp: [
    {
      name: "quickStart",
      title: "Quick Start"
    },
    {
      name: "instructions",
      title: "Instructions",
      link: "/help"
    }
  ],
  subMenuSettings: [
    {
      name: "configuration",
      title: "Configuration",
      link: "/configuration"
    },
    {
      name: "user",
      title: "Users",
      link: "/users"
    },
    {
      name: "user",
      title: "Page",
      link: "/page"
    }
  ],
  topMenuItems: [
    {
      name: "administration",
      title: "Administration",
      link: "/administration"
    },
    {
      name: "connections",
      title: "Connections",
      link: "/connections"
    },
    {
      name: "rules",
      title: "Rules",
      link: "/rules"
    },
    {
      name: "materials",
      title: "Materials"
    },
    {
      name: "attachments",
      title: "Attachments",
      link: "/attachments"
    },
    {
      name: "statistics",
      title: "Statistics",
      link: "/statistics"
    },
    {
      name: "preview",
      title: "Preview",
      link: "/preview"
    },
    {
      name: "log",
      title: "Log",
      link: "/history"
    },
    {
      name: "settings",
      title: "Settings",
      link: "/settings"
    },
    {
      name: "help",
      title: "Help",
      link: "/help"
    }
  ]
});
