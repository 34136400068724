ocCommonProviders.service("errorHandlingService", ["modal", function( modal) {
    "use strict";
    this.handleResponse = _handleResponse;

    function _handleResponse(status, data, scope){
        if(typeof data !=="undefined"){
            switch(data.code){
                case 12337:
                    scope.clearBlockLayers();
                    break;
                case 12336:
                    scope.clearBlockLayers();
                    scope.closeAllConnectionsTabs();
                    scope.loadConnectionList();
                    break;
                default:
                    break;
            }
         }

        switch(status){
            case 400 :
               break;
            case 405 :
               break;
            case 500 :
               modal.info({
                    message: data.msg +"Error Code:"+data.code,
                    ok: "Ok",
                    cancel: ""
                },
                function() {},
                function() {}
	              );
            break;
            case 401:
                window.location = "/";
            break;
            default:
                void 0;
        }
    }


}]);
