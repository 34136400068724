ocCommonProviders.directive("textboxListContainer",["attachmentTargetList",function (attachmentTargetList) {
    "use strict";
    return {
    restrict: "C",
    templateUrl:"/static/partials/textboxListContainer.html",
    link: function(scope, element, attrs){
      scope.attachmentSearchCallback=attachmentTargetList.resourceFilter;
      scope.selectResource=function(item){
        scope.selection=item;
      }
    }
  };
}]);
