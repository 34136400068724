ocCommonProviders.service("variablesService", [
  "db",
  "login",
  "errorHandlingService",
  "$timeout",
  "userService",
  "$q",
  "flowService",
  "api",
  "modal",
  "subscriptionModel",
  "uiFilterService",
  function (
    db,
    login,
    errorHandling,
    $timeout,
    userService,
    $q,
    flowService,
    api,
    modal,
    subscriptionModel,
    uiFilter
  ) {
    "use strict";
    this.getVariables = _getVariables;
    this.getFilters=_getFilters;
    this.getAllVariables = _getAllVariables;
    this.setVariable = _setVariable;
    this.removeVariable = _removeVariable;
    this.updateVariable = _updateVariable;
    this.spawnModal = _spawnModal;
    this.searchInVariables = _searchInVariables;
    this.replaceToValue = _replaceToValue;
    this.variableTransform=_variableTransform;
    const resourceTypeDict={
      "textbox":"Text Banner",
      "connection":"Connection"
    }

    function _variableTransform(variable){
     if(angular.isDefined(variable.is_used_in)) return variable;
      let isUsedIn = [];
      if (angular.isDefined(variable.connection)) {
        variable.connection.forEach((cnn) => {
          isUsedIn.push({
            resource_name: cnn.title,
            resource_type:resourceTypeDict["connection"],
            value: cnn.value
          })
        });

      }

      if (angular.isDefined(variable.resource)) {
        variable.resource.forEach((res) => {
          isUsedIn.push({
            resource_name: res.resource_title,
            resource_type:resourceTypeDict[res.resource_type],
            value: res.value
          })
        });
      }

      if(isUsedIn.length>0){
        variable["is_used_in"]=angular.copy(isUsedIn);
      }else{
        variable["is_used_in"]=[];
      }

    return variable;
  }

    function _getVariables(filter_example) {
      var state = subscriptionModel.get("variable");
      var deferred = $q.defer();
      if (state.initialized) {
        deferred.resolve(state.data);
      } else {
        const onSuccess = function (data) {

          data.variable.map((item) => {
            let isUsedIn = [];
          if (angular.isDefined(item.connection)) {
            item.connection.forEach((cnn) => {
              isUsedIn.push({
              resource_name: cnn.connection_title,
              resource_type:resourceTypeDict["connection"],
              value: cnn.value
            })
          });

          }

          if (angular.isDefined(item.resource)) {
            item.resource.forEach((res) => {
              isUsedIn.push({
              resource_name: res.resource_title,
              resource_type:resourceTypeDict[res.resource_type],
              value: res.value
            })
          });
          }

          if(isUsedIn.length>0){
            item["is_used_in"]=isUsedIn;
          }
        });
          subscriptionModel.set("variable", data.variable, true);
          deferred.resolve(data.variable);
        }
        const onFailure = function (data) {
          subscriptionModel.set("variable", [], false);
          deferred.reject(data);
        }
        window.api.variableGetAll(`filter_example=${filter_example}`, onSuccess, onFailure);
      }
      return deferred.promise;
    }

    function _getAllVariables() {
      var deferred = $q.defer();
      const onSuccess = function(data) {
        deferred.resolve(data.variable);
      }
      const onFailure = function() {
        deferred.reject();
      }
      window.api.variableGetCustomer(onSuccess, onFailure);
      return deferred.promise;
    }

    function _setVariable(variable, success) {
      var flowId = window.api.projectid;
      var customerId = window.api.customerid;

      var dataToSend = {
        value: variable.value,
        description: variable.description,
        type: variable.type,
        example: variable.example
      };
      return window.api.variablePost(dataToSend, success);
    }

    function _updateVariable(variable, success) {

      var dataToSend = {
        value: variable.value,
        description: variable.description,
        type: variable.type,
        example: variable.example
      };
      return window.api.variablePut(variable.id, dataToSend, success);
    }

    function _removeVariable(variable, success) {
      return window.api.variableDelete(variable.id, success);
    }

    function _spawnModal(msg) {
      return modal.info(
        {
          message: msg,
          ok: "Ok"
        },
        function () { },
        function () { }
      );
    }

    function _searchInVariables(value, searchValue) {
      if (value.id < 0) return true;

      if (
        new RegExp(buildSearchPattern(RegExp.escape(searchValue)), "ig").test(
          value.value + " " + value.type + " " + value.description
        )
      ) {
        return true;
      }

      return false;

      function buildSearchPattern(inputSearchText) {
        var patternText = "";
        inputSearchText.split(/\s/g).forEach(function preparePattern(value) {
          patternText += "(?=.*" + value + ".*)";
        });
        patternText += ".+";
        return patternText;
      }
    }

    function _replaceToValue(innerText, variableDict) {
      var curlyRe = new RegExp(/\{([^}]+)\}/g);
      var variableFromText = null;
      var variablesFromText = [];
      if (!innerText) return;

      while ((variableFromText = curlyRe.exec(innerText))) {
        variablesFromText.push(variableFromText[1]);
      }

      variablesFromText.forEach(function (item) {
        innerText = innerText.replace(
          "{" + item + "}",
          variableDict.filter(function (variable) {
            return variable.value === item;
          })[0].example
        );
      });
      return innerText;
    }

    function _getFilters(onFilterCallback,collection){
      return uiFilter.define("variablesFilterGroup",collection,{
            id: "variablesFilterSearch",
            name: "search",
            className: "variables_filter__search",
            onChangeHandler: function(variables,query){
              var fromValue = item =>item.value.toUpperCase().indexOf(query.toUpperCase()) != -1;
              var fromType = item => item.type.toUpperCase().indexOf(query.toUpperCase()) != -1;
              var fromDescription = item => item.description.toUpperCase().indexOf(query.toUpperCase()) != -1;
              var result = variables.filter(item => fromValue(item) || fromType(item) || fromDescription(item));
              return result;
            }
      },onFilterCallback)
  }
  }
]);
