module.filter("sortConnectionGroups", function() {
  "use strict";
  return function(groups) {
    if(angular.isUndefined(groups)) return [];
    var partitionLeft = [];
    var partitionRight = [];

    for (var i=0; i<groups.length;i++) {
      if ((groups[i].name === "Enclosures") || (groups[i].name === "Attachments")) {
        partitionRight.push(groups[i]);
      } else {
        partitionLeft.push(groups[i]);
      }
    }
    return partitionLeft.concat(partitionRight.sort((group1, group2)=>{
      if(group1.name < group2.name) return -1;
      if(group1.name > group2.name) return 1;
      return 0;
  }));
}});
