ocCommonProviders.directive("resizable", ["$window",function($window) {
    "use strict";
    return function($scope) {
        $scope.initializeWindowSize = function() {
            $scope.windowHeight = $window.innerHeight;
            $scope.windowWidth = $window.innerWidth;
            return $scope.windowWidth;
        };
        $scope.initializeWindowSize();
        var scope = $scope;
        return angular.element($window).bind("resize", function() {
            scope.initializeWindowSize();
            return scope.$apply();
        });
    };
}]);

ocCommonProviders.directive("loadingOverlay", ["$window",function($window) {
    "use strict";
    return {
        restrict: "C",
        scope: {
            loadIndicator: "="
        },

        template: "",
        link: function(scope, element, attrs) {
            scope.initializeWindowSize = function() {
                scope.windowHeight = $window.innerHeight;
                $scope.windowWidth = $window.innerWidth;
                return $scope.windowWidth;
            };
            scope.$watch(attrs.loadIndicator, function(value) {
                scope.windowWidth = $window.innerWidth;
                scope.windowHeight = $window.innerHeight;
                angular.element($window).bind("resize", function() {
                    scope.$apply();
                });
            });
        }
    };
}]);

ocCommonProviders.directive("stateLoadingIndicator", ["$rootScope", "$window", "$interval", "utilityService", "$timeout",
    function($rootScope, $window, $interval, utilityService, $timeout) {
        "use strict";
        return {
            restrict: "C",
            template: "<div ng-show=\"isStateLoading\" class=\"loading-indicator\">" +
                "<div class=\"loading-indicator-background\" ng-style=\"{width:maxWidth, height:maxHeight}\"></div>" +
                "<div class=\"middle\" ng-style=\"{width:maxWidth, height:maxHeight}\">" +
                "<div class=\"spinner\"><img src=\"/static/img/ajax-loader.svg\" /></div>" +
                "<h3 class=\"loading-title\">Loading...</h3>" +
                "</div>" +
                "</div>",
            replace: true,
            link: function(scope, elem, attrs) {
                scope.scrollListener = false;
                scope.isStateLoading = false;

                $rootScope.$on("LOADON", function(event, data) {
                    scope.isStateLoading = true;
                    if (data) {
                        resize(true);
                    } else {
                        resize();
                    }
                });

                $rootScope.$on("LOADOFF", function() {
                    var interval = $interval(function() {
                        scope.isStateLoading = false;
                    }, 2, 1);

                });

                var resizeIntervalCounter = 2;

                function resInt() {
                    var resizeInterval = setInterval(function() {
                        resize(true);
                        if (resizeIntervalCounter < 0) {
                            clearInterval(resizeInterval);
                            resizeInterval = null;
                            resizeIntervalCounter = 2;
                        }
                        resizeIntervalCounter--;
                    }, 502);
                }

                var resize = function(second) {
                    scope.maxWidth = utilityService.getDocWidth();
                    scope.maxHeight = utilityService.getDocHeight();

                    if (second) {
                        $timeout(function() {
                            scope.$apply();
                        });
                    } else
                        addScrollTrigger();
                };

                angular.element($window).bind("resize", function() {
                    resize(true);
                });

                function addScrollTrigger() {
                    $window.onscroll = function() {
                        $timeout(function() {
                            resize(true);
                            $window.onscroll = false;
                        });
                    };
                }

                addScrollTrigger();
                resize();
            }
        };
    }
]);
