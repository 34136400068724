ocCommonProviders.service("inputSearchService", ["$rootScope",function($rootScope) {
  var self = this;
  this.settings = _settings;
  this.doCustomFilters = _doCustomFilters;
  this.updateCollectionState=_updateCollectionState;
  this.updateFilterState=_updateFilterState;
  this.getCollectionOrginal=_getCollectionOrginal;
  this.collectionScope=[];

  function _settings(objSettings) {
    self.searchSettings = objSettings;
  }

  function _doCustomFilters() {
    _doBasicFilters();
    _doAdditionalFilters()
  }

  function _doBasicFilters(){
    for (let filter of self.searchSettings.basicFilters) {
      _updateCollectionState(filter.actionHandler());
    }
  }

  function _doAdditionalFilters(){
    for (let filter of self.searchSettings.additionalFilters) {
      _updateCollectionState(filter.actionHandler());
    }
  }

  function _updateCollectionState(state){
    if(!self.searchSettings.collectionStateOrginal){
      self.searchSettings.collectionState=state;
      self.searchSettings.collectionStateOrginal=angular.copy(self.searchSettings.collectionState);
    }else{
      self.searchSettings.collectionState=state;
      $rootScope.$emit("SEARCH");
    }
  }

  function _updateFilterState(params){
    var filter=self.searchSettings.basicFilters.filter((filter)=>filter.name===params.name)[0];
    filter.state=params.state;
  }

  function _getCollectionOrginal(){
    return self.searchSettings.collectionStateOrginal;
  }
}]);
