ocCommonProviders.directive("administrationEditCustomer",["$uibModal","$window", function ($uibModal,$window) {
    "use strict";
    return {
    restrict: "C",
    templateUrl:"customerEditElement",
    link: function(scope, element, attrs){
        scope.resize(true);
    }

  };
}]);
