module.controller("administrationController", [
  "$rootScope",
  "$scope",
  "$timeout",
  "$interval",
  "login",
  "db",
  "api",
  "adminService",
  "$window",
  "modal",
  "bannerList",
  "pagesListService",
  "$q",
  "flowService",
  "utilityService",
  "fontService",
  "subscriptionModel",
  "menuService",
  "$uibModal",
  "resourceAccessService",
  function (
    $rootScope,
    $scope,
    $timeout,
    $interval,
    login,
    db,
    api,
    adminService,
    $window,
    modal,
    bannerList,
    pagesListService,
    $q,
    flowService,
    utilityService,
    fontService,
    subscriptionModel,
    menuService,
    $modal,
    resourceAccess
  ) {


    $rootScope.$broadcast("CHANGE_TAB", "Administration");
    $scope.openedFlow = -1;
    $scope.editedFlow = -1;
    $scope.flow_preloading = true;
    $scope.addingFlow = false;
    $scope.flowStringSearch = "";
    $scope.flowStatusSearch = "All";
    $scope.customerStatusSearch = "All";
    $scope.customerStringSearch = "";
    $scope.editedCustomer = -1;
    $scope.addingCustomer = false;
    $scope.blockingLayer = false;
    $scope.blockingLayerHigher = false;
    $scope.application_level = ["basic", "basic-plus", "premium"];
    $scope.selectedCustomer = 0;
    $scope.selectedFreeArea = -1;
    $scope.editedPage = -1;
    $scope.addingPage = false;
    $scope.selectedPage = -1;
    $scope.editedFreeArea = -1;
    $scope.addingFreeArea = false;
    $scope.pages = [];
    $scope.freeAreas = [];
    $scope.selectedConvertions = ["thumbnail"];
    $scope.activeView = "flow";
    $scope.tempFreeAreaElement = [];
    $scope.flowList = [];
    $scope.fontList = [];

    $scope._projectmodel = subscriptionModel.get("project");
    $scope._customermodel = subscriptionModel.get("customer");
    $scope._fontmodel = subscriptionModel.get("font");


 const _getFlowWithMaxId=()=>{
    var maxId = 0;
    $scope.flowList.forEach((flow) => {
      maxId = Math.max(flow.id, maxId);
    });
    return maxId;
  }

  $timeout(()=>{
    $scope.selectedFlow = window.api.projectid;
  },100);

  $scope.$watch("flowList.length", (newValue, oldValue) => {
      if ((window.api.projectid===-1) && newValue>0) {
          if ($scope.flowList.length > 0) {
            $timeout(()=>{
              $scope.selectFlow(_getFlowWithMaxId());
            },100);
          }
      }
    });
    $onInit();
    function $onInit() {
      initSearchCache();
    }

    function initSearchCache() {

      let flowSearchCache = db.get("administration", "flowStringSearch") || "";
      let customerSearchCache = db.get("administration", "customerStringSearch") || "";
      $scope.flowStringSearch = flowSearchCache;
      $scope.customerStringSearch = customerSearchCache;

      if (!db.isNamespace("administration")) {
        db.createNamespace("administration");
      }
      $scope.$watch('flowStringSearch', function (newValue, oldValue) {
        if (newValue != oldValue) {
          db.set("administration", "flowStringSearch", newValue);
        }
      });

      $scope.$watch('customerStringSearch', function (newValue, oldValue) {
        if (newValue != oldValue) {
          db.set("administration", "customerStringSearch", newValue);
        }
      });
    }

    var _subscribeProject = function (data) {
      if (data.data && $scope.editedFlow == data.data.id) {
        $scope.cancelEditFlow();
      }
      adminService.loadCustomerFlow($scope);
      $scope.$apply();
    };

    var _subscribeCustomer = function (data) {
      if (data.data && $scope.editedCustomer == data.data.id) {
        $scope.cancelEditCustomer();
      }
      $scope._customermodel = subscriptionModel.get("customer");
      $scope.customerList = $scope._customermodel.data;
      $scope.$apply();
    };

    var _subscribeFont = function (data) {
      $scope._fontmodel = subscriptionModel.get("font");
      $scope.fontList = $scope._fontmodel.data;
      $scope.$apply();
    };

    var _subscribePage = function (data) {
      if (data.data && $scope.editedPage == data.data.id) {
        $scope.cancelEditPage();
      }
      if ((data.operation !== "priority") && (data.operation !== "delete")) {

        $scope._projectmodel = subscriptionModel.get("project");
        $scope.pages = $scope._projectmodel.data[data.id].pages;
        let page = $scope.pages.filter((page) => page.id === data.data.id)[0];
        let backgroundId;
        if (page.hasOwnProperty("background_id")) {
          backgroundId = page.background_id;
        }
        $scope.selectPage(page.id, backgroundId);
        $timeout(() => {
          $scope.updateScroll(page.id);
        }, 0);
      }
      $scope.$apply();
    };

    function viewCustomerAdd() {
      $scope.addCustomer();
      $scope.$apply();
    }

    function selectCustomer(id) {
      $scope.selectCustomer(id);
      $scope.$apply();
    }

    function editCustomer(id) {
      $scope.editCustomer(id);
      $scope.$apply();
    }

    subscriptionModel.on("project", _subscribeProject);
    subscriptionModel.on("customer", _subscribeCustomer);
    subscriptionModel.on("admin_page", _subscribePage);
    subscriptionModel.on("font", _subscribeFont);
    subscriptionModel.on("view.customer.add", viewCustomerAdd);
    subscriptionModel.on('view.customer.click', selectCustomer);
    subscriptionModel.on('view.customer.edit.click', editCustomer);

    $scope.$on("$destroy", function () {
      subscriptionModel.off("view.customer.add", viewCustomerAdd);
      subscriptionModel.off('view.customer.click', selectCustomer);
      subscriptionModel.off('view.customer.edit.click', editCustomer);
      subscriptionModel.off("project", _subscribeProject);
      subscriptionModel.off("customer", _subscribeCustomer);
      subscriptionModel.off("admin_page", _subscribePage);
      subscriptionModel.off("font", _subscribeFont);
    });

    $scope.parseNameToInt = function (obj) {
      return parseInt(obj.name);
    };

    $scope.isBackground = true;


    var resize = function (second) {
      var maxWidth = utilityService.getDocWidth();
      var maxHeight = utilityService.getDocHeight();
      var bl = angular.element(".blockingLayerAdmin");
      var blh = angular.element(".blockingLayerAdminHigher");
      blh.css({
        width: maxWidth + "px",
        height: maxHeight + "px"
      });
      bl.css({
        width: maxWidth + "px",
        height: maxHeight + "px"
      });
    };

    $scope.resize = resize;

    angular.element($window).bind("resize", function () {
      resize(true);
    });

    resize();

    $scope.getPageById = function (id) {
      for (var i = 0; i < $scope.pages.length; i++) {
        if ($scope.pages[i].id == id) return $scope.pages[i];
      }
    };
    $scope.addFlow = function () {
      if ($scope.selectedCustomer >= 0) {
        $scope.blockingLayer = true;
        $scope.addingFlow = true;
        $scope.openedFlow = -1;
        $scope.editedFlow = -1;
        $scope.selectFlow(-1);
        $scope.tempElement = [
          {
            name: "",
            description: "",
            limit: "",
            conversion: "thumbnail",
            id: -1,
            active: true,
            pages_count: 0
          }
        ];
        $scope.pages = [];
      }
    };

    $scope.openFlow = function (id, flow) {
      if (!$scope.blockingLayer) {
        window.api.setProject(id);
        $scope.openedFlow = id;
        $scope.fileData = {};
        $scope.previewImageContent = "white";
        $scope.pages = [];
        $scope.freeAreas = [];
        pagesListService.loadPages(id).then($scope.applyPagesData);
        $scope.blockingLayer = true;
        $timeout(resize, 0);
      }
    };

    $scope.applyPagesData = function (pages) {
      $scope.pages = pages;
      if ($scope.pages.length > 0) {
        $scope.selectPage($scope.pages[0].id, $scope.pages[0].background_id);
      } else {
        $scope.selectedPage = -1;
      }
    };

    $scope.isBlankPage = function (selectedPage) {
      const result = $scope.pages.filter((page) => page.id === selectedPage)[0];
      return result && result.additional_page;
    }

    $scope.closeFlow = function (flow, count) {
      flow.pages_count = count;
      $scope.openedFlow = -1;
      $scope.blockingLayer = false;
      delete $rootScope.canvas;
    };

    $scope.selectFlow = function (id) {
      window.api.setProject(id);
      $scope.selectedFlow = id;
    };

    $scope.copyOfFlows = {};
    $scope.editFlow = function (id, name) {
      if (!$scope.blockingLayer) {
        $scope.blockingLayer = true;
        resize(true);
        $scope.openedFlow = -1;
        $scope.editedFlow = id;
        $scope.selectFlow(id);
        $scope.copyOfFlowName = name;
        pagesListService.loadPages(id).then($scope.applyPagesData);
        $scope.fileData = {};
        $scope.copyOfFlows = angular.copy($scope.flowList);
      }
    };

    $scope.cancelEditFlow =(id) =>{
      if (id >= 0) {
        $scope.flowList = angular.copy($scope.copyOfFlows);
      }else{
        $scope.selectFlow(_getFlowWithMaxId());
      }
      _exitFromEditMode();
    };

    function _exitFromEditMode() {
      $scope.blockingLayer = false;
      $scope.editedFlow = -1;
      $scope.addingFlow = false;
    }

    $scope.addCustomer = function () {
      $scope.selectedCustomer = -1;
      $scope.flowList = [];
      $scope.fontList = [];
      $scope.addingCustomer = true;
      $scope.blockingLayer = true;
      $scope.editedCustomer = -1;
      $scope.original_application_level = "";
      $scope.tempCustomerElement = [
        {
          name: "",
          level: "",
          id: -1,
          active: true
        }
      ];
    };

    $scope.selectCustomer = function (id) {
      $scope.selectedCustomer = id;
      $scope.flowList = [];
      $scope.fontList = [];
      subscriptionModel.auth.customerid = id;
      $scope.login.setAdminCustomerId();
      db.set("login", "activeProjectIndex", 0);
      $scope.flow_preloading = true;
      resourceAccess.loadAccessList($scope.selectedCustomer);
    };

    function _loadFonts() {
      fontService.getCustomerFonts($scope.selectedCustomer).then(
        function (font) {
          for (var i = 0; i < font.length; i++) {
            font[i].url =
              "/customer/" +
              $scope.selectedCustomer +
              "/font/" +
              font[i].id +
              "/bin?Coyote-Authentication-Token=" +
              login.sessionToken;
          }
          $scope.fontList = font;
          $scope.flow_preloading = false;
        },
        function () {
          $scope.flow_preloading = false;
        }
      );
    }


    $scope.deleteFont = function (fontId) {
      fontService
        .deleteFontById($scope.selectedCustomer, fontId)
        .then(function () {
          for (var i = 0; i < $scope.fontList.length; i++) {
            if ($scope.fontList[i].id === fontId) {
              $scope.fontList[i].status = "deleted";
              break;
            }
          }
        });
    };

    $scope.unDeleteFont = function (fontId) {
      fontService
        .unDeleteFontById($scope.selectedCustomer, fontId)
        .then(function () {
          for (var i = 0; i < $scope.fontList.length; i++) {
            if ($scope.fontList[i].id === fontId) {
              $scope.fontList[i].status = "active";
              break;
            }
          }
        });
    };

    $scope.uploadFontSuccess = function () {
      _loadFonts();
    };

    $scope.checkLevel = function (newValue, oldValue, id) {
      function discardChange(id, value) {
        for (var i = 0; i < $scope.customerList.length; i++) {
          if ($scope.customerList[i].id === id) {
            $scope.customerList[i].application_level = value;
          }
        }
      }

      if (
        oldValue == "premium" ||
        (oldValue == "basic-plus" && newValue == "basic")
      ) {
        modal.info(
          {
            message: "Unsupported operation",
            ok: "Ok",
            cancel: ""
          },
          function () {
            discardChange(id, oldValue);
          },
          function () { }
        );
      }
    };

    $scope.copyOfCustomer = {};
    $scope.original_application_level = null;

    $scope.editCustomer = function (id) {
      resize(true);
      $scope.editedCustomer = id;
      $scope.blockingLayer = true;
      $scope.copyOfCustomer = angular.copy($scope.customerList);
      for (var i = 0; i < $scope.customerList.length; i++) {
        if ($scope.customerList[i].id === id) {
          $scope.original_application_level =
            $scope.customerList[i].application_level;
        }
      }
    };

    $scope.cancelEditCustomer = function (id) {
      $scope.blockingLayer = false;
      $scope.editedCustomer = -1;
      $scope.addingCustomer = false;
      if (id >= 0) {
        $scope.customerList = angular.copy($scope.copyOfCustomer);
      }
    };

    $scope._modifyCustomer = function (name, application_level, active, id) {
      adminService
        .editCustomer($scope, name, application_level, active, id)
        .then(
          function () {
            subscriptionModel.auth.customerid = id;
            $scope.login.setAdminCustomerId();
            $rootScope.$emit("LOADOFF");
          },
          function () {
            $rootScope.$emit("LOADOFF");
          }
        );
    };

    $scope.saveCustomer = function (name, application_level, active, id) {
      $rootScope.$emit("LOADON");
      if (id > 0) {
        var list = $scope.copyOfCustomer;
        var customer = null;
        for (var i = 0; i < list.length; i++) {
          if (list[i].id == id) {
            customer = angular.copy(list[i]);
            break;
          }
        }

        if (customer && customer.active != active && customer.connection_running_count > 0) {
          modal.confirm(
            {
              message: 'Customer has connections in "running" state. Proceed?',
              ok: "Yes",
              cancel: "No"
            },
            function () {
              $scope._modifyCustomer(name, application_level, active, id);
            },
            function () {
              $rootScope.$emit("LOADOFF");
            }
          );
        } else {
          $scope._modifyCustomer(name, application_level, active, id);
        }
      } else {
        adminService.addCustomer($scope, name, application_level, active).then(
          function (data) {
            if ($scope.customerList.length == 0) {
              subscriptionModel.get("customer").initialized = false;
              adminService.loadCustomers($scope);
            }
            subscriptionModel.auth.customerid = data.id;
            $scope.login.setAdminCustomerId();
            $rootScope.$emit("LOADOFF");
          },
          function () {
            $rootScope.$emit("LOADOFF");
          }
        );
      }
    };

    $scope.saveFlow = function (
      name,
      description,
      attachment_limit,
      enclosure_limit,
      active,
      customerId,
      projectId
    ) {
      if (projectId > 0) {
        var _flow = null;
        for (var i = 0; i < $scope.copyOfFlows.length; i++) {
          if ($scope.copyOfFlows[i].id == $scope.editedFlow) {
            _flow = $scope.copyOfFlows[i];
            break;
          }
        }
        if (_flow && _flow.active != active && _flow.connection_running_count > 0) {
          modal.confirm(
            {
              message:
                'Flow contains connections in "running" state. Proceed?',
              ok: "Yes",
              cancel: "No"
            },
            function () {
              adminService.editFlow(
                $scope,
                name,
                description,
                attachment_limit,
                enclosure_limit,
                active,
                customerId,
                projectId
              ).then(() => {
                _exitFromEditMode();
              })
            },
            function () {
              $rootScope.$emit("LOADOFF");
            }
          );
        } else {
          adminService.editFlow(
            $scope,
            name,
            description,
            attachment_limit,
            enclosure_limit,
            active,
            customerId,
            projectId
          ).then(() => {
            _exitFromEditMode()
          })
        }
      } else {
        adminService
          .addFlow(
            $scope,
            name,
            description,
            attachment_limit,
            enclosure_limit,
            active,
            customerId
          )
          .then(function (data) {
            $scope.selectFlow(data.projectid);
          });
      }
    };
    $scope.copyFlow = function (flow) {
      $modal.open({
        templateUrl: "/static/partials/flowCopyModal.html",
        controller: "flowCopyModalController",
        backdrop: true,
        windowClass: "flow-copy-modal",
        resolve: { flow: () => flow, copyFlowCallback: () => adminService.copyFlow, onClose:()=>(data,fclose)=>{
          $timeout(()=>{
            $scope.selectFlow(data.projectid);
            fclose(data);
          })

        }}

      });

    }

    $scope.copyOfPages = {};
    $scope.editPage = function (id, backgroundId) {
      $scope.blockingLayerHigher = true;
      $scope.editedPage = id;
      $scope.selectPage(id, backgroundId);
      $scope.copyOfPages = angular.copy($scope.pages);
      $(".jspContainer").unbind("mousewheel");
      $(".jspContainer").bind("mousewheel", function (event) { return false; });
    };

    $scope.cancelEditPage = function (id) {
      $scope.blockingLayerHigher = false;
      $scope.editedPage = -1;
      $scope.addingPage = false;
      $(".jspContainer").unbind("mousewheel");
      $(".jspContainer").bind("mousewheel", function (event) { return true; });
    };

    $scope.savePage = function (name, width, height, id) {
      if (id > 0) {
        adminService.editPage($scope, name, width, height, id);
      } else {
        adminService.addPage($scope, name, width, height);
      }
    };

    $scope.deletePage = function (id) {
      if (id >= 0)
        adminService.deletePage($scope, id).then(function (pages) {
          $timeout(() => {
            $scope.fileData = {};
            $scope.previewImageContent = "white";
            $scope.freeAreas = [];
            $scope.pages = pages;
            var data = {};
            adminService.fillWithData($scope, data);
          }, 0);
        });
    };

    $scope.selectPage = function (id, backgroundId) {
      var page = $scope.pages.filter((page) => page.id === id);
      $scope.fileData = {};
      $scope.previewImageContent = "white";
      var data = {};
      data.resourceid = backgroundId;

      if (!$rootScope.canvas) {
        $rootScope.canvas = new fabric.Canvas("bannerCanvas");
      } else {
        $rootScope.canvas.clear();
      }
      $scope.selectedFreeArea = -1;

      $scope.selectedPage = id;
      for (var i = 0; i < $scope.pages.length; i++) {
        if ($scope.pages[i].id == id) {
          $scope.freeAreas = $scope.pages[i].free_areas;
          break;
        }
      }
      adminService.fillWithData($scope, data);
      $scope.refreshPreview();
    };

    $scope.addPage = function () {

      $scope.addingPage = true;
      $scope.blockingLayerHigher = true;
      $scope.editedPage = -1;
      $scope.selectedPage = -1;
      $scope.previewImageContent = "";
      $scope.freeAreas = [];
      $scope.fileData = {};

      $scope.pages[0] && $scope.updateScroll($scope.pages[0].id);
      if (!$rootScope.canvas) {
        $rootScope.canvas = new fabric.Canvas("bannerCanvas");
      } else {
        $rootScope.canvas.clear();
      }
      $scope.tempPageElement = [
        {
          id: -1,
          name: "",
          width: "210",
          height: "297"
        }
      ];


      $scope.refreshPreview();
    };

    $scope.addFreeArea = function () {
      if ($scope.selectedPage >= 0) {
        var e = $("#freeAreaScroll")
          .jScrollPane()
          .data("jsp");
        e.scrollToY(0);
        $scope.addingFreeArea = true;
        $scope.blockingLayerHigher = true;
        $scope.editedFreeArea = -1;
        $scope.selectedFreeArea = -1;
        $scope.tempFreeAreaElement = [
          {
            id: -1,
            shape: {
              width: 10.0,
              height: 10.0,
              x: 10.0,
              y: 10.0
            }
          }
        ];
        $scope.convertToDecimal($scope.tempFreeAreaElement[0]);
        $scope.refreshPreview();
      }
    };

    $scope.copyOfFreeAreas = {};
    $scope.editFreeArea = function (id) {
      $scope.blockingLayerHigher = true;
      $scope.selectedFreeArea = id;
      $scope.editedFreeArea = id;
      $scope.copyOfFreeAreas = angular.copy($scope.freeAreas);
      $scope.refreshPreview();
    };

    $scope.cancelEditFreeArea = function (id) {
      if (id > 0) {
        $scope.freeAreas = angular.copy($scope.copyOfFreeAreas);
      }
      $scope.blockingLayerHigher = false;
      $scope.editedFreeArea = -1;
      $scope.addingFreeArea = false;
      $scope.tempFreeAreaElement = [];
      $scope.refreshPreview();
    };

    $scope.saveFreeArea = function (width, height, x, y, id) {
      if (id > 0) {
        adminService.editFreeArea($scope, width, height, x, y, id);
      } else {
        adminService.addFreeArea($scope, width, height, x, y);
      }
    };





    $scope.refreshPreview = function () {
      var tmpPage = $scope.getPageById($scope.selectedPage);
      if (!tmpPage) {
        return;
      }
      if ($scope.tempFreeAreaElement.length > 0) {
        bannerList
          .drawAllBanners(
            $scope,
            $scope.freeAreas,
            $scope.selectedFreeArea,
            tmpPage.background_id,
            $scope.tempFreeAreaElement[0]
          )
          .then(function () { });
      } else {
        bannerList
          .drawAllBanners(
            $scope,
            $scope.freeAreas,
            $scope.selectedFreeArea,
            tmpPage.background_id
          )
          .then(function () { });
      }
    };

    $scope.updatePreview = function () {
      $interval.cancel($scope.updateInterval);
      $scope.updateInterval = $interval(
        function () {
          $scope.refreshPreview();
        },
        700,
        1
      );
    };

    $scope.convertToDecimal = function (freeArea) {
      freeArea.shape = _convertShapeToFixed(freeArea.shape);
    };

    function _convertShapeToFixed(shape) {
      void 0
      shape.width = parseFloat(shape.width).toFixed(2);
      shape.width = isNaN(shape.width) ? "" : shape.width;
      shape.height = parseFloat(shape.height).toFixed(2);
      shape.height = isNaN(shape.height) ? "" : shape.height;
      shape.x = parseFloat(shape.x).toFixed(2);
      shape.x = isNaN(shape.x) ? "" : shape.x;
      shape.y = parseFloat(shape.y).toFixed(2);
      shape.y = isNaN(shape.y) ? "" : shape.y;
      return shape;
    }

    $scope.deleteFreeArea = function (id) {
      if (id > 0) {
        adminService.deleteFreeArea($scope, id).then(function () {
          bannerList
            .drawAllBanners($scope, $scope.freeAreas, null, null)
            .then(function () { });
        });
      }
    };

    $scope.selectFreeArea = function (id) {
      $scope.selectedFreeArea = id;
      var tmpPage = $scope.getPageById($scope.selectedPage);
      bannerList
        .drawAllBanners(
          $scope,
          $scope.freeAreas,
          $scope.selectedFreeArea,
          tmpPage.background_id
        )
        .then(function () { });
    };

    $scope.fillWithData = function (data, newOne) {
      adminService.fillWithData($scope, data, newOne);
      if (newOne) {
        var tmpPage = $scope.getPageById($scope.selectedPage);
        bannerList
          .drawAllBanners(
            $scope,
            $scope.freeAreas,
            $scope.selectedFreeArea,
            tmpPage.background_id
          )
          .then(function () { });
      }
    };

    $scope.deleteBackground = function () {
      if ($scope.fileData.id)
        adminService.deleteBackground($scope).then(function () {
          for (var i = 0; i < $scope.pages.length; i++) {
            if ($scope.pages[i].id === $scope.selectedPage) {
              $scope.pages[i].background_id = null;
            }
          }
          $scope.fileData = {};
          $scope.previewImageContent = "white";
          $scope.backgroundPreviewUrl = "";
          var tmpPage = $scope.getPageById($scope.selectedPage);
          bannerList
            .drawAllBanners(
              $scope,
              $scope.freeAreas,
              $scope.selectedFreeArea,
              tmpPage.background_id
            )
            .then(function () { });
        });
    };

    $scope.previewMyImage = function (convertionFormat, resourceid) {
      var deferred = $q.defer();

      if (resourceid !== null) {
        var customerId = window.api.customerid;
        const url = window.api.getResourceUrl(resourceid, 'thumbnail');
        $scope.backgroundPreviewUrl = url;
        pagesListService.loadImageToDom(url).then(
          function (data) {
            bannerList
              .drawAllBanners(
                $scope,
                $scope.freeAreas,
                $scope.selectedFreeArea,
                resourceid
              )
              .then(function () {
                deferred.resolve();
              });
          },
          function () { }
        );
      }
      return deferred.promise;
    };

    $scope.preventClick = function (event, elem) {
      if ($scope.selectedPage < 0 || !angular.equals({}, elem)) {
        event.preventDefault();
      }
    };

    $scope.setConversions = function (data) {
      adminService.setConversions($scope, data);
    };
    $scope.init = function () {
      $scope.flow_preloading = true;
      if (!subscriptionModel.auth) return;
      adminService.loadCustomers($scope).then(
        function () {
          $scope.flow_preloading = false;
        },
        function () {
          $scope.flow_preloading = false;
        }
      );
    };
    $scope.init();
    $scope.$on("CHANGE_ADDRESS", function (url, val) {
      if (val === "Administration") {
        $scope.init();
      }
    });

    $scope.updateScroll = function (id) {
      if ($("#pageScroll #" + id).length) {
        $scope.$broadcast("scrollBarScroll", $("#pageScroll #" + id));
      }
    };

    $scope.uiFilters = {
      filterActiveCustomer: true,
      filterInactiveCustomer: true,
      filterActiveFlow: true,
      filterInactiveFlow: true
    };

    $scope.refreshActiveFlow = function (value) {
      $scope.uiFilters.filterActiveFlow = value;
    };

    $scope.refreshInactiveFlow = function (value) {
      $scope.uiFilters.filterInactiveFlow = value;
    };

    $scope.refreshActiveCustomer = function (value) {
      $scope.uiFilters.filterActiveCustomer = value;
    };

    $scope.refreshInactiveCustomer = function (value) {
      $scope.uiFilters.filterInactiveCustomer = value;
    };

    $scope.setFilteringFlow = function (param) {
      if (param === "all") {
        $scope.uiFilters.filterActiveFlow = true;
        $scope.uiFilters.filterInactiveFlow = true;
      }
    };

    $scope.setFilteringCustomer = function (param) {
      if (param === "all") {
        $scope.uiFilters.filterActiveCustomer = true;
        $scope.uiFilters.filterInactiveCustomer = true;
      }
    };

    $scope.flowSearchFilter = function (flow) {
      if (flow.id === $scope.editedFlow) return true;

      if (
        !(
          flow.name
            .toUpperCase()
            .match($scope.flowStringSearch.toUpperCase()) ||
          flow.description
            .toUpperCase()
            .match($scope.flowStringSearch.toUpperCase()) ||
          flow.id.toString().match($scope.flowStringSearch)
        )
      ) {
        return false;
      }

      return true;
    };

    $scope.flowStatusFilter = function (flow) {
      if (flow.id === $scope.editedFlow) return true;

      if (
        $scope.uiFilters.filterActiveFlow &&
        $scope.uiFilters.filterInactiveFlow
      )
        return true;

      if (
        $scope.uiFilters.filterInactiveFlow &&
        $scope.uiFilters.filterInactiveFlow !== flow.active
      ) {
        return true;
      }

      if (
        $scope.uiFilters.filterActiveFlow &&
        $scope.uiFilters.filterActiveFlow === flow.active
      ) {
        return true;
      }

      return false;
    };

    $scope.customerSearchFilter = function (customer) {
      if (customer.id === $scope.editedCustomer) return true;

      if (!(customer.name.toUpperCase().match($scope.customerStringSearch.toUpperCase()) || customer.id.toString().match($scope.customerStringSearch))
      ) {
        return false;
      }
      return true;
    };

    $scope.customerStatusFilter = function (customer) {
      if (customer.id === $scope.editedCustomer) return true;

      if (
        $scope.uiFilters.filterActiveCustomer &&
        $scope.uiFilters.filterInactiveCustomer
      )
        return true;

      if (
        $scope.uiFilters.filterInactiveCustomer &&
        $scope.uiFilters.filterInactiveCustomer !== customer.active
      ) {
        return true;
      }

      if (
        $scope.uiFilters.filterActiveCustomer &&
        $scope.uiFilters.filterActiveCustomer === customer.active
      ) {
        return true;
      }

      return false;
    };
  }
]);
