ocCommonProviders.directive("administrationEditFlow",[ function () {
    "use strict";
    return {
    restrict: "C",
    templateUrl:"/static/partials/administrationEditFlowElement.html",
    link: function(scope, element, attrs){
          scope.resize(true);
    }

      };
}]);



