module.controller("uiFilterController", ["$scope","$element","$compile","uiFilterService",function($scope,$element,$compile,uiFilter){
    this.$onInit=function(){
        uiFilter.reloadCallbackDef((filters)=>_initFilters(filters))
    }

        this.$onChanges = function (changes) { 
        if(angular.isDefined(changes.filters)&& angular.isDefined(changes.filters.currentValue) && angular.isArray(changes.filters.currentValue)){
            _initFilters(this.filters);
        }
      }

      var _initFilters=(filters)=>{
        $element.find(".uiFilter").empty();
        filters.forEach((fGroup)=>{
            if(fGroup.name===this.group){
            fGroup.items.forEach((item,index)=>{
                if(fGroup.items.length-1!=index){
                    var newScope=$scope.$new(true);
                    newScope.$ctrl=item;
                    switch(newScope.$ctrl.name){
                        case "search":
                            $element.find(".uiFilter").append($compile("<ui-search-filter group="+fGroup.name+" on-change='$ctrl.onChange()' class-name={{$ctrl.className}} id={{$ctrl.id}}/>")(newScope));
                            break;
                        case "dropDown":
                            $element.find(".uiFilter").append($compile("<ui-drop-down-filter group="+fGroup.name+" icon='filter.icon' data-title='$ctrl.title' menu-items='$ctrl.menuItems' list-items='$ctrl.listItems' quantifiers='$ctrl.quantifiers' class-name={{$ctrl.className}}/>")(newScope));
                            break;
                        case "date":
                            $element.find(".uiFilter").append($compile("<ui-date-filter group="+fGroup.name+"}} />")(newScope));
                            break;
                        case "condition":
                            $element.find(".uiFilter").append($compile("<ui-condition-filter group="+fGroup.name+"quantifiers='$ctrl.quantifiers' class-name={{$ctrl.className}}/>")(newScope));
                            break;
                        default:
                            throw new Error("uiFilter: Uknown filter type");
                    }
                }
            });
        }
    });
    }

}]);