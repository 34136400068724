ocCommonProviders.service("ValidationService", ["modal", function(modal) {
"use strict";
    this.checkIsPositive = _checkIsPositive;
    this.checkIsInt = _checkIsInt;
    this.checkIsEmpty = _checkIsEmpty;
    this.checkIsInMaxSize = _checkIsInMaxSize;
    this.checkIsFromList = _checkIsFromList;

    function _checkIsPositive(value) {
      if( value > 0 ){
       return true;
      }else{
            modal.info({
                message: "Number in name must be greater than 0",
                ok: "Ok",
                cancel: ""
                },
                function(){},
                function() {}
            );
      }
    }

    function _checkIsInt(value) {
      if( !isNaN(value) &&
             parseInt(Number(value)) == value &&
             !isNaN(parseInt(value, 10))
       ){
       return true;
       }else{
            modal.info({
                        message: "Name must be integer",
                        ok: "Ok",
                        cancel: ""
                        },
                        function(){},
                        function(){}
                    );
       }
    }

    function _checkIsEmpty(element,type){
        if(element === "" || typeof element === "undefined" || element === null){
                    modal.info({
                        message: "You cannot save without entering a " + type,
                        ok: "Ok",
                        cancel: ""
                        },
                        function(){},
                        function() {}
                    );
            return true;
        }
    }

    function _checkIsInMaxSize(element,type,max){
        if(!(element === "" || typeof element === "undefined" || element === null))
            if(element.length > max ){
                modal.info({
                            message: type + " should be below " + max + " characters long. ",
                            ok: "Ok",
                            cancel: ""
                            },
                            function(){},
                            function() {}
                        );
                return true;
            }
    }

    function _checkIsFromList(element,type,list){
        if(list.indexOf(element) < 0 ){
            modal.info({
                        message: type + " has incorrect value",
                        ok: "Ok",
                        cancel: ""
                        },
                        function(){},
                        function() {}
                    );
            return false;
        }

        return true;
    }


}]);
