module.filter("ocInlineDisplayFilter", function () {
    "use strict";

    return function (collection, mainPropName, descPropName, stringLength) {
        if (collection && angular.isArray(collection)) {
            let result = collection.reduce(function (prev, next, index) {
                if (descPropName) {
                    let type=next[descPropName] || descPropName;
                    return _addSeparatorIfNotLast(prev += `${next[mainPropName]} (${type})`, index, collection.length - 1);
                }
                return _addSeparatorIfNotLast(prev += next[mainPropName], index, collection.length - 1);
            }, '');

            return _collectToLastIndexOfBracket(result, stringLength);
        }
        return "-"
    }

    function _collectToLastIndexOfBracket(target, stringLength) {
        if (stringLength && (target.length > stringLength)) {
            let str = target.substring(0, stringLength);
            let lastBracketIndex = str.lastIndexOf(")");
            let result = (lastBracketIndex != -1) ? str.substring(0, lastBracketIndex + 1) : str;
            return `${result}...`;
        }
        return target;
    }

    function _addSeparatorIfNotLast(target, currIndex, lastIndex) {
        if (currIndex != lastIndex) {
            return target += ', '
        }
        return target;
    }
});