ocCommonProviders.service("navigationContextService", ["$location","$route",function($location, $route) {
    var _subscriptions = [];
    this.goTo = _goTo;
    this.fallback = _fallback;
    this.subscribe = _subscribe;
    this.unsubscribe = _unsubscribe;
    this.getContext = _getContext;
    this.call = _call;
    this.clearRouteParams = _clearRouteParams;

    function _goTo(targetUrl, from) {
      if (angular.isDefined(from)) {
        $location.path(targetUrl).search({ from: from });
      } else {
        $location.path(targetUrl);
      }
    }

    function _fallback(targetUrl, payload) {
      _call("fallback", payload);
      _goTo(targetUrl);
    }

    function _subscribe(evtName, callback) {
      var subscription = _subscriptions.filter(subscription => subscription.evt === evtName)[0];

      if (!subscription) {
        const subscriber = { evt: evtName, callback:callback};
        _subscriptions.push(subscriber);
      }
    }

    function _unsubscribe(evtName) {
      _subscriptions = _subscriptions.filter(subscription => subscription.evt != evtName);
    }

    function _getContext() {
      if($route.current.params.from){
        return `/${$route.current.params.from}`;
      }
      return;
    }

    function _call(evt, payload) {
      var subscription = _subscriptions.filter(subscription => subscription.evt === evt)[0];
      subscription.callback(payload);
    }

    function _clearRouteParams() {
      const params=$location.search();
      if(params.from){
        $location.search("from", null);
      }
    }
  }
]);
