ocCommonProviders.directive("bannersListDirective", ["$uibModal","protocols",function($modal,protocols) {
    "use strict";
    return {
        restrict: "C",
        templateUrl: "/static/partials/bannersList.html",
        link: function(scope, element, attrs, attachmentsController) {

            scope.openUrlModal = _openModal;
            var ModalInstanceCtrl = ['$scope', '$uibModalInstance', 'protocols', 'url', 'attachementData',
                function($scope, $uibModalInstance, protocols, url, attachementData) { 
                    $scope.addProtocol=attachementData.addProtocol;
                    $scope.protocols = protocols;
                    $scope.url = url || attachementData.url;
                    $scope.selectedProtocol =attachementData.protocol || protocols[0];
                    $scope.ok = function() {
                        let protocolDisplayName="";
                        if($scope.addProtocol){
                            protocolDisplayName=$scope.selectedProtocol.displayName;
                        }
                        attachementData.url = protocolDisplayName+$scope.parsedUrl.replace(/(\r|\s|\n|\t|\u200b)/gm,"");
                        attachementData.contentHtml=$scope.contentHtml;
                        attachementData.protocol=$scope.selectedProtocol;
                        attachementData.addProtocol=$scope.addProtocol;
                        $uibModalInstance.close(attachementData); 
                        localStorage.setItem("customModalIsOpened",false)                  
                    };

                    $scope.cancel = function() {
                        $uibModalInstance.dismiss();
                        localStorage.setItem("customModalIsOpened",false)
                    };
                }
            ]

            function _openModal(attachementData) {
                localStorage.setItem("customModalIsOpened",true)
                var modal = $modal.open({
                    templateUrl: "/static/partials/urlModal.html",
                    controller: ModalInstanceCtrl,
                    backdrop: "static",
                    resolve: {
                        protocols: function() {
                            return protocols;
                        },
                        url: function() {
                            return attachementData.contentHtml;
                        },
                        attachementData: function() {
                            return attachementData
                        }
                    }
                });

                modal.result.then(function(result) {
                    attachementData = result;
                    localStorage.setItem("customModalIsOpened",false);
                }, function() {

                });
            }
        }
    };
}]);
