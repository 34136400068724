module.controller("settingsController", ["$q", "$scope", "db", "flowService", "$rootScope", "userService", "modal", "errorHandlingService", "login", "api", "$timeout", "utilityService", "subscriptionModel","growlHandlingService",
    function($q, $scope, db, flowService, $rootScope, userService, modal, errorHandlingService, login, api, $timeout, utilityService, subscriptionModel,growlHandlingService) {
        "use strict";
        $rootScope.$broadcast("CHANGE_TAB", "Settings");

        $scope._projectmodel = subscriptionModel.get('project');

        $scope.flowSearchFilter = function(flow) {
            if (typeof flow !== "undefined" && typeof $scope.flowStringSearch !== "undefined")
                if (!(flow.name.toUpperCase().match($scope.flowStringSearch.toUpperCase()) || flow.description.toUpperCase().match($scope.flowStringSearch.toUpperCase()))) {
                    return false;
                }
            if (!flow.active) {
                return false;
            }
            return true;
        };


        $scope.editFlow = function(flow) {
            $scope.editedFlow = flow.id;
            $scope.blockingLayer = true;
            $scope.highlightedElement = flow.id;
            $scope.cloneEdited = angular.copy(flow);
            $scope.resize(true);
        };

        function resetFlowById(flow) {
            var deferred = $q.defer();
            for (var i = 0; i < $scope.flows.length; i++) {
                if ($scope.flows[i].id == flow.id) {
                    $scope.flows[i] = flow;
                    deferred.resolve();
                    break;
                }
            }
            deferred.reject();
            return deferred.promise;
        }

        $scope.discard = function(flow) {
            var deferred = $q.defer();

            resetFlowById($scope.cloneEdited);
            $scope.editedFlow = null;
            $scope.blockingLayer = false;
            $scope.highlightedElement = null;

            deferred.resolve();
            return deferred.promise;
        };
        $scope.cancelFlow = function(flow) {
            if (!angular.equals($scope.cloneEdited, flow)) {
                modal.confirm({
                        message: "Do you want to discard changes?",
                        ok: "Yes",
                        cancel: "No"
                    },
                    function() {
                        $scope.discard(flow);
                    },
                    function() {}
                );
            } else {
                $scope.discard(flow);
            }
        };

        $scope.saveFlow = function(flow) {
            var deferred = $q.defer();
            var customerId = window.api.customerid;
            var dataToSend = {
                "name": flow.name,
                "description": flow.description,
                "attachment_limit": flow.attachment_limit
            };

            if (flow.attachment_limit.length === 0 || flow.description.length === 0) {
                modal.info({
                        message: "Pleases specify attachment limit value and set the description.",
                        ok: "Ok",
                        cancel: ""
                    },
                    function() {
                        deferred.reject();
                    },
                    function() {}
                );
                return deferred.promise;
            }

            if (flow.id > 0 && !angular.equals($scope.cloneEdited, flow)) {
                const onSuccess = function(data) {
                  $scope.discard().then(function() {
                    $scope.init();
                    growlHandlingService.success("Your Flow has been successfully saved.");
                  });
                }
                const onFailure = function(data) {
                  errorHandlingService.handleResponse(404, data, self);
                }
                window.api.projectPut(flow.id, dataToSend, onSuccess, onFailure);
            }

            deferred.reject();
            return deferred.promise;
        };

        $scope.init = function() {
            flowService.getFlows().then(function(projects) {
                $scope.flows = projects;
            });
        };

        $scope.deleteBackground = function(scope) {
            var deffered = $q.defer();
            var customerId = window.api.customerid;
            var projectId = scope.selectedFlow;
            const onSuccess = function(data) {
              modal.info({
                  message: "Your Background has been successfully removed",
                  ok: "Ok",
                  cancel: ""
                },
                function() {
                  for (var i = 0; i < scope.pages.length; i++) {
                    if (scope.pages[i].id === scope.selectedPage) {
                      scope.pages[i].background_id = null;
                    }
                  }
                  scope.fileData = {};
                  deffered.resolve();
                  scope.previewImageContent = "white";
                  scope.backgroundPreviewUrl = "";
                },
                function() {}
              );

            }
            const onFailure = function(data) {
              errorHandlingService.handleResponse(status, data, scope);
              deffered.reject();
            }
            window.api.pageDeleteBackground(scope.selectedPage, onSuccess, onFailure);
            return deffered.promise;
        }


        $scope.resize = function(second) {
            var maxWidth = utilityService.getDocWidth();
            var maxHeight = utilityService.getDocHeight();
            var bl = angular.element(".blockingLayerAdmin");
            bl.css({
                width: maxWidth + "px",
                height: maxHeight + "px"
            });
            if (second)
                $timeout(function() {
                    $scope.$apply();
                }, 0);
        };


        $scope.init();

    }
]);
