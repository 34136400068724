module.controller("maintenanceController", ["$rootScope", "$scope", "$timeout", "login", "db", "api", "maintenanceService", "$window", "modal", "$q", "utilityService",
    function($rootScope, $scope, $timeout, login, db, api, maintenanceService, $window, modal, $q, utilityService) {
        "use strict";
        $rootScope.$broadcast("CHANGE_TAB", "Administration");
        $scope.serviceMessageStatusSearch = "All";
        $scope.serviceMessageStringSearch = "";

        $scope.selectedServiceMessage = 0;
        $scope.addingServiceMessage = false;
        $scope.editedServiceMessage = -1;

        $scope.blockingLayer = false;
        $scope.blockingLayerHigher = false;
        $scope.messageType = ["service", "notice", "other"];
        $scope.serviceMessageList = null;

        $scope.parseNameToInt = function(obj) {
            return parseInt(obj.name);
        };

        $scope.isBackground = true;

        $scope.addServiceMessage = function() {
            var date = new Date();
            var day = date.getDate();
            var monthIndex = date.getMonth() + 1;
            var year = date.getFullYear();
            var month;
            if (monthIndex < 10) {
                month = "0" + monthIndex;
            } else {
                month = "" + monthIndex;
            }

            if (day < 10) {
                day = "0" + day;
            } else {
                day = "" + day;
            }

            $scope.selectedServiceMessage = -1;
            $scope.addingServiceMessage = true;
            $scope.blockingLayer = true;
            $scope.editedServiceMessage = -1;
            $scope.original_type = "";
            $scope.tempServiceMessageElement = [{
                type: "service",
                message: "",
                id: -1,
                active: true,
                date_from: year + "-" + month + "-" + day + " " + "00:00:00",
                date_to: year + "-" + month + "-" + day + " " + "00:00:00"
            }];
        };

        $scope.selectServiceMessage = function(id) {
            $scope.selectedServiceMessage = id;
            $scope.login.adminServiceMessageIdInt = id;
        };

        $scope.copyOfServiceMessage = {};

        $scope.editServiceMessage = function(id) {
            resize(true);
            $scope.editedServiceMessage = id;
            $scope.blockingLayer = true;
            $scope.copyOfServiceMessage = angular.copy($scope.serviceMessageList);
            for (var i = 0; i < $scope.serviceMessageList.length; i++) {
                if ($scope.serviceMessageList[i].id === id) {
                    $scope.original_serviceMessage_type = $scope.serviceMessageList[i].type;
                }
            }
        };

        $scope.cancelEditServiceMessage = function(id) {
            $scope.blockingLayer = false;
            $scope.editedServiceMessage = -1;
            $scope.addingServiceMessage = false;
            if (id >= 0) {
                $scope.serviceMessageList = angular.copy($scope.copyOfServiceMessage);
            }
        };

        $scope._modifyServiceMessage = function(type, message, active, date_to, date_from, id) {
            maintenanceService.editServiceMessage($scope, type, active, message, date_from, date_to, id).then(function() {
                $scope.login.adminServiceMessageIdInt = id;
                $rootScope.$emit("LOADOFF");
                $rootScope.$emit("reloadMaintenanceMessages");
            }, function() {
                $rootScope.$emit("LOADOFF");
            });
        };

        $scope.saveServiceMessage = function(type, message, active, date_to, date_from, id, valid) {

            if (valid)
                return false;
            if (date_from)
                date_from = moment(date_from).utc().format("YYYY-MM-DD HH:mm:ss");
            if (date_to)
                date_to = moment(date_to).utc().format("YYYY-MM-DD HH:mm:ss");

            if((new Date(date_from).getTime())>=(new Date(date_to).getTime())){
                modal.info({
                    message:"'Date from' cannot be greater or equal than 'Date to'",
                    ok: "Ok",
                    cancel: ""
                });
                return;
            }

            $rootScope.$emit("LOADON");
            if (id > 0) {
                var list = $scope.copyOfServiceMessage;
                var serviceMessage = null;
                for (var i = 0; i < list.length; i++) {
                    if (list[i].id == id) {
                        serviceMessage = angular.copy(list[i]);
                        break;
                    }
                }
                if (serviceMessage && serviceMessage.active != active) {
                    maintenanceService.serviceMessages().then(
                        function(data) {
                            $scope._modifyServiceMessage(type, message, active, date_to, date_from, id);
                        },
                        function() {
                            $rootScope.$emit("LOADOFF");
                        }
                    );
                } else {
                    $scope._modifyServiceMessage(type, message, active, date_to, date_from, id);
                }

            } else {
                maintenanceService.addServiceMessage($scope, type, active, message, date_from, date_to).then(function(data) {
                    $scope.login.adminServiceMessageIdInt = data.id;
                    $rootScope.$emit("LOADOFF");

                }, function() {
                    $rootScope.$emit("LOADOFF");
                });
            }
            $rootScope.$emit("reloadMaintenanceMessages");
        };

        $rootScope.$emit("LOADON");
        $q.all([maintenanceService.loadServiceMessages($scope)]).then(function() {
            $rootScope.$emit("LOADOFF");
        }, function() {
            $rootScope.$emit("LOADOFF");
        });

        $scope.uiFilter = {
            filterActiveServiceMessage: true,
            filterInactiveServiceMessage: false
        };
        $scope.filterActiveServiceMessage = true;
        $scope.filterInactiveServiceMessage = false;

        $scope.refreshActiveServiceMessage = function(value) {
            $scope.uiFilter.filterActiveServiceMessage = value;
        };

        $scope.refreshInactiveServiceMessage = function(value) {
            $scope.uiFilter.filterInactiveServiceMessage = value;
        };

        $scope.setFilteringServiceMessage = function(param) {
            if (param === "all") {
                $scope.uiFilter.filterActiveServiceMessage = true;
                $scope.uiFilter.filterInactiveServiceMessage = true;
            }
        };

        $scope.serviceMessageSearchFilter = function(serviceMessage) {
            if (serviceMessage.id === $scope.editedServiceMessage) return true;

            if (!(serviceMessage.message.toUpperCase().match($scope.serviceMessageStringSearch.toUpperCase()) || (serviceMessage.id.toString().match($scope.serviceMessageStringSearch)))) {
                return false;
            }
            return true;
        };

        $scope.serviceMessageStatusFilter = function(serviceMessage) {

            if (serviceMessage.id === $scope.editedServiceMessage) return true;

            if ($scope.uiFilter.filterActiveServiceMessage && $scope.uiFilter.filterInactiveServiceMessage) return true;

            if ($scope.uiFilter.filterInactiveServiceMessage && $scope.uiFilter.filterInactiveServiceMessage !== serviceMessage.active) {
                return true;
            }

            if ($scope.uiFilter.filterActiveServiceMessage && $scope.uiFilter.filterActiveServiceMessage === serviceMessage.active) {
                return true;
            }

            return false;
        };


        var resize = function(second) {
            var maxWidth = utilityService.getDocWidth();
            var maxHeight = utilityService.getDocHeight();
            var bl = angular.element(".blockingLayerAdmin");
            var blh = angular.element(".blockingLayerAdminHigher");
            blh.css({
                width: maxWidth + "px",
                height: maxHeight + "px"
            });
            bl.css({
                width: maxWidth + "px",
                height: maxHeight + "px"
            });
        };

        $scope.resize = resize;

        angular.element($window).bind("resize", function() {
            resize(true);
        });

        resize();

    }
]);
