ocCommonProviders.service("uiSortingService", function() {
  const _directions = {
    ASC: "asc",
    DESC: "desc",
    INITIAL: "initial"
  };
  var _sortings = [];
  var _subscribers = [];

  this.define = _define;
  this.get = _get;
  this.set = _set;
  this.refresh=_refresh;
  this.doSorting = _doSorting;
  this.updateSortingState = _updateSortingState;
  this.getSortingState = _getSortingState;
  this.subscribe = _subscribe;

  function _define(conf, scopeContext) {
    if (!_sortings.some(c => c.group === conf.group)) {
      _sortings.push(conf);
    } else {
      const index = _sortings.findIndex(c => c.group === conf.group);
      _sortings[index].callback = conf.callback; 
    }
  }

  function _get(group) {
    return _sortings.filter(def => def.group === group)[0];
  }

  function _set(group, collection) {
    _sortings.forEach(def => {
      if (def.group === group) {
        def.collection = collection;
      }
    });
  }

  function _refresh(group,currentCollection){
    _sortings.forEach(def => {
      if (def.group === group) {
        const sorting=def.sortings.filter((sorting)=>sorting.state!="initial")[0];
        def.collection=currentCollection;
        if(!sorting) return;
        _doSorting(group, sorting.sortBy)(sorting.state);
      }
    });
  }

  function _doSorting(group, sortBy) {
    return function(direction) {
      if(direction===_directions.INITIAL) return;
      var def = _get(group);
      if (direction === _directions.ASC) {
        def.callback(_doSortingAsc(def.collection, sortBy));
      } else {
        def.callback(_doSortingDesc(def.collection, sortBy));
      }
    };
  }

  function _doSortingAsc(collection, sortBy) {
    return collection.sort(function(item1, item2) {
      if (item1[sortBy].toLowerCase() > item2[sortBy].toLowerCase()) {
        return -1;
      }
      if (item1[sortBy].toLowerCase() < item2[sortBy].toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  function _doSortingDesc(collection, sortBy) {
    return collection.sort(function(item1, item2) {
      if (item1[sortBy].toLowerCase() > item2[sortBy].toLowerCase()){
        return 1;
      }
      if (item1[sortBy].toLowerCase() < item2[sortBy].toLowerCase()) {
        return -1;
      }
      return 0;
    });
  }

  function _updateSortingState(name, group) {
    _sortings.forEach(def => {
      if (def.group === group) {
        def.sortings.forEach(sorting => {
          if (sorting.name === name) {
            sorting.state = _getDirection(sorting.state);
          } else {
            sorting.state = _directions.INITIAL;
          }
        });
        _call(group);
        return;
      }
    });
  }

  function _getSortingState(name, group) {
    var _state;
    _sortings.forEach(def => {
      if (def.group === group) {
        def.sortings.forEach(sorting => {
          if (sorting.name === name) {
            _state = sorting.state;
            return;
          }
        });
        return;
      }
    });

    return _state;
  }

  function _getDirection(currState) {
    switch (currState) {
      case _directions.ASC:
        return _directions.DESC;
      case _directions.DESC:
        return _directions.ASC;
      case _directions.INITIAL:
        return _directions.DESC;
      default:
        return _directions.INITIAL;
    }
  }

  function _subscribe(subscriberName, subscribtionGroup, callback) {
    _subscribers.push({
      name: subscriberName,
      group: subscribtionGroup,
      call: callback
    });
  }

  function _call(group) {
    var _fSortings = _sortings.filter(sorting => sorting.group === group)[0]
      .sortings;
    _subscribers.forEach(subscriber => {
      if (subscriber.group === group) {
        _fSortings.forEach(sorting =>
          subscriber.call(sorting.name, sorting.state)
        );
      }
    });
  }
});
