ocCommonProviders.service("Connection", [
  "db",
  "login",
  "modal",
  "api",
  "errorHandlingService",
  "$rootScope",
  "$timeout",
  "userService",
  "bannerList",
  "$q",
  "pagesListService",
  "utilityService",
  "attachmentTargetList",
  "serverTime",
  "flowService",
  "$window",
  "tagsService",
  "previewService",
  "subscriptionModel",
  "connectionDict",
  "growlHandlingService",
  "resourceAccessService",
  "rulesService",

  function(
    db,
    login,
    modal,
    api,
    errorHandling,
    $rootScope,
    $timeout,
    userService,
    bannerList,
    $q,
    pagesListService,
    utilityService,
    attachmentTargetList,
    serverTime,
    flowService,
    $window,
    tagsService,
    previewService,
    subscriptionModel,
    connectionDict,
    growlHandlingService,
    resourceAccessService,
    rulesService,

  ) {
    "use strict";
    var self = this;
    this.sendEmailToApprovers=_sendEmailToApprovers;
    this.resourceFallback;
    const statusNames = {
      run: "running",
      edit: "editing",
      stop: "stopped",
      pending: "pending",
      expired: "expired",
      waiting: "waiting",
      toEdit: "edit",
      toRun: "run",
      toStop: "stop",
      inProduction: "production",
      deleted: "deleted",
      approve:"request run",
      stopRequest:"request stop"
    };


    var _changeAction = function(
      actionName,
      statusNames,
      blockingLayer,
      connection
    ) {
      var connectionStatus;
      switch (actionName) {
        case statusNames.toStop:
          connectionStatus = statusNames.stop;
          break;
        case statusNames.toRun:
          connectionStatus = statusNames.run;
          break;
        case statusNames.toEdit:
          connectionStatus = statusNames.edit;
          _blockAllExceptConnection(connection, blockingLayer);
          _resize();
          break;
        case statusNames.run:
          connectionStatus = statusNames.stop;
          break;
        case statusNames.edit:
          connectionStatus = statusNames.stop;
          break;
        case statusNames.expired:
          connectionStatus = statusNames.stop;
          break;
        case statusNames.stop:
          connectionStatus = statusNames.run;
          break;
        case statusNames.waiting:
          connectionStatus = statusNames.stop;
          break;
        case statusNames.pending:
          connectionStatus = statusNames.stop;
          break;
        default:
          void 0;
          break;
      }
      return connectionStatus;
    }; 

    var _getDocHeight = function() {
      return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.body.clientHeight,
        document.documentElement.clientHeight
      );
    };

    var _getDocWidth = function() {
      return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.body.clientWidth,
        document.documentElement.clientWidth
      );
    };

    var _resize = function(second) {
      var maxWidth = _getDocWidth();
      var maxHeight = _getDocHeight();
      var bl = angular.element(".blockingLayer");
      bl.css({
        width: maxWidth + "px",
        height: maxHeight + "px"
      });
      if (second)
        $timeout(function() {
          scope.$apply();
        });
    };

    var _addTime = function(dateString, to) {
      if (dateString.length > 15) return dateString;

      var d = serverTime.getServerTime();
      var fromDate = serverTime.dateParser(dateString);
      var todayFlag;
      var result = "";
      var h, m, s;
      if (
        fromDate.getFullYear() === d.getFullYear() &&
        fromDate.getMonth() === d.getMonth() &&
        fromDate.getDate() === d.getDate()
      )
        todayFlag = true;
      else todayFlag = false;

      if (to) {
        if (!todayFlag) {
          result = dateString + " 23:59:59";
          h = 23;
          m = 59;
          s = 59;
        } else {
          result = dateString + " 23:59:59";
          h = 23;
          m = 59;
          s = 59;
        }
      } else {
        if (todayFlag) {
          return "NOW";
        } else {
          result = dateString + " 00:00:01";
          h = 0;
          m = 0;
          s = 1;
        }
      }

      fromDate.setHours(h);
      fromDate.setMinutes(m);
      fromDate.setSeconds(s);

      var resultUtc = moment(fromDate)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");

      return resultUtc;
    };

    var _arrayFind = function(arr, elem) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] === elem) return true;
      }
      return false;
    };

    var _prepareLocalDateFormatShort = function(utcDate) {
      if (typeof utcDate === "undefined" || utcDate === null || utcDate === "")
        return null;
      var local = serverTime.utcToLocal(utcDate);

      return (
        "" +
        local.getFullYear() +
        "-" +
        ("0" + (local.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + local.getDate()).slice(-2)
      );
    };

    var _clearBlockLayers = function(blockingLayer) {
      $(".toFront").removeClass("toFront");
      blockingLayer = false;
    };

    var _blockAllExceptConnection = function(con, blockingLayer) {
      $("#con_" + con.id).addClass("toFront");
      blockingLayer = true;
    };

    var _loadData = function(rootScope, scope, id, con) {
      scope.activeConnection = id;
      scope.activeConnectionObj = con;

      scope.changeConnectionState(id, con);
      scope.updateStatus();

      if (scope.isEdited(con)) {
        con.lock = true;
        rootScope.$emit("LOADOFF");
      }
    };

    var _updateStatus = function(connections, statusNames) {
      var now = serverTime.getServerTimeUTC();
      var from, to;

      for (var i = 0; i < connections.length; i++) {
        from = serverTime.dateParser(connections[i].valid_from_utc);
        to = serverTime.dateParser(connections[i].valid_to_utc);

        if (
          (connections[i].status == statusNames.run &&
            now < from &&
            now < to) ||
          (to === null &&
            now < from &&
            connections[i].status == statusNames.run)
        ) {
          connections[i].status = statusNames.pending;
          continue;
        }
        if (
          !connections[i].message &&
          connections[i].status === statusNames.stop
        ) {
          connections[i].status = statusNames.waiting;
        }

        if (to !== null && to < now) {
          if (connections[i].status !== statusNames.deleted)
            connections[i].status = statusNames.expired;
        }
      }
    };
    this.dcaContext=null;
    this.validateDates = _validateDates;
    this.validateConnection = _validateConnection;
    this.createNewConnection = _createNewConnection;
    this.connectionCopy=_connectionCopy;
    this.getConnectionIndexById = _getConnectionIndexById;
    this.prepareConnectionsListOnLoad = _prepareConnectionsListOnLoad;
    this.changeConnectionState = _changeConnectionState;
    this.changeTabState = _changeTabState;
    this.getConnectionStatusActionName = _getConnectionStatusActionName;
    this.prepareSaveData = _prepareSaveData;
    this.compareData = _compareData;
    this.loadConnectionList = _loadConnectionList;
    this.sendState = _sendState;
    this.sendStopState = _sendStopState;
    this.changeConnectionStatus = _changeConnectionStatus;
    this.deleteConnection = _deleteConnection;
    this.sendStopRequest = _sendStopRequest;
    this.cancelConnection = _cancelConnection;
    this.cancelEditingConnection=_cancelEditingConnection;
    this.selectProject = _selectProject;
    this.setFiltering = _setFiltering;
    this.filterStatuses = _filterStatuses;
    this.loadConnectionWithAttachmentList = _loadConnectionWithAttachmentList;
    this.loadConnectionListByBannerAreaId = _loadConnectionListByBannerAreaId;
    this.loadConnectionWithStatusHistoryAndTags = _loadConnectionWithStatusHistoryAndTags;
    this.connectionPreview = _connectionPreview;
    this.updateStatus = _updateStatus;
    this.searchInConnections = _searchInConnections;
    this.dcaViewSwitch = _dcaViewSwitch;
    this.changeTestStatus = _changeTestStatus;
    this.filterTestedConnections = _filterTestedConnections;
    this.findConnectionById = _findConnectionById;
    this.loadSVGFabric = _loadSVGFabric;
    this.loadBannerImage=_loadBannerImage;
    this.initController = _initController;
    this.onConnectionStatusChange=_onConnectionStatusChange;
    this.testAllConnections=_testAllConnections;
    this.changeProductionStatus = (connection) => {
      var defer = $q.defer();
      const onSuccess = (data)=>defer.resolve(data);
      const onFailure = (data)=>defer.reject(data);
      window.api.connectionMock(connection.id, onSuccess, onFailure);
      return defer.promise;
    }

    function _onConnectionStatusChange(con) {
        if (con.test) {
          _onTestStatusAcive(con);
          return;
        }
        _onTestStatusInactive(con);
      }

      function _onTestStatusAcive(con) {
        if (con.status === connectionDict.statuses.RUNNING) {
          growlHandlingService.success(connectionDict.testStatusChange(con.title).activeTestStatusRunning());
          return;
        }
        growlHandlingService.success(connectionDict.testStatusChange(con.title).activeTestStatusNotRunning());
      }

      function _onTestStatusInactive(con) {
        if (con.status === connectionDict.statuses.RUNNING) {
          growlHandlingService.success(connectionDict.testStatusChange(con.title).inactiveTestStatusRunning());
          return;
        }
        growlHandlingService.success(connectionDict.testStatusChange(con.title).inactiveTestStatusNotRunning());
      }

    function _findConnectionById(connectionsList, connectionId) {
      for (var i = 0; i < connectionsList.length; i++) {
        if (connectionsList[i].id == connectionId) {
          return connectionsList[i];
        }
      }
    }

    function _filterTestedConnections(value, index, add) {
      if (userService.isAdmin()) return true;
      if (value.test) return false;
      else return true;
    }

    function _dcaViewSwitch() {
      if ($scope.dcaViewMain.name == "Banners") {
        bannerList.loadBannerList($scope, "banner").then(function(resources) {
          $scope.dcaBannersList = resources;
        });
      }

      if ($scope.dcaViewMain.name == "Attachments") {
      }
      $scope.editEnclosure = false;
      $scope.editAttachments = false;
      $scope.editBanners = false;
      $scope.editTextBoxes = false;
      $scope.selectedAttachmentTableElement = null;
      $scope.selectedTextBoxTableElement = null;
      $scope.fileData = null;
      $scope.dcaView = null;
      $scope.previewImageContent = null;
    }

    function _validateDates(start, end) {
      if (start == "NOW") {
        var now = serverTime.getServerTime();
      } else {
        start = serverTime.dateParser(start);
      }
      if (end == "INF") return true;
      else {
        end = serverTime.dateParser(end);
      }
      if (start > end) {
        return false;
      }
      return true;
    }

    function _validateConnection(
      projectId,
      ual,
      statusNames,
      dcaViewMain,
      title,
      description,
      validFrom,
      validTo,
      aid,
      connection,
      index,
      banner_area_id,
      textbox_id,
      connections
    ) {
      var errorStr = "";
      if (title.length > 128)
        errorStr += "Title should be below 128 characters long. ";

      if (title === "" || title === null){
        errorStr += "You have to enter title. ";
      }else if (connections.some((c)=>((c.title===title) && (c.id !==-1) && (c.id !==connection.id))) ){
        errorStr += "Connection with that title already exists. ";
      }

      ual = userService.getUAL();
      ual.isAdmin = userService.isAdmin();

      if (typeof connection.rule_id == "undefined")
          errorStr += 'You have to choose a "rule" ';

      if (aid === null && dcaViewMain.name === "Attachments")
        errorStr += 'You have to choose an "attachment". ';

      if (aid === null && dcaViewMain.name === "Enclosures")
        errorStr += 'You have to choose an "enclosure". ';

      if (aid === null && dcaViewMain.name === "Banners")
        errorStr += 'You have to choose a "banner". ';

      if (textbox_id === null && dcaViewMain.name === "Textboxes")
        errorStr += 'You have to choose a "textbox". ';

      if (errorStr !== "") {
        modal.info(
          {
            message: errorStr,
            ok: "Ok",
            cancel: ""
          },
          function() {},
          function() {}
        );
        return false;
      }

      if (connection.status === statusNames.expired) {
        modal.info(
          {
            message: 'You cannot save/edit "expired" connections.',
            ok: "Ok",
            cancel: ""
          },
          function() {},
          function() {}
        );
        return false;
      }
      if (connection.lock === true) {
        return false;
      }
      var error = null;

      if (
        typeof aid === "undefined" &&
        !connection.targetlist_id &&
        (typeof aid === "undefined" &&
          typeof connection.fulfillment !== "undefined" &&
          connection.fulfillment.length === 0)
      ) {
        error = true;
      }

      if (error) {
        return false;
      }

      if (!_validateDates(validFrom, validTo)) {
        modal.info(
          {
            message:
              'Please ensure that the "valid from" date is set before the "valid to" date',
            ok: "Ok",
            cancel: ""
          },
          function() {},
          function() {}
        );

        return false;
      }
      return true;
    }

    function _connectionCopy(
      projectId,
      ual,
      statusNames,
      dcaViewMain,
      aid,
      connection,
      index,
      banner_area_id,
      textbox_id,
      connections){
      var defer = $q.defer();
      var customerId = window.api.customerid;
      connection.valid_from=connection.valid_from_utc;
      connection.valid_to=connection.valid_to_utc;
      let title = connection.title;
      let description = connection.description;
      let validFrom = connection.valid_from;
      let validTo = connection.valid_to;

      if (!_validateConnection(
          projectId,
          ual,
          statusNames,
          dcaViewMain,
          title,
          description,
          validFrom,
          validTo,
          aid,
          connection,
          index,
          banner_area_id,
          textbox_id,
          connections
        )) {
        defer.reject(false);
      }else{
        const onSuccess = (data)=>defer.resolve(data);
        const onFailure = (data)=>defer.reject(data);
        window.api.connectionCopy(connection.parent_id, connection, onSuccess, onFailure);
      }
      return defer.promise;
    }


    function _createNewConnection(
      projectId,
      ual,
      statusNames,
      dcaViewMain,
      aid,
      connection,
      index,
      banner_area_id,
      textbox_id,
      dataToSend,
      connections
    ) {

      var defer = $q.defer();
      var customerId = window.api.customerid;
      let validFrom = connection.valid_from;
      let validTo = connection.valid_to;
      let title = connection.title;
      let description = connection.description;
      if (validFrom === null || validFrom === "") {
        validFrom = "NOW";
      } else {
        validFrom = _addTime(validFrom, false);
      }

      if (validTo === null || validTo === "") {
        validTo = "INF";
      } else {
        validTo = _addTime(validTo, true);
      }
      if (
        !_validateConnection(
          projectId,
          ual,
          statusNames,
          dcaViewMain,
          title,
          description,
          validFrom,
          validTo,
          aid,
          connection,
          index,
          banner_area_id,
          textbox_id,
          connections
        )
      ) {
        defer.reject(false);
        return defer.promise;
      }
      if (!connection.isnew) {
        if (dataToSend !== null) {
          const onSuccess = function(data) {
            data.isNew = false;
            defer.resolve(data);
          };
          const onFailure = function(data) {
            defer.reject(data);
          }
          window.api.connectionPut(connection.id, dataToSend, onSuccess, onFailure);
        } else {
          return true;
        }

        return defer.promise;
      } 

      var dataTags = _swapSemis(connection.tag);
      var newDataToSend;
      if (
        connection.rule_id === null ||
        typeof connection.rule_id === "undefined"
      ) {
        newDataToSend = {
          title: title,
          description: description,
          content_id: aid,
          rule_id: "ALL", 
          valid_from: validFrom,
          valid_to: validTo,
          banner_area_id: banner_area_id,
          tag: dataTags,
          test: connection.test
        };
      } else {
        newDataToSend = {
          title: title,
          description: description,
          content_id: aid,
          rule_id: connection.rule_id,
          valid_from: validFrom,
          valid_to: validTo,
          banner_area_id: banner_area_id,
          tag: dataTags,
          test: connection.test
        };
      }

      if (textbox_id > 0) {
        newDataToSend.content_id = textbox_id;
      }

      if (banner_area_id > 0) {
        newDataToSend.scale = connection.scale;
        newDataToSend.horizontal_align = connection.horizontal_align;
        newDataToSend.vertical_align = connection.vertical_align;
        newDataToSend.url = connection.url;
      }

      if (
        typeof connection.rule_id !== "undefined" &&
        connection.rule_id !== null
      ) {
        newDataToSend.targetlist_id = null;
      }

      if (!banner_area_id) {
        delete newDataToSend.banner_area_id;
      }
      const onSuccess = function(data) {
        data.isNew = true;
        defer.resolve(data);
      }
      const onFailure = function(data) {
        defer.reject(data);
      }
      window.api.connectionPost(newDataToSend, onSuccess, onFailure);
      return defer.promise;
    }

    function _getConnectionIndexById(id, connections, connectionsList) {
      var conn;
      if (typeof connections === "undefined") conn = connectionsList;
      else conn = connections;

      for (var i = 0; i < conn.length; i++) {
        if (conn[i].id === id) return i;
      }
      return false;
    }

    function _convertTags(tags) {
      var tagsArray = [];
      for (var i = 0; i < tags.length; i++) {
        tagsArray.push(tags[i].value);
      }
      return tagsArray.join();
    }

    function _prepareConnectionsListOnLoad(connectionsList, statusNames) {
      for (var i = 0; i < connectionsList.length; i++) {
        if (connectionsList[i].status === statusNames.edit) {
          connectionsList[i].lock = false;
        } else {
          connectionsList[i].lock = true;
        }

        connectionsList[i].valid_to = _prepareLocalDateFormatShort(
          connectionsList[i].valid_to_utc
        );
        connectionsList[i].valid_from = _prepareLocalDateFormatShort(
          connectionsList[i].valid_from_utc
        );

        if (
          db.get("login", "usernameString") !== connectionsList[i].editing_by &&
          connectionsList[i].status === statusNames.edit
        ) {
          connectionsList[i].someoneEdits = true;
        } else {
          connectionsList[i].someoneEdits = false;
        }

        var connectionTag = connectionsList[i].tag;

        var ff = tagsService.filterConnectionFC(connectionTag, true);
        if (ff.length > 0) {
          connectionsList[i].fulfillment = ff;
          connectionsList[i].fulfillment["stringify"] = _convertTags(ff);
        }
        connectionTag = tagsService.filterConnectionFC(
          connectionsList[i].tag,
          false
        );

        connectionsList[i].tag = connectionTag;
        connectionsList[i].tag.stringify = _convertTags(connectionsList[i].tag);
      }
    }

    function _changeConnectionState(statusNames, open, id, con) {
      var lock = con.lock;

      switch (con.status) {
        case statusNames.run:
          con.lock = true;
          break;
        case statusNames.edit:
          con.lock = false;
          break;
        case statusNames.expired:
          con.lock = true;
          break;
        case statusNames.stop:
          con.lock = true;
          break;
        case statusNames.waiting:
          con.lock = true;
          break;
        case statusNames.pending:
          con.lock = true;
          break;
        case statusNames.inProduction:
          con.lock = true;
          break;
        default:
          void 0;
          break;
      }
    }

    function _changeTabState(rootScope, scope, id, con) {
      var evt =document.createEvent('Event');evt.initEvent('RESCALE',true,true);document.dispatchEvent(evt);

      if (scope.showdragdrop) {
        return false;
      }

      rootScope.$emit("LOADON");
      scope.editEnclosure = false;
      scope.editAttachments = false;
      scope.editTargetList = false;
      scope.editBanners = false;
      scope.editTextBoxes = false;

      if (
        (scope.loading[con.id] &&
          con.status === scope.statusNames.edit &&
          con.editing_by === null) ||
        (scope.loading[con.id] &&
          con.status === scope.statusNames.edit &&
          con.editing_by === db.get("login", "usernameString")) ||
        (scope.loading[con.id] &&
          con.editing_by !== null &&
          con.editing_by === db.get("login", "usernameString"))
      ) {
        rootScope.$emit("LOADOFF");
        return false;
      }

      scope.backupConnection(con);
      if (!scope.loading[con.id]) {
        scope.closeAllConnectionsTabs();

        scope.attachmentList = [];
        scope.dcaBannersList = [];
        scope.dcaView = null;
        scope.bannerList = [];
        scope.pagesList = [];
        scope.previewImageContent = {};
        pagesListService.selectPageElement(-1);
        scope.targetFileData = {
          id: "",
          filename: "",
          title: "",
          description: "",
          pages: "",
          filesize: "",
          editable: "",
          created_by: "",
          isNew: "",
          inUse: "",
          modified: "",
          connections: "",
          created_at: ""
        };
        scope.fileData = {
          id: "",
          filename: "",
          title: "",
          description: "",
          pages: "",
          filesize: "",
          editable: "",
          created_by: "",
          isNew: "",
          inUse: "",
          modified: "",
          connections: "",
          created_at: ""
        };
        scope.activeConnection = null;
        scope.activeConnectionObj = null;

        var attach = [];
        var ban = [];
        var target = [];
        var promises = [];

        if (con.banner_area_id === null) {
          promises.push(
            attachmentTargetList
              .loadAttachmentList(scope, "attachment", con.content_id)
              .then(function(resources) {
                attach = resources;
                if (attach.length > 0) {
                  _loadData(rootScope, scope, id, con);
                }
              })
          );
        } else {
          promises.push(
            bannerList
              .loadBannerList(scope, "banner", con.content_id)
              .then(function(resources) {
                ban = resources;
                if (ban.length > 0) {
                  scope.dcaSwitch(con.banner_area_id, true);
                  var findedBannerObj;
                  for (var i = 0; i < ban.length; i++) {
                    if (ban[i].id === con.content_id) findedBannerObj = ban[i];
                  }
                  pagesListService.initDrawings(scope).then(function() {
                    bannerList
                      .loadBannerImage(
                        scope,
                        con.content_id,
                        findedBannerObj,
                        con.id
                      )
                      .then(function() {
                        _loadData(rootScope, scope, id, con);
                      });
                  });
                }
              })
          );
        }

        promises.push(
          attachmentTargetList
            .loadAttachmentList(scope, "targetlist", con.targetlist_id)
            .then(function(resources) {
              target = resources;
            })
        );

        $q.all(promises).then(function() {
          if (scope.activeConnectionObj !== null) {
            scope.selectAttachmentTableElementById(
              scope.activeConnectionObj.content_id
            );
          }

          if (target.length > 0 && (ban.length > 0 || attach.length > 0)) {
            if (attach.length > 0) {
              _loadData(rootScope, scope, id, con);
            }
            if (ban.length > 0) {
              scope.dcaSwitch(con.banner_area_id, true);
              var findedBannerObj = {
                settings: {
                  original_width: 0,
                  original_height: 0
                }
              };
              bannerList
                .loadBannerImage(scope, con.content_id, findedBannerObj, con.id)
                .then(function() {
                  _loadData(rootScope, scope, id, con);
                });
            }
          }

          scope.$emit("LOADOFF");
        });

        scope.loading[con.id] = !scope.loading[con.id];
      } else {
        scope.activeConnection = null;
        scope.activeConnectionObj = null;

        _loadData(rootScope, scope, id, con);
        scope.loading[con.id] = !scope.loading[con.id];
        scope.$emit("LOADOFF");
      }
    }

    function _getConnectionStatusActionName(statusNames, open, con,dca) {
      var hasAccessToResource=userService.hasAccessToResource(resourceAccessService.getAccess(dca));
      switch (con.status) {
        case statusNames.run:
          if (hasAccessToResource) {
            return statusNames.toStop;
          }else{
            return statusNames.stopRequest;
          }
          break;
        case statusNames.edit:
          if (open) return statusNames.edit;
          else return statusNames.edit;
          break;
        case statusNames.expired:
          if (open) return statusNames.expired;
          else return statusNames.expired;
          break;
        case statusNames.stop:
          if (open){
            return statusNames.toEdit;
          }else if(hasAccessToResource){
            return statusNames.toRun;
          }else{
            return statusNames.approve;
          }
          break;
        case statusNames.waiting:
          if (open){
            return statusNames.toEdit;
          }else if(hasAccessToResource){
            return statusNames.toRun;
          }else{
            return statusNames.approve;
          }
          break;
        case statusNames.deleted:
          return statusNames.deleted;
        case statusNames.pending:
          if (hasAccessToResource) {
            return statusNames.toStop;
          }else{
            return statusNames.stopRequest;
          }
          break;
        default:
          void 0;
          break;
      }
    }

    function _prepareSaveData(
      index,
      connection,
      aid,
      banner_area_id,
      rule,
      textbox_id
    ) {
      return rulesService.getRules().then((rules)=>{
      var defer = $q.defer();
      if (typeof aid !== "undefined") connection.content_id = aid;
      if(userService.getUserLevel()==='basic'){
          connection.rule_id=rules.filter((rule)=>rule.name==='ALL')[0].id;
          connection.rule_name='ALL';
      }else{
        if (typeof rule !== "undefined" && rule != null) {
          connection.rule_id = rule.id;
          connection.rule_name = rule.name;
        }
      }

      if (typeof banner_area_id != "undefined" && banner_area_id > 0)
        connection.banner_area_id = banner_area_id;

      if (typeof textbox_id != "undefined" && textbox_id > 0)
        connection.content_id = textbox_id;

      if (
        typeof connection.fulfillment !== "undefined" &&
        connection.fulfillment.length > 0
      ) {
        connection.fulfillment[0].value =
          "fc_" + connection.fulfillment[0].value;
        connection.targetlist_id = null; 
        if (typeof connection.tag !== "undefined") {
          connection.tag.push(connection.fulfillment[0]);
        } else {
          connection.tag = [];
          connection.tag.push(connection.fulfillment[0]);
        }
      }

      defer.resolve(connection);
      return defer.promise;
    });
    }

    function _swapSemis(tags) {
      var str = "";
      if (tags) {
        for (var i = 0; i < tags.length; i++) {
          str += tags[i].value + ":" + tags[i].color + ";";
        }
        str = str.substr(0, str.length - 1);
      }
      return str;
    }

    function _compareData(
      compare,
      connection,
      banner_area_id,
      textbox_id
    ) {
      var defer = $q.defer();
      if (
        compare.length === 0 ||
        (compare.length === 1 && compare[0] === "status") ||
        (compare.length === 2 &&
          compare[0] === "status" &&
          compare[1] === "lock") ||
        (compare.length === 2 &&
          compare[1] === "status" &&
          compare[0] === "lock")
      ) {
        defer.reject();
      } else {
        var wynik = {};
        for (var conElem in connection)
          for (var diff = 0; diff < compare.length; diff++) {
            if (compare[diff] == conElem) {
              switch (conElem) {
                case "title":
                  wynik.title = connection[conElem];
                  break;
                case "tag":
                  wynik.tag = _swapSemis(connection.tag);
                  break;
                case "description":
                  wynik.description = connection[conElem];
                  break;
                case "content_id":
                  wynik.content_id = connection[conElem];
                  break;
                case "targetlist_id":
                  wynik.targetlist_id = connection[conElem];
                  break;
                case "valid_from":
                  if (connection.valid_from) wynik.valid_from = _addTime(connection.valid_from, false);
                  else wynik.valid_from = "NOW";
                  break;
                case "valid_to":
                  if (connection.valid_to) wynik.valid_to = _addTime(connection.valid_to, true);
                  else wynik.valid_to = "INF";
                  break;
                case "banner_area_id":
                  if (banner_area_id > 0) {
                    wynik.banner_area_id = banner_area_id;
                  }
                  break;
                case "url":
                  wynik.url = connection[conElem];
                  break;
                case "textbox_id":
                  if (textbox_id > 0) {
                    wynik.textbox_id = textbox_id;
                  }
                  break;
                case "status": 
                  break;
                case "lock": 
                  break;
                case "test":
                  wynik.test = connection.test;
                  break;
                case "fulfillment":
                  wynik.tag = _swapSemis(connection.tag);
                  break;
                case "rule_id":
                  wynik.rule_id = connection.rule_id;
                  wynik.rule_name = connection.rule_name;
                  break;
                case "rule_name":
                  wynik.rule_id = connection.rule_id;
                  wynik.rule_name = connection.rule_name;
                  break;
                case "scale":
                  wynik.scale = connection.scale;
                  break;
                case "vertical_align":
                  wynik.vertical_align = connection.vertical_align;
                  break;
                case "horizontal_align":
                  wynik.horizontal_align = connection.horizontal_align;
                  break;
                default:
                  void 0;
              } 
            } 
          }

        defer.resolve(wynik);
      }

      return defer.promise;
    }

    function _loadConnectionList(projectId,reload) {
      void 0;
      var deferred = $q.defer();
      var state = subscriptionModel.get("connection");

      if (state.initialized && !reload) {
        deferred.resolve(state.data);
      } else {
        const onSuccess = function(data) {
          subscriptionModel.set("connection", data.connections, true);
          deferred.resolve(data.connections);
        }
        const onFailure = function(data) {
          subscriptionModel.set("connection", [], false);
          deferred.reject();
        }
        window.api.connectionGetAll('with_tag=1', onSuccess, onFailure);
      }
      return deferred.promise;
    }

    function _loadConnectionWithAttachmentList(projectId) {
      var deferred = $q.defer();
      const onSuccess = function(data) {
        deferred.resolve(data);
      };
      const onFailure = function(data) {
        deferred.reject();
      };
      window.api.connectionGetAll('with_attachment_content=1', onSuccess, onFailure);
      return deferred.promise;
    }

    function _loadConnectionWithStatusHistoryAndTags(projectId) {
      var deferred = $q.defer();
      const onSuccess = function(data) {
        deferred.resolve(data);
      };
      const onFailure = function(data) {
        deferred.reject();
      };
      window.api.connectionGetAll('statuses_history=1&with_tag=1', onSuccess, onFailure);
      return deferred.promise;
    }

    function _loadConnectionListByBannerAreaId(id) {
      var deferred = $q.defer();
      return deferred.promise;
    }

    function _sendState(connection, connectionStatus) {
      var defer = $q.defer();
      const onSuccess = function(data) {
        defer.resolve(data);
      };
      const onFailure = function(data, status) {
        defer.reject(data);
      };
      window.api.connectionStatus(connection.id, {status: connectionStatus}, onSuccess, onFailure);
      return defer.promise;
    } 

    function _sendStopState(connection, statusNames) {
      var defer = $q.defer();
      const onSuccess = function(data) {
        defer.resolve(data);
      };
      const onFailure = function(data, status) {
        defer.reject(data);
      };
      const data = {
        status: statusNames.stop,
        message: $("#whyStopped").val()
      }
      window.api.connectionStatus(connection.id, data, onSuccess, onFailure);
      return defer.promise;
    }

    function _changeConnectionStatus(
      showdragdrop,
      statusNames,
      connection,
      loading,
      blockingLayer,
      dca
    ) {
      if (showdragdrop) {
        return false;
      }
      var defer = $q.defer();
      var connectionStatus;
      var actionName = _getConnectionStatusActionName(
        statusNames,
        loading[connection.id],
        connection,
        dca
      );

      if (actionName !== statusNames.toStop) {
        if (actionName === statusNames.toRun) {
          modal.confirm(
            {
              message:
                "Are you sure you want to run this Connection in production?",
              ok: "Yes",
              cancel: "No"
            },
            function() {
              connectionStatus = _changeAction(
                actionName,
                statusNames,
                blockingLayer,
                connection
              );
              defer.resolve(connectionStatus);
            },
            function() {}
          );
        } else {
          connectionStatus = _changeAction(
            actionName,
            statusNames,
            blockingLayer,
            connection
          );
          defer.resolve(connectionStatus);
        }
      } else {
        modal.stop(
          {
            message: "Why are you stopping this connection?",
            ok: "Ok",
            cancel: "Cancel"
          },
          function(data) {
            defer.reject();
          },
          function() {}
        );
      }

      return defer.promise;
    }

    function _deleteConnection(statusNames, con, scope) {
      if (con.lock === true) {
        return $q.when(false);
      }

      if (con.isnew) {
        return $q.when(true);
      }
      var defer = $q.defer();
      if (con.status === statusNames.edit) {
        modal.confirm(
          {
            message:
              "Are you sure you want to delete the " +
              con.title +
              " connection?",
            ok: "Delete",
            cancel: "Cancel"
          },
          function() {
            var customerId = window.api.customerid;
            const onSuccess = function(data) {
              defer.resolve(data);
            };
            const onFailure = function(data) {
              defer.reject();
            }
            window.api.connectionDelete(con.id, onSuccess, onFailure);
          },
          function() {
            void 0
            defer.reject();
          }
        );
      }

      return defer.promise;
    }

    function _sendStopRequest(statusNames, connection) {
      var defer = $q.defer();
      var connectionStatus = statusNames.stop;
      var sendData = {
        status: connectionStatus
      };

      if (
        connection.message === null ||
        typeof connection.message === "undefined" ||
        connection.message.length === 0
      ) {
        sendData.status = "waiting";
      }

      var customerId = window.api.customerid;
      const onSuccess = function(data) {
        data.connectionStatus = sendData.status;
        defer.resolve(data);
      };
      const onFailure = function(data, status) {
        defer.reject(data, status);
      };
      window.api.connectionStatus(connection.id, sendData, onSuccess, onFailure);
      return defer.promise;
    }

    function _cancelConnection(connection,changes) {
      var defer = $q.defer();
      var saveFlag = false;

      if (changes.length > 0) {
				modal.confirm(
					{
						message: "Do you want to discard changes?",
						ok: "Discard",
						cancel: "Cancel",
						save: "Save"
					},
					function() {
						activeConnection = null;
						activeConnectionObj = null;
            _clearBlockLayers(blockingLayer);
             subscriptionModel.update({
          operation:"remove",
          data:{id:-1},
          type:"connection",
          projectid:window.api.projectid,
          who:{ name:"", id:null}
        });
						loading = [];
						defer.resolve();
					},
					function() {},
					function() {
						$timeout(function() {
							angular
								.element(
									document.querySelector("#con_" + connection.id + " .save")
								)
								.click();
						}, 0);
					}
        );
      }

      return defer.promise;
    }


    function _stopRequest(connection){
      var defer = $q.defer();
      _sendStopRequest(statusNames, connection).then(function(data) {
        if (!data.changed) {
          modal.info(
            {
              message: "Could not change status",
              ok: "Ok",
              cancel: ""
            },
            function() {
              defer.resolve(connection)
            },
            function() {
              defer.resolve(connection)
            }
          );
        } else {
          connection.status=data.connectionStatus;
          connection.lock=true;
          defer.resolve(connection)
        }
      });

      return defer.promise;
    }


    function _cancelEditingConnection(connection,connectionBackup,onClose){
      let changes = attachmentTargetList.compareObjects2(connection,connectionBackup,true);
      if(changes.length>0){
        modal.confirm({
          message: "Do you want to discard changes?",
          ok: "Ok",
          cancel: "Cancel",
        },function(){
          _stopRequest(connectionBackup).then((stopResult)=>{
            onClose(stopResult);
          });
        },function() {});
      }else{
        _stopRequest(connection).then((stopResult)=>{
          onClose(stopResult);
        });
      }
    }



    function _selectProject(scope, index, init) {
      var deferred = $q.defer();
      var deffered2 = $q.defer();

      if (index === 0 && db.get("login", "activeProjectIndex") && init) {
        index = db.get("login", "activeProjectIndex");
      }

      if(scope.projectsList[index]){
        db.set("login", "activeProjectIndex", index);
        window.api.setProject(scope.projectsList[index].id);
      }
      scope.activeProject = index;

      if (scope.ual.bannersInMenu) {
        flowService.getFlowsWithBanner(scope.projectsList[index].id).then(
          function(ret) {
            pagesListService.setPagesList(ret.pages);
            scope.dcaSwitchList = pagesListService.getPagesList();
            deffered2.resolve();
          },
          function() {
            void 0;
            deffered2.reject();
          }
        );
      } else {
        deffered2.resolve();
      }

      if (userService.getUser().application_level === "premium") {
        deffered2.promise.then(function() {
          scope.loadBannerList().then(
            function() {
              deferred.resolve(scope.activePartial);
              if (scope.activePartial !== 4) $rootScope.$emit("LOADOFF");
            },
            function() {
              deferred.reject();
              $rootScope.$emit("LOADOFF");
            }
          );
        });
      } else {
        scope.loadConnectionList().then(
          function() {
            deferred.resolve(scope.activePartial);
            $rootScope.$emit("LOADOFF");
          },
          function() {
            deferred.reject();
            $rootScope.$emit("LOADOFF");
          }
        );
        scope.closeAllConnectionsTabs();
      }

      return $q.all([deferred.promise, deffered2.promise]);
    }

    function _setFiltering(filter, setFilters) {
      switch (setFilters) {
        case "default":
          filter.byRunning = true;
          filter.byStopped = true;
          filter.byPending = true;
          filter.byEditing = true;
          filter.byWaiting = true;
          filter.byDeleted = false;
          filter.byExpired = false;
          break;
        case "all":
          filter.byRunning = true;
          filter.byStopped = true;
          filter.byPending = true;
          filter.byWaiting = true;
          filter.byDeleted = true;
          filter.byEditing = true;
          filter.byExpired = true;

          break;
        case "none":
          filter.byRunning = false;
          filter.byStopped = false;
          filter.byPending = false;
          filter.byEditing = false;
          filter.byExpired = false;
          filter.byWaiting = false;
          filter.byDeleted = false;
          break;
        default:
          break;
      }
    }

    function _filterStatuses(open, statusNames, value, filter) {
      if (open) return true;
      if (filter.byRunning && value.status === statusNames.run) return true;
      if (filter.byStopped && value.status === statusNames.stop) return true;
      if (filter.byPending && value.status === statusNames.pending) return true;
      if (filter.byEditing && value.status === statusNames.edit) return true;
      if (filter.byExpired && value.status === statusNames.expired) return true;
      if (filter.byDeleted && value.status === statusNames.deleted) return true;
      if (filter.byWaiting && value.status === statusNames.waiting) return true;
      return false;
    }

    function _connectionPreview(projectId, previewTime, test_mode) {
      var deferred = $q.defer();
      var customerId = window.api.customerid;
      var base_url = api.baseUrl + "/customer/" + customerId + "/project/" + projectId + "/preview";
      let params = "test_mode=" + test_mode;
      if (previewTime) {
        params += "&preview_time=" + previewTime;
      }
      const url = window.api.previewUrl(params)
      previewService.downloadPreview(url).then(function() {
        deferred.resolve();
      });
      return deferred.promise;
    }

    function _regexSearchTags(tags, reg) {
      var pattern = new RegExp(reg + "", "i");
      for (var i = 0; i < tags.length; i++) {
        if (pattern.test(tags[i].value)) return true;
      }
      return false;
    }

    function concatTags(tags) {
      if (typeof tags == "undefined") return "";
      var str = "";
      for (var i = 0; i < tags.length; i++) {
        str += " " + tags[i].value;
      }
      return str;
    }

    function _searchInConnections(value, attachmentSearch) {
      if (value.id < 0) return true;
      if (
        value.status === "editing" &&
        value.edited_by === db.get("login", "usernameString")
      )
        return true;

      if (
        value.status === "editing" &&
        value.edited_by !== db.get("login", "usernameString") &&
        attachmentSearch.length < 0
      )
        return false;

      if (
        new RegExp(
          buildSearchPattern(RegExp.escape(attachmentSearch)),
          "ig"
        ).test(
          value.rule_name +
            " " +
            value.content_title +
            " " +
            value.content_name +
            " " +
            value.content_description +
            " " +
            value.title +
            " " +
            value.description +
            concatTags(value.tag)
        )
      ) {
        return true;
      } else {
        if (_regexSearchTags(value.tag, attachmentSearch)) {
          return true;
        }
      }

      return false;

      function buildSearchPattern(inputSearchText) {
        var patternText = "";
        inputSearchText.split(/\s/g).forEach(function preparePattern(value) {
          patternText += "(?=.*" + value + ".*)";
        });
        patternText += ".+";
        return patternText;
      }
    }

    function _changeTestStatus(con) {
      var customerId = window.api.customerid;
      var projectId = window.api.projectid;
      var deffered = $q.defer();
      if (con.id !== -1) {
        const data = {
          test: con.test
        };
        const onSuccess = function(data) {
          deffered.resolve(data);
        };
        const onFailure = function(data) {
          $rootScope.$emit("LOADOFF");
          deffered.reject(data);
        };
        window.api.connectionTest(con.id, data, onSuccess, onFailure);
      }

      return deffered.promise;
    }

    function _loadBannerImage(dca,url,connection,name){

     _initCanvas({
        width:270,
        height:379,
        ID:connection.id,
        DCA:dca,
        canvasName:name
      }).then((canvas)=>{

      if(dca && dca.shape) {
        dca.shape.background_color='';
        _redrawDCA(canvas, dca.shape)

      new fabric.Image.fromURL(url, function(oImg) {
        oImg.hasBorders = false;
        oImg.hasControls = false;

          var scaleX,scaleY,width,height,left,top;
          left=(dca.shape.x+1) * 1.274;
          top=(dca.shape.y+1) * 1.275;

        if(!bannerList.imgBannerInstance){
          scaleX=1.274;
          scaleY=1.275;
          width=dca.shape.width-1;
          height=dca.shape.height-1;

        }else{
          width=bannerList.imgBannerInstance.width-1;
          height=bannerList.imgBannerInstance.height-1;
          scaleX=bannerList.imgBannerInstance.scaleX;
          scaleY=bannerList.imgBannerInstance.scaleY;
        }

        oImg.set({
          left: left,
          top: top,
          height: height,
          width: width,
          scaleX: scaleX,
          scaleY:scaleY
        });

        canvas.add(oImg);
      });
    }
  });
    }



    function _loadSVGFabric(element, dca, selectedImage, connection,bgColor,name) {

      const DOMURL = window.URL || window.webkitURL || window;
      var url='';

      const svg=_getSVG(element,dca);
      url = DOMURL.createObjectURL(svg);

    _initCanvas({width:270,height:379,ID:connection.id,DCA:dca,canvasName:name}).then((canvas)=>{

      if (utilityService.checkBrowserIsIE()) {
        utilityService.disableSVG(canvas, 1.275);
        return;
      }

      var tmpScaleY;

      if (utilityService.checkBrowserIsChrome()) {
        tmpScaleY = 1.595
      } else {
        tmpScaleY = 1.3
      }

      dca.shape.background_color=connection.background_color;
     _redrawDCA(canvas, Object.assign(dca.shape,{background_color:bgColor || connection.background_color}));


      new fabric.Image.fromURL(url, function(oImg) {
        oImg.hasBorders = false;
        oImg.hasControls = false;
        oImg.set({
          left: dca.shape.x * 1.274,
          top: dca.shape.y * 1.275,
          height: dca.shape.height,
          width: Math.ceil(dca.shape.width-1),
          scaleX: 1.275,
          scaleY: tmpScaleY
        });
        selectedImage = oImg;
        canvas.add(selectedImage);
      });

      canvas.renderAll();
    })
    }

    function _initCanvas(config){
      var deffered = $q.defer();
      if(config.canvasName){
        var canvas =new fabric.StaticCanvas(`${config.canvasName}_${config.ID}`);
      }else{
        angular.element("#targetPreviewCanvas").empty();
        angular.element("#targetPreviewCanvas").append(`<canvas id="textBoxCanvas_${config.ID}"></canvas>`)
        var canvas =new fabric.StaticCanvas("textBoxCanvas_" + config.ID);
      }

      canvas.setWidth(config.width);
      canvas.setHeight(config.height);
      _loadCanvasBackground(canvas,config.DCA);
      deffered.resolve(canvas);
      return  deffered.promise
    }



    function _loadCanvasBackground(canvas,dca){
      var resource=pagesListService.getPagesList().filter((page)=>dca.id===page.bid)[0];
      if(resource && angular.isDefined(resource.bgid)){
        pagesListService.loadImage({},resource.bgid,'background').then((url)=>{
          canvas.setBackgroundImage(url, canvas.renderAll.bind(canvas),{
            width: canvas.width,
            height: canvas.height,
          });
        })}
    }

    function _getSVG(element,dca){
      var textHtml = "";
      var parsedElement="";
      if(element){
        var parsedElement = element.replace(/contenteditable="false"/g,'contenteditable="true"');
      }
      var filter = new CKEDITOR.htmlParser.filter({
        elements: {
          variable: function(el) {
            if (el.attributes.test) {
              el.setHtml(
                el
                  .getHtml()
                  .replace(
                    "{" + el.attributes.original + "}",
                    el.attributes.test
                  )
              );
            }
          }
        }
      });

        var fragment = CKEDITOR.htmlParser.fragment.fromHtml(parsedElement),
        writer = new CKEDITOR.htmlParser.basicWriter();

        filter.applyTo(fragment);
        fragment.writeHtml(writer);
        textHtml = writer.getHtml();
        var parsedBody = $(document.createElement("body"));
        var style =
          "width:" + (dca.shape.width || 210) * 3.8 + "px;font-size:initial;";
        parsedBody.attr("style", style);
        parsedBody.append(textHtml);
        parsedBody.appendTo(document.body);
        var el = parsedBody[0];

        var width = (dca.shape.width || 210) * 3.8;
        var height = (dca.shape.height || 297) * 4.45;
        var html = new XMLSerializer().serializeToString(el);
        var tmpHeight = height + 50;
        var body =
          '<svg xmlns="http://www.w3.org/2000/svg" width="' +
          width +
          'px" height="' +
          tmpHeight +
          'px">' +
          '<foreignObject width="100%" height="100%">' +
          html +
          "</foreignObject>" +
          "</svg>";

        parsedBody.remove();

        var svg = new Blob([body], {
          type: "image/svg+xml"
        });

        return svg;
    }

    function _redrawDCA(canvas, shape) {
      var _scale = 1.275;
      var dca = new fabric.Rect({
        left: shape.x * _scale,
        top: shape.y * _scale,
        width: shape.width,
        height: shape.height,
        scaleX: _scale,
        scaleY: _scale,
        strokeWidth: 1,
        fill: shape.background_color,
        stroke: "rgba(236,102,8,1.0)"
      });

      canvas.add(dca);
    }

    function _sendEmailToApprovers(conn,status){
      var deferred = $q.defer();
      const onSuccess = function(data) {
        var user=userService.getUser();
        var flow=window.api.projectid;
        if(status==='run'){
          growlHandlingService.success(`User '${user.firstname} ${user.lastname}' requested for running Connection '${conn.title}' in Flow '${flow}'`);
        }else{
          growlHandlingService.success(`User '${user.firstname} ${user.lastname}' requested for stopping Connection '${conn.title}' in Flow '${flow}'`);
        }
        deferred.resolve(data);
      }
      const onFailure = function(data) {
        deferred.reject();
      }
      window.api.connectionApprove(conn.id, onSuccess, onFailure);
      return deferred.promise;
    }

    function _initController(scope, type) {
       resourceAccessService.loadAccessList(window.api.customerid);
      scope._planType = type;


      switch (type) {
        default:
          scope.projectsList = [];
          scope.connectionsList = [];
          scope.attachmentList = null;
          scope.activeProject = window.api.projectid;
          scope.activeConnection = null;
          scope.activeConnectionObj = null;
          scope.selectedConvertions = [];
          scope.monthsParser = [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12"
          ];
          scope.statusNames = {
            run: "running",
            edit: "editing",
            stop: "stopped",
            pending: "pending",
            expired: "expired",
            waiting: "waiting",
            toEdit: "edit",
            toRun: "run",
            toStop: "stop",
            inProduction: "production",
            deleted: "deleted",
            approve:"request run",
            stopRequest:"request stop"
          };
          scope.filter = {
            byRunning: true,
            byPending: true,
            byStopped: true,
            byEditing: true,
            byExpired: false,
            byDeleted: false,
            byWaiting: true
          };
          scope.showLog = false;
          scope.frontElementId = null;
          scope.dcaSwitchList = [];
          scope.showdragdrop = false;
          scope.activePartial = 0;
          scope.connectionsBackup = [];
          scope.dragdropswitch = false;
          scope.blockingLayer = false;
          scope.ual = null;
          scope.attachementSearch = "";
          scope.editEnclosure = false;
          scope.editAttachments = false;
          scope.editBanners = false;
          scope.editTextBoxes = false;
          scope.editTargetList = false;

          scope.arrayFind = utilityService.arrayFind;
          scope.checkIfNameExists = utilityService.checkIfNameExists;
          scope.showOptions = utilityService.showOptions;
          scope.attachmentLimit = utilityService.attachmentLimit;
          scope.getDateIncremented = utilityService.getDateIncremented;
          scope.getTimes = utilityService.getTimes;
          scope.daysDiff = utilityService.daysDiff;
          scope.scrollTo = utilityService.scrollTo;
          scope.isFilterDefault = utilityService.isFilterDefault;
      }
    }


    function _testAllConnections(info,testFlag){
      const defer = $q.defer();
      const customerId = window.api.customerid;
      const flowId=window.api.projectid;
      var contentMsg;
      var resourceType;
      var requestPayload={test:testFlag};

      if(!info){
        info={name: "Attachments", id: 0};
      }

      if(info.id>0){
        resourceType='banner'
        contentMsg=`Page ${info.page_name} - ${info.name}`
        requestPayload=angular.extend(requestPayload,{banner_area_id:info.id});
      }else{
        contentMsg=info.name;
        resourceType=info.name.substr(0,info.name.length-1).toLowerCase();
      }
      const onSuccess = function(data) {
        if(testFlag){
          growlHandlingService.success(connectionDict.testStatusChange(contentMsg).acitiveTestStatusAll());
        }else{
          growlHandlingService.success(connectionDict.testStatusChange(contentMsg).inactiveTestStatusAll());
        }
        defer.resolve(data)
      };
      const onFailure = function(data) {
        growlHandlingService.error(connectionDict.CONNECTIONS_STATUS_CHANGE_ERROR);
        defer.reject(data);
      }
      window.api.connectionTestAll(resourceType, requestPayload, onSuccess, onFailure);
      return defer.promise;
    }

  }
]);
