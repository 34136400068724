ocCommonProviders.directive("tagsDirective", [
  "tagsService",
  "$compile",
  "modal",
  "db",
  "$rootScope",
  function(tagsService, $compile, modal, db, $rootScope) {
    "use strict";
    return {
      restrict: "C",
      scope: {
        onColorChange: "&"
      },
      link: function(scope, element, attrs) {
        var currentElement =
          scope.$parent.attachementData ||
          scope.$parent.selectedElement ||
          scope.access;
        var tagsList = [];
        function rescalebox() {
          if (
            $(element)
              .parent()
              .find(".bootstrap-tagsinput")
              .height() > 77
          ) {
            $(element)
              .parent()
              .parent()
              .parent()
              .parent()
              .parent()
              .css(
                "min-height",
                $(element)
                  .parent()
                  .find(".bootstrap-tagsinput")
                  .height() + 50
              );
            $(".toFrontEdit").css(
              "min-height",
              $(element)
                .parent()
                .find(".bootstrap-tagsinput")
                .height() + 56
            );
          }
        }

        document.addEventListener("RESCALE", function(e) {
          rescalebox();
        });

        scope.$on("$destroy", function() {
          $(".toFrontEdit ").css("min-height", 0);
          $(".toFrontEdit").attr("style", "height: auto!important");
          $(".highlightTable").css({ "min-height": "36px" });
          $(".basicInfo").css({ "min-height": "36px" });
          $(".highlightTable").css({ "background-color": "#e6ebf3" });
        });

        tagsService.get(function(data) {
          tagsList = data;
          init(data);
        });

        function findColor(name) {
          for (var i = 0; i < tagsList.length; i++) {
            if (tagsList[i].value == name) {
              return tagsList[i].color;
            }
          }
        }

        function addDot(elem, thisColor) {
          var dot = $('<span class="dotColor">&nbsp;</span>');
          dot.colorpicker({
            container: false,
            component: $(elem),
            format: "rgba",
            color: thisColor || "#fff",
            template:
              '<div class="colorpicker dropdown-menu">' +
              '<div class="colorpicker-saturation"><i><b></b></i></div>' +
              '<div class="colorpicker-hue"><i></i></div>' +
              '<div class="colorpicker-alpha"><i></i></div>' +
              '<div class="colorpicker-color"><div /></div>' +
              '<div class="colorpicker-selectors"></div>' +
              "<div class=\"colorpicker-apply btn\" onclick=\"$('.dotColor').colorpicker('hide');\">Apply</div>" +
              "</div>"
          });
          dot.on("changeColor", function(event) {
            scope.onColorChange({ color: $(this).colorpicker("getValue") });
            $(elem).css({
              background: $(this).colorpicker("getValue")
            });
            for (var i = 0; i < currentElement.tag.length; i++) {
              var match = $(elem).text() + "";
              var patt = new RegExp(
                "^\\s{1}" + currentElement.tag[i].value + "$"
              );
              var res = patt.test(match);

              if (res) {
                $(elem).css({
                  color: tagsService.fixTextcontrast(
                    $(this).colorpicker("getValue")
                  )
                });
                const colorValue = $(this).colorpicker("getValue");
                currentElement.tag[i].color = colorValue;

                if (scope.$parent.connectionsList) {
                  const connection = scope.$parent.connectionsList.find(
                    item => item.id === currentElement.id
                  );
                  if (connection) {
                    const newTagArray = [...connection.tag];
                    newTagArray["stringify"] = connection.tag["stringify"];
                    newTagArray[i].color = colorValue;
                    connection.tag = newTagArray;
                  }
                }
              }
            }
          });
          return dot;
        }

        function init(tags) {
          if (db.get("login", "enclosure_enabled"))
            tags = tagsService.filterConnectionFC(tags, false);

          var bh = new Bloodhound({
            local: tagsService.convertTagsToArray(tags),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            datumTokenizer: Bloodhound.tokenizers.whitespace
          });

          $(element).tagsinput({
            maxChars: 16,
            typeaheadjs: {
              name: "tags",
              source: bh.ttAdapter()
            }
          });
          $(element)
            .parent()
            .find(".bootstrap-tagsinput span.tag")
            .each(function(index, elem) {
              var thisColor;
              for (var i = 0; i < currentElement.tag.length; i++) {
                if (currentElement.tag[i].value == $(elem).text()) {
                  if (currentElement.tag[i].color !== null) {
                    thisColor = currentElement.tag[i].color;
                    $(elem).css({
                      background: currentElement.tag[i].color
                    });
                    $(elem).css({
                      color: tagsService.fixTextcontrast(
                        currentElement.tag[i].color
                      )
                    });
                  } else {
                    if (findColor($(elem).text()) === null)
                      thisColor = "rgba(159,201,235,1)";
                    else thisColor = findColor($(elem).text());
                    $(elem).css({
                      background: thisColor
                    });
                    $(elem).css({
                      color: tagsService.fixTextcontrast(thisColor)
                    });
                  }
                  break;
                }
              }

              $(elem).prepend(addDot(elem, thisColor));
            });

          $(element).on("itemAdded", function(event) {
            $rootScope.$emit("ITEM_ADDED");
            if (angular.isUndefined(event.item)) {
            }

            if (
              /^[\w \- ÀÈÌÒÙ àèìòù ÁÉÍÓÚ ĄĆĘŁŃÓŚŻŹ ąćęłńóśżź Ý áéíóúý ÂÊÎÔÛ âêîôû ÃÑÕ ãñõ ÄËÏÖÜŸ äëïöüŸ ¡¿çÇŒœ ßØøÅå ÆæÞþÐð]{3,16}$/.test(
                event.item
              )
            ) {
              rescalebox();
              if (!currentElement) {
                currentElement = { tag: [] };
              }
              if (
                !currentElement.tag ||
                currentElement.tag.length === 0 ||
                typeof currentElement.tag.length == "undefined"
              ) {
                currentElement.tag = [];
                var stringi = currentElement.tag;
                currentElement.tag["stringify"] = stringi.stringify;
              }

              var foundColor = findColor(event.item);
              if (findColor(event.item) === null)
                foundColor = "rgba(159,201,235,1)";
              else foundColor = findColor(event.item);

              const newTag = {
                value: event.item,
                color: foundColor
              };

              if (scope.$parent.connectionsList) {
                const connection = scope.$parent.connectionsList.find(
                  item => item.id === currentElement.id
                );
                if (connection) {
                  const newTagArray = [...connection.tag, newTag];
                  newTagArray["stringify"] = connection.tag["stringify"];
                  connection.tag = newTagArray;
                }
              }

              currentElement.tag.push(newTag);

              $(event.target)
                .parent()
                .find(".bootstrap-tagsinput span.tag")
                .each(function(index, elem) {
                  if ($(elem).text() == event.item) {
                    $(elem).css({
                      background: foundColor
                    });
                    $(elem).prepend(addDot(elem, foundColor));
                  }
                });
            } else {
              modal.info(
                {
                  message:
                    "The tag name can contain only alphanumeric characters, white space, dash and have length between 3 and 16 characters, please try again",
                  ok: "Ok",
                  cancel: ""
                },
                function() {
                  $(element).tagsinput("remove", event.item);
                  $(element).focus();
                },
                function() {}
              );
            }
          });

          $(element).on("itemRemoved", function(event) {
            $rootScope.$emit("ITEM_REMOVED");
            for (var i = 0; i < currentElement.tag.length; i++) {
              if (
                currentElement.tag[i].value == event.item &&
                currentElement.tag[i].valueOriginal === undefined
              ) {
                if (scope.$parent.connectionsList) {
                  const connection = scope.$parent.connectionsList.find(
                    item => item.id === currentElement.id
                  );
                  if (connection) {
                    const newTagArray = connection.tag.filter(
                      tag => tag.value !== event.item
                    );
                    newTagArray["stringify"] = connection.tag["stringify"];
                    connection.tag = newTagArray;
                  }
                }

                currentElement.tag.splice(i, 1);
                break;
              }
            }
            rescalebox();
          });
          rescalebox();
        } 
      }
    };
  }
]);
